var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { DataTableContext } from "@/components/ui/data_table_context";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "@/components/ui/dropdown_menu";
import { PlayIcon } from "@heroicons/react/20/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { createAndTriggerBackfillJob } from "../../common/endpoints";
import { getConvertedJsonQuery } from "../../common/query_builder/json_query_converter";
export const BackfillColumnDropdown = ({ tableRows, selectedRowIds, brickId, brickName, }) => {
    const dataTableContextValue = useContext(DataTableContext);
    const queryClient = useQueryClient();
    const createAndTriggerBackfillMutation = useMutation({
        mutationFn: ({ runType, }) => __awaiter(void 0, void 0, void 0, function* () {
            if (!dataTableContextValue) {
                throw new Error("DataTableContext is not set");
            }
            const { filterQuery, tableObjectName } = dataTableContextValue;
            if (!brickId || !brickName) {
                console.error(`Backfill: brickId or brickName is undefined for column ${brickId}`);
                return undefined;
            }
            const jobName = `${tableObjectName.startsWith("Call") ? "Call" : tableObjectName} - ${brickName}`;
            const getCreateAndTriggerBackfillJobParams = () => {
                if (runType === "RUN_FIRST_10") {
                    return {
                        filterQuery: null,
                        objectIds: tableRows.slice(0, 10).map((row) => row.id),
                    };
                }
                if (runType === "RUN_SELECTED") {
                    return {
                        filterQuery: null,
                        objectIds: selectedRowIds,
                    };
                }
                if (filterQuery === undefined) {
                    console.error(`Backfill: filterQuery is undefined for column ${brickId}`);
                    return { filterQuery: null, objectIds: null };
                }
                return {
                    filterQuery: getConvertedJsonQuery(filterQuery),
                    objectIds: null,
                };
            };
            const { filterQuery: finalFilterQuery, objectIds } = getCreateAndTriggerBackfillJobParams();
            return createAndTriggerBackfillJob(jobName, "BRICK_BACKFILL", tableObjectName, finalFilterQuery, objectIds, brickId);
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["backfillJobs"],
            });
        },
    });
    const handleRun = (runType) => {
        createAndTriggerBackfillMutation.mutate({ runType });
    };
    return (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { children: _jsxs(Button, Object.assign({ variant: "tableAction", size: "sm", className: "rounded-md" }, { children: [_jsx(PlayIcon, { className: "w-4 h-4 shrink-0" }), "Run"] })) }), _jsxs(DropdownMenuContent, Object.assign({ align: "end", className: "w-52" }, { children: [_jsx(DropdownMenuItem, Object.assign({ onClick: () => handleRun("RUN_ALL") }, { children: _jsxs("div", Object.assign({ className: "flex w-full gap-2 justify-between self-stretch" }, { children: [_jsx("span", { children: "Run" }), dataTableContextValue && (_jsx(Badge, { children: dataTableContextValue.totalRowCount }))] })) })), _jsx(DropdownMenuItem, Object.assign({ onClick: () => handleRun("RUN_FIRST_10") }, { children: "Run First 10 Rows" })), selectedRowIds.length > 0 && (_jsx(DropdownMenuItem, Object.assign({ onClick: () => handleRun("RUN_SELECTED") }, { children: _jsxs("div", Object.assign({ className: "flex w-full gap-2 justify-between self-stretch" }, { children: [_jsx("span", { children: "Run selected" }), _jsx(Badge, { children: selectedRowIds.length })] })) })))] }))] }));
};
