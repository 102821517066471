var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import useFeatureFlagEnabled from "@/use_feature_flag_enabled";
import useParagonAuth from "@/use_paragon_auth";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useContext, useMemo, useState } from "react";
import { UserContext } from "../App";
import { LOCAL_URL, STAGING_URL, integrationListingContent } from "./constants";
import IntegrationModal from "./integration_modal";
import IntegrationTile from "./integration_tile";
import { SlackPreCallDeliveryTarget } from "./slack_pre_call_delivery_target";
import useCalendarIntegrations from "./use_calendar_integrations";
import useGmailIntegrations from "./use_gmail_integrations";
import useGongIntegrations from "./use_gong_integrations";
const USER_INTEGRATIONS = ["Google Calendar", "Gmail"];
const Integrations = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const gongSyncEnabled = useFeatureFlagEnabled("GONG_SYNC");
    const { integrations: calendarIntegrationData, loaded: calendarDataLoaded } = useCalendarIntegrations();
    const { integrations: gmailIntegrationData, loaded: gmailDataLoaded } = useGmailIntegrations();
    const { user, paragon, paragonUserLoaded } = useParagonAuth();
    const { integrations: gongIntegrationData, loaded: gongDataLoaded } = useGongIntegrations();
    const isStagingOrLocal = window.location.href.startsWith(STAGING_URL) ||
        window.location.href.startsWith(LOCAL_URL);
    const getConnectionStatusForParagonIntegration = (integration) => {
        if (!user || !user.authenticated) {
            return "Not connected";
        }
        const { enabled, credentialStatus } = user.integrations[integration.type] || {};
        if (!enabled) {
            return "Not connected";
        }
        if (credentialStatus !== "VALID") {
            return "Needs reconnection";
        }
        return "Connected";
    };
    const integrations = [
        ...calendarIntegrationData,
        ...gmailIntegrationData,
        ...(gongSyncEnabled ? gongIntegrationData : []),
        ...paragon.getIntegrationMetadata().map((integration) => ({
            name: integration.name,
            icon: integration.icon,
            handleConnect: () => __awaiter(void 0, void 0, void 0, function* () {
                if (integration.name.toLowerCase() === "zoom" && isStagingOrLocal) {
                    if (window.location.href.startsWith(LOCAL_URL)) {
                        yield paragon.installIntegration(integration.type, {
                            overrideRedirectUrl: `${LOCAL_URL}/oauth_redirect`,
                        });
                    }
                    else {
                        yield paragon.installIntegration(integration.type, {
                            overrideRedirectUrl: `${STAGING_URL}/oauth_redirect`,
                        });
                    }
                }
                else {
                    // DO not override for prod
                    yield paragon.installIntegration(integration.type, {});
                }
            }),
            handleDisconnect: () => __awaiter(void 0, void 0, void 0, function* () {
                yield paragon.uninstallIntegration(integration.type, {});
            }),
            status: getConnectionStatusForParagonIntegration(integration),
            listingContent: integrationListingContent[integration.name],
        })),
    ];
    const anyLoading = !calendarDataLoaded ||
        !gmailDataLoaded ||
        !paragonUserLoaded ||
        !gongDataLoaded;
    const { userIsTenantAdmin } = useContext(UserContext);
    const [selectedIntegrationName, setSelectedIntegrationName] = useState();
    const selectedIntegration = useMemo(() => {
        return integrations.find((integration) => integration.name === selectedIntegrationName);
    }, [selectedIntegrationName, integrations]);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "mt-4 ml-8 text-lg font-black leading-7" }, { children: "Connected Integrations" })), _jsx("div", Object.assign({ className: "grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" }, { children: integrations.map((integration) => {
                    if (USER_INTEGRATIONS.indexOf(integration.name) === -1 &&
                        !userIsTenantAdmin) {
                        return null;
                    }
                    return (_jsx(IntegrationTile, { name: integration.name, icon: integration.icon, status: integration.status, handleClick: () => setSelectedIntegrationName(integration.name) }, integration.name));
                }) })), anyLoading && (_jsxs("div", Object.assign({ className: "flex justify-center mt-8" }, { children: [_jsx(ArrowPathIcon, { className: "h-5 w-5 animate-spin" }), _jsx("span", Object.assign({ className: "ml-2" }, { children: "Loading integrations" }))] }))), selectedIntegration && (_jsx(IntegrationModal, Object.assign({ isModalOpen: !!selectedIntegration, closeModal: () => setSelectedIntegrationName(undefined), name: selectedIntegration.name, icon: selectedIntegration.icon, connectionStatus: selectedIntegration.status, connectIntegration: selectedIntegration.handleConnect, disconnectIntegration: selectedIntegration.handleDisconnect, shortDescription: (_a = selectedIntegration.listingContent) === null || _a === void 0 ? void 0 : _a.shortDescription, longDescription: (_b = selectedIntegration.listingContent) === null || _b === void 0 ? void 0 : _b.longDescription, capabilities: (_c = selectedIntegration.listingContent) === null || _c === void 0 ? void 0 : _c.capabilities, connectButtonLabel: (_d = selectedIntegration.listingContent) === null || _d === void 0 ? void 0 : _d.connectButtonLabel, connectButtonLogo: (_e = selectedIntegration.listingContent) === null || _e === void 0 ? void 0 : _e.connectButtonLogo, connectButtonDisabled: (selectedIntegration.name === "Salesforce" &&
                    ((_f = integrations.find((i) => i.name === "HubSpot")) === null || _f === void 0 ? void 0 : _f.status) ===
                        "Connected") ||
                    (selectedIntegration.name === "HubSpot" &&
                        ((_g = integrations.find((i) => i.name === "Salesforce")) === null || _g === void 0 ? void 0 : _g.status) ===
                            "Connected") }, { children: selectedIntegration.name === "Slack" && (_jsx(SlackPreCallDeliveryTarget, {})) })))] }));
};
export default Integrations;
