var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// Page for users to opt in/out of experiments and under development features.
import { useEffect, useState } from "react";
import { getHeapStats, getThreadStats } from "./common/endpoints";
export function DiagnosticStatsPage(props) {
    const [stats, setStats] = useState("");
    const updateState = () => __awaiter(this, void 0, void 0, function* () {
        switch (props.method) {
            case "heapz":
                setStats(yield getHeapStats());
                return;
            case "threadz":
                setStats(yield getThreadStats());
                return;
        }
    });
    useEffect(() => {
        updateState();
    }, []);
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20" }, { children: _jsx("code", Object.assign({ className: "whitespace-pre text-sm font-medium text-gray-900" }, { children: stats })) })) }));
}
