import { useCallback, useMemo } from "react";
import { accountsFetcher, contactsFetcher, leadsFetcher, } from "../calls_table/calls_table_fetchers";
import { WiserBackendQueryConverter } from "../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../common/query_builder/search_params_utils";
import { updateSearchParams } from "../common/table_utils";
import { templateFetcherWithoutNone } from "../teams/team.helpers";
import { CrmObjectTypeEnum, } from "../types";
import { departmentsForFilter, teamsForFilter, tenantUsersForFilter, } from "./object_fetchers";
export function useFilterConfig(FIELDS) {
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            ["account_id", accountsFetcher],
            ["contact_id", contactsFetcher],
            ["default_internal_meeting_template_id", templateFetcherWithoutNone],
            ["default_external_meeting_template_id", templateFetcherWithoutNone],
            ["owner_id", tenantUsersForFilter],
            ["manager_ids", tenantUsersForFilter],
            ["team_ids", teamsForFilter],
            ["department_ids", departmentsForFilter],
            ["team_id", teamsForFilter],
            ["department_id", departmentsForFilter],
            ["lead_id", leadsFetcher],
        ]);
    }, []);
    const filterConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxSubConditions: 5,
            referenceObjectFetcherMap,
        };
    }, [FIELDS, referenceObjectFetcherMap]);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    return { filterConfig, wiserBackendQueryConverter };
}
export function useInitialFilter(searchParams, wiserBackendQueryConverter) {
    return useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams, wiserBackendQueryConverter]);
}
export function useFilterChange(navigate, searchParams) {
    return useCallback((updatedFilter) => {
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        navigate(`?${updateSearchParams([
            {
                key: "filter",
                value: filterParam,
            },
        ], searchParams)}`);
    }, [navigate, searchParams]);
}
export function useBuildFieldsForFilter({ crmFields, fields, table, structuredOutputColumns, brickNames, }) {
    return useMemo(() => {
        var _a;
        const buildFields = (crmField, objectType) => {
            const field = {
                id: `${objectType.toLowerCase()}_fields__z${crmField.id}`,
                name: crmField.name.startsWith(objectType)
                    ? crmField.name
                    : `${objectType}: ${crmField.name}`,
            };
            switch (crmField.content_type_definition.type) {
                case "text":
                    field.type = "string";
                    break;
                case "number":
                case "boolean":
                    field.type = crmField.content_type_definition.type;
                    break;
                case "date":
                    field.type = "datestring";
                    field.options = [
                        "Today",
                        "Yesterday",
                        "Tomorrow",
                        "Next week",
                        "Last week",
                        "Last 2 weeks",
                        "Last month",
                        "Last year",
                        "This month",
                        "This quarter",
                        "Last quarter",
                    ];
                    break;
                case "single_select":
                case "multi_select":
                    field.type = "select";
                    field.options = crmField.content_type_definition.restrictions.picklist_values;
                    break;
                default:
                    return null;
            }
            return field;
        };
        const getFieldTypeForStructuredOutput = (fieldName, type, enums) => {
            let prefix = "call_brick_structured_outputs__";
            if (table === CrmObjectTypeEnum.ACCOUNT ||
                table === CrmObjectTypeEnum.OPPORTUNITY) {
                prefix = "account_brick_structured_outputs__";
            }
            else if (table === CrmObjectTypeEnum.CONTACT) {
                prefix = "contact_brick_structured_outputs__";
            }
            const field = {
                id: `${prefix}${fieldName}`,
                name: fieldName,
            };
            if (type === "array" && enums && enums.length > 0) {
                field.options = enums;
                field.type = "multi_select";
            }
            else if (type === "string" && enums && enums.length > 0) {
                field.options = enums;
                field.type = "select";
            }
            else if (type === "integer") {
                field.type = "number";
            }
            else if (type === "boolean" || type === "string" || type === "number") {
                // These are the only other possible types
                field.type = type;
            }
            return field;
        };
        const brickNameFields = (_a = brickNames === null || brickNames === void 0 ? void 0 : brickNames.map((brickName) => {
            return {
                id: `playbook_data__${brickName}`,
                name: brickName,
                type: "string",
                options: [],
            };
        })) !== null && _a !== void 0 ? _a : [];
        const accountFields = [];
        const opportunityFields = [];
        const contactFields = [];
        const leadFields = [];
        const structuredOutputFields = structuredOutputColumns.map((column) => {
            const columnName = Object.keys(column)[0];
            const fieldDef = column[columnName];
            const field = getFieldTypeForStructuredOutput(columnName, fieldDef.type, fieldDef.enum);
            return field;
        });
        crmFields.forEach((crmField) => {
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.ACCOUNT)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.ACCOUNT);
                if (field)
                    accountFields.push(field);
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.OPPORTUNITY)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.OPPORTUNITY);
                if (field)
                    opportunityFields.push(field);
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.CONTACT)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.CONTACT);
                if (field)
                    contactFields.push(field);
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.LEAD)) {
                const field = buildFields(crmField, CrmObjectTypeEnum.LEAD);
                if (field)
                    leadFields.push(field);
            }
        });
        switch (table) {
            case "calls":
                return [
                    ...fields,
                    ...accountFields,
                    ...opportunityFields,
                    ...leadFields,
                    ...structuredOutputFields,
                    ...brickNameFields,
                ];
            case CrmObjectTypeEnum.ACCOUNT:
                return [...fields, ...accountFields, ...structuredOutputFields];
            case CrmObjectTypeEnum.OPPORTUNITY:
                return [
                    ...fields,
                    ...opportunityFields,
                    ...accountFields,
                    ...structuredOutputFields,
                ];
            default:
                return [
                    ...fields,
                    ...contactFields,
                    ...accountFields,
                    ...structuredOutputFields,
                ];
        }
    }, [crmFields, fields, structuredOutputColumns, brickNames]);
}
