var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { ArrowPathIcon, EllipsisVerticalIcon, PencilIcon, TrashIcon, } from "@heroicons/react/24/outline";
import { PopoverContent } from "@radix-ui/react-popover";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChevronIcon from "../common/chevron_icon";
import { executePreCallPrepBlock, getPreCallPrepOutput, updatePreCallPrepOutput, } from "../common/endpoints";
import DragIndicatorIcon from "../icons/drag_indicator_icon";
import CallPrepOutput from "./call_prep_output";
const CallPrepBlock = ({ callPrepBlock, dragHandleProps, onDelete, callId, defaultOpen = false, templateId, regeneratingAllBlocks: refreshingAllBlocks, }) => {
    const [callPrepOutput, setCallPrepOutput] = useState();
    const [isExecuting, setIsExecuting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const navigate = useNavigate();
    const executeBlock = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsExecuting(true);
        const newOutput = yield executePreCallPrepBlock(callId, callPrepBlock.id);
        setCallPrepOutput(newOutput);
        setIsExecuting(false);
    });
    useEffect(() => {
        // open the block to make the loading spinner & output visible
        if (isExecuting || refreshingAllBlocks) {
            setIsOpen(true);
        }
    }, [isExecuting, refreshingAllBlocks]);
    useEffect(() => {
        getPreCallPrepOutput(callId, callPrepBlock.id).then((output) => {
            setCallPrepOutput(output);
        });
    }, [callPrepBlock, callId]);
    const noInformationAvailable = (callPrepOutput === null || callPrepOutput === void 0 ? void 0 : callPrepOutput.errorMessage) === "No information available";
    useEffect(() => {
        if (noInformationAvailable) {
            setIsOpen(false);
        }
        else {
            setIsOpen(defaultOpen);
        }
    }, [defaultOpen, noInformationAvailable]);
    const updateCallPrepOutput = (callPrepOutput) => __awaiter(void 0, void 0, void 0, function* () {
        yield updatePreCallPrepOutput(callPrepOutput.id, callPrepOutput.html || "");
        setCallPrepOutput(callPrepOutput);
    });
    const toggleOpen = () => {
        if (noInformationAvailable) {
            // if the block is not available, don't toggle the open state
            return;
        }
        setIsOpen(!isOpen);
    };
    return (_jsxs("div", Object.assign({ className: `w-full ${isOpen ? "h-full" : "max-h-[68px]"} mb-5 px-2 py-4 bg-white rounded-lg border border-neutral-200 flex-col justify-start items-start gap-4 inline-flex overflow-hidden` }, { children: [_jsxs("div", Object.assign({ className: "self-stretch justify-between items-center inline-flex cursor-pointer", onClick: toggleOpen }, { children: [_jsxs("div", Object.assign({ className: "justify-start items-center flex gap-2" }, { children: [_jsx("div", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex" }, (dragHandleProps || {}), { children: _jsx(DragIndicatorIcon, {}) })), _jsx("div", Object.assign({ className: cn("font-bold leading-normal", noInformationAvailable ? "text-wds-gray-4" : "") }, { children: callPrepBlock.name })), noInformationAvailable ? (_jsx("div", Object.assign({ className: "px-2 py-0.5 bg-slate-50 rounded-lg border border-wds-gray-3 justify-start items-center gap-1 inline-flex" }, { children: _jsx("div", Object.assign({ className: "text-neutral-700 text-xs font-medium leading-none" }, { children: callPrepOutput === null || callPrepOutput === void 0 ? void 0 : callPrepOutput.errorMessage })) }))) : null] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsxs(Popover, Object.assign({ open: popoverOpen, onOpenChange: (open) => setPopoverOpen(open) }, { children: [_jsx(PopoverTrigger, Object.assign({ onClick: (e) => {
                                            setPopoverOpen((prev) => !prev);
                                            e.stopPropagation();
                                        } }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "text-wds-black hover:text-wds-black rounded-full px-2" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-5 h-5" }) })) })), _jsx(PopoverContent, Object.assign({ side: "bottom", align: "end", sideOffset: 0 }, { children: _jsx("div", Object.assign({ className: "z-10 w-56 py-2 bg-white rounded-lg shadow border border-neutral-200 flex-col inline-flex" }, { children: _jsxs(_Fragment, { children: [_jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-gray-100", onClick: () => {
                                                            // note: this doesn't work if the block is not in the template
                                                            // for now it is fine because in most cases the block will be in the template
                                                            navigate("/research", {
                                                                state: {
                                                                    editingBlockId: callPrepBlock.id,
                                                                    templateId,
                                                                },
                                                            });
                                                        } }, { children: [_jsx(PencilIcon, { className: "w-5 h-5 relative text-blue-600" }), "Edit block configuration"] })), callPrepOutput && (_jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-gray-100", onClick: () => setIsEditing(true) }, { children: [_jsx(PencilIcon, { className: "w-5 h-5 relative text-blue-600" }), "Edit block content"] }))), _jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-gray-100", onClick: (e) => {
                                                            e.stopPropagation(); // prevents the parent div's onClick from firing
                                                            setPopoverOpen(false);
                                                            executeBlock();
                                                        }, disabled: isExecuting || refreshingAllBlocks }, { children: [_jsx(ArrowPathIcon, { className: "w-5 h-5 relative text-blue-600" }), callPrepOutput
                                                                ? "Regenerate block content"
                                                                : "Generate block content"] })), _jsxs("button", Object.assign({ className: "self-stretch px-4 py-2 bg-white gap-1 items-center inline-flex hover:bg-gray-100", onClick: onDelete }, { children: [_jsx(TrashIcon, { className: "w-5 h-5 relative text-red-600" }), "Remove from this call"] }))] }) })) }))] })), isOpen ? (_jsx(ChevronIcon, { direction: "down", hoverCircle: true, toggle: toggleOpen })) : (_jsx(ChevronIcon, { direction: "up", hoverCircle: true, toggle: toggleOpen }))] }))] })), _jsx(CallPrepOutput, { callPrepOutput: callPrepOutput, isExecuting: isExecuting || refreshingAllBlocks, updateCallPrepOutput: updateCallPrepOutput, isEditing: isEditing, setIsEditing: setIsEditing })] })));
};
export default CallPrepBlock;
