import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger, } from "@/components/ui/collapsible";
import { Sidebar, SidebarContent, SidebarFooter, SidebarGroup, SidebarGroupContent, SidebarHeader, SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarMenuSub, SidebarMenuSubButton, SidebarMenuSubItem, SidebarSeparator, useSidebar, } from "@/components/ui/sidebar";
import * as Icons from "@heroicons/react/24/outline";
import * as IconsSolid from "@heroicons/react/24/solid";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getNavbar } from "./common/endpoints";
import WiserLogoDarkBgTransparent from "./icons/wiser_logo_dark_bg_transparent";
import { NavBottom } from "./nav_bottom";
const getNavigationMenuItems = (navItems, includeStaffUserItems, includeTenantAdminItems, enabledFeatures) => {
    const filterItem = (item) => {
        if (item.feature_flag) {
            return enabledFeatures.includes(item.feature_flag);
        }
        if (item.is_staff) {
            return includeStaffUserItems;
        }
        if (item.is_admin) {
            return includeTenantAdminItems;
        }
        return true;
    };
    const items = navItems
        .filter((item) => filterItem(item))
        .map((item) => {
        const IconComponent = Icons[item.icon_name];
        const IconComponentSolid = IconsSolid[item.icon_name];
        if (item.items.length === 0) {
            return {
                name: item.name,
                href: item.href,
                icon: IconComponent,
                iconSolid: IconComponentSolid,
            };
        }
        return {
            name: item.name,
            href: item.href,
            icon: IconComponent,
            iconSolid: IconComponentSolid,
            items: item.items
                .filter((subItem) => filterItem(subItem))
                .map((subItem) => {
                const IconComp = Icons[subItem.icon_name];
                const IconCompSolid = IconsSolid[subItem.icon_name];
                return {
                    name: subItem.name,
                    href: subItem.href,
                    icon: IconComp,
                    iconSolid: IconCompSolid,
                };
            }),
        };
    });
    return items;
};
export function AppSidebar({ onLogoutButtonClick, includeStaffUserItems, includeTenantAdminItems, enabledFeatures, }) {
    const { state, open, setOpen, toggleSidebar, isMobile, openMobile, setOpenMobile, } = useSidebar();
    const { data: cachedNavbar } = useQuery({
        queryFn: getNavbar,
        queryKey: ["getNavbar"],
        staleTime: Number.POSITIVE_INFINITY,
    });
    const isOpen = open || openMobile;
    const location = useLocation();
    const items = getNavigationMenuItems(cachedNavbar || [], includeStaffUserItems, includeTenantAdminItems, enabledFeatures);
    return (_jsxs(Sidebar, Object.assign({ side: "left", collapsible: "icon" }, { children: [_jsx(SidebarHeader, Object.assign({ className: open ? "p-4" : "" }, { children: _jsxs("div", Object.assign({ className: "flex justify-start items-center relative text-wds-gray-5 gap-2" }, { children: [!isOpen && (_jsxs(Button, Object.assign({ variant: "ghost", onClick: toggleSidebar, className: "hover:bg-wds-blue-5 hover:text-wds-blue-1 m-0 p-0" }, { children: [_jsx(Icons.Bars3Icon, { className: "h-6 w-10 text-wds-blue-1", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Open sidebar" }))] }))), isOpen && (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs(Button, Object.assign({ variant: "ghost", onClick: toggleSidebar, className: "hover:bg-wds-blue-5 hover:text-wds-blue-1 m-0 p-0" }, { children: [_jsx(IconsSolid.XMarkIcon, { className: "h-6 w-10 text-wds-blue-1", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Close sidebar" }))] })) }), _jsx("div", { children: _jsx("span", Object.assign({ className: "h-10 w-auto" }, { children: _jsx(WiserLogoDarkBgTransparent, { className: "w-[147px] h-8" }) })) })] }))] })) })), _jsx(SidebarSeparator, {}), _jsx(SidebarContent, { children: _jsx(SidebarGroup, { children: _jsx(SidebarGroupContent, { children: _jsx(SidebarMenu, { children: items.map((item) => {
                                var _a;
                                const isSelected = `/${item.href.split("?")[0]}` === location.pathname;
                                const IconComponent = isSelected ? item.iconSolid : item.icon;
                                return (_jsx(Collapsible, Object.assign({ asChild: true, defaultOpen: false, className: "group/collapsible" }, { children: _jsxs(SidebarMenuItem, Object.assign({ className: isSelected ? "bg-wds-blue-5" : "" }, { children: [_jsx(CollapsibleTrigger, Object.assign({ className: "w-full" }, { children: _jsx(SidebarMenuButton, Object.assign({ asChild: true, tooltip: item.name, className: "h-10 text-sm" }, { children: _jsxs("a", Object.assign({ href: item.href !== "#" ? `/${item.href}` : undefined, onClick: (e) => {
                                                            if (item.href === "#") {
                                                                if (isMobile) {
                                                                    setOpenMobile(true);
                                                                }
                                                                else {
                                                                    setOpen(true);
                                                                }
                                                            }
                                                            else {
                                                                // Handle left-click navigation normally
                                                                if (!e.ctrlKey &&
                                                                    !e.metaKey &&
                                                                    e.button === 0) {
                                                                    if (isMobile) {
                                                                        setOpenMobile(false);
                                                                    }
                                                                    else {
                                                                        setOpen(false);
                                                                    }
                                                                }
                                                            }
                                                        }, onAuxClick: (e) => {
                                                            // Handle middle-click to open in a new tab
                                                            if (e.button === 1 && item.href !== "#") {
                                                                window.open(`/${item.href}`, "_blank");
                                                            }
                                                        } }, { children: [_jsx(IconComponent, { className: `h-6 w-6 shrink-0 stroke-2 ${isSelected ? "text-wds-blue-1" : ""}` }), _jsx("span", Object.assign({ className: "group-data-[state=collapsed]:hidden text-[15px]" }, { children: item.name })), item.items && (_jsx(Icons.ChevronRightIcon, { className: "h-6 w-6 stroke-2 transition-transform duration-200 group-data-[state=collapsed]:hidden group-data-[state=open]/collapsible:rotate-90" }))] })) })) })), _jsx(CollapsibleContent, { children: _jsx(SidebarMenuSub, { children: (_a = item.items) === null || _a === void 0 ? void 0 : _a.map((subItem) => {
                                                        const isSelected = `/${subItem.href.split("?")[0]}` ===
                                                            location.pathname;
                                                        const IconComp = isSelected
                                                            ? subItem.iconSolid
                                                            : subItem.icon;
                                                        return (_jsx(SidebarMenuSubItem, Object.assign({ className: isSelected ? "bg-wds-blue-5" : "" }, { children: _jsx(SidebarMenuSubButton, Object.assign({ className: "cursor-pointer h-10 text-sm" }, { children: _jsxs("a", Object.assign({ className: "flex flex-row justify-between gap-2", href: subItem.href !== "#"
                                                                        ? `/${subItem.href}`
                                                                        : undefined, onClick: () => {
                                                                        if (subItem.href === "#") {
                                                                            return;
                                                                        }
                                                                        if (isMobile) {
                                                                            setOpenMobile(false);
                                                                        }
                                                                        else {
                                                                            setOpen(false);
                                                                        }
                                                                    } }, { children: [_jsx(IconComp, { className: `h-6 w-6 shrink-0 stroke-2 ${isSelected ? "text-wds-blue-1" : ""}` }), _jsx("span", Object.assign({ className: "text-[15px]" }, { children: subItem.name }))] })) })) }), subItem.name));
                                                    }) }) })] }), item.name) }), item.name));
                            }) }) }) }) }), _jsx(SidebarFooter, { children: _jsx(NavBottom, { onLogoutButtonClick: onLogoutButtonClick, expanded: isOpen }) })] })));
}
