var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Page for users to opt in/out of experiments and under development features.
import { cn } from "@/lib/utils";
import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { getFeatureFlagData, getFeatureFlags, getTenants, toggleFeatureEnabledForAll, toggleFeatureForTenant, toggleFeatureForUser, } from "./common/endpoints";
/**
 * Experiments page is UI for the backend feature flag configuration.
 */
export function ExperimentsPage() {
    const [featureFlagData, setFeatureFlagData] = useState(null);
    const [featureFlags, setFeatureFlags] = useState([]);
    const [tenantId, setTenantId] = useState(null);
    useEffect(() => {
        getFeatureFlagData().then((data) => setFeatureFlagData(data));
        getFeatureFlags().then((response) => setFeatureFlags(response.featureFlags));
    }, []);
    useEffect(() => {
        getTenants().then(({ active_tenant_id }) => {
            setTenantId(active_tenant_id);
        });
    }, []);
    const isFeatureFlagEnabledForAll = (featureFlagName) => {
        var _a, _b;
        return ((_b = (_a = featureFlags.find((flag) => flag.name === featureFlagName)) === null || _a === void 0 ? void 0 : _a.enabledForAll) !== null && _b !== void 0 ? _b : false);
    };
    const isFeatureFlagEnabledForSelf = (featureFlagName) => {
        var _a, _b;
        return ((_b = (_a = featureFlags.find((flag) => flag.name === featureFlagName)) === null || _a === void 0 ? void 0 : _a.enabledForSelf) !== null && _b !== void 0 ? _b : false);
    };
    const isFeatureFlagEnabledForTenant = (featureFlagName) => {
        var _a, _b;
        if (!tenantId) {
            return false;
        }
        return ((_b = (_a = featureFlags
            .find((flag) => flag.name === featureFlagName)) === null || _a === void 0 ? void 0 : _a.tenantAllowlist.includes(tenantId)) !== null && _b !== void 0 ? _b : false);
    };
    const refreshFeatureFlags = () => __awaiter(this, void 0, void 0, function* () {
        const response = yield getFeatureFlags();
        setFeatureFlags(response.featureFlags);
    });
    if (!featureFlagData) {
        return _jsx("div", { children: "Loading..." });
    }
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: "px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20" }, { children: _jsx("div", Object.assign({ className: "mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0" }, { children: _jsx("div", { children: _jsxs("div", { children: [_jsx("div", { className: "mt-2 space-y-6 divide-y divide-gray-100 border-gray-200 text-sm leading-6" }), _jsxs("div", Object.assign({ className: "flex pt-6 w-max" }, { children: [_jsx("dt", Object.assign({ className: "flex-none pr-6 font-bold text-lg text-gray-900 sm:w-64" }, { children: "Feature description" })), _jsx("dd", Object.assign({ className: "pl-6" }, { children: _jsx("span", Object.assign({ className: "font-bold text-lg text-gray-900" }, { children: "Enabled for me?" })) })), _jsx("dd", Object.assign({ className: "pl-6" }, { children: _jsx("span", Object.assign({ className: "font-bold text-lg text-gray-900" }, { children: "Enabled for tenant?" })) })), _jsx("dd", Object.assign({ className: "pl-6" }, { children: _jsx("span", Object.assign({ className: "font-bold text-lg text-gray-900" }, { children: "Enabled for all?" })) }))] })), featureFlagData.featureFlagIdentifiers.map((featureFlagIdentifier) => {
                                return (_jsxs(Switch.Group, Object.assign({ as: "div", className: "flex pt-6" }, { children: [_jsx(Switch.Label, Object.assign({ as: "dt", className: "flex-none pr-6 font-medium text-gray-900 sm:w-64", passive: true }, { children: featureFlagIdentifier.description })), _jsx("dd", Object.assign({ className: "flex flex-auto items-center justify-end" }, { children: _jsx(Switch, Object.assign({ checked: isFeatureFlagEnabledForSelf(featureFlagIdentifier.name), onChange: () => __awaiter(this, void 0, void 0, function* () {
                                                    yield toggleFeatureForUser(featureFlagIdentifier.name);
                                                    yield refreshFeatureFlags();
                                                }), className: cn(isFeatureFlagEnabledForSelf(featureFlagIdentifier.name)
                                                    ? "bg-indigo-600"
                                                    : "bg-gray-200", "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600") }, { children: _jsx("span", { "aria-hidden": "true", className: cn(isFeatureFlagEnabledForSelf(featureFlagIdentifier.name)
                                                        ? "translate-x-3.5"
                                                        : "translate-x-0", "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out") }) })) })), _jsx("dd", Object.assign({ className: "flex flex-auto items-center justify-end" }, { children: _jsx(Switch, Object.assign({ checked: isFeatureFlagEnabledForTenant(featureFlagIdentifier.name), onChange: () => __awaiter(this, void 0, void 0, function* () {
                                                    yield toggleFeatureForTenant(featureFlagIdentifier.name, tenantId);
                                                    yield refreshFeatureFlags();
                                                }), className: cn(isFeatureFlagEnabledForTenant(featureFlagIdentifier.name)
                                                    ? "bg-indigo-600"
                                                    : "bg-gray-200", "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600") }, { children: _jsx("span", { "aria-hidden": "true", className: cn(isFeatureFlagEnabledForTenant(featureFlagIdentifier.name)
                                                        ? "translate-x-3.5"
                                                        : "translate-x-0", "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out") }) })) })), _jsx("dd", Object.assign({ className: "flex flex-auto items-center justify-end" }, { children: _jsx(Switch, Object.assign({ checked: isFeatureFlagEnabledForAll(featureFlagIdentifier.name), onChange: () => __awaiter(this, void 0, void 0, function* () {
                                                    yield toggleFeatureEnabledForAll(featureFlagIdentifier.name);
                                                    yield refreshFeatureFlags();
                                                }), className: cn(isFeatureFlagEnabledForAll(featureFlagIdentifier.name)
                                                    ? "bg-indigo-600"
                                                    : "bg-gray-200", "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600") }, { children: _jsx("span", { "aria-hidden": "true", className: cn(isFeatureFlagEnabledForAll(featureFlagIdentifier.name)
                                                        ? "translate-x-3.5"
                                                        : "translate-x-0", "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out") }) })) }))] }), featureFlagIdentifier.name));
                            })] }) }) })) })) }));
}
