import { WiserBackendQueryOperator } from "../common/query_builder/types";
export const convertNewSearchParamsIntoFilterQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    copySearchParams.delete("page");
    copySearchParams.delete("per_page");
    const filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    const order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    const json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "meeting_host",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
