import { capitalize } from "../strings";
const getAccountLabel = (connectedCrm) => {
    if (connectedCrm === "HubSpot") {
        return "company";
    }
    return "account";
};
const getOpportunityLabel = (connectedCrm) => {
    if (connectedCrm === "HubSpot") {
        return "deal";
    }
    return "opportunity";
};
const getAccountLabelForDisplay = (connectedCrm) => {
    return capitalize(getAccountLabel(connectedCrm));
};
const getOpportunityLabelForDisplay = (connectedCrm) => {
    return capitalize(getOpportunityLabel(connectedCrm));
};
export { getAccountLabel, getOpportunityLabel, getAccountLabelForDisplay, getOpportunityLabelForDisplay, };
