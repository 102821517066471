import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flexRender } from "@tanstack/react-table";
import { useContext } from "react";
import { UserContext } from "../../App";
import useFeatureFlagEnabled from "../../use_feature_flag_enabled";
import { BackfillColumnDropdown } from "./backfill_column_dropdown";
export const ColumnHeaderRenderer = ({ table, header, }) => {
    var _a, _b, _c;
    const { userIsStaff, userIsTenantAdmin } = useContext(UserContext);
    const backfillBrickColumnsEnabled = useFeatureFlagEnabled("BACKFILL_BRICK_COLUMNS");
    const tableRows = table.getFilteredRowModel().rows.map((row) => ({
        id: row.original.id,
    }));
    const selectedRowIds = table
        .getSelectedRowModel()
        .rows.map((row) => row.original.id);
    if (!backfillBrickColumnsEnabled || !(userIsTenantAdmin || userIsStaff)) {
        return (_jsx("div", Object.assign({ className: "group flex gap-2 items-center" }, { children: _jsx("span", { children: flexRender(header.column.columnDef.header, header.getContext()) }) })));
    }
    return (_jsxs("div", Object.assign({ className: "group flex gap-2 items-center justify-between w-full" }, { children: [_jsx("span", { children: flexRender(header.column.columnDef.header, header.getContext()) }), ((_a = header.column.columnDef.meta) === null || _a === void 0 ? void 0 : _a.brickId) && (_jsx("div", Object.assign({ className: "invisible group-hover:visible" }, { children: _jsx(BackfillColumnDropdown, { tableRows: tableRows, selectedRowIds: selectedRowIds, brickId: (_b = header.column.columnDef.meta) === null || _b === void 0 ? void 0 : _b.brickId, brickName: (_c = header.column.columnDef.meta) === null || _c === void 0 ? void 0 : _c.brickName }) })))] })));
};
