var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import * as React from "react";
/**
 * RadioGroup Component
 *
 * A customizable radio group component using `RadioGroupPrimitive` from Radix UI.
 * This component supports forwarding refs and spreads additional props to the root element.
 *
 * Usage:
 * ```
 * <RadioGroup className="custom-class" defaultValue="option1">
 *   <RadioGroupItem value="option1">Option 1</RadioGroupItem>
 *   <RadioGroupItem value="option2">Option 2</RadioGroupItem>
 * </RadioGroup>
 * ```
 *
 * Props:
 * - `className` (string): Additional CSS classes to apply to the radio group container.
 * - `...props` (React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>): Any other props accepted by the `RadioGroupPrimitive.Root` component.
 *
 * @param {object} props - The properties for the radio group component.
 * @param {string} props.className - Additional CSS classes to apply to the radio group container.
 * @param {React.Ref<React.ElementRef<typeof RadioGroupPrimitive.Root>>} ref - The ref to be forwarded to the radio group element.
 * @returns {JSX.Element} The rendered radio group component.
 */
const RadioGroup = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(RadioGroupPrimitive.Root, Object.assign({ className: cn("grid gap-2", className) }, props, { ref: ref })));
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;
/**
 * RadioGroupItem Component
 *
 * A customizable radio group item component using `RadioGroupPrimitive` from Radix UI.
 * This component supports forwarding refs and spreads additional props to the item element.
 *
 * Usage:
 * ```
 * <RadioGroupItem value="option1" className="custom-class" />
 * ```
 *
 * Props:
 * - `className` (string): Additional CSS classes to apply to the radio group item.
 * - `...props` (React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>): Any other props accepted by the `RadioGroupPrimitive.Item` component.
 *
 * @param {object} props - The properties for the radio group item component.
 * @param {string} props.className - Additional CSS classes to apply to the radio group item.
 * @param {React.Ref<React.ElementRef<typeof RadioGroupPrimitive.Item>>} ref - The ref to be forwarded to the radio group item element.
 * @returns {JSX.Element} The rendered radio group item component.
 */
const RadioGroupItem = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(RadioGroupPrimitive.Item, Object.assign({ ref: ref, className: cn("aspect-square h-4 w-4 rounded-full border border-wds-gray-5 text-primary shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50", className) }, props, { children: _jsx(RadioGroupPrimitive.Indicator, Object.assign({ className: "flex items-center justify-center" }, { children: _jsx("div", { className: "h-3 w-3 bg-primary rounded-full border border-white" }) })) })));
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
export { RadioGroup, RadioGroupItem };
