var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { ArrowUpIcon, EyeIcon, EyeSlashIcon, } from "@heroicons/react/24/outline";
import * as React from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "./tooltip";
/**
 * Input Component
 *
 * A versatile input component that supports password visibility toggle,
 * caps lock detection, and optional leading icons. The component
 * also forwards refs and spreads additional props to the input element.
 * This component also supports rendering icons in-line with the input.
 * By default the icon is rendered at the start on the left of the input.
 *
 * Usage:
 * ```
 * <Input type="text" placeholder="Enter your name" className="custom-class" />
 * <Input type="text" placeholder="Enter your name" Icon={MagnifyingGlassIcon} />
 * <Input type="password" placeholder="Enter your password" className="custom-class" />
 * ```
 *
 * Props:
 * - `Icon` (React.ElementType): Optional leading icon component.
 * - `className` (string): Additional CSS classes to apply to the input container.
 * - `type` (string): The type of the input, such as "text" or "password".
 * - `...props` (React.InputHTMLAttributes<HTMLInputElement>): Any other HTML attributes to apply to the input element.
 *
 * @param {object} props - The properties for the input component.
 * @param {React.ElementType} props.Icon - Optional leading icon component.
 * @param {string} props.className - Additional CSS classes to apply to the input container.
 * @param {string} props.type - The type of the input, such as "text" or "password".
 * @param {React.Ref<HTMLInputElement>} ref - The ref to be forwarded to the input element.
 * @returns {JSX.Element} The rendered input component.
 */
const Input = React.forwardRef((_a, ref) => {
    var { Icon, className, type } = _a, props = __rest(_a, ["Icon", "className", "type"]);
    const [showPassword, setShowPassword] = React.useState(false);
    const [capsLockActive, setCapsLockActive] = React.useState(false);
    const handleKeyPress = (event) => {
        const capsLockOn = event.getModifierState("CapsLock");
        setCapsLockActive(capsLockOn);
    };
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const inputClasses = cn("flex h-10 w-full rounded-md border border-input bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-wds-gray-5 text-wds-black focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50", Icon && "pl-10", type === "password" && (!capsLockActive ? "pr-8" : "pr-16"), className);
    return (_jsxs("div", Object.assign({ className: cn("relative", className) }, { children: [Icon && (_jsx("div", Object.assign({ className: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-accent-dark h-10 w-8" }, { children: _jsx(Icon, {}) }))), _jsx("input", Object.assign({ type: type === "password" && showPassword ? "text" : type, className: inputClasses, onKeyDown: handleKeyPress, ref: ref }, props)), type === "password" && (_jsxs("div", Object.assign({ className: "absolute right-0 flex items-center pr-3 -translate-y-1/2 top-1/2 gap-x-1" }, { children: [showPassword ? (_jsx(EyeSlashIcon, { className: "cursor-pointer", onClick: togglePasswordVisibility })) : (_jsx(EyeIcon, { className: "cursor-pointer", onClick: togglePasswordVisibility })), capsLockActive && type === "password" && (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(ArrowUpIcon, {}) })), _jsx(TooltipContent, { children: _jsx("p", { children: "Caps Lock is on!" }) })] }) }))] })))] })));
});
Input.displayName = "Input";
export { Input };
