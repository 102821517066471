import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
export const PlaybackRate = ({ playbackSpeed, setPlaybackSpeed, disabled, }) => {
    if (disabled) {
        return (_jsx("div", Object.assign({ className: "text-wds-gray-5 font-bold text-[15px] leading-[22px]" }, { children: `${playbackSpeed}x` })));
    }
    const options = [0.5, 1, 1.25, 1.5, 1.75, 2, 2.5];
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "sm", className: "text-wds-gray-5 font-bold text-[15px] leading-[22px] hover:bg-wds-blue-1" }, { children: `${playbackSpeed}x` })) })), _jsx(PopoverContent, Object.assign({ className: "text-wds-gray-5 font-bold" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col space-y-2 place-items-center" }, { children: [_jsx("div", Object.assign({ className: "text-[15px] leading-[22px]" }, { children: "Playback speed" })), _jsx("div", Object.assign({ className: "flex flex-row space-x-0" }, { children: options.map((option) => (_jsx(Button, Object.assign({ variant: "ghost", size: "sm", className: cn("text-wds-gray-5 font-bold hover:bg-wds-blue-1 text-[15px] leading-[22px]", option === playbackSpeed
                                    ? "bg-wds-blue-1 text-wds-black"
                                    : ""), onClick: () => setPlaybackSpeed(option) }, { children: `${option}x` }), option))) }))] })) }))] }));
};
