var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AsyncSearchSelect } from "@/components/ui/async_search_select";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll_area";
import { useToast } from "@/components/ui/use_toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DayPickerProvider } from "react-day-picker";
import { useForm } from "react-hook-form";
import { DEPARTMENT_SCHEMA, checkNameAlreadyExists, departmentsFetcher, } from "./department.helpers";
import { updateDepartment } from "./endpoints";
const DepartmentForm = ({ department, setSelectedDepartment, }) => {
    var _a;
    const queryClient = useQueryClient();
    const { toast } = useToast();
    const form = useForm({
        resolver: zodResolver(DEPARTMENT_SCHEMA),
        defaultValues: {
            Name: department.name,
            "Parent department": {
                label: department.parent_department_name,
                value: (_a = department.parent_department_id) === null || _a === void 0 ? void 0 : _a.toString(),
            },
        },
    });
    const updateMutation = useMutation({
        mutationFn: (values) => __awaiter(void 0, void 0, void 0, function* () {
            return yield updateDepartment(values);
        }),
        onSuccess: (data) => {
            queryClient.invalidateQueries();
            toast({
                title: "Department updated",
                description: "Department updated successfully",
                variant: "green",
            });
            setSelectedDepartment(undefined);
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `${error}`,
                variant: "destructive",
            });
        },
    });
    const initialProps = {
        selected: new Date(),
    };
    return (_jsx(DayPickerProvider, Object.assign({ initialProps: initialProps }, { children: _jsx("div", Object.assign({ className: "max-h-full overflow-y-auto pb-4" }, { children: _jsx(ScrollArea, { children: _jsx(Form, Object.assign({}, form, { children: _jsxs("form", Object.assign({ onSubmit: form.handleSubmit((values) => __awaiter(void 0, void 0, void 0, function* () {
                            return yield updateMutation.mutateAsync(Object.assign(Object.assign({}, values), { id: department.id }));
                        })), className: "space-y-8" }, { children: [_jsx(FormField, { control: form.control, name: "Name", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Name" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({ placeholder: "Department name" }, field, { onBlur: (event) => __awaiter(void 0, void 0, void 0, function* () {
                                                    if (event.target.value === department.name) {
                                                        form.clearErrors("Name");
                                                    }
                                                    else {
                                                        const nameExists = yield checkNameAlreadyExists(event.target.value, queryClient);
                                                        if (nameExists) {
                                                            form.setError("Name", {
                                                                type: "validate",
                                                                message: "This name is already in use",
                                                            });
                                                        }
                                                        else {
                                                            form.clearErrors("Name");
                                                        }
                                                    }
                                                }) })) }), _jsx(FormDescription, { children: "This is your department name." }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "Parent department", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Parent Department" }), _jsx(FormDescription, { children: "This is the parent department of this department." }), _jsx(FormControl, { children: _jsx(AsyncSearchSelect, { dataFetcher: (userQuery) => departmentsFetcher(queryClient, userQuery), onSelect: (option) => {
                                                    field.onChange(option);
                                                    if (option &&
                                                        option.value === department.id.toString()) {
                                                        form.setError("Parent department", {
                                                            type: "validate",
                                                            message: "Cannot set parent department to itself",
                                                        });
                                                    }
                                                    else {
                                                        form.clearErrors("Parent department");
                                                    }
                                                }, placeholder: "Department name", selectedOption: field.value }) }), _jsx(FormMessage, {})] })) }), _jsx(Button, Object.assign({ type: "submit", disabled: !form.formState.isValid ||
                                    Object.keys(form.formState.errors).length > 0 }, { children: "Submit" }))] })) })) }) })) })));
};
export default DepartmentForm;
