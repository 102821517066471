import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { DocumentTextIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { AccountBlockCard } from "./styled_components";
import { useAccountCalls } from "./use_account_calls";
import { STATIC_ACCOUNT_BLOCK_IDS } from "./use_account_research_blocks";
import { AccountBrickOutputWithReadMore } from "./v2/account_brick_collapsed_card";
import { AccountResearchBlockOutputRefreshButton } from "./v2/account_brick_refresh_button";
const Interactions = ({ scrollToBlock, queryCallsResponse, }) => {
    if (!queryCallsResponse)
        return null;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm font-medium" }, { children: "Interactions" })), _jsx("div", Object.assign({ className: "flex items-start gap-2" }, { children: _jsx(Button, Object.assign({ variant: "link", onClick: () => scrollToBlock(STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS), className: "p-0 h-auto" }, { children: _jsxs("div", Object.assign({ className: "flex items-center gap-1 text-wds-blue-3" }, { children: [_jsx(PhoneIcon, { className: "w-5 h-5 shrink-0" }), _jsx("span", { children: `${queryCallsResponse.total} ${queryCallsResponse.total === 1 ? "call" : "calls"}` })] })) })) }))] })));
};
const NextInteraction = ({ queryCallsResponse, }) => {
    const futureCalls = queryCallsResponse === null || queryCallsResponse === void 0 ? void 0 : queryCallsResponse.calls.filter((call) => new Date(call.call_time) > new Date());
    const nextCall = futureCalls && futureCalls.length > 0 ? futureCalls[0] : undefined;
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-sm font-medium" }, { children: "Next scheduled call" })), _jsx("div", Object.assign({ className: "flex items-center gap-1" }, { children: nextCall ? (_jsx(Link, Object.assign({ to: `/home/call/${nextCall.id}`, target: "_blank" }, { children: _jsx(Button, Object.assign({ variant: "link", className: "p-0 h-auto" }, { children: _jsx("span", Object.assign({ className: "text-wds-blue-3" }, { children: nextCall.name })) })) }))) : (_jsx("span", Object.assign({ className: "text-wds-gray-4" }, { children: "No next call scheduled yet" }))) }))] })));
};
const AccountSummary = ({ accountSummaryBlock, onReadMoreClick, }) => {
    if (!accountSummaryBlock) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 self-stretch text-sm font-medium" }, { children: "Wiser's summary" })), accountSummaryBlock.html && (_jsx(AccountBrickOutputWithReadMore, { accountResearchBlockOutput: accountSummaryBlock, onReadMoreClick: onReadMoreClick, outputGenerationInProgress: false }))] })));
};
export const AccountOverviewBlock = ({ account, accountSummaryBlock, scrollToBlock, onReadMoreClick, highlight, brick, generateResearchBlockOutput, outputGenerationInProgress, }) => {
    const { queryCallsResponse, callsLoading } = useAccountCalls({
        accountId: account.id,
    });
    return (_jsxs(AccountBlockCard, Object.assign({ className: cn("rounded-3xl", highlight &&
            "border-2 shadow-[0_4px_8px_0_rgba(35,45,56,0.16)] border-[rgba(53,110,255,0.32)]") }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 justify-between items-center self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(DocumentTextIcon, { className: "w-6 h-6 shrink-0" }), _jsx("span", Object.assign({ className: "text-lg font-medium" }, { children: "Overview" }))] })), accountSummaryBlock && brick && generateResearchBlockOutput && (_jsx(AccountResearchBlockOutputRefreshButton, { account: account, brick: brick, accountResearchBlockOutput: accountSummaryBlock, generateResearchBlockOutput: generateResearchBlockOutput, outputGenerationInProgress: outputGenerationInProgress }))] })), callsLoading || !queryCallsResponse ? (_jsx("span", Object.assign({ className: "text-wds-gray-4 animate-pulse" }, { children: "Fetching calls" }))) : (_jsxs("div", Object.assign({ className: "flex items-start gap-12" }, { children: [_jsx(Interactions, { scrollToBlock: scrollToBlock, queryCallsResponse: queryCallsResponse }), _jsx(NextInteraction, { queryCallsResponse: queryCallsResponse })] }))), _jsx(AccountSummary, { accountSummaryBlock: accountSummaryBlock, onReadMoreClick: onReadMoreClick })] })));
};
