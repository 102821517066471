var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { flexRender } from "@tanstack/react-table";
import { useMemo } from "react";
import { z } from "zod";
import { queryMeetingTemplates, queryPlaybooks } from "../common/endpoints";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
export const MEETING_TEMPLATE_SCHEMA = z.object({
    Name: z.string().min(1),
    Description: z.string().min(1),
    "Description for LLM": z.string().min(1),
    "Brick set": z.object({
        label: z.string().min(1),
        value: z.string().min(1),
    }),
    "Research set": z.object({
        label: z.string().min(1),
        value: z.string().min(1),
    }),
    "Meeting type for CRM": z.string().min(1).optional(),
});
export const addSearchParamsToQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    const filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    const order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    const json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "description",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
export const DEFAULT_MEETING_TEMPLATE_TABLE_LAYOUT = {
    table_name: "MeetingTemplate",
    name: "Default View",
    is_default: true,
    is_public: false,
    column_order: [],
    columns: {
        select: 35,
        name: 150,
        description: 150,
        description_for_llm: 150,
        pre_call_template_name: 150,
        playbook_name: 150,
        actions: 150,
    },
    query_string: 'order_by=[{"field":"name","order":"ASC"}]',
};
export function getColumns(onRowClick) {
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center" }, { children: _jsx("div", Object.assign({ className: "text-sm font-normal" }, { children: row.original.name })) })) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "description",
            accessorKey: "description",
            header: "Description",
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center" }, { children: _jsx("div", Object.assign({ className: "text-sm font-normal" }, { children: row.original.description })) })) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.description,
        },
        {
            id: "description_for_llm",
            accessorKey: "description_for_llm",
            header: "Description for LLM",
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center" }, { children: _jsx("div", Object.assign({ className: "text-sm font-normal" }, { children: row.original.description_for_llm })) })) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.description_for_llm,
        },
        {
            id: "pre_call_template_name",
            accessorKey: "pre_call_template_name",
            header: "Research set",
            cell: ({ row, cell }) => flexRender(row.original.pre_call_template_name, cell.getContext()),
            accessorFn: (row) => row.pre_call_template_name,
        },
        {
            id: "tracker_brick_set_name",
            accessorKey: "tracker_brick_set_name",
            header: "Brick set",
            cell: ({ row, cell }) => flexRender(row.original.tracker_brick_set_name, cell.getContext()),
            accessorFn: (row) => row.tracker_brick_set_name,
        },
        {
            id: "actions",
            accessorKey: "actions",
            header: "Update template",
            cell: ({ row }) => (_jsx(Button, Object.assign({ variant: "link", size: "icon", onClick: () => onRowClick(row.original) }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5" }) }))),
            enableHiding: false,
        },
        {
            id: "meeting_type_for_crm",
            accessorKey: "meeting_type_for_crm",
            header: "CRM meeting type",
            cell: ({ row, cell }) => flexRender(row.original.meeting_type_for_crm, cell.getContext()),
            accessorFn: (row) => row.meeting_type_for_crm,
        },
    ];
}
const _checkNameAlreadyExists = (name, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const json_query = JSON.stringify({
        table: "MeetingTemplate",
        order_by_fields: ["name"],
        structured_filter: {
            where_condition: {
                field: "name",
                operator: WiserBackendQueryOperator.EQUALS,
                value: name,
            },
        },
    });
    const response = yield queryClient.fetchQuery({
        queryKey: ["queryMeetingTemplates", name],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () { return yield queryMeetingTemplates(json_query); }),
    });
    return response.data.length > 0;
});
export const brickSetFetcher = (queryClient, userQuery, ids, cursor, brickSetType) => __awaiter(void 0, void 0, void 0, function* () {
    const brickSetQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
                {
                    where_condition: {
                        field: "brick_set_type",
                        operator: WiserBackendQueryOperator.EQUALS,
                        value: brickSetType,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return queryPlaybooks(JSON.stringify({
                table: "BrickSet",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["queryBrickSets", cursor, userQuery, brickSetType, ids],
    });
    const results = brickSetQueryResponse.data.map((brickSet) => {
        return {
            label: brickSet.name,
            value: brickSet.id.toString(),
        };
    });
    return { results };
});
export const useFormFieldMeta = (queryClient, selectedTemplate) => {
    const formFieldMeta = useMemo(() => ({
        Name: {
            type: "text",
            placeholder: "Name",
            defaultValue: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.name,
            required: true,
            onBlur: (event, form) => __awaiter(void 0, void 0, void 0, function* () {
                if ((selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.name) === event.target.value) {
                    form.clearErrors("Name");
                    return;
                }
                const nameExists = yield _checkNameAlreadyExists(event.target.value, queryClient);
                if (nameExists) {
                    form.setError("Name", {
                        type: "validate",
                        message: "This name is already in use",
                    });
                }
                else {
                    form.clearErrors("Name");
                }
            }),
        },
        Description: {
            type: "text",
            placeholder: "Enter a description for the meeting template",
            defaultValue: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.description,
            required: true,
        },
        "Description for LLM": {
            type: "text",
            placeholder: "Enter a description for the LLM; can be the same as description.",
            defaultValue: selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.description_for_llm,
            required: true,
        },
        "Brick set": {
            id: "TRACKER",
            type: "async_search_select",
            placeholder: "Brick set",
            defaultValue: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.tracker_brick_set_id)
                ? {
                    label: selectedTemplate.tracker_brick_set_name || "",
                    value: selectedTemplate.tracker_brick_set_id.toString(),
                }
                : undefined,
            optionsFetcher: (userQuery) => brickSetFetcher(queryClient, userQuery, undefined, undefined, "TRACKER"),
            required: true,
        },
        "Research set": {
            id: "CALL",
            type: "async_search_select",
            placeholder: "Research set",
            defaultValue: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.pre_call_template_id)
                ? {
                    label: selectedTemplate.pre_call_template_name,
                    value: selectedTemplate.pre_call_template_id.toString(),
                }
                : undefined,
            optionsFetcher: (userQuery) => brickSetFetcher(queryClient, userQuery, undefined, undefined, "CALL"),
            required: true,
        },
        "Meeting type for CRM": {
            type: "text",
            placeholder: "Enter the meeting type to be pushed to CRM",
            defaultValue: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.meeting_type_for_crm) || "",
        },
    }), [selectedTemplate]);
    return formFieldMeta;
};
