var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "@/components/ui/data_table_skeleton";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { debounce } from "../common/debounce";
import { getActiveTenant, getAllPreCallPrepBlocks, queryMeetingTemplates, } from "../common/endpoints";
import { useCustomSearchParams } from "../crm/use_memoised_search_params";
import { addSearchParamsToQuery } from "./meeting_template.helpers";
import { DEFAULT_MEETING_TEMPLATE_TABLE_LAYOUT } from "./meeting_template.helpers";
import MeetingTemplateTable from "./meeting_template_table";
export default function MeetingTemplateIndex() {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const { searchParams, layouts, isLoading: layoutsLoading, } = useCustomSearchParams("MeetingTemplate", DEFAULT_MEETING_TEMPLATE_TABLE_LAYOUT);
    const filterQuery = addSearchParamsToQuery(searchParams, "MeetingTemplate", debouncedQuery);
    const pageSize = Number.parseInt(searchParams.get("pageSize") || "50") || 50;
    const { data: selectTemplateBlock, isLoading: selectTemplateBlockLoading, isError: selectTemplateBlockError, } = useQuery({
        queryKey: ["select_template_block"],
        queryFn: () => __awaiter(this, void 0, void 0, function* () {
            const allBlocks = yield getAllPreCallPrepBlocks();
            const selectionBlocks = allBlocks.filter((block) => block.research_block_type === "MEETING_TEMPLATE_ASSIGNMENT");
            if (selectionBlocks.length > 0) {
                return selectionBlocks[0];
            }
            return null;
        }),
    });
    const { data, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery({
        queryKey: ["queryMeetingTemplates", filterQuery, pageSize],
        queryFn: ({ pageParam = 0 }) => queryMeetingTemplates(filterQuery, pageParam + 1, pageSize),
        getNextPageParam: (_lastGroup, groups) => groups.length,
        initialPageParam: 0,
    });
    //flatten the array of arrays from the useInfiniteQuery hook
    const meetingTemplates = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const total = useMemo(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0) {
            return data.pages[0].total;
        }
        return 0;
    }, [data === null || data === void 0 ? void 0 : data.pages, data]);
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsx("div", Object.assign({ className: "w-120 text-black text-3xl p-2" }, { children: "Meeting templates" })), !data ||
                isLoading ||
                layoutsLoading ||
                selectTemplateBlockLoading ||
                selectTemplateBlockError ||
                !activeTenant ||
                !layouts ||
                layouts.length === 0 ? (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true })) : (_jsx(MeetingTemplateTable, { meetingTemplates: meetingTemplates, total: total, layouts: layouts, searchQuery: searchQuery, setSearchQuery: setSearchQuery, tenant: activeTenant, searchParams: searchParams, filterQuery: filterQuery, fetchNextPage: fetchNextPage, isFetching: isFetchingNextPage, selectTemplateBlock: selectTemplateBlock }))] })));
}
