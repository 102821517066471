import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { cva } from "class-variance-authority";
import { Badge } from "./badge";
const removableBadgeVariants = cva("", {
    variants: {
        variant: {
            default: "px-1 rounded-xl",
            filter: "px-3 py-1.5 rounded-md",
        },
        size: {
            default: "text-xs gap-1",
            md: "text-sm gap-1.5",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
    compoundVariants: [
        {
            variant: "filter",
            size: "md",
        },
    ],
});
export const RemovableBadge = ({ content, onRemove, variant = "default", size, }) => {
    const isFilter = variant === "filter";
    const textSize = size || (isFilter ? "md" : "default");
    return (_jsxs(Badge, Object.assign({ className: cn(removableBadgeVariants({ variant, size: textSize }), "flex items-center", "hover:ring-2 hover:ring-muted-foreground"), variant: isFilter ? "gray" : "secondary" }, { children: [_jsx("span", { children: content }), _jsxs("button", Object.assign({ "aria-label": `Remove ${content} option`, "aria-roledescription": "button to remove option", type: "button", onMouseDown: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }, onClick: onRemove }, { children: [_jsxs("span", Object.assign({ className: "sr-only" }, { children: ["Remove ", content, " option"] })), _jsx(XMarkIcon, { className: cn(isFilter ? "h-3 w-3" : "h-4 w-4", "hover:stroke-destructive") })] }))] })));
};
export { removableBadgeVariants };
