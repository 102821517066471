var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { z } from "zod";
import BaseModal2 from "../base_modal2";
import { TenantCreditTypeEnum } from "../types";
import { getAllTenantCredits, updateTenantCredits } from "./endpoints";
export const CreditManagementModal = ({ open, setOpen, }) => {
    const { data: allTenantCredits, isLoading } = useQuery({
        queryKey: ["getAllTenantCredits"],
        queryFn: getAllTenantCredits,
    });
    const updateTenantCreditsMutation = useMutation({
        mutationFn: (data) => updateTenantCredits(data.tenantId, data.creditType, data.credits),
        onSuccess: () => {
            setOpen(false);
        },
    });
    const formFieldMeta = useMemo(() => {
        return {
            Tenant: {
                type: "searchable_dropdown",
                required: true,
                placeholder: "Tenant",
                defaultValue: undefined,
                options: (allTenantCredits === null || allTenantCredits === void 0 ? void 0 : allTenantCredits.tenants.map((tenant) => {
                    return {
                        label: tenant.name,
                        value: tenant.id.toString(),
                    };
                })) || [],
            },
            CreditType: {
                type: "select",
                required: true,
                placeholder: "Credit Type",
                options: (allTenantCredits === null || allTenantCredits === void 0 ? void 0 : allTenantCredits.credit_types.map((creditType) => {
                    return {
                        label: creditType,
                        value: creditType,
                    };
                })) || [],
            },
            Credits: {
                type: "number",
                required: true,
                placeholder: "Credits",
            },
        };
    }, [allTenantCredits]);
    if (!allTenantCredits || isLoading) {
        return null;
    }
    return (_jsx(BaseModal2, { title: "Credit Management", description: "Edit credits for any tenant", open: open, setOpen: setOpen, submitText: "Update Credits", submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
            yield updateTenantCreditsMutation.mutateAsync({
                tenantId: Number.parseInt(fields.Tenant.value),
                creditType: fields.CreditType,
                credits: fields.Credits,
            });
        }), zodSchema: z.object({
            Tenant: z.object({
                label: z.string().min(1),
                value: z.string().min(1),
            }),
            CreditType: z.nativeEnum(TenantCreditTypeEnum),
            Credits: z.number().min(0, "Must be a positive number").int(),
        }), formFieldMeta: formFieldMeta }));
};
