import { v4 as uuid4 } from "uuid";
import { WiserBackendQueryOperator, } from "../query_builder/types";
export class WiserBackendSortRuleConverter {
    constructor(config) {
        this.config = config;
    }
    convertSortRulesToBackendOrderBy(sortRules) {
        return sortRules
            .filter((sortRule) => !!this.config.fields.find((field) => field.id === sortRule.field.id))
            .map((sortRule) => {
            var _a;
            const field = this.config.fields.find((field) => field.id === sortRule.field.id);
            if ((_a = field.meta) === null || _a === void 0 ? void 0 : _a.relatedModelFieldName) {
                if (field.meta.proxyFieldKey) {
                    return {
                        field: `${field.name.replaceAll(" ", "_").toLowerCase()}`,
                        order: sortRule.sortOrder,
                        outer_ref_field_name: field.meta.relatedModelFieldName,
                        subquery: {
                            table: field.meta.model,
                            select_field: field.meta.proxyFieldValue,
                            order_by_fields: [],
                            structured_filter: {
                                where_condition: {
                                    operator: WiserBackendQueryOperator.EQUALS,
                                    field: field.meta.proxyFieldKey,
                                    value: Number.parseInt(field.id),
                                },
                            },
                        },
                    };
                }
                return {
                    field: `${field.name.replaceAll(" ", "_").toLowerCase()}`,
                    order: sortRule.sortOrder,
                    outer_ref_field_name: field.meta.relatedModelFieldName,
                    subquery: {
                        table: field.meta.model,
                        select_field: field.id,
                        order_by_fields: [],
                        structured_filter: {
                            where_condition: {
                                operator: WiserBackendQueryOperator.AND,
                                subconditions: [],
                            },
                        },
                    },
                };
            }
            return {
                field: field.id,
                order: sortRule.sortOrder,
            };
        });
    }
    convertBackendOrderByToSortRules(orderByClauses) {
        const sortRules = orderByClauses.map((orderByClause) => {
            if (!orderByClause.subquery) {
                const field = this.config.fields.find((field) => field.id === orderByClause.field);
                if (!field) {
                    return undefined;
                }
                return {
                    id: uuid4(),
                    field,
                    sortOrder: orderByClause.order,
                };
            }
            // For clauses that use subquery, the field in the clause is the new annotated
            // field name. What we really want is the field using which it was built.
            // There can be two types of subqueries:
            // 1. That don't use a proxy field (e.g. a direct field of a related model). This
            //    is same as the selected field.
            // 2. That use a proxy field (e.g. for CRM field values). In this case the actual
            //    field is fetched using the subquery's filter condition.
            const subquery = orderByClause.subquery;
            // Type 1
            if (subquery.structured_filter.where_condition.operator ===
                WiserBackendQueryOperator.AND) {
                const field = this.config.fields.find((field) => field.id === subquery.select_field);
                if (!field) {
                    return undefined;
                }
                return {
                    id: uuid4(),
                    field,
                    sortOrder: orderByClause.order,
                };
            }
            // Type 2
            if (subquery.structured_filter.where_condition.operator ===
                WiserBackendQueryOperator.EQUALS) {
                const field = this.config.fields.find((field) => field.id ===
                    `${subquery.structured_filter.where_condition.value}`);
                if (!field) {
                    return undefined;
                }
                return {
                    id: uuid4(),
                    field,
                    sortOrder: orderByClause.order,
                };
            }
        });
        return sortRules.filter((rule) => !!rule);
    }
}
