var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { queryTable } from "@/common/endpoints";
import { QueryFilterPopover } from "@/common/query_builder/ui/query_filter_popover";
import { SortRuleBuilderPopover } from "@/common/sort_rule_builder/ui/sort_rule_builder_popover";
import { GridStatePopover } from "@/components/ui/grid_state_popover";
import { useFilterConfig } from "@/table_hooks/use_filters";
import { useSortRuleConfig } from "@/table_hooks/use_sort";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ModuleRegistry, RowSelectionModule, } from "ag-grid-community";
import { AllEnterpriseModule, ServerSideRowModelModule, } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { createElement, useCallback, useMemo, useRef, useState } from "react";
import { useAgGridFilterChange } from "../hooks/use_ag_grid_filter_change";
import { useAgGridSortChange } from "../hooks/use_ag_grid_sort_change";
import { useAgGridStates } from "../hooks/use_ag_grid_states";
import { useAgGridTableSchema } from "../hooks/use_ag_grid_table_schema";
import { DEFAULT_DATA_GRID_THEME } from "./ag_grid_themes";
import { DataTableContext } from "./data_table_context";
// See the AG Grid docs -- some of the heavier components require importing
// modules like this.
// https://www.ag-grid.com/javascript-data-grid/modules/
ModuleRegistry.registerModules([
    AllEnterpriseModule,
    RowSelectionModule,
    ServerSideRowModelModule,
]);
function hashString(str) {
    return __awaiter(this, void 0, void 0, function* () {
        const hash = yield crypto.subtle.digest("SHA-256", new TextEncoder().encode(str));
        return Array.from(new Uint8Array(hash))
            .map((b) => b.toString(16))
            .join("");
    });
}
export const AgDataGrid = ({ tableName, ignoredColumns, tanstackColDefs, useTanstackCellRenderers, defaultGridState, filterableFields, sortableFields, floatingBar, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    // Overall data flow in setting up a grid:
    // 1. get the table schema
    // 2. get the initial state from the URL / local storage
    // 3. create initial state overrides from the layout selector
    // 4. create a list of column definitions, using 2 & 3
    // 5. create the ag-grid
    // 6. apply the initial state to the grid
    // (optional / depends on user interaction)
    // 7. save the grid state to local storage when it changes
    // 8. update the URL when the grid state changes
    // 9. update the grid state when the URL changes
    // 10. update the grid state when the layout selector changes (rebuilds from #3)
    const gridApi = useRef(null);
    const [readyToSaveState, setReadyToSaveState] = useState(false);
    const gridStateReady = useRef(false);
    const [selectedStateFingerprint, setSelectedStateFingerprint] = useState("0");
    const [selectedGridLayout, setSelectedGridLayout] = useState(undefined);
    const [searchQueryValue, setSearchQueryValue] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    // Debounce search query changes to prevent excessive API calls
    const onSearchQueryValueChange = () => {
        var _a;
        (_a = gridApi.current) === null || _a === void 0 ? void 0 : _a.api.refreshServerSide();
    };
    // NOTE: Our custom implementation of debounce in `debounce.ts` does not work
    // for functions and only works for values.
    // Ref: https://chatgpt.com/share/67e910c3-88d8-800d-bd5e-7292d512101d
    const [debouncedOnSearchQueryValueChange] = useState(() => {
        let timeoutId;
        return () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                onSearchQueryValueChange();
            }, 400);
        };
    });
    // We store states in local storage. These states are quite large, so we only
    // keep that last N (otherwise, this was already crashing in my local development)
    const MAX_STORED_STATES = 10; // Configure maximum number of states to keep
    const getStoredStates = () => {
        const stored = localStorage.getItem("gridStates");
        return stored ? JSON.parse(stored) : { states: {}, keys: [] };
    };
    const onSaveGridColumnState = useCallback((params) => __awaiter(void 0, void 0, void 0, function* () {
        var _p, _q, _r, _s;
        // We use this same callback from multiple events, which have different
        // params types.
        // This fingerprints the grid state, sets that fingerprint in
        // the URL, and stores the map of fingerprint->state in local storage.
        const api = params.api;
        const state = Object.assign(Object.assign({}, api.getState()), { wiserFilter: (_q = (_p = api.getGridOption("context")) === null || _p === void 0 ? void 0 : _p.wiserFilter) !== null && _q !== void 0 ? _q : {}, wiserSort: (_s = (_r = api.getGridOption("context")) === null || _r === void 0 ? void 0 : _r.wiserSort) !== null && _s !== void 0 ? _s : [] });
        if (!readyToSaveState || state.columnOrder === undefined) {
            // The grid is not yet initialized, so we don't save the state.
            return;
        }
        // These don't serialize consistently.
        state.sideBar = undefined;
        // Compare current state with state from URL
        // Order the state to ensure consistent fingerprints
        const orderedState = {
            columnOrder: state.columnOrder,
            columnSizing: state.columnSizing,
            columnVisibility: state.columnVisibility,
            wiserFilter: state.wiserFilter,
            wiserSort: state.wiserSort,
        };
        const stateFingerprint = yield hashString(
        // This ensures that the state is recursively sorted by key
        // to generate stable fingerprints.
        JSON.stringify(orderedState, (_, value) => value && typeof value === "object" && !Array.isArray(value)
            ? Object.keys(value)
                .sort()
                .reduce((acc, k) => {
                acc[k] = value[k];
                return acc;
            }, {})
            : value));
        setSelectedStateFingerprint(stateFingerprint);
        setFinalInitialState(state);
        const stateFingerprintString = stateFingerprint.toString();
        // Get current stored states
        const storedStates = getStoredStates();
        // Add new state
        storedStates.states[stateFingerprint] = state;
        // Update keys list - remove if exists, then add to front
        storedStates.keys = storedStates.keys.filter((k) => k !== stateFingerprintString);
        storedStates.keys.unshift(stateFingerprintString);
        // Trim to MAX_STORED_STATES
        while (storedStates.keys.length > MAX_STORED_STATES) {
            const oldKey = storedStates.keys.pop();
            if (oldKey) {
                delete storedStates.states[oldKey];
            }
        }
        // Save back to localStorage
        localStorage.setItem("gridStates", JSON.stringify(storedStates));
        // Update URL
        const url = new URL(window.location.href);
        url.searchParams.set("state", stateFingerprintString);
        window.history.pushState({}, "", url.toString());
        return stateFingerprint;
    }), [readyToSaveState]);
    const { columnDefs } = useAgGridTableSchema(tableName, tanstackColDefs, ignoredColumns, useTanstackCellRenderers);
    const initialState = useMemo(() => {
        var _a;
        const url = new URL(window.location.href);
        const state = (_a = url.searchParams.get("state")) === null || _a === void 0 ? void 0 : _a.toString();
        if (!state)
            return defaultGridState.state;
        const storedStates = getStoredStates();
        const parsedState = storedStates.states[state];
        if (!parsedState) {
            console.log("Could not find serialized state", state.toString());
            return {};
        }
        gridStateReady.current = true;
        return parsedState;
    }, []);
    const [finalInitialState, setFinalInitialState] = useState(initialState);
    const columnsWithSorts = useMemo(() => {
        var _a;
        if (!columnDefs)
            return [];
        if (!((_a = finalInitialState.columnOrder) === null || _a === void 0 ? void 0 : _a.orderedColIds))
            return columnDefs;
        return [
            ...columnDefs.sort((a, b) => {
                const aIndex = finalInitialState.columnOrder.orderedColIds.findIndex((columnName) => columnName === a.field);
                if (aIndex === -1)
                    return 1;
                const bIndex = finalInitialState.columnOrder.orderedColIds.findIndex((columnName) => columnName === b.field);
                if (bIndex === -1)
                    return -1;
                return aIndex - bIndex;
            }),
        ];
    }, [columnDefs, finalInitialState]);
    // We have to work around some of the AG Grid init flow, because it doesn't
    // like to apply table state without some coercion.
    const firstLoad = useRef(true);
    const serverSideDatasource = useMemo(() => {
        return {
            getRows: (params) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b, _c, _d, _e, _f;
                const startRow = params.request.startRow;
                const endRow = params.request.endRow;
                firstLoad.current = false;
                // TODO: Use react query for fetching table data.
                const data = yield queryTable({
                    tableName: tableName,
                    filter: JSON.stringify((_c = (_b = (_a = gridApi.current) === null || _a === void 0 ? void 0 : _a.api.getGridOption("context")) === null || _b === void 0 ? void 0 : _b.wiserFilter) !== null && _c !== void 0 ? _c : {}),
                    sort: JSON.stringify((_f = (_e = (_d = gridApi.current) === null || _d === void 0 ? void 0 : _d.api.getGridOption("context")) === null || _e === void 0 ? void 0 : _e.wiserSort) !== null && _f !== void 0 ? _f : []),
                    startRow: startRow !== null && startRow !== void 0 ? startRow : 0,
                    endRow: endRow !== null && endRow !== void 0 ? endRow : 50,
                    searchQuery: searchQueryValue,
                });
                const rows = data.rows.map((row) => {
                    if (!row)
                        return null;
                    return row;
                });
                params.success({
                    rowData: rows,
                    rowCount: data.totalRows,
                });
            }),
        };
    }, [columnDefs, searchQueryValue]);
    const applyLayout = (layout) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const state = layout.state;
        if ((_a = state === null || state === void 0 ? void 0 : state.columnSizing) === null || _a === void 0 ? void 0 : _a.columnSizingModel) {
            const columnWidths = (_b = state.columnSizing) === null || _b === void 0 ? void 0 : _b.columnSizingModel.map((col) => ({
                key: col.colId,
                newWidth: col.width,
            }));
            (_c = gridApi.current) === null || _c === void 0 ? void 0 : _c.api.setColumnWidths(columnWidths);
        }
        (_d = gridApi.current) === null || _d === void 0 ? void 0 : _d.api.setColumnsVisible((_f = (_e = state.columnVisibility) === null || _e === void 0 ? void 0 : _e.hiddenColIds) !== null && _f !== void 0 ? _f : [], false);
        (_g = gridApi.current) === null || _g === void 0 ? void 0 : _g.api.setGridOption("context", {
            wiserFilter: state.wiserFilter,
            wiserSort: state.wiserSort,
        });
        (_h = gridApi.current) === null || _h === void 0 ? void 0 : _h.api.refreshServerSide();
        setFinalInitialState(state);
        setSelectedGridLayout(layout);
        gridStateReady.current = true;
    };
    const { savedLayouts, mutateSavedLayoutsAndApply } = useAgGridStates(tableName, defaultGridState, applyLayout);
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(filterableFields);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(sortableFields);
    const initialFilter = useMemo(() => {
        if (!finalInitialState.wiserFilter ||
            Object.keys(finalInitialState.wiserFilter).length === 0)
            return undefined;
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(finalInitialState.wiserFilter);
    }, [finalInitialState]);
    const initialSortRules = useMemo(() => {
        if (!finalInitialState.wiserSort)
            return [];
        return wiserBackendSortRuleConverter.convertBackendOrderByToSortRules(finalInitialState.wiserSort);
    }, [finalInitialState]);
    const { onFilterChange } = useAgGridFilterChange((_a = gridApi.current) === null || _a === void 0 ? void 0 : _a.api, onSaveGridColumnState);
    const { onSortRulesChange } = useAgGridSortChange((_b = gridApi.current) === null || _b === void 0 ? void 0 : _b.api, onSaveGridColumnState);
    const toolPanelDefs = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                    },
                },
            ],
        };
    }, []);
    const rowSelection = useMemo(() => {
        return {
            mode: "multiRow",
        };
    }, []);
    const allRowsInGrid = [];
    (_c = gridApi.current) === null || _c === void 0 ? void 0 : _c.api.forEachNode((node) => {
        if (node.data) {
            allRowsInGrid.push(node.data);
        }
    });
    const agGrid = useMemo(() => {
        var _a, _b;
        // This avoids doing an intiial render we will just immediately replace.
        if (!gridStateReady.current)
            return null;
        return (_jsx(AgGridReact, { theme: DEFAULT_DATA_GRID_THEME, ref: gridApi, columnDefs: columnsWithSorts, 
            // While initialState is not sufficient to do all setup from layouts,
            // it is required for pagination and filtering to get set up correctly.
            initialState: finalInitialState, rowModelType: "serverSide", serverSideDatasource: serverSideDatasource, defaultColDef: {
                resizable: true,
            }, getRowId: (params) => {
                return params.data.id.toString();
            }, rowHeight: 60, rowSelection: rowSelection, cacheBlockSize: 50, maxBlocksInCache: 2, loadingOverlayComponentParams: {
                loadingMessage: "One moment please...",
            }, pagination: true, paginationPageSize: (_b = (_a = initialState.pagination) === null || _a === void 0 ? void 0 : _a.pageSize) !== null && _b !== void 0 ? _b : 20, autoSizeStrategy: {
                type: "fitCellContents",
                // TODO: Figure out a max width for each column
            }, sideBar: toolPanelDefs, onSelectionChanged: () => {
                var _a;
                // For server side rendered grids, the select all button in the header does not
                // update the selected rows and instead maintains a different grid state.
                // Hence this method is needed to convert it into a simple selected rows list
                // which can then be used to render floating bars etc.
                const serverSelectionState = (_a = gridApi.current) === null || _a === void 0 ? void 0 : _a.api.getServerSideSelectionState();
                if (!serverSelectionState) {
                    setSelectedRows([]);
                    return;
                }
                const selectedRows = serverSelectionState.selectAll
                    ? allRowsInGrid
                    : serverSelectionState.toggledNodes.map((rowId) => allRowsInGrid.find((row) => `${row.id}` === rowId));
                setSelectedRows(selectedRows.filter((row) => row !== undefined));
            }, onColumnResized: onSaveGridColumnState, onColumnMoved: onSaveGridColumnState, onColumnVisible: onSaveGridColumnState, onColumnPinned: onSaveGridColumnState, onPaginationChanged: onSaveGridColumnState, onGridReady: (e) => {
                e.api.setGridOption("context", {
                    wiserFilter: finalInitialState.wiserFilter,
                    wiserSort: finalInitialState.wiserSort,
                });
            }, onFirstDataRendered: (e) => {
                // NOTE: this doesn't work in onGridReady.
                setTimeout(() => {
                    var _a, _b, _c, _d;
                    let waitForPage = false;
                    if (((_a = initialState.pagination) === null || _a === void 0 ? void 0 : _a.page) &&
                        initialState.pagination.page !== e.api.paginationGetCurrentPage()) {
                        // This doesn't work if you try to run it immediately.
                        // Strangely, it also doesn't work if I drop either this
                        // paginationGoToPage or the one above in onGridReady.
                        waitForPage = true;
                        setTimeout(() => {
                            e.api.paginationGoToPage(initialState.pagination.page);
                            setReadyToSaveState(true);
                        }, 1000);
                    }
                    e.api.setColumnsVisible((_c = (_b = finalInitialState.columnVisibility) === null || _b === void 0 ? void 0 : _b.hiddenColIds) !== null && _c !== void 0 ? _c : [], false);
                    if ((_d = finalInitialState.columnSizing) === null || _d === void 0 ? void 0 : _d.columnSizingModel) {
                        e.api.setColumnWidths(finalInitialState.columnSizing.columnSizingModel.map((col) => ({
                            key: col.colId,
                            newWidth: col.width,
                        })));
                    }
                    if (!waitForPage) {
                        setReadyToSaveState(true);
                    }
                }, 20);
            } }));
    }, [columnDefs, finalInitialState, gridStateReady]);
    return (_jsx(DataTableContext.Provider, Object.assign({ value: {
            tableObjectName: tableName,
            totalRowCount: (_e = (_d = gridApi.current) === null || _d === void 0 ? void 0 : _d.api.getDisplayedRowCount()) !== null && _e !== void 0 ? _e : 0,
            filterQuery: JSON.stringify((_h = (_g = (_f = gridApi.current) === null || _f === void 0 ? void 0 : _f.api.getGridOption("context")) === null || _g === void 0 ? void 0 : _g.wiserFilter) !== null && _h !== void 0 ? _h : {}),
        } }, { children: _jsx("div", Object.assign({ className: "ag-theme-alpine h-screen w-full" }, { children: _jsxs("div", Object.assign({ className: "h-full flex flex-col" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 justify-between items-center p-2 border-b" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2 items-center p-2 border-b" }, { children: [_jsx(GridStatePopover, { tableName: "calls", defaultGridState: defaultGridState, savedLayouts: savedLayouts, applyLayout: applyLayout, currentGridState: {
                                            fingerprint: selectedStateFingerprint !== "0"
                                                ? selectedStateFingerprint
                                                : ((_j = selectedGridLayout === null || selectedGridLayout === void 0 ? void 0 : selectedGridLayout.fingerprint_v2) !== null && _j !== void 0 ? _j : "0"),
                                            state: Object.assign(Object.assign({}, finalInitialState), { wiserFilter: (_l = (_k = gridApi.current) === null || _k === void 0 ? void 0 : _k.api.getGridOption("context")) === null || _l === void 0 ? void 0 : _l.wiserFilter, wiserSort: (_o = (_m = gridApi.current) === null || _m === void 0 ? void 0 : _m.api.getGridOption("context")) === null || _o === void 0 ? void 0 : _o.wiserSort }),
                                            explicitlySet: gridStateReady.current,
                                            selectedGridLayout: selectedGridLayout,
                                        }, mutateSavedLayoutsAndApply: mutateSavedLayoutsAndApply }), _jsx(QueryFilterPopover, { filterPanelTitle: "Filter Calls", onFilterChange: onFilterChange, filterConfig: filterConfig, initialFilter: initialFilter }), _jsx(SortRuleBuilderPopover, { panelTitle: "Sort Calls", config: sortRuleConfig, onSortRulesChange: onSortRulesChange, initialSortRules: initialSortRules }, initialSortRules.map((rule) => rule.id).join(","))] })), _jsxs("div", Object.assign({ className: "flex w-80 h-8 px-3 items-center gap-1 border border-gray-300 bg-white rounded-lg" }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-4 w-4 text-gray-500 shrink-0" }), _jsx("input", { type: "text", placeholder: "Search", className: "w-full h-full focus:outline-none border-0 focus:ring-0 text-gray-500", value: searchQueryValue, onChange: (e) => {
                                            setSearchQueryValue(e.target.value);
                                            debouncedOnSearchQueryValueChange();
                                        } })] }))] })), agGrid, floatingBar && selectedRows.length > 0 && (_jsx("div", Object.assign({ className: "flex justify-end p-2 border-t" }, { children: createElement(floatingBar, {
                            getVisibleColumns: () => {
                                return columnsWithSorts.map((col) => {
                                    var _a, _b, _c;
                                    return ({
                                        accessor: (_a = col.field) !== null && _a !== void 0 ? _a : "",
                                        header: (_c = (_b = col.headerName) !== null && _b !== void 0 ? _b : col.field) !== null && _c !== void 0 ? _c : "",
                                    });
                                });
                            },
                            getSelectedRows: () => {
                                return selectedRows;
                            },
                            toggleAllRowsSelected: (value) => {
                                var _a, _b;
                                if (value) {
                                    (_a = gridApi.current) === null || _a === void 0 ? void 0 : _a.api.selectAll();
                                }
                                else {
                                    (_b = gridApi.current) === null || _b === void 0 ? void 0 : _b.api.deselectAll();
                                }
                            },
                        }) })))] })) })) })));
};
