var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import amplitudeInstance from "../amplitude";
import { useStreamSocket, } from "../common/stream_socket";
import WaitingSpinner from "../waiting_spinner";
const BotToggle = ({ callId, botStatus: zoomBotStatus, setAudioCaptureState, }) => {
    const isBotActive = (status) => {
        return [
            "Initializing",
            "Started",
            "In Waiting Room",
            "Connected",
            "Speaking",
            "Waiting to Record",
            "Recording",
            "Needs Host to Join Meeting",
            "Blocked by Host's Local Recording Settings",
        ].includes(status);
    };
    setAudioCaptureState(isBotActive(zoomBotStatus));
    const streamSocket = useStreamSocket();
    const [toggleEnabled, setToggleEnabled] = useState(isBotActive(zoomBotStatus));
    const [showSpinner, setShowSpinner] = useState(false);
    const retryJoinInterval = useRef(null);
    const joinZoomAttempts = useRef(0);
    useEffect(() => {
        setToggleEnabled(isBotActive(zoomBotStatus));
        setShowSpinner(false);
        if (retryJoinInterval.current) {
            clearInterval(retryJoinInterval.current);
            retryJoinInterval.current = null;
        }
    }, [zoomBotStatus]);
    const onToggle = (active) => __awaiter(void 0, void 0, void 0, function* () {
        setShowSpinner(true);
        // The call startZoomBot/stopZoomBot will update the zoomBotStatus
        // which should automatically update the toggle state & spinner state
        if (active) {
            streamSocket.send(JSON.stringify({ type: "start_bot" }));
            amplitudeInstance.track("Toggle Bot On", { botStatus: zoomBotStatus });
        }
        else {
            streamSocket.send(JSON.stringify({ type: "stop_bot" }));
            amplitudeInstance.track("Toggle Bot Off", { botStatus: zoomBotStatus });
        }
        // Leaving the spinner going forever while failing to connect is not nice.
        // So, we retry on an interval and give up eventually. This also handles
        // cases where the socket startup is slower than a user clicking on the
        // zoom toggle, but more general than just fixing the socket sequence.
        joinZoomAttempts.current = 0;
        retryJoinInterval.current = setInterval(() => {
            if (joinZoomAttempts.current > 5) {
                // Either the join worked or we've tried too many times and should give up.
                clearInterval(retryJoinInterval.current);
                setShowSpinner(false);
            }
            joinZoomAttempts.current += 1;
            if (active) {
                streamSocket.send(JSON.stringify({ type: "start_bot" }));
            }
            else {
                streamSocket.send(JSON.stringify({ type: "stop_bot" }));
            }
        }, 4000);
    });
    useEffect(() => {
        return () => {
            if (retryJoinInterval.current) {
                clearInterval(retryJoinInterval.current);
                retryJoinInterval.current = null;
            }
        };
    }, []);
    const botText = zoomBotStatus.length === 0 ? "Start Bot" : `Bot ${zoomBotStatus}`;
    return (_jsx("div", Object.assign({ className: "w-full max-w-xs mx-auto pr-2 lg:border-r border-gray-300" }, { children: _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx(Switch, Object.assign({ checked: toggleEnabled, onChange: onToggle, disabled: showSpinner, className: `${toggleEnabled ? "bg-blue-600" : "bg-gray-200"} relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500` }, { children: _jsx("span", Object.assign({ className: `${toggleEnabled ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 transform bg-white rounded-full transition-transform` }, { children: _jsx("div", Object.assign({ className: "absolute inset-0 bg-white rounded-full flex items-center justify-center p-0.5" }, { children: _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", alt: "Squiggle", className: "w-full h-full object-contain" }) })) })) })), _jsx("span", Object.assign({ className: "text-gray-700 shrink-0" }, { children: showSpinner ? _jsx(WaitingSpinner, { text: "Bot Loading..." }) : botText }))] })) })));
};
export default BotToggle;
