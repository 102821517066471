import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { getJobs } from "../../common/endpoints";
export const BackfillProgress = ({ tableObjectName, }) => {
    var _a, _b, _c;
    const queryClient = useQueryClient();
    const { data: allJobs } = useQuery({
        queryKey: ["backfillJobs"],
        queryFn: getJobs,
        refetchInterval: (query) => {
            const jobs = query.state.data;
            if (jobs === null || jobs === void 0 ? void 0 : jobs.some((job) => job.status === "Running")) {
                return 10000; // 10 seconds
            }
            return false;
        },
    });
    const [expanded, setExpanded] = useState(false);
    const dismissJob = (jobId) => {
        const dismissedJobs = localStorage.getItem("dismissedBackfillJobs");
        if (!dismissedJobs) {
            localStorage.setItem("dismissedBackfillJobs", jobId.toString());
        }
        else {
            localStorage.setItem("dismissedBackfillJobs", `${dismissedJobs},${jobId}`);
        }
        queryClient.invalidateQueries({
            queryKey: ["backfillJobs"],
        });
    };
    const isDismissedByUser = (jobId) => {
        const dismissedJobs = localStorage.getItem("dismissedBackfillJobs");
        if (!dismissedJobs) {
            return false;
        }
        return dismissedJobs.split(",").includes(jobId.toString());
    };
    // Only show jobs that are running or have been triggered in the last 24 hours
    const jobsToRender = (_c = (_b = (_a = allJobs === null || allJobs === void 0 ? void 0 : allJobs.filter((job) => !isDismissedByUser(job.id))) === null || _a === void 0 ? void 0 : _a.filter((job) => job.job_type === "BRICK_BACKFILL")) === null || _b === void 0 ? void 0 : _b.filter((job) => job.name.startsWith(tableObjectName)).filter((job) => job.status === "Running" ||
        new Date(job.triggered_at).getTime() >
            Date.now() - 1000 * 60 * 60 * 24).sort((a, b) => new Date(b.triggered_at).getTime() -
        new Date(a.triggered_at).getTime())) !== null && _c !== void 0 ? _c : [];
    const jobTaskStatusText = (job) => {
        if (job.status === "Running" &&
            job.total_tasks > 0 &&
            job.completed_tasks === job.total_tasks) {
            // While this type of job will eventually be marked as "Completed" in the BE,
            // but it will only happen via the beat and hence might be as slow as the next beat
            // run. So we just show "Completed" here to make it more responsive.
            return "Completed";
        }
        return job.status;
    };
    const jobTaskCount = (job) => {
        if (job.status === "Queued" || job.total_tasks <= 0) {
            return "-/-";
        }
        if (job.status === "Failed") {
            return `${job.completed_tasks} Completed, ${job.failed_tasks} Failed`;
        }
        if (job.status === "Completed") {
            if (job.failed_tasks > 0) {
                return `${job.completed_tasks} Completed, ${job.failed_tasks} Failed`;
            }
            return `${job.completed_tasks}/${job.total_tasks}`;
        }
        return `${job.completed_tasks}/${job.total_tasks}`;
    };
    const progressColor = (job, progress) => {
        if (job.status === "Failed") {
            return "bg-red-500";
        }
        if (progress > 0 && progress < 100) {
            return "bg-wds-blue-3";
        }
        if (progress === 100) {
            return "bg-green-500";
        }
        return "bg-wds-gray-4";
    };
    const renderJobDetails = (job) => {
        const progress = ((job.completed_tasks + job.failed_tasks) / job.total_tasks) * 100;
        return (_jsxs("div", Object.assign({ className: "flex justify-between items-start self-stretch gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col gap-1 w-full" }, { children: [_jsx("span", { children: job.name }), _jsxs("span", Object.assign({ className: "flex flex-row justify-between self-stretch items-center text-wds-gray-4 text-sm" }, { children: [jobTaskStatusText(job), " ", _jsx("span", { children: jobTaskCount(job) })] })), job.total_tasks > 0 && (_jsx("div", Object.assign({ className: "w-full h-2 bg-gray-200 rounded-full" }, { children: _jsx("div", { className: cn("h-full rounded-full", progressColor(job, progress)), style: { width: `${progress}%` } }) }))), job.failure_reason && (_jsx("span", Object.assign({ className: "text-red-500 text-sm" }, { children: job.failure_reason })))] }), job.id), _jsx(Button, Object.assign({ variant: "ghost", className: "p-0 hover:bg-transparent text-wds-gray-4", onClick: () => dismissJob(job.id) }, { children: _jsx(XCircleIcon, { className: "w-4 h-4 shrink-0" }) }))] })));
    };
    if (jobsToRender.length === 0) {
        return null;
    }
    return (_jsxs(Card, Object.assign({ className: "flex flex-col z-10 absolute bottom-4 right-4 w-96" }, { children: [_jsxs(CardHeader, Object.assign({ className: "flex-row space-y-0 items-center justify-between self-stretch py-2" }, { children: [_jsx("span", Object.assign({ className: "font-bold" }, { children: "Backfill Progress" })), _jsx(Button, Object.assign({ variant: "ghost", onClick: () => setExpanded(!expanded), className: "p-0 hover:bg-transparent" }, { children: expanded ? (_jsx(ChevronUpIcon, { className: "w-4 h-4" })) : (_jsx(ChevronDownIcon, { className: "w-4 h-4" })) }))] })), expanded && (_jsxs(_Fragment, { children: [_jsx(CardContent, Object.assign({ className: "flex flex-col gap-4 pb-2 max-h-32 overflow-y-auto" }, { children: jobsToRender.map((job) => renderJobDetails(job)) })), _jsx(CardFooter, Object.assign({ className: "pt-2" }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "p-0 hover:bg-transparent", onClick: () => window.location.reload() }, { children: "Reload the page to see updates" })) }))] }))] })));
};
