var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import amplitudeInstance from "../amplitude";
import * as endpoints from "../common/endpoints";
import { useStreamSocket, } from "../common/stream_socket";
import { PostCallOutputTarget, } from "../types";
import * as actions from "./actions";
import { CRM_DATA_UPDATE_TARGETS, INTERNAL_MESSAGING_TARGETS, } from "./data_processing_config";
import FieldMappingCard from "./field_mapping_card";
import { FieldMappingCardSummary } from "./field_mapping_card";
import useIntegrations from "./use_integrations";
import usePostCallOutputs from "./use_post_call_outputs";
import { isEmailItem, isTenantConnectedToExternalCrm } from "./utils";
const DataProcessingTab = (props) => {
    const { postCallOutputMap, updatePostCallOutputInState, fetchPostCallOutput, } = usePostCallOutputs({
        callId: props.call.id,
    });
    const { integrations, loaded } = useIntegrations();
    // This set is used to identify post-call output items that are getting regenerated
    const [postCallOutputsRegenerationIdsSet, setPostCallOutputsRegenerationIdsSet,] = useState(new Set());
    const connectedIntegrations = useMemo(() => {
        if (!loaded) {
            return [];
        }
        return integrations;
    }, [loaded]);
    const [integrationsData, setIntegrationsData] = useState([]);
    const [undoStack, setUndoStack] = useState(new Map());
    const provenanceRecords = useMemo(() => {
        const provenances = new Map();
        for (const noteSection of props.callNotes) {
            for (const note of noteSection.notes) {
                if (!note.provenances)
                    continue;
                for (const provenance of note.provenances) {
                    provenances.set(provenance.id, provenance);
                }
            }
        }
        return Array.from(provenances.values());
    }, [props.callNotes]);
    // This state is used to render the loading state on the field mapping card
    // while the item is being updated or processed.
    // The key is the post call output item id and the value is a string indicating
    // the status of the item.
    const [itemLoadingStatus, setItemLoadingStatus] = useState(new Map());
    // Ref for getting the latest state value during playbook item updates received via polling
    const postCallOutputMapRef = useRef(postCallOutputMap);
    useEffect(() => {
        postCallOutputMapRef.current = postCallOutputMap;
    }, [postCallOutputMap]);
    const streamSocket = useStreamSocket();
    useEffect(() => {
        streamSocket.addListener("post_call_created_from_notes", handlePostCallRegenerated);
        streamSocket.connectToStreamSocket(props.call.id);
    }, []);
    const handlePostCallRegenerated = (message) => {
        if (!message.post_call_created_from_notes ||
            !message.post_call_created_from_notes.stage_content ||
            !message.post_call_created_from_notes.stage_content.sourcePlaybookItemId) {
            return;
        }
        const playbookItemId = message.post_call_created_from_notes.stage_content.sourcePlaybookItemId;
        Array.from(postCallOutputMapRef.current.entries()).forEach(([itemId, { item }]) => {
            var _a;
            if (((_a = item.fieldMap.sourcePlaybookItem) === null || _a === void 0 ? void 0 : _a.id) === playbookItemId) {
                fetchPostCallOutput(itemId).then(() => {
                    updateItemLoadingStatus(itemId, "LOADED");
                    setPostCallOutputsRegenerationIdsSet((prev) => {
                        const updatedIdsSet = new Set(prev);
                        updatedIdsSet.delete(itemId);
                        return updatedIdsSet;
                    });
                });
            }
        });
    };
    const updateUndoStack = (itemId, value) => {
        setUndoStack((prev) => {
            const stack = prev.get(itemId) || [];
            return new Map(prev.set(itemId, [...stack, value]));
        });
    };
    const updateItemLoadingStatus = (postCallOutputId, status) => {
        setItemLoadingStatus((prev) => {
            const updatedMap = new Map(prev);
            updatedMap.set(postCallOutputId, status);
            return updatedMap;
        });
    };
    useEffect(() => {
        setItemLoadingStatus((prev) => {
            const updatedMap = new Map(prev);
            Array.from(postCallOutputMap.keys()).forEach((itemId) => {
                if (!updatedMap.has(itemId)) {
                    updatedMap.set(itemId, "LOADED");
                }
            });
            return updatedMap;
        });
    }, [postCallOutputMap]);
    const postCallOutputs = useMemo(() => {
        return Array.from(postCallOutputMap.values());
    }, [postCallOutputMap]);
    const postCallOutputItemIdsToRender = useMemo(() => {
        return (postCallOutputs
            // This ensures that items with some delivery targets are only rendered here
            .filter(({ item }) => {
            var _a, _b;
            return ((_a = item.fieldMap.sourceBrick) === null || _a === void 0 ? void 0 : _a.delivery_targets) &&
                ((_b = item.fieldMap.sourceBrick) === null || _b === void 0 ? void 0 : _b.delivery_targets.length) > 0;
        })
            .map(({ item }) => item.id));
    }, [postCallOutputs]);
    const onSendItemClick = (itemId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        updateItemLoadingStatus(itemId, "SYNCING");
        const postCallOutputToExecute = (_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item;
        const updatePostCallOutputResponse = yield endpoints.executePostCallOutput(postCallOutputToExecute.id, (_c = (_b = postCallOutputToExecute.valueToUpdate) !== null && _b !== void 0 ? _b : postCallOutputToExecute.proposedValue) !== null && _c !== void 0 ? _c : "");
        if (updatePostCallOutputResponse.status === "FAILURE") {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updatePostCallOutputInState(updatePostCallOutputResponse.postCallOutput);
        updateItemLoadingStatus(itemId, "LOADED");
    });
    const onDataItemValueUpdate = (itemId, value, undoClicked) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e, _f;
        const postCallOutputToUpdate = (_d = postCallOutputMap.get(itemId)) === null || _d === void 0 ? void 0 : _d.item;
        const updatedDataItem = Object.assign(Object.assign({}, postCallOutputToUpdate), { state: "PENDING", valueToUpdate: value });
        updateItemLoadingStatus(itemId, "UPDATING");
        const updatePostCallOutputPromise = endpoints.updatePostCallOutput(updatedDataItem.id, updatedDataItem);
        // We update the items in state before waiting for the promise to resolve
        // becase we want to render the new state as soon as the user clicks on the
        // "Undo" button or save the edits.
        if (!undoClicked) {
            updateUndoStack(itemId, (_f = (_e = postCallOutputToUpdate.valueToUpdate) !== null && _e !== void 0 ? _e : postCallOutputToUpdate.proposedValue) !== null && _f !== void 0 ? _f : "");
        }
        updatePostCallOutputInState(updatedDataItem);
        const updatePostCallOutputResponse = yield updatePostCallOutputPromise;
        if (updatePostCallOutputResponse.status === "FAILURE") {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updateItemLoadingStatus(itemId, "LOADED");
    });
    const onDataItemTargetUpdate = (itemId, target, targetDetails, executePostCallAfterNMinutes, updateType) => __awaiter(void 0, void 0, void 0, function* () {
        var _g;
        const dataItem = (_g = postCallOutputMap.get(itemId)) === null || _g === void 0 ? void 0 : _g.item;
        const updatedDataItem = Object.assign(Object.assign({}, dataItem), { fieldMap: Object.assign(Object.assign({}, dataItem.fieldMap), { target,
                targetDetails,
                executePostCallAfterNMinutes,
                updateType }), target: target, targetDetails: targetDetails, valueToUpdate: undefined });
        updateItemLoadingStatus(itemId, "UPDATING");
        const response = yield endpoints.updatePostCallOutput(updatedDataItem.id, updatedDataItem);
        updatePostCallOutputInState(Object.assign(Object.assign({}, updatedDataItem), response.postCallOutput));
        if (response.status === "FAILURE" && response.error) {
            updateItemLoadingStatus(itemId, "INVALID_FIELD_VALUE");
            return;
        }
        updateItemLoadingStatus(itemId, "LOADED");
    });
    useEffect(() => {
        if (connectedIntegrations.length === 0) {
            return;
        }
        const connectedIntegrationTargets = connectedIntegrations.map((i) => i.name);
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.SALESFORCE)) {
            actions.fetchSalesforceIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
        if (connectedIntegrationTargets.includes(PostCallOutputTarget.HUBSPOT)) {
            actions.fetchHubspotIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
        if (!isTenantConnectedToExternalCrm(connectedIntegrations)) {
            // Wiser CRM is automatically connected if the tenant is not using any external CRM.
            actions.fetchWiserCrmIntegrationData().then((data) => {
                setIntegrationsData((prev) => [...prev, data]);
            });
        }
    }, [connectedIntegrations]);
    const handlePostCallBrickRegenerated = (brickId) => {
        // All the outputs come from the post call output here.
        Array.from(postCallOutputMapRef.current.entries()).forEach(([itemId, { item }]) => {
            var _a;
            if (((_a = item.fieldMap.sourceBrick) === null || _a === void 0 ? void 0 : _a.id) === brickId) {
                fetchPostCallOutput(itemId).then(() => {
                    updateItemLoadingStatus(itemId, "LOADED");
                    setPostCallOutputsRegenerationIdsSet((prev) => {
                        const updatedIdsSet = new Set(prev);
                        updatedIdsSet.delete(itemId);
                        return updatedIdsSet;
                    });
                });
            }
        });
    };
    const onPostCallOutputRegenerateBrickClicked = (itemId) => {
        var _a, _b, _c;
        if (!postCallOutputMap.has(itemId)) {
            return;
        }
        if (!((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item.fieldMap.sourceBrick)) {
            return;
        }
        updateItemLoadingStatus(itemId, "UPDATING");
        setPostCallOutputsRegenerationIdsSet((prev) => {
            const updatedIdsSet = new Set(prev);
            updatedIdsSet.add(itemId);
            return updatedIdsSet;
        });
        const sourceBrickId = (_c = (_b = postCallOutputMap.get(itemId)) === null || _b === void 0 ? void 0 : _b.item.fieldMap.sourceBrick) === null || _c === void 0 ? void 0 : _c.id;
        endpoints.executeBricks([sourceBrickId], () => {
            // Outputs here will come from the post call output.
            handlePostCallBrickRegenerated(sourceBrickId);
        }, props.call.id, undefined /* accountId */, true /* forceRefresh */);
    };
    const renderSection = (sectionTitle, postCallItemIds, showTrackerNotes) => {
        if (postCallItemIds.length === 0) {
            return null;
        }
        const getItemProperties = (item) => {
            if (!(item === null || item === void 0 ? void 0 : item.isLoaded))
                return { hasMappedContent: false, isMapped: false, isSynced: false };
            const hasMappedContent = !!item.item.valueToUpdate;
            const isMapped = !!item.item.targetDetails;
            const isSynced = item.item.state === "PROCESSED";
            return { hasMappedContent, isMapped, isSynced };
        };
        const compareItems = (aProps, bProps) => {
            // Req 1: Items with mapped content appear first
            if (aProps.hasMappedContent !== bProps.hasMappedContent) {
                return aProps.hasMappedContent ? -1 : 1;
            }
            // Req 2: Mapped items appear before unmapped items
            if (aProps.isMapped !== bProps.isMapped) {
                return aProps.isMapped ? -1 : 1;
            }
            // Req 3: Not synced items appear before synced items
            if (aProps.isSynced !== bProps.isSynced) {
                return aProps.isSynced ? 1 : -1;
            }
            return 0;
        };
        let reOrderedPostCallItemIds = postCallItemIds;
        if (sectionTitle === "Update CRM data") {
            reOrderedPostCallItemIds = postCallItemIds.sort((a, b) => {
                const aProps = getItemProperties(postCallOutputMap.get(a));
                const bProps = getItemProperties(postCallOutputMap.get(b));
                return compareItems(aProps, bProps);
            });
        }
        return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "self-stretch font-bold text-base" }, { children: sectionTitle })), reOrderedPostCallItemIds.map((itemId) => {
                    var _a, _b, _c, _d;
                    return (_jsx("div", Object.assign({ className: "self-stretch" }, { children: postCallOutputMap.has(itemId) &&
                            itemLoadingStatus.has(itemId) &&
                            ((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.isLoaded) ? (_jsx(FieldMappingCard, { postCallOutputItem: (_b = postCallOutputMap.get(itemId)) === null || _b === void 0 ? void 0 : _b.item, undoStack: undoStack.get(itemId) || [], call: props.call, itemLoadingStatus: itemLoadingStatus.get(itemId), onSendClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                var _e;
                                amplitudeInstance.track("Click Send Post-Call Item", {
                                    targetType: (_e = postCallOutputMap.get(itemId)) === null || _e === void 0 ? void 0 : _e.item.target,
                                });
                                yield onSendItemClick(itemId);
                            }), onDataItemValueUpdate: (value, undoClicked) => __awaiter(void 0, void 0, void 0, function* () {
                                yield onDataItemValueUpdate(itemId, value, undoClicked);
                            }), onDataItemTargetUpdate: (target, targetDetails, executePostCallAfterNMinutes, updateType) => __awaiter(void 0, void 0, void 0, function* () {
                                yield onDataItemTargetUpdate(itemId, target, targetDetails, executePostCallAfterNMinutes, updateType);
                            }), toggleDataItemEditOpen: (value) => {
                                if (value) {
                                    updateItemLoadingStatus(itemId, "USER_EDITING");
                                }
                                else {
                                    updateItemLoadingStatus(itemId, "LOADED");
                                }
                            }, postCallIntegrations: connectedIntegrations, integrationsData: integrationsData, showTrackerNotes: showTrackerNotes, executePostCallAfterNMinutes: (_c = postCallOutputMap.get(itemId)) === null || _c === void 0 ? void 0 : _c.item.fieldMap.executePostCallAfterNMinutes, postCallOutputRegenerationInProgress: !!postCallOutputsRegenerationIdsSet.has(itemId), onPostCallOutputRegenerateClicked: () => onPostCallOutputRegenerateBrickClicked(itemId), provenanceRecords: provenanceRecords }, itemId)) : (_jsx(FieldMappingCardSummary, { postCallOutputItem: (_d = postCallOutputMap.get(itemId)) === null || _d === void 0 ? void 0 : _d.item }, itemId)) }), itemId));
                })] })));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col w-full gap-6 items-start" }, { children: [renderSection("Follow-up email", postCallOutputItemIdsToRender
                // Show this as an email if the delivery target is email or if the
                // source playbook item is a follow-up email
                .filter((itemId) => {
                var _a;
                return postCallOutputMap.has(itemId) &&
                    isEmailItem((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item);
            }), 
            /* showTrackerNotes= */ false), renderSection("Internal messaging", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => {
                var _a;
                return INTERNAL_MESSAGING_TARGETS.includes((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item.target);
            }), 
            /* showTrackerNotes= */ false), renderSection("Push meeting to CRM", postCallOutputItemIdsToRender
                .filter((itemId) => postCallOutputMap.has(itemId))
                .filter((itemId) => {
                var _a;
                return ((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item.target) ===
                    PostCallOutputTarget.CRM_EVENT;
            }), 
            /* showTrackerNotes= */ false), renderSection("Update CRM data", postCallOutputItemIdsToRender
                // Follow-up emails currently have no target associated (will eventually
                // get GMAIL as the target), but we don't want to render them in the un-mapped
                // list here because it is already rendered at the top.
                .filter((itemId) => {
                var _a;
                return postCallOutputMap.has(itemId) &&
                    !isEmailItem((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item);
            })
                .filter((itemId) => {
                var _a, _b;
                return !((_a = postCallOutputMap.get(itemId)) === null || _a === void 0 ? void 0 : _a.item.target) ||
                    CRM_DATA_UPDATE_TARGETS.includes((_b = postCallOutputMap.get(itemId)) === null || _b === void 0 ? void 0 : _b.item.target);
            }), 
            /* showTrackerNotes= */ true)] })));
};
export default DataProcessingTab;
