var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Request from "../common/request";
export const getFilteredVideosPaginated = (page, pageSize, filters, search = "") => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    const url = "/call_nav/get_filtered_videos_paginated";
    const response = yield Request.post(url, {
        page,
        page_size: pageSize,
        search,
        tags: (_a = filters === null || filters === void 0 ? void 0 : filters.tags.map((tag) => tag.value)) !== null && _a !== void 0 ? _a : null,
        teams: (_b = filters === null || filters === void 0 ? void 0 : filters.teams.map((team) => Number.parseInt(team.value, 10)).filter(Boolean)) !== null && _b !== void 0 ? _b : null,
        departments: (_c = filters === null || filters === void 0 ? void 0 : filters.departments.map((dept) => Number.parseInt(dept.value, 10)).filter(Boolean)) !== null && _c !== void 0 ? _c : null,
        participants: (_d = filters === null || filters === void 0 ? void 0 : filters.participants.map((participant) => participant.value)) !== null && _d !== void 0 ? _d : null,
        accounts: (_e = filters === null || filters === void 0 ? void 0 : filters.accounts.map((account) => Number.parseInt(account.value, 10)).filter(Boolean)) !== null && _e !== void 0 ? _e : null,
    });
    return response;
});
