import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingSelectItems } from "../common/loaders";
import { CallHighlightsHeading } from "./call_highlights_heading";
import { useCallHighlights } from "./use_call_highlights";
export const CallKeypoints = ({ call, regeneratingCallHighlights, }) => {
    const { callHighlights, callHighlightsLoading } = useCallHighlights(call.id);
    if (!(callHighlights === null || callHighlights === void 0 ? void 0 : callHighlights.call_keypoints) ||
        callHighlights.call_keypoints.length === 0) {
        return null;
    }
    const renderKeypoint = (keypoint, idx) => {
        return _jsx("li", { children: keypoint }, idx);
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2 self-stretch px-6 py-4 rounded-2xl bg-wds-gray-3 bg-white" }, { children: [_jsx(CallHighlightsHeading, { title: "Key points" }), callHighlightsLoading || regeneratingCallHighlights ? (_jsx(LoadingSelectItems, {})) : (_jsx("ul", Object.assign({ className: "list-decimal list-outside space-y-4" }, { children: callHighlights.call_keypoints.map((keypoint, idx) => renderKeypoint(keypoint, idx)) })))] })));
};
