var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// This file contains the component for rendering field mapping card details
// for a post-call output item.
// This is used for all integrations, and has support for showing/hiding the
// current field value.
import { Button } from "@/components/ui/button";
import { ExpandablePostCall, useExpandablePostCall, } from "@/components/ui/expandable";
import { HoverCard, HoverCardContent, HoverCardTrigger, } from "@/components/ui/hover_card";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { EmailLink } from "../common/email_link";
import { getAppIntegrationData } from "../common/endpoints";
import ResearchBlockWithProvenance from "../common/research_block_with_provenance";
import { getExternalCrmObjectLink } from "../crm/common/external_crm_link";
import { formatTime, formatToUsNoSecs } from "../strings";
import { PostCallOutputTarget, } from "../types";
import { INTEGRATION_DETAILS } from "./data_processing_config";
import { FieldMappingNewValue } from "./field_mapping_new_value";
import { getEmailContentFromText, isCrmEventPushItem, isEmailItem, } from "./utils";
const FieldMappingCardContent = ({ imageUrl, contentTitle, contentBody, provenanceRecords, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col space-y-2 w-full gap-4 pt-2 items-start" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row items-center font-bold text-xs shrink-0 w-full" }, { children: [imageUrl && _jsx("img", { src: imageUrl, className: "w-5 h-5 mr-2" }), contentTitle] })), (contentBody === null || contentBody === void 0 ? void 0 : contentBody.includes("/>")) ? (_jsx(ResearchBlockWithProvenance, { htmlString: contentBody, provenanceRecords: provenanceRecords, collapsable: false })) : (_jsx("div", Object.assign({ className: "self-stretch grow text-wds-gray-6 font-normal text-sm whitespace-pre-wrap w-full" }, { children: contentBody })))] })));
};
export const FieldMappingCardValue = (props) => {
    var _a;
    const [editNewValue, setEditNewValue] = useState(false);
    const onNewValueEdit = (content) => __awaiter(void 0, void 0, void 0, function* () {
        yield props.onDataItemValueUpdate(content, /* undoClicked= */ false);
    });
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", props.postCallOutputItem.callId],
        queryFn: getAppIntegrationData,
        staleTime: Number.POSITIVE_INFINITY,
    });
    const HoverableAction = ({ children }) => {
        var _a, _b;
        const contentsMap = new Map();
        switch (props.postCallOutputItem.target) {
            case PostCallOutputTarget.SALESFORCE: {
                const targetDetails = props.postCallOutputItem
                    .targetDetails;
                contentsMap.set("Object", targetDetails.objectType);
                contentsMap.set("Name", _jsx("div", Object.assign({ className: "link-styles" }, { children: _jsx("a", Object.assign({ href: getExternalCrmObjectLink("Salesforce", appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], targetDetails.objectType, targetDetails.objectId), target: "_blank", rel: "noreferrer" }, { children: (_a = targetDetails.objectName) !== null && _a !== void 0 ? _a : "Unknown" })) })));
                contentsMap.set("Field", `${targetDetails.fieldLabel} (${targetDetails.fieldName})`);
                break;
            }
            case PostCallOutputTarget.HUBSPOT: {
                const targetDetails = props.postCallOutputItem
                    .targetDetails;
                contentsMap.set("Object", targetDetails.objectType);
                contentsMap.set("Name", _jsx("div", Object.assign({ className: "link-styles" }, { children: _jsx("a", Object.assign({ href: getExternalCrmObjectLink("HubSpot", appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], targetDetails.objectType, targetDetails.objectId), target: "_blank", rel: "noreferrer" }, { children: (_b = targetDetails.objectName) !== null && _b !== void 0 ? _b : "Unknown" })) })));
                contentsMap.set("Field", `${targetDetails.fieldLabel} (${targetDetails.fieldName})`);
                break;
            }
            case PostCallOutputTarget.WISER_CRM: {
                const targetDetails = props.postCallOutputItem
                    .targetDetails;
                contentsMap.set("Object", targetDetails.objectType);
                contentsMap.set("Field", targetDetails.objectName || "");
                break;
            }
            case PostCallOutputTarget.SLACK: {
                const targetDetails = props.postCallOutputItem
                    .targetDetails;
                contentsMap.set("Channel", targetDetails.channelName || "");
                break;
            }
        }
        if (contentsMap.size === 0 &&
            props.postCallOutputItem.state !== "PROCESSED") {
            return _jsx(_Fragment, { children: children });
        }
        return (_jsxs(HoverCard, { children: [_jsx(HoverCardTrigger, { children: children }), _jsxs(HoverCardContent, { children: [props.postCallOutputItem.state === "PROCESSED" && "Click to re-send", Array.from(contentsMap).map(([key, value]) => (_jsxs("div", Object.assign({ className: "flex gap-1 font-medium text-sm font-wds-gray-6" }, { children: [key, ": ", value] }), key)))] })] }));
    };
    const ActionButton = () => {
        var _a, _b, _c, _d, _e;
        if (isEmailItem(props.postCallOutputItem)) {
            return (_jsxs(Button, Object.assign({ variant: "outline", className: "px-3 font-bold text-sm h-8 w-30" }, { children: [_jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/gmail_logo.png", className: "w-4 h-4 shrink-0 mr-2" }), _jsx(EmailLink, { subject: getEmailContentFromText((_b = (_a = props.postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : props.postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "").subject, body: getEmailContentFromText((_d = (_c = props.postCallOutputItem.valueToUpdate) !== null && _c !== void 0 ? _c : props.postCallOutputItem.proposedValue) !== null && _d !== void 0 ? _d : "").body, label: _jsx("div", Object.assign({ className: "flex gap-2 items-center justify-center" }, { children: "Draft" })) })] })));
        }
        let target = props.postCallOutputItem.target;
        if (isCrmEventPushItem(props.postCallOutputItem)) {
            const targetDetails = props.postCallOutputItem
                .targetDetails;
            if (targetDetails.connectedCrm === "Salesforce") {
                target = PostCallOutputTarget.SALESFORCE;
            }
            else if (targetDetails.connectedCrm === "HubSpot") {
                target = PostCallOutputTarget.HUBSPOT;
            }
            else if (targetDetails.connectedCrm === "Wiser CRM") {
                target = PostCallOutputTarget.WISER_CRM;
            }
        }
        const iconUrl = (_e = Array.from(INTEGRATION_DETAILS.values()).find((i) => i.name === target)) === null || _e === void 0 ? void 0 : _e.icon_url;
        if (props.postCallOutputItem.state === "PROCESSED") {
            return (_jsxs("div", Object.assign({ className: "flex flex-col items-end" }, { children: [_jsx(HoverableAction, { children: _jsxs(Button, Object.assign({ variant: "outline", className: "bg-green-100 border-green-200 text-green-800", onClick: props.onSendClick }, { children: [_jsx("div", { className: "bg-green-600 rounded-full h-1.5 w-1.5 mr-1" }), "Synced", _jsx("img", { src: iconUrl, className: "w-4 h-4 ml-1" })] })) }), _jsx("div", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: formatToUsNoSecs(new Date(props.postCallOutputItem.createdAt)) }))] })));
        }
        if (props.postCallOutputItem.fieldMap.executePostCallAfterNMinutes > 0) {
            return (_jsxs("div", Object.assign({ className: "flex flex-col items-end" }, { children: [_jsx(HoverableAction, { children: _jsx(Button, Object.assign({ variant: "light", className: "h-5 w-24 px-2", onClick: props.onSendClick }, { children: _jsxs("div", Object.assign({ className: "flex flex-row space-x-1 items-center text-xs" }, { children: [_jsx("img", { src: iconUrl, className: "w-4 h-4" }), _jsx("div", { children: "Auto-sync" })] })) })) }), _jsxs("div", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: ["Will sync at", " ", formatTime(new Date(new Date(props.postCallOutputItem.createdAt).getTime() +
                                props.postCallOutputItem.fieldMap
                                    .executePostCallAfterNMinutes *
                                    60 *
                                    1000))] }))] })));
        }
        return (_jsx(HoverableAction, { children: _jsxs(Button, Object.assign({ variant: "outline", className: "px-3 font-bold text-sm h-8 w-30", onClick: props.onSendClick }, { children: [_jsx("img", { src: iconUrl, className: "w-4 h-4 mr-2" }), "Send"] })) }));
    };
    const NewValue = () => {
        const { showMore, setShowMore } = useExpandablePostCall();
        return (_jsxs("div", Object.assign({ className: "flex flex-row items-start justify-between w-full space-x-4" }, { children: [_jsx(FieldMappingNewValue, { editNewValue: editNewValue, setEditNewValue: (value) => {
                        setEditNewValue(value);
                        props.toggleDataItemEditOpen(value);
                        if (setShowMore)
                            setShowMore(value);
                    }, postCallOutputItem: props.postCallOutputItem, onNewValueChange: onNewValueEdit, fieldSchema: props.fieldSchema, expanded: showMore, provenanceRecords: props.provenanceRecords }), _jsx(ActionButton, {})] })));
    };
    return (_jsx("div", Object.assign({ className: "flex items-start flex-1 gap-4 pt-2 px-4 pb-3" }, { children: _jsx(ExpandablePostCall, { alwaysShowExpandButton: true, showLessContent: _jsx(NewValue, {}), showMoreContent: _jsxs(_Fragment, { children: [_jsx(NewValue, {}), props.showCurrentFieldValue && (_jsxs(_Fragment, { children: [_jsx("div", { className: "pt-2 border-b border-b-gray-200" }), _jsx(FieldMappingCardContent, { imageUrl: (_a = Array.from(INTEGRATION_DETAILS.values()).find((i) => i.name === props.postCallOutputItem.target)) === null || _a === void 0 ? void 0 : _a.icon_url, contentTitle: "Current field value", contentBody: props.postCallOutputItem.currentValue, provenanceRecords: props.provenanceRecords })] })), props.showTrackerNotes && (_jsxs(_Fragment, { children: [_jsx("div", { className: "pt-2 border-b border-b-gray-200" }), _jsx(FieldMappingCardContent, { imageUrl: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", contentTitle: "Content captured by Wiser", contentBody: props.postCallOutputItem.contentCaptured, provenanceRecords: props.provenanceRecords })] }))] }) }) })));
};
