import { themeQuartz } from "ag-grid-community";
const DEFAULT_DATA_GRID_THEME = themeQuartz.withParams({
    browserColorScheme: "light",
    fontFamily: {
        googleFont: "Lato",
    },
    headerFontSize: 14,
    headerFontWeight: "bold",
});
export { DEFAULT_DATA_GRID_THEME };
