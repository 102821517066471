import App from "./App";
import "./index.css";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

const initializeSentry = () => {
  const host = window.location.host;

  // It is fine to hardcode the DSN, it's a client key & is not sensitive.
  // https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/#dsn-utilization
  const dsn =
    "https://10f25338198156b3407241e23e339eeb@o4507069265018880.ingest.us.sentry.io/4507069779476480";

  const environment = {
    "staging.getwiser.io": "staging",
    "launch.getwiser.io": "canary",
    "app.getwiser.io": "production",
  }[host];

  if (environment) {
    Sentry.init({
      dsn,
      integrations: [],
      environment,
    });
  }
};

initializeSentry();

const container = document.getElementById("app");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
