import { CodeBracketSquareIcon } from "@heroicons/react/24/outline";
/**
 * A utility function to get classNames based on the column's properties.
 * This helps to easily style each column in the table based on its properties.
 */
export function getCommonPinningStyles({ column, }) {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === "left" && column.getIsLastColumn("left");
    const isFirstRightPinnedColumn = isPinned === "right" && column.getIsFirstColumn("right");
    return {
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
        right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
        position: isPinned ? "sticky" : "relative",
        zIndex: isPinned ? 1 : 0,
    };
}
export const dataTableConfig = {
    comparisonOperators: [
        { label: "Contains", value: "ilike" },
        { label: "Does not contain", value: "notIlike" },
        { label: "Is", value: "eq" },
        { label: "Is not", value: "notEq" },
        { label: "Starts with", value: "startsWith" },
        { label: "Ends with", value: "endsWith" },
        { label: "Is empty", value: "isNull" },
        { label: "Is not empty", value: "isNotNull" },
    ],
    selectableOperators: [
        { label: "Is", value: "eq" },
        { label: "Is not", value: "notEq" },
        { label: "Is empty", value: "isNull" },
        { label: "Is not empty", value: "isNotNull" },
    ],
    logicalOperators: [
        {
            label: "And",
            value: "and",
            description: "All conditions must be met",
        },
        {
            label: "Or",
            value: "or",
            description: "At least one condition must be met",
        },
    ],
    featureFlags: [
        {
            label: "Advanced filter",
            value: "advancedFilter",
            icon: CodeBracketSquareIcon,
            tooltipTitle: "Toggle advanced filter",
            tooltipDescription: "A notion like query builder to filter rows.",
        },
    ],
};
