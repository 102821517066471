var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AsyncSearchSelect } from "@/components/ui/async_search_select";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { CrmObjectTypeEnum, } from "../types";
import CallCrmObjectBaseModal, { CrmInputField, } from "./call_crm_object_base_modal";
import { onlineAccountsFetcher } from "./call_crm_object_modal.helpers";
import { createCrmContact, fetchCrmObject, getCrmAccount, getCrmFields, getLiveCallById, setCallAccountAndOpportunity, updateCrmContact, } from "./endpoints";
/**
 * This component is used to create or edit a contact in the CRM.
 * It can be used for both with or without a call context. For e.g. we use this in the call header,
 * as well as account research page.
 * TODO: Find a better way rather than making the props optional.
 */
export const CallCrmContactModal = ({ tenant, call, contact, open, setOpen, updateSelectedCall, onSuccess, }) => {
    var _a, _b;
    const { data: crmFields, isLoading: crmFieldsLoading, isError: crmFieldsError, } = useQuery({
        queryKey: ["crmFields", tenant.id],
        queryFn: getCrmFields,
    });
    const contactExistsInCrm = !tenant.connected_crm || !!contact.external_id;
    const requiredCrmFields = useMemo(() => {
        if (crmFieldsLoading || crmFieldsError)
            return [];
        if (!crmFields)
            return [];
        return crmFields.filter((crmField) => crmField.applicable_objects.includes(CrmObjectTypeEnum.CONTACT) &&
            !crmField.nullable);
    }, [crmFieldsLoading, crmFieldsError, crmFields]);
    const crmFieldValues = useMemo(() => {
        const cValues = {};
        requiredCrmFields.map((crmField) => {
            var _a, _b;
            if ((_a = contact.contact_fields) === null || _a === void 0 ? void 0 : _a[`z${crmField.id}`]) {
                cValues[crmField.id] = (_b = contact.contact_fields) === null || _b === void 0 ? void 0 : _b[`z${crmField.id}`];
            }
        });
        return cValues;
    }, [contact, requiredCrmFields]);
    const [updatedContactData, setUpdatedContactData] = useState({
        email: contact.email,
        name: contact.name,
        linkedinProfileUrl: contact.linkedin_profile_url,
        crmFieldValues,
    });
    const [isSaving, setIsSaving] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState((_a = contact.account_id) !== null && _a !== void 0 ? _a : (_b = call === null || call === void 0 ? void 0 : call.account) === null || _b === void 0 ? void 0 : _b.id);
    const [saveErrorMsg, setSaveErrorMsg] = useState(null);
    const { data: crmAccount } = useQuery({
        queryKey: ["crmAccount", selectedAccountId],
        queryFn: () => {
            if (!selectedAccountId)
                return;
            return getCrmAccount(selectedAccountId);
        },
    });
    useEffect(() => {
        if (!crmAccount)
            return;
        setUpdatedContactData((prev) => {
            return Object.assign(Object.assign({}, prev), { accountId: crmAccount.id });
        });
    }, [crmAccount]);
    const fetchAccountMutation = useMutation({
        mutationFn: ({ accountId }) => fetchCrmObject(CrmObjectTypeEnum.ACCOUNT, accountId),
        onSuccess: ({ id }) => {
            setSelectedAccountId(id);
        },
    });
    const queryClient = useQueryClient();
    const contactUpdateMutation = useMutation({
        mutationFn: ({ contactId, contactData, }) => {
            var _a;
            return updateCrmContact(contactId, (_a = contactData.name) !== null && _a !== void 0 ? _a : "", contactData.email, contactData.accountId, contactData.linkedinProfileUrl);
        },
        onSettled: (data) => {
            if (data && data.type === "error") {
                setSaveErrorMsg(data.error);
            }
            else {
                setSaveErrorMsg(null);
            }
        },
    });
    const contactCreateMutation = useMutation({
        mutationFn: ({ contactData }) => {
            var _a, _b;
            return createCrmContact((_a = contactData.name) !== null && _a !== void 0 ? _a : "", contactData.email, contactData.linkedinProfileUrl, contactData.accountId, (_b = contactData.crmFieldValues) !== null && _b !== void 0 ? _b : {});
        },
        onSettled: (data) => {
            if (data && data.type === "error") {
                setSaveErrorMsg(data.error);
            }
            else {
                setSaveErrorMsg(null);
            }
        },
    });
    const defaultContactFields = [
        {
            id: 0,
            fieldLabel: "Email",
            fieldValue: updatedContactData.email,
            placeholder: "Email of the contact",
            onFieldValueChange: (value) => {
                setUpdatedContactData((prev) => {
                    return Object.assign(Object.assign({}, prev), { email: value });
                });
            },
            // Disabling the input for email as changing the email
            // creates a new contact object in Wiser which will then need
            // to be added as a participant to the call.
            inputDisabled: true,
        },
        {
            id: 1,
            fieldLabel: "Linkedin profile URL",
            fieldValue: updatedContactData.linkedinProfileUrl,
            placeholder: "e.g. https://www.linkedin.com/acme",
            onFieldValueChange: (value) => {
                setUpdatedContactData((prev) => {
                    return Object.assign(Object.assign({}, prev), { linkedinProfileUrl: value });
                });
            },
        },
    ];
    if (!tenant.connected_crm) {
        // WiserCRM should render name field in default.
        defaultContactFields.push({
            id: 2,
            fieldLabel: "Name",
            fieldValue: updatedContactData.name,
            placeholder: "Name of the contact",
            onFieldValueChange: (value) => {
                setUpdatedContactData((prev) => {
                    return Object.assign(Object.assign({}, prev), { name: value });
                });
            },
        });
    }
    const renderCrmContactForm = () => {
        var _a;
        return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 items-start" }, { children: [defaultContactFields.map((field) => (_jsx(CrmInputField, { fieldId: field.id, fieldLabel: field.fieldLabel, fieldValue: field.fieldValue, placeholder: field.placeholder, onValueChange: (newValue) => {
                        setSaveErrorMsg(null);
                        field.onFieldValueChange(newValue);
                    }, inputDisabled: field.inputDisabled }, field.fieldLabel))), requiredCrmFields
                    .sort((field1, field2) => field1.name.localeCompare(field2.name))
                    .map((crmField) => (_jsx(CrmInputField, { fieldId: crmField.id, fieldSchema: crmField.content_type_definition, fieldLabel: crmField.name, fieldValue: updatedContactData.crmFieldValues
                        ? updatedContactData.crmFieldValues[crmField.id]
                        : undefined, onValueChange: (value) => {
                        setSaveErrorMsg(null);
                        setUpdatedContactData((prev) => {
                            return Object.assign(Object.assign({}, prev), { crmFieldValues: Object.assign(Object.assign({}, prev.crmFieldValues), { [crmField.id]: value }) });
                        });
                    } }, crmField.id))), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: "Account" })), _jsx(AsyncSearchSelect, { dataFetcher: onlineAccountsFetcher, onSelect: (option) => {
                                if (!option)
                                    return;
                                fetchAccountMutation.mutate({ accountId: option.value });
                                setSaveErrorMsg(null);
                            }, placeholder: "Linked account for this contact", selectedOption: crmAccount
                                ? {
                                    value: `${crmAccount.id}`,
                                    label: (_a = crmAccount.name) !== null && _a !== void 0 ? _a : crmAccount.website,
                                }
                                : undefined }), fetchAccountMutation.isPending && (_jsx("span", Object.assign({ className: "text-xs text-wds-gray-5 font-medium" }, { children: "Syncing Account..." })))] }))] })));
    };
    const enableSubmit = useMemo(() => {
        const isFieldValid = (field) => !!field && field.length > 0;
        const allRequiredFieldsFilled = (contactData) => {
            if (!tenant.connected_crm && !isFieldValid(contactData.name)) {
                return false;
            }
            if (!isFieldValid(contactData.email))
                return false;
            if (!isFieldValid(contactData.linkedinProfileUrl))
                return false;
            if (!contactData.accountId)
                return false;
            if (requiredCrmFields.length === 0) {
                return true;
            }
            if (!contactData.crmFieldValues) {
                return false;
            }
            return requiredCrmFields.every((crmField) => { var _a; return isFieldValid((_a = contactData.crmFieldValues) === null || _a === void 0 ? void 0 : _a[crmField.id]); });
        };
        return allRequiredFieldsFilled(updatedContactData);
    }, [updatedContactData, requiredCrmFields]);
    if (crmFieldsLoading || crmFieldsError || !crmFields) {
        // No loading state/error state UI yet.
        return null;
    }
    return (_jsx(CallCrmObjectBaseModal, { tenant: tenant, title: contactExistsInCrm ? "Edit contact" : "Create new contact", description: contactExistsInCrm
            ? "You can edit the contact for this attendee in your connected CRM."
            : "You can create a new contact for this attendee in your connected CRM.", open: open, setOpen: setOpen, submitCallback: (_) => __awaiter(void 0, void 0, void 0, function* () {
            setIsSaving(true);
            setSaveErrorMsg(null);
            const updatedContact = contactExistsInCrm
                ? yield contactUpdateMutation.mutateAsync({
                    contactId: contact.id,
                    contactData: updatedContactData,
                })
                : yield contactCreateMutation.mutateAsync({
                    contactData: updatedContactData,
                });
            if (updatedContact.type === "error") {
                // No need to close the modal since we display error message in the UI.
                setIsSaving(false);
                return;
            }
            if (onSuccess) {
                onSuccess();
            }
            if (!call || !updateSelectedCall) {
                setIsSaving(false);
                setOpen(false);
                return;
            }
            if (!call.account && updatedContact.account_id) {
                // If the call is not mapped to an account, map it to the
                // account that this contact was created for.
                yield setCallAccountAndOpportunity(call.id, updatedContact.account_id);
            }
            const updatedCall = yield queryClient.fetchQuery({
                queryKey: ["getLiveCallById", call.id],
                queryFn: () => getLiveCallById(call.id),
            });
            updateSelectedCall(updatedCall);
            setIsSaving(false);
            setOpen(false);
        }), disableSubmit: !enableSubmit || isSaving || fetchAccountMutation.isPending, child: renderCrmContactForm(), isSaving: isSaving, error: saveErrorMsg }));
};
