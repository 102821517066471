var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import Request from "../common/request";
import { APPLICABLE_MEETING_TEMPLATES, EXTERNAL_MEETING_TEMPLATE_OVERRIDE, INTERNAL_MEETING_TEMPLATE_OVERRIDE, PARENT_TEAM, } from "./team.helpers";
export const getTeams = (json_query, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    const response = yield Request.get("/api/get_teams", {
        json_query: new_json_query,
        per_page: perPage,
        page,
    });
    return response;
});
export const downlodAllTeams = (json_query) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    return yield Request.get("/api/export_all_teams", {
        json_query: new_json_query,
    }, {
        responseType: "blob",
    });
});
export const createTeam = (values) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    return yield Request.post("/api/create_team", {
        name: values.Name,
        parent_team_id: (_a = values[PARENT_TEAM]) === null || _a === void 0 ? void 0 : _a.value,
        applicable_meeting_template_ids: (_b = values[APPLICABLE_MEETING_TEMPLATES]) === null || _b === void 0 ? void 0 : _b.map((option) => option.value),
        default_internal_meeting_template_id: (_c = values[INTERNAL_MEETING_TEMPLATE_OVERRIDE]) === null || _c === void 0 ? void 0 : _c.value,
        default_external_meeting_template_id: (_d = values[EXTERNAL_MEETING_TEMPLATE_OVERRIDE]) === null || _d === void 0 ? void 0 : _d.value,
    });
});
export const updateTeam = (values) => __awaiter(void 0, void 0, void 0, function* () {
    var _e, _f, _g, _h;
    return yield Request.post("/api/update_team", {
        id: values.id,
        name: values.Name,
        parent_team_id: (_e = values[PARENT_TEAM]) === null || _e === void 0 ? void 0 : _e.value,
        applicable_meeting_template_ids: (_f = values[APPLICABLE_MEETING_TEMPLATES]) === null || _f === void 0 ? void 0 : _f.map((option) => option.value),
        default_internal_meeting_template_id: (_g = values[INTERNAL_MEETING_TEMPLATE_OVERRIDE]) === null || _g === void 0 ? void 0 : _g.value,
        default_external_meeting_template_id: (_h = values[EXTERNAL_MEETING_TEMPLATE_OVERRIDE]) === null || _h === void 0 ? void 0 : _h.value,
    });
});
export const deleteTeam = (teamId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_team", { id: teamId });
});
