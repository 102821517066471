import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { Tab } from "@headlessui/react";
import { useState } from "react";
import amplitudeInstance from "../amplitude";
import PreCallPrep from "../pre_call_prep";
import { RunningSummary } from "./running_summary";
// This is the main dashboard for the live call view. It contains the running summary, playbook, and notes sections.
export const LiveCallMainDashboard = (props) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const selectTab = (index) => {
        setSelectedTabIndex(index);
        amplitudeInstance.track("Click Live Dashboard Tab", {
            tab: index,
            tabName: dashboardItems[index].label,
        });
    };
    const dashboardItems = [
        {
            label: "Research",
            renderer: () => _jsx(PreCallPrep, { call: props.call, dashboardView: true }),
        },
        {
            label: "Trackers",
            renderer: () => _jsx(_Fragment, {}),
        },
        {
            label: "Summary",
            renderer: () => (_jsx(RunningSummary, { call: props.call, startTimeMsec: props.startTimeMsec, summaries: props.callSummaries })),
        },
    ];
    if (props.transcriptWidget) {
        dashboardItems.push({
            label: "Transcript",
            renderer: () => props.transcriptWidget,
        });
    }
    if (props.notesWidget) {
        dashboardItems.push({
            label: "Notes",
            renderer: () => props.notesWidget,
        });
    }
    if (props.chatWidget) {
        dashboardItems.push({
            label: "Chat",
            renderer: () => props.chatWidget,
        });
    }
    const goToChatTab = () => {
        if (!props.chatWidget)
            return;
        selectTab(dashboardItems.length - 1);
    };
    return (_jsx("div", Object.assign({ className: "w-full h-full flex flex-col justify-start items-start rounded-lg bg-wds-gray-2 md:border border-wds-gray-3" }, { children: _jsxs(Tab.Group, Object.assign({ selectedIndex: selectedTabIndex, onChange: selectTab }, { children: [_jsx(Tab.List, Object.assign({ className: "flex px-8 pt-2 mb-4 md:mb-0 items-center self-stretch gap-1 md:gap-4 border-b border-wds-gray-3 bg-white md:rounded-t-lg" }, { children: dashboardItems.map((item) => (_jsx(Tab, Object.assign({ className: ({ selected }) => cn("outline-none px-2 md:px-4 py-2 justify-start items-center flex rounded-t-lg border-blue-600 focus:outline-none focus:ring-0", selected
                            ? "border-b-2 font-bold"
                            : "hover:bg-blue-100 font-normal") }, { children: item.label }), item.label))) })), _jsx(Tab.Panels, Object.assign({ className: "overflow-auto w-full h-full bg-white md:rounded-b-lg" }, { children: dashboardItems.map((item) => (_jsx(Tab.Panel, Object.assign({ className: "w-full h-full" }, { children: item.renderer() }), item.label))) }))] })) })));
};
