var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { PaperAirplaneIcon, UserIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Mention, MentionsInput, } from "react-mentions";
import { usersFetcher } from "../calls_table/calls_table_fetchers";
const CommentInput = ({ onSubmit, isPending, className = "", }) => {
    const [text, setText] = useState("");
    const queryClient = useQueryClient();
    const handleSubmit = (e) => {
        e.preventDefault();
        if (text.trim()) {
            onSubmit(text.trim());
            setText("");
        }
    };
    const fetchUserSuggestions = (query) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const users = yield usersFetcher(query, undefined, undefined, queryClient);
            return users.results.map((user) => ({
                id: user.value,
                display: user.label,
            }));
        }
        catch (error) {
            console.error(error);
            return [];
        }
    });
    const userDataCallback = (searchTerm, callback) => __awaiter(void 0, void 0, void 0, function* () {
        const results = yield fetchUserSuggestions(searchTerm);
        callback(results);
    });
    const handleChange = (_, newValue) => {
        setText(newValue);
    };
    // Cannot use tailwind, the tailwind classes don't reach the mentions input
    const mentionsInputStyle = {
        input: {
            overflow: "hidden",
            width: "100%",
            height: "100%",
            color: "inherit",
            backgroundColor: "transparent",
            fontFamily: "inherit",
            fontSize: "inherit",
            outline: "none",
            border: "none",
        },
        suggestions: {
            list: {
                backgroundColor: "transparent",
                border: "1px solid #e5e7eb",
                borderRadius: "0.375rem",
                boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                fontSize: "0.875rem",
                overflow: "auto",
                maxHeight: "150px",
                width: "100%",
                zIndex: 20,
            },
            item: {
                padding: "0.25rem 0.75rem",
                cursor: "pointer",
                borderRadius: "0.25rem",
                margin: "2px 0",
            },
            itemHighlighted: {
                backgroundColor: "#f3f4f6",
            },
        },
    };
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit, className: `flex items-start gap-3 ${className}` }, { children: [_jsx("div", Object.assign({ className: "h-8 w-8 rounded-full flex-shrink-0" }, { children: _jsx(Avatar, { children: _jsx(AvatarFallback, { children: _jsx(UserIcon, { className: "h-5 w-5 text-wds-gray-5" }) }) }) })), _jsxs("div", Object.assign({ className: "flex-1 relative" }, { children: [_jsx(MentionsInput, Object.assign({ value: text, onChange: handleChange, placeholder: "Mention anyone by typing '@'. Press enter to submit & shift+enter to add a new line.", style: mentionsInputStyle, onKeyDown: (e) => {
                            // override the default behavior of the enter key, it adds a new line
                            // instead, shift+enter to add a new line & enter to submit
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                if (text.trim()) {
                                    onSubmit(text.trim());
                                    setText("");
                                }
                            }
                        }, allowSuggestionsAboveCursor: true, className: "w-full text-sm border border-wds-gray-3 rounded-md py-2 px-3 focus-within:ring-1 focus-within:ring-wds-blue-5 focus-within:border-wds-blue-5" }, { children: _jsx(Mention, { trigger: "@", displayTransform: (id, display) => `@${display}`, data: userDataCallback, markup: "@[@__display__](__id__)", renderSuggestion: (suggestion, search, highlightedDisplay, index, focused) => (_jsxs("div", Object.assign({ className: `flex items-center py-1 px-2 ${focused ? "bg-wds-blue-1" : ""}` }, { children: [_jsx("div", Object.assign({ className: "h-6 w-6 rounded-full flex-shrink-0 mr-2 bg-wds-gray-2 flex items-center justify-center" }, { children: _jsx(UserIcon, { className: "h-4 w-4 text-wds-gray-5" }) })), _jsx("span", Object.assign({ className: `text-sm ${focused ? "text-wds-blue-6" : "text-wds-gray-8"}` }, { children: highlightedDisplay }))] }))) }) })), _jsx("div", Object.assign({ className: "absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2" }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "ghost", size: "icon", className: "text-wds-blue-5 hover:text-wds-blue-6 disabled:text-wds-gray-4 h-auto w-auto p-0", disabled: isPending || !text.trim(), title: "Post" }, { children: _jsx(PaperAirplaneIcon, { className: "h-5 w-5" }) })) }))] }))] })));
};
export default CommentInput;
