var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import * as endpoints from "../common/endpoints";
import { useFieldMappings } from "./use_field_mappings";
/**
 * This hook is used to get the post call outputs for a call.
 *
 * @param callId - The call id for which the post call outputs are to be fetched.
 */
const usePostCallOutputs = ({ callId }) => {
    const queryClient = useQueryClient();
    const { postCallOutputs } = useFieldMappings({ callId });
    const postCallOutputMap = useMemo(() => {
        return new Map(postCallOutputs === null || postCallOutputs === void 0 ? void 0 : postCallOutputs.map((item) => [item.id, { isLoaded: true, item }]));
    }, [postCallOutputs]);
    const updatePostCallOutputInState = (updatedItem) => {
        const updatedPostCallOutputs = postCallOutputs === null || postCallOutputs === void 0 ? void 0 : postCallOutputs.map((item) => {
            if (item.id === updatedItem.id) {
                return updatedItem;
            }
            return item;
        });
        queryClient.setQueryData(["createPostCallOutputs", updatedItem.callId], updatedPostCallOutputs);
    };
    const fetchPostCallOutput = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const output = yield queryClient.fetchQuery({
            queryKey: ["getPostCallOutput", id],
            queryFn: () => endpoints.getPostCallOutput(id),
        });
        const updatedPostCallOutputs = postCallOutputs === null || postCallOutputs === void 0 ? void 0 : postCallOutputs.map((item) => {
            if (item.id === output.id) {
                return output;
            }
            return item;
        });
        queryClient.setQueryData(["createPostCallOutputs", output.callId], updatedPostCallOutputs);
    });
    return {
        postCallOutputMap,
        updatePostCallOutputInState,
        fetchPostCallOutput,
    };
};
export default usePostCallOutputs;
