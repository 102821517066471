var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AsyncSearchMultiSelect } from "@/components/ui/async_search_multi_select";
import { AsyncSearchSelect } from "@/components/ui/async_search_select";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll_area";
import { useToast } from "@/components/ui/use_toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DayPickerProvider } from "react-day-picker";
import { useForm, useWatch } from "react-hook-form";
import { updateTeam } from "./endpoints";
import { APPLICABLE_MEETING_TEMPLATES, EXTERNAL_MEETING_TEMPLATE_OVERRIDE, INTERNAL_MEETING_TEMPLATE_OVERRIDE, PARENT_TEAM, TEAM_SCHEMA, checkNameAlreadyExists, teamsFetcher, templateFetcher, templateFetcherWithoutNone, } from "./team.helpers";
const TeamForm = ({ team, setSelectedTeam, }) => {
    var _a, _b, _c;
    const queryClient = useQueryClient();
    const { toast } = useToast();
    const form = useForm({
        resolver: zodResolver(TEAM_SCHEMA),
        defaultValues: {
            Name: team.name,
            [PARENT_TEAM]: {
                label: team.parent_team_name || "None",
                value: ((_a = team.parent_team_id) === null || _a === void 0 ? void 0 : _a.toString()) || "None",
            },
            [APPLICABLE_MEETING_TEMPLATES]: team.applicable_meeting_template_ids.map((id, index) => {
                var _a;
                return {
                    label: (_a = team.applicable_meeting_template_names) === null || _a === void 0 ? void 0 : _a[index],
                    value: id.toString(),
                };
            }),
            [INTERNAL_MEETING_TEMPLATE_OVERRIDE]: {
                label: team.default_internal_meeting_template_name,
                value: (_b = team.default_internal_meeting_template_id) === null || _b === void 0 ? void 0 : _b.toString(),
            },
            [EXTERNAL_MEETING_TEMPLATE_OVERRIDE]: {
                label: team.default_external_meeting_template_name,
                value: (_c = team.default_external_meeting_template_id) === null || _c === void 0 ? void 0 : _c.toString(),
            },
        },
    });
    const updateMutation = useMutation({
        mutationFn: (values) => __awaiter(void 0, void 0, void 0, function* () {
            return yield updateTeam(values);
        }),
        onSuccess: (data) => {
            queryClient.invalidateQueries();
            toast({
                title: "Team updated",
                description: "Team updated successfully",
                variant: "green",
            });
            setSelectedTeam(undefined);
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `${error}`,
                variant: "destructive",
            });
        },
    });
    const initialProps = {
        selected: new Date(),
    };
    return (_jsx(DayPickerProvider, Object.assign({ initialProps: initialProps }, { children: _jsx("div", Object.assign({ className: "max-h-full overflow-y-auto pb-4" }, { children: _jsx(ScrollArea, { children: _jsx(Form, Object.assign({}, form, { children: _jsxs("form", Object.assign({ onSubmit: form.handleSubmit((values) => __awaiter(void 0, void 0, void 0, function* () {
                            return yield updateMutation.mutateAsync(Object.assign(Object.assign({}, values), { id: team.id }));
                        })), className: "space-y-8" }, { children: [_jsx(FormField, { control: form.control, name: "Name", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Name" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({ placeholder: "Team name" }, field, { onBlur: (event) => __awaiter(void 0, void 0, void 0, function* () {
                                                    if (event.target.value === team.name) {
                                                        form.clearErrors("Name");
                                                    }
                                                    else {
                                                        const nameExists = yield checkNameAlreadyExists(event.target.value, queryClient);
                                                        if (nameExists) {
                                                            form.setError("Name", {
                                                                type: "validate",
                                                                message: "This name is already in use",
                                                            });
                                                        }
                                                        else {
                                                            form.clearErrors("Name");
                                                        }
                                                    }
                                                }) })) }), _jsx(FormDescription, { children: "This is your team name." }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "Parent team", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Parent Team" }), _jsx(FormDescription, { children: "This is the parent team of this team." }), _jsx(FormControl, { children: _jsx(AsyncSearchSelect, { dataFetcher: (userQuery) => teamsFetcher(queryClient, userQuery), onSelect: (option) => {
                                                    field.onChange(option);
                                                    if (option && option.value === team.id.toString()) {
                                                        form.setError("Parent team", {
                                                            type: "validate",
                                                            message: "Cannot set parent team to itself",
                                                        });
                                                    }
                                                    else {
                                                        form.clearErrors("Parent team");
                                                    }
                                                }, placeholder: "Team name", selectedOption: field.value || { label: "None", value: "None" } }) }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "Applicable meeting templates", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Applicable meeting templates" }), _jsx(FormDescription, { children: "These are the meeting templates that are applicable to this team" }), _jsx(FormControl, { children: _jsx(AsyncSearchMultiSelect, { dataFetcher: (userQuery) => templateFetcherWithoutNone(userQuery, undefined, undefined, queryClient), onChange: (options) => {
                                                    var _a, _b;
                                                    field.onChange(options);
                                                    const optionValues = options === null || options === void 0 ? void 0 : options.map((option) => option.value);
                                                    const externalMeetingTemplateValue = (_a = form.getValues(EXTERNAL_MEETING_TEMPLATE_OVERRIDE)) === null || _a === void 0 ? void 0 : _a.value;
                                                    const internalMeetingTemplateValue = (_b = form.getValues(INTERNAL_MEETING_TEMPLATE_OVERRIDE)) === null || _b === void 0 ? void 0 : _b.value;
                                                    if (!externalMeetingTemplateValue ||
                                                        !internalMeetingTemplateValue) {
                                                        return;
                                                    }
                                                    // If the selected template is not in the list of options, reset the value to None
                                                    if (!optionValues.includes(externalMeetingTemplateValue)) {
                                                        form.setValue(EXTERNAL_MEETING_TEMPLATE_OVERRIDE, {
                                                            label: "None",
                                                            value: "None",
                                                        });
                                                    }
                                                    if (!optionValues.includes(internalMeetingTemplateValue)) {
                                                        form.setValue(INTERNAL_MEETING_TEMPLATE_OVERRIDE, {
                                                            label: "None",
                                                            value: "None",
                                                        });
                                                    }
                                                }, placeholder: "Templates", selectedOptions: field.value || [] }) }), _jsx(FormMessage, {})] })) }), [
                                INTERNAL_MEETING_TEMPLATE_OVERRIDE,
                                EXTERNAL_MEETING_TEMPLATE_OVERRIDE,
                            ].map((fieldName) => (_jsx(FormField, { control: form.control, name: fieldName, render: ({ field }) => {
                                    // Watch for changes in "Applicable meeting templates"
                                    const applicableMeetingTemplates = useWatch({
                                        control: form.control,
                                        name: APPLICABLE_MEETING_TEMPLATES,
                                    });
                                    // Ensure that the selected template is in the list of applicable templates
                                    const filteredIds = applicableMeetingTemplates === null || applicableMeetingTemplates === void 0 ? void 0 : applicableMeetingTemplates.map((t) => Number(t.value));
                                    return (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: fieldName }), _jsxs(FormDescription, { children: ["This will cause all", " ", fieldName === "Internal meeting template override"
                                                        ? "internal"
                                                        : "external", " ", "meetings created for this team to use this template."] }), _jsx(FormControl, { children: _jsx(AsyncSearchSelect, { dataFetcher: (userQuery) => templateFetcher(queryClient, userQuery, filteredIds), onSelect: field.onChange, placeholder: `Select a ${fieldName}`, selectedOption: field.value }, (filteredIds === null || filteredIds === void 0 ? void 0 : filteredIds.join(",")) || "default") }), _jsx(FormMessage, {})] }));
                                } }, fieldName))), _jsx(Button, Object.assign({ type: "submit", disabled: !form.formState.isValid ||
                                    Object.keys(form.formState.errors).length > 0 }, { children: "Submit" }))] })) })) }) })) })));
};
export default TeamForm;
