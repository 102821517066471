var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, } from "@/components/ui/modal_dialog";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React from "react";
const DeleteModal = (props) => {
    const [deleting, setDeleting] = React.useState(false);
    return (_jsx(Dialog, Object.assign({ open: props.deleteModal, onOpenChange: (open) => props.setShowDeleteModal(open) }, { children: _jsxs(DialogContent, { children: [_jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: props.title || "Are you absolutely sure?" }), _jsx(DialogDescription, { children: props.description ||
                                "This action cannot be undone. This will permanently delete this data from our servers" })] }), _jsx(DialogFooter, Object.assign({ className: "sm:justify-end" }, { children: _jsx(DialogClose, Object.assign({ asChild: true }, { children: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ type: "button", variant: "secondary", onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        props.setShowDeleteModal(false);
                                    } }, { children: "Close" })), _jsxs(Button, Object.assign({ type: "button", variant: "destructive", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                        var _a;
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setDeleting(true);
                                        const response = yield ((_a = props.deleteCallback) === null || _a === void 0 ? void 0 : _a.call(props));
                                        setDeleting(false);
                                        props.setShowDeleteModal(false);
                                    }) }, { children: [deleting ? (_jsx(ArrowPathIcon, { className: "mr-2 h-4 w-4 animate-spin" })) : null, props.deleteText || "Delete"] }))] }) })) }))] }) })));
};
export default DeleteModal;
