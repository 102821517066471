var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { downlodAllTeams } from "./endpoints";
export function TeamsExport({ filterQuery }) {
    const [exporting, setExporting] = useState(false);
    const handleExportClick = () => __awaiter(this, void 0, void 0, function* () {
        setExporting(true);
        const response = yield downlodAllTeams(filterQuery);
        const nowTime = new Date().getTime();
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `teams${nowTime}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        setExporting(false);
    });
    return (_jsx("div", Object.assign({ className: "flex items-center gap-2" }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "sm", disabled: exporting, onClick: () => __awaiter(this, void 0, void 0, function* () { return yield handleExportClick(); }), className: "text-wds-gray-5 border-wds-gray-5 hover:text-wds-gray-6 hover:border-wds-gray-6" }, { children: !exporting ? (_jsx(ArrowDownTrayIcon, { className: "h-4 w-4", "aria-hidden": "true" })) : (_jsx(ArrowPathIcon, { className: "h-4 w-4 animate-spin", "aria-hidden": "true" })) })) })));
}
