// Handy helpers for formatting strings -- rendering times, pluralizing, etc.
export function formatCallTime(callStartTime, time) {
    // Input times are in milliseconds.
    const sign = callStartTime > time ? "-" : "";
    const callTime = Math.round(Math.abs(time - callStartTime) / 1000);
    const minutes = Math.floor(callTime / 60);
    const seconds = callTime % 60;
    // Format the time as MM:SS.
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    return `${sign}${formattedMinutes}:${formattedSeconds}`;
}
export const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};
export const formatMilliseconds = (msec) => {
    const totalSeconds = Math.floor(msec / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
export const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};
export const formatToMM_DD_YYYY = (date) => {
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};
export const formatToYYYY_MM_DD = (date) => {
    // if you lookup online, you'll find `date.toISOString().split('T')[0]` or `date.toISOString().substring(10)`
    // that doesn't work because toISOString() will return the UTC time, not local, so it'd sometimes give wrong date.
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};
export const formatToUsNoSecs = (date) => {
    const options = {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(date);
};
export const truncateString = (str, maxLength) => {
    if (str.length <= maxLength)
        return str;
    return `${str.slice(0, maxLength - 3)}...`;
};
export var CallFilter;
(function (CallFilter) {
    CallFilter["DATES"] = "dates";
    CallFilter["ACCOUNTS"] = "accounts";
    CallFilter["CONTACTS"] = "contacts";
})(CallFilter || (CallFilter = {}));
export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export const isValidUrl = (url) => {
    if (!url)
        return false;
    try {
        new URL(url);
        return true;
    }
    catch (e) {
        return false;
    }
};
export const formatSecondsToMinSec = (msecs) => {
    const seconds = Math.floor(msecs / 1000);
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
        .toString()
        .padStart(2, "0")}`;
};
export const formatToLocalISOString = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 16);
};
export const replaceUnserscoreWithSpace = (str) => {
    return str.replace(/_/g, " ");
};
export function convertHeadersToBold(markdownText) {
    if (!markdownText) {
        return "";
    }
    // Split the text into lines
    const lines = markdownText.split("\n");
    // Process each line
    const processedLines = lines.map((line) => {
        // Check if the line is a header (starts with # followed by a space)
        if (/^#{1,6}\s/.test(line)) {
            // Extract the content after the # symbols and make it bold
            const headerContent = line.replace(/^#{1,6}\s+/, "");
            return `**${headerContent}**`;
        }
        // Return the line unchanged if it's not a header
        return line;
    });
    // Join the lines back together
    return processedLines.join("\n");
}
