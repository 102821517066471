var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CrmObjectTypeEnum } from "../types";
import { queryCrmObjects } from "./endpoints";
/**
 * Fetches accounts from the CRM.
 * NOTE: This uses the `query_crm_objects` endpoint which fetches the CRM objects from
 * the external CRM instead of using our cached db objects in order to ensure that
 * users can find and map to accounts that haven't synced yet.
 *
 * @param userQuery The query to search for.
 * @param _ids The IDs of the accounts to fetch.
 * @param cursor The cursor to fetch the next page of results.
 * @param queryClient The query client to use to fetch the data.
 * @returns The accounts.
 */
export const onlineAccountsFetcher = (userQuery, _ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
            next_cursor: undefined,
        };
    }
    const accountsQueryResponse = yield queryClient.fetchQuery({
        queryKey: ["queryCrmObjects", CrmObjectTypeEnum.ACCOUNT, userQuery],
        queryFn: () => queryCrmObjects(CrmObjectTypeEnum.ACCOUNT, 
        /* fieldToQuery= */ "name", userQuery, cursor),
    });
    return {
        results: accountsQueryResponse.results.map((account) => {
            return { label: account.name, value: account.id };
        }),
        next_cursor: accountsQueryResponse.next_cursor,
    };
});
/**
 * Fetches opportunities from the CRM.
 * NOTE: This uses the `query_crm_objects` endpoint which fetches the CRM objects from
 * the external CRM instead of using our cached db objects in order to ensure that
 * users can find and map to opportunities that haven't synced yet.
 *
 * @param userQuery The query to search for.
 * @param _ids The IDs of the opportunities to fetch.
 * @param cursor The cursor to fetch the next page of results.
 * @param queryClient The query client to use to fetch the data.
 * @returns The opportunities.
 */
export const onlineOpportunitiesFetcher = (userQuery, _ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
            next_cursor: undefined,
        };
    }
    const accountsQueryResponse = yield queryClient.fetchQuery({
        queryKey: [
            "queryCrmObjects",
            CrmObjectTypeEnum.OPPORTUNITY,
            userQuery,
            cursor,
        ],
        queryFn: () => queryCrmObjects(CrmObjectTypeEnum.OPPORTUNITY, 
        /* fieldToQuery= */ "name", userQuery, cursor),
    });
    return {
        results: accountsQueryResponse.results.map((account) => {
            return { label: account.name, value: account.id };
        }),
        next_cursor: accountsQueryResponse.next_cursor,
    };
});
export const onlineLeadsFetcher = (userQuery, _ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    if (!queryClient) {
        return {
            results: [],
            next_cursor: undefined,
        };
    }
    const leadsQueryResponse = yield queryClient.fetchQuery({
        queryKey: ["queryCrmObjects", CrmObjectTypeEnum.LEAD, userQuery, cursor],
        queryFn: () => queryCrmObjects(CrmObjectTypeEnum.LEAD, 
        /* fieldToQuery= */ "Name", userQuery, cursor),
    });
    return {
        results: leadsQueryResponse.results.map((lead) => {
            var _a, _b;
            return { label: (_b = (_a = lead.email) !== null && _a !== void 0 ? _a : lead.name) !== null && _b !== void 0 ? _b : "", value: lead.id };
        }),
        next_cursor: leadsQueryResponse.next_cursor,
    };
});
