var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { DataTableContext } from "@/components/ui/data_table_context";
import { DataTableSkeleton } from "@/components/ui/data_table_skeleton";
import { Drawer, DrawerContent } from "@/components/ui/drawer_dialog";
import { ScrollArea } from "@/components/ui/scroll_area";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { convertHeadersToBold } from "@/strings";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useInfiniteQuery, useQuery, useQueryClient, } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Mosaic } from "react-mosaic-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BrickScoreDisplay } from "../bricks/brick_score_display";
import { getAccountLabel } from "../common/account_popover/account_popover_button";
import { createCrmOpportunity, createOrUpdateCrmOpportunityData, getAccountData, getActiveTenant, getAllBricks, getColumnsForStructuredOutputsForAccountsTable, getOpportunityData, queryCrmOwners, updateCrmOpportunity, } from "../common/endpoints";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { convertSearchParamsToBackendFilter } from "../common/query_builder/search_params_utils";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { convertSearchParamsToBackendOrderBy } from "../common/sort_rule_builder/search_params_utils";
import { getCrmChatSource } from "../live_cards_chat_bot/chat_source_menu";
import { ChatWithTable } from "../live_cards_chat_bot/chat_with_table";
import useTableChatMosaic from "../live_cards_chat_bot/use_table_chat_mosaic";
import { BackfillProgress } from "../table_backfill/components/backfill_progress";
import { CrmObjectTypeEnum, } from "../types";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
import { AccountPage } from "./account_details";
import CrmFieldValue from "./crm_field_value";
import { CrmObjectReferenceSwitcher } from "./crm_object_reference_switcher";
import { CrmTableV2 } from "./crm_table_v2";
import { useCrmFields } from "./use_crm_fields";
import { useCrmFilterFetchers } from "./use_filters";
import { useCustomSearchParams } from "./use_memoised_search_params";
const DEFAULT_OPPORTUNITIES_FILTER = {
    where_condition: {
        operator: WiserBackendQueryOperator.AND,
        subconditions: [],
    },
};
const DEFAULT_OPPORTUNITIES_ORDER_BY = [
    {
        field: "name",
        order: "ASC",
    },
];
const DEFAULT_TABLE_LAYOUT = {
    table_name: "Opportunity",
    name: "Default view",
    is_default: true,
    is_public: false,
    query_string: "",
    columns: {
        "row-select": 40,
        name: 150,
        account: 200,
        last_modified_time: 250,
        last_call_time: 250,
        last_call_link: 150,
        last_call_summary: 400,
    },
    column_order: [],
};
export const CrmOpportunitiesV2 = () => {
    var _a, _b;
    const { searchParams, layouts, isLoading, isError } = useCustomSearchParams("Opportunity", DEFAULT_TABLE_LAYOUT);
    const navigate = useNavigate();
    const { accountIdParam } = useParams();
    const hasAccountIdParam = accountIdParam !== undefined;
    const filter = searchParams.has("filter")
        ? ((_a = convertSearchParamsToBackendFilter(searchParams)) !== null && _a !== void 0 ? _a : DEFAULT_OPPORTUNITIES_FILTER)
        : DEFAULT_OPPORTUNITIES_FILTER;
    const orderBy = searchParams.has("order_by")
        ? ((_b = convertSearchParamsToBackendOrderBy(searchParams)) !== null && _b !== void 0 ? _b : DEFAULT_OPPORTUNITIES_ORDER_BY)
        : DEFAULT_OPPORTUNITIES_ORDER_BY;
    // State to store the CRM opportunities keyed by their ID.
    // These are useful when the user inline edits a column of an opportunity.
    const [crmOpportunitiesMap, setCrmOpportunitiesMap] = useState(new Map());
    const searchQuery = useRef("");
    const setSearchQuery = (query) => {
        searchQuery.current = query;
        queryClient.invalidateQueries({ queryKey: ["getOpportunityData"] });
    };
    const [visibleColumns, setVisibleColumns] = useState();
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    // Fetch all Account Research Blocks for rendering the research block columns
    const { data: structuredOutputColumns, isLoading: isStructuredOutputColumnsLoading, isError: isStructuredOutputColumnsError, } = useQuery({
        queryKey: ["getColumnsForStructuredOutputsForAccountsTable"],
        queryFn: getColumnsForStructuredOutputsForAccountsTable,
    });
    const filterQuery = JSON.stringify({
        table: "Opportunity",
        structured_filter: {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    filter,
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: searchQuery.current,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        order_by_v2: orderBy,
    });
    const { data, fetchNextPage, isFetching, isLoading: isLoadingOpportunities, } = useInfiniteQuery({
        initialPageParam: 0,
        queryKey: ["getOpportunityData", filterQuery],
        queryFn: ({ pageParam = 0 }) => getOpportunityData(filterQuery, pageParam + 1, 50),
        getNextPageParam: (_lastGroup, groups) => groups.length,
    });
    useEffect(() => {
        if (!data)
            return;
        setCrmOpportunitiesMap(new Map(data.pages
            .flatMap((page) => page.data)
            .map((opportunity) => [opportunity.id, opportunity])));
    }, [data]);
    const opportunitiesPaginatedData = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const total = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0 ? data === null || data === void 0 ? void 0 : data.pages[0].total : 0; }, [data]);
    // Fetch all Account Research Blocks for rendering the research block
    // columns
    const { data: allBricksResponse } = useQuery({
        queryKey: ["getAllBricks"],
        queryFn: getAllBricks,
    });
    const allBricks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.bricks;
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        const columns = [
            columnHelper.accessor("id", {
                id: "row-select",
                header: ({ table }) => (_jsx("div", Object.assign({ className: "flex items-center justify-center pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
                cell: ({ row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center shadow-2xl pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
                enableHiding: false,
                size: 40,
            }),
            columnHelper.accessor((row) => row.name, {
                id: "name",
                header: "Name",
                cell: (props) => (_jsx(CrmFieldValue, { crmObjectId: Number.parseInt(props.row.id), crmFieldId: -1, crmFieldSchema: { type: "text", restrictions: { length: 1000 } }, crmFieldValue: props.getValue(), onNewValueChange: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        setCrmOpportunitiesMap((prevCrmOpportunitiesMap) => {
                            const updatedCrmOpportunity = prevCrmOpportunitiesMap.get(Number.parseInt(props.row.id));
                            if (updatedCrmOpportunity) {
                                updatedCrmOpportunity.name = newValue;
                                return new Map([
                                    ...prevCrmOpportunitiesMap,
                                    [Number.parseInt(props.row.id), updatedCrmOpportunity],
                                ]);
                            }
                            return prevCrmOpportunitiesMap;
                        });
                        yield updateCrmOpportunity(Number.parseInt(props.row.id), 
                        /* name= */ newValue, 
                        /* accountId= */ undefined);
                    }) })),
                enableHiding: false,
                meta: {
                    fieldId: -1,
                    fieldSchema: {
                        type: "text",
                        restrictions: { length: 1000 },
                    },
                    required: true,
                },
            }),
            columnHelper.accessor((row) => { var _a; return (_a = row.account_name) !== null && _a !== void 0 ? _a : ""; }, {
                id: "account",
                header: "Account",
                cell: (props) => {
                    var _a;
                    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center space-x-2" }, { children: [_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                                    columnId: "account",
                                    objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
                                }, selectedOption: props.row.original.account_id
                                    ? {
                                        label: (_a = props.row.original.account_name) !== null && _a !== void 0 ? _a : "",
                                        value: `${props.row.original.account_id}`,
                                    }
                                    : undefined, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                                    const updatedOpportunity = yield updateCrmOpportunity(Number.parseInt(props.row.id), 
                                    /* name= */ undefined, 
                                    /* accountId= */ Number.parseInt(option.value));
                                    setCrmOpportunitiesMap((prevMap) => {
                                        const existingOpportunity = prevMap.get(Number.parseInt(props.row.id));
                                        if (updatedOpportunity) {
                                            return new Map([
                                                ...prevMap,
                                                [
                                                    Number.parseInt(props.row.id),
                                                    Object.assign(Object.assign({}, existingOpportunity), { account_name: updatedOpportunity.account_name, account_id: updatedOpportunity.account_id }),
                                                ],
                                            ]);
                                        }
                                        return prevMap;
                                    });
                                }) }), props.row.original.account_id && (_jsx(Link, Object.assign({ to: `${window.location.pathname}/account/${props.row.original.account_id}${window.location.search}` }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "group flex h-8 w-8 hover:bg-wds-gray-2 rounded-full text-wds-blue-3 hover:text-wds-blue-5 translate-y-0.5" }, { children: _jsx(ArrowTopRightOnSquareIcon, { className: "w-5 h-5 flex-shrink-0" }) })) })))] })));
                },
            }),
            columnHelper.accessor("timestamp", {
                id: "last_modified_time",
                header: "Last modified time",
                cell: (props) => new Date(props.getValue()).toLocaleString(),
            }),
            columnHelper.accessor("last_call_time", {
                id: "last_call_time",
                header: "Last call time",
                cell: (props) => props.getValue() ? new Date(props.getValue()).toLocaleString() : null,
            }),
            columnHelper.accessor("last_call_link", {
                id: "last_call_link",
                header: "Last call link",
                cell: (props) => (_jsx("span", Object.assign({ className: "link-styles" }, { children: props.getValue() ? (_jsx("a", Object.assign({ href: props.getValue() }, { children: "Link to call" }))) : null }))),
            }),
            columnHelper.accessor("last_call_summary", {
                id: "last_call_summary",
                header: "Last call summary",
                cell: (props) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: props.getValue() })) })), _jsx(TooltipContent, { children: _jsx(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: _jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: props.getValue() })) })) })] }) })),
            }),
            ...(allBricks !== null && allBricks !== void 0 ? allBricks : [])
                .filter((block) => block.research_block_type === "ACCOUNT")
                .map((researchBlock) => columnHelper.accessor((row) => {
                var _a, _b, _c;
                return (_c = (_b = (_a = row.research_block_outputs) === null || _a === void 0 ? void 0 : _a.find((blockOutput) => blockOutput.block_id === researchBlock.id)) === null || _b === void 0 ? void 0 : _b.markdown) !== null && _c !== void 0 ? _c : "";
            }, {
                id: `${researchBlock.id}`,
                header: researchBlock.name,
                meta: {
                    brickId: researchBlock.id,
                    brickName: researchBlock.name,
                    brickType: researchBlock.research_block_type,
                },
                cell: (props) => {
                    var _a;
                    const content = props.getValue();
                    if (!content || content.length === 0) {
                        return null;
                    }
                    // Find scoring brick output if it exists
                    const scoringBrickOutput = researchBlock.scoring_brick_id
                        ? (_a = props.row.original.research_block_outputs) === null || _a === void 0 ? void 0 : _a.find((blockOutput) => blockOutput.block_id === researchBlock.scoring_brick_id)
                        : null;
                    const scoringOutputAsJson = scoringBrickOutput === null || scoringBrickOutput === void 0 ? void 0 : scoringBrickOutput.markdown;
                    const scoringOutput = scoringOutputAsJson
                        ? JSON.parse(scoringOutputAsJson)
                        : null;
                    const scoringColor = scoringOutput === null || scoringOutput === void 0 ? void 0 : scoringOutput.score_color;
                    const rationale = scoringOutput === null || scoringOutput === void 0 ? void 0 : scoringOutput.rationale;
                    return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: "overflow-hidden text-ellipsis link-styles inline-flex items-center gap-1" }, { children: [scoringColor && (_jsx(BrickScoreDisplay, { scoring_color: scoringColor, rationale: rationale || "No rationale provided" })), _jsx(ReactMarkdown, Object.assign({ className: "flex line-clamp-2" }, { children: convertHeadersToBold(props.getValue()) }))] })) })), _jsx(TooltipContent, { children: _jsxs(ScrollArea, Object.assign({ className: "w-96 max-h-60 overflow-y-auto" }, { children: [scoringColor && (_jsx(BrickScoreDisplay, { scoring_color: scoringColor, rationale: rationale || "No rationale provided" })), _jsx(ReactMarkdown, { children: props.getValue() })] })) })] }) }));
                },
                size: 400,
            })),
            columnHelper.accessor("owner_id", {
                id: "owner_id",
                header: "Owner",
                cell: (props) => {
                    return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "crm_owner",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                                const { results } = yield queryCrmOwners(userQuery, 
                                /* ids= */ undefined, cursor);
                                return {
                                    results: results.map((owner) => ({
                                        value: owner.id.toString(),
                                        label: owner.name || owner.email,
                                    })),
                                };
                            }),
                        }, onSelectedOptionChange: (option) => __awaiter(void 0, void 0, void 0, function* () {
                            const ownerId = Number.parseInt(option.value, 10);
                            const response = yield updateCrmOpportunity(Number.parseInt(props.row.id), 
                            /* name= */ undefined, 
                            /* accountId= */ undefined, 
                            /* ownerId= */ ownerId);
                            setCrmOpportunitiesMap((prevMap) => {
                                const existingOpportunity = prevMap.get(Number.parseInt(props.row.id));
                                if (existingOpportunity) {
                                    existingOpportunity.owner_id = response.owner_id;
                                    existingOpportunity.owner_name = response.owner_name;
                                    existingOpportunity.owner_email = response.owner_email;
                                    return new Map([
                                        ...prevMap,
                                        [Number.parseInt(props.row.id), existingOpportunity],
                                    ]);
                                }
                                return prevMap;
                            });
                        }), selectedOption: props.row.original.owner_id
                            ? {
                                value: props.row.original.owner_id.toString(),
                                label: props.row.original.owner_name ||
                                    props.row.original.owner_email ||
                                    "",
                            }
                            : undefined }));
                },
            }),
            columnHelper.accessor("manager_name", {
                id: "manager_name",
                header: "Manager",
                cell: (props) => props.getValue(),
            }),
            columnHelper.accessor("team_name", {
                id: "team_name",
                header: "Team",
                cell: (props) => props.getValue(),
            }),
            columnHelper.accessor("department_name", {
                id: "department_name",
                header: "Department",
                cell: (props) => props.getValue(),
            }),
            ...(structuredOutputColumns !== null && structuredOutputColumns !== void 0 ? structuredOutputColumns : []).map((column) => {
                const columnName = Object.keys(column)[0];
                return columnHelper.accessor((row) => { var _a; return (_a = row.account_brick_structured_outputs) === null || _a === void 0 ? void 0 : _a[columnName]; }, {
                    id: columnName,
                    header: columnName,
                    cell: (props) => {
                        var _a, _b, _c, _d;
                        return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: (_b = (_a = props.row.original.account_brick_structured_outputs) === null || _a === void 0 ? void 0 : _a[columnName]) !== null && _b !== void 0 ? _b : "" })) })), _jsx(TooltipContent, { children: (_d = (_c = props.row.original.account_brick_structured_outputs) === null || _c === void 0 ? void 0 : _c[columnName]) !== null && _d !== void 0 ? _d : "" })] }) }));
                    },
                });
            }),
        ];
        return columns;
    }, [allBricks, structuredOutputColumns]);
    const queryClient = useQueryClient();
    const accountsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => {
                const filterConditions = [
                    {
                        where_condition: {
                            field: "name",
                            operator: WiserBackendQueryOperator.LIKE,
                            value: userQuery,
                        },
                    },
                ];
                if (ids) {
                    filterConditions.push({
                        where_condition: {
                            field: "id",
                            operator: WiserBackendQueryOperator.IN,
                            value: ids,
                        },
                    });
                }
                return getAccountData(JSON.stringify({
                    table: "Account",
                    order_by_fields: ["name"],
                    structured_filter: {
                        where_condition: {
                            operator: WiserBackendQueryOperator.AND,
                            subconditions: filterConditions,
                        },
                    },
                }), 1, 200);
            },
            queryKey: ["getAccounts", cursor, userQuery, ids],
        });
        return {
            results: accountsQueryResponse.data.map((account) => {
                return {
                    label: getAccountLabel(undefined, Object.assign(Object.assign({}, account), { account_name: "" })),
                    value: account.id.toString(),
                };
            }),
        };
    });
    const { referenceObjectFetcherMap } = useCrmFilterFetchers();
    const filterFields = useMemo(() => {
        const filterFields = [
            {
                id: "name",
                name: "Name",
                type: "string",
            },
            {
                id: "account_id",
                name: "Account",
                type: "reference",
            },
            {
                id: "last_call_time",
                name: "Last call time",
                type: "date",
                options: [
                    "Today",
                    "Yesterday",
                    "Tomorrow",
                    "Next week",
                    "Last week",
                    "Last 2 weeks",
                    "Last month",
                    "Last year",
                ],
            },
            {
                id: "timestamp",
                name: "Last modified time",
                type: "date",
                options: [
                    "Today",
                    "Yesterday",
                    "Tomorrow",
                    "Next week",
                    "Last week",
                    "Last 2 weeks",
                    "Last month",
                    "Last year",
                ],
            },
            {
                id: "owner_id",
                name: "Owner",
                type: "reference",
            },
            {
                id: "manager_id",
                name: "Manager",
                type: "reference",
            },
            {
                id: "team_id",
                name: "Team",
                type: "reference",
            },
            {
                id: "department_id",
                name: "Department",
                type: "reference",
            },
        ];
        return filterFields;
    }, []);
    // Memoize the table data to prevent unnecessary re-renders.
    const tableData = useMemo(() => {
        return Array.from(crmOpportunitiesMap.values());
    }, [crmOpportunitiesMap]);
    const onNewRowCreate = (newRowData, crmFieldValues) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const newCrmOpportunity = yield createCrmOpportunity((_c = newRowData.find((rowData) => rowData.columnId === "name")) === null || _c === void 0 ? void 0 : _c.value, (_d = newRowData.find((rowData) => rowData.columnId === "account")) === null || _d === void 0 ? void 0 : _d.value, crmFieldValues);
        setCrmOpportunitiesMap((prevMap) => {
            return new Map([...prevMap, [newCrmOpportunity.id, newCrmOpportunity]]);
        });
    });
    const onCrmFieldValueChange = (opportunityId, field, newValue) => __awaiter(void 0, void 0, void 0, function* () {
        yield createOrUpdateCrmOpportunityData(opportunityId, field.id, newValue);
        setCrmOpportunitiesMap((prevMap) => {
            const updatedOpportunity = prevMap.get(opportunityId);
            if (updatedOpportunity) {
                // If crm_data contains a field with the same ID, update the value.
                // else add a new crm_data object.
                if (updatedOpportunity.opportunity_fields) {
                    updatedOpportunity.opportunity_fields[`z${field.id}`] = newValue;
                }
                else {
                    updatedOpportunity.opportunity_fields = {
                        [`z${field.id}`]: newValue,
                    };
                }
                return new Map([...prevMap, [opportunityId, updatedOpportunity]]);
            }
            return prevMap;
        });
    });
    const { crmFields } = useCrmFields();
    const { chatCollapsed, updateChatCollapsed, mosaicState, onResize, onRelease, } = useTableChatMosaic();
    const enableChatWithCrmTable = useFeatureFlagEnabled("CHAT_WITH_CRM_TABLE");
    const backfillBrickColumnsEnabled = useFeatureFlagEnabled("BACKFILL_BRICK_COLUMNS");
    if (!opportunitiesPaginatedData ||
        isLoadingOpportunities ||
        !crmFields ||
        isLoading ||
        isError ||
        !layouts ||
        layouts.length === 0 ||
        !structuredOutputColumns ||
        isStructuredOutputColumnsLoading) {
        return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
    }
    const CrmTableElement = (_jsx(CrmTableV2, { crmObject: CrmObjectTypeEnum.OPPORTUNITY, crmFields: crmFields, tableRows: tableData, totalRows: total, defaultColumns: columns, defaultFilterFields: filterFields, referenceObjectFetcherMap: referenceObjectFetcherMap, columnObjectsFetcher: [
            {
                columnId: "account",
                objectsFetcher: (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () { return accountsFetcher(userQuery, /* ids= */ undefined, cursor); }),
            },
        ], defaultTableLayout: DEFAULT_TABLE_LAYOUT, onNewRowCreate: onNewRowCreate, onCrmFieldValueChange: onCrmFieldValueChange, onSearchQueryChange: setSearchQuery, searchParams: searchParams, layouts: layouts, fetchNextPage: fetchNextPage, isFetching: isFetching, invalidateTableData: () => {
            queryClient.invalidateQueries({
                queryKey: ["getOpportunityData"],
            });
        }, onVisibleColumnsChange: setVisibleColumns, structuredOutputColumns: structuredOutputColumns }));
    const renderCrmTableOrChat = () => {
        if (!enableChatWithCrmTable) {
            return CrmTableElement;
        }
        return (_jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => {
                if (id === "table")
                    return CrmTableElement;
                if (id === "chat")
                    return (_jsx(ChatWithTable, { filterQuery: getConvertedJsonQuery(filterQuery), collapsed: chatCollapsed, setCollapsed: updateChatCollapsed, chatSource: getCrmChatSource("opportunity", tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm), visibleColumns: visibleColumns }));
                return _jsx(_Fragment, {});
            }, initialValue: mosaicState, onChange: onResize, onRelease: onRelease, value: mosaicState, className: "h-[83vh] my-mosaic" }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataTableContext.Provider, Object.assign({ value: {
                    filterQuery: filterQuery,
                    tableObjectName: "Opportunity",
                    totalRowCount: total,
                } }, { children: renderCrmTableOrChat() })), _jsx(Drawer, Object.assign({ open: hasAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate(`/crm/home/opportunities${window.location.search}`);
                } }, { children: _jsx(DrawerContent, Object.assign({ className: "h-full sm:max-w-none w-[92vw] min-h-screen p-0 m-0", hideCloseButton: true }, { children: _jsx(AccountPage, {}) })) })), backfillBrickColumnsEnabled && (_jsx(BackfillProgress, { tableObjectName: "Opportunity" }))] }));
};
