import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { classesForPopover, classesForPopoverIcon, } from "../call_crm_details_popover";
export const getAccountName = (account) => {
    if (!account) {
        return undefined;
    }
    if (account.account_name && account.account_name.length > 0) {
        return account.account_name;
    }
    if (account.name && account.name.length > 0) {
        return account.name;
    }
    if (account.website && account.website.length > 0) {
        return account.website;
    }
    if (account.linkedin_profile_url && account.linkedin_profile_url.length > 0) {
        return account.linkedin_profile_url;
    }
    return undefined;
};
export const getAccountLabel = (tenant, account) => {
    const accountName = getAccountName(account);
    if (accountName && ((account === null || account === void 0 ? void 0 : account.external_id) || !(tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm))) {
        return accountName;
    }
    if (accountName && !(account === null || account === void 0 ? void 0 : account.external_id) && (tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm)) {
        return `Potential ${accountName}`;
    }
    // No account label
    return (tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm) === "HubSpot" ? "No company" : "No account";
};
export const AccountPopoverButton = (props) => {
    // Boolean to find if the account exists in user's CRM.
    // If the user is connected to an external CRM,
    // we check if the external_id of the account is populated.
    // If the user is not connected to external CRM, presence of an account is
    // necessary and sufficient condition.
    const accountExistsInCrm = !!props.account &&
        (!props.tenant.connected_crm || !!props.account.external_id);
    return (_jsxs("div", Object.assign({ className: cn("flex py-[2px] px-2 gap-2 items-center rounded-lg border", classesForPopover(accountExistsInCrm, props.variant), props.className) }, { children: [_jsx(BriefcaseIcon, { className: cn("w-4 h-4 stroke-2 shrink-0", classesForPopoverIcon(accountExistsInCrm, props.variant)) }), _jsx("div", Object.assign({ className: "font-normal leading-snug truncate text-ellipsis" }, { children: getAccountLabel(props.tenant, props.account) }))] })));
};
