import axios from "axios";
import { getCookie } from "../cookies";
import { showErrorModal } from "../error_modal";
const api = axios.create();
api.interceptors.request.use((config) => {
    const token = localStorage.getItem("wiser_jwt");
    if (token) {
        config.headers.Authorization = token;
    }
    const csrfToken = getCookie("csrftoken");
    if (csrfToken) {
        config.headers["X-CSRFToken"] = csrfToken;
    }
    return config;
});
const handleResponse = (promise, options) => {
    return promise
        .then((response) => response.data)
        .catch((error) => {
        var _a;
        if (options === null || options === void 0 ? void 0 : options.customErrorHandler) {
            const handled = options.customErrorHandler(error);
            if (handled) {
                return;
            }
        }
        if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) {
            // Do not show the error modal for 4XX and 5XX errors.
            if (error.response.status >= 400 && error.response.status < 600) {
                return;
            }
        }
        showErrorModal(error);
    });
};
const Request = {
    get: (url, params, axiosConfig, options) => {
        return handleResponse(api.get(url, Object.assign({ params }, (axiosConfig || {}))), options);
    },
    post: (url, data, axiosConfig, options) => {
        return handleResponse(api.post(url, data, axiosConfig), options);
    },
    put: (url, data, axiosConfig, options) => {
        return handleResponse(api.put(url, data, axiosConfig), options);
    },
    del: (url, axiosConfig, options) => {
        return handleResponse(api.delete(url, axiosConfig), options);
    },
};
export default Request;
