import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { DataTable } from "@/components/ui/data_table";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTableContext } from "@/components/ui/data_table_context";
import { getColumnOrder, useMatchingLayout, } from "@/components/ui/data_table_toolbar_hooks";
import { DataTableToolbarV2 } from "@/components/ui/data_table_toolbar_v2";
import { Drawer, DrawerContent, DrawerTitle, } from "@/components/ui/drawer_dialog";
import { useQuery, useQueryClient, } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getAppIntegrationData } from "../common/endpoints";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { AccountPage } from "../crm/account_details";
import CallPage from "../home/call_v3";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { useBuildFieldsForFilter, useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useInitialSortRules, useSortRuleConfig, useSortRulesChange, } from "../table_hooks/use_sort";
import { getColumnIdColumnNameMap, getColumns } from "./calls_table_columns";
import { FIELDS, SORTABLE_FIELDS } from "./calls_table_filters";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
import { CallsTableToolbarActions } from "./calls_table_toolbar_actions";
export function CallsTable(data) {
    var _a;
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { callIdParam, accountIdParam } = useParams();
    const hasCallOrAccountIdParam = callIdParam !== undefined || accountIdParam !== undefined;
    const calls = data.calls;
    const total = data.total;
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", data.tenant.id],
        queryFn: getAppIntegrationData,
    });
    // Memoize the columns so they don't re-render on every render
    const columns = React.useMemo(() => getColumns(data.tenant, data.filterQuery, data.crmFields, data.structuredOutputColumns, queryClient, data.playbookItemGroups, data.allTrackerBricks, appIntegrationData), [data.playbookItemGroups, appIntegrationData, data.structuredOutputColumns]);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = React.useState(getColumnOrder(columns, matchingLayout));
    const columnIdMap = React.useMemo(() => getColumnIdColumnNameMap(), []);
    const playbook_item_names = React.useMemo(() => {
        var _a;
        return data.playbookItemGroups
            ? data.playbookItemGroups.map((item) => item.default_item.name)
            : (_a = data.allTrackerBricks) === null || _a === void 0 ? void 0 : _a.map((item) => item.name);
    }, []);
    const fieldsForFilter = useBuildFieldsForFilter({
        crmFields: data.crmFields,
        fields: FIELDS,
        table: "calls",
        structuredOutputColumns: data.structuredOutputColumns,
        brickNames: playbook_item_names,
    });
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(fieldsForFilter);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const { table } = useDataTable({
        data: calls,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: Object.assign(Object.assign(Object.assign(Object.assign({ call_state: false, call_state_with_reason: false, call_source: false, meeting_host: false, participants: false, call_duration_minutes: false, post_call_all_slack_items_sent: false, post_call_any_slack_item_sent: false, post_call_crm_updates_mapped: false, post_call_crm_updates_pushed: false, playbook_content: false, description: false, account_id: false, opportunity_id: false, salesperson_speech_speed_wpm: false, salesperson_talk_time_percent: false, autojoin: false }, Object.fromEntries((_a = playbook_item_names === null || playbook_item_names === void 0 ? void 0 : playbook_item_names.map((name) => [name, false])) !== null && _a !== void 0 ? _a : [])), Object.fromEntries(data.structuredOutputColumns.map((column) => [
                Object.keys(column)[0],
                false,
            ]))), Object.fromEntries(data.crmFields.map((crmField) => [
                `account_fields__z${crmField.id.toString()}`,
                false,
            ]))), Object.fromEntries(data.crmFields.map((crmField) => [
                `opportunity_fields__z${crmField.id.toString()}`,
                false,
            ]))),
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    return (_jsxs(_Fragment, { children: [_jsx(DataTableContext.Provider, Object.assign({ value: {
                    filterQuery: data.filterQuery,
                    tableObjectName: "CallExtendedWithBricks",
                    totalRowCount: total,
                } }, { children: _jsx(DataTable, Object.assign({ table: table, floatingBar: _jsx(CallsTableFloatingBar, { getVisibleColumns: () => table.getVisibleLeafColumns().map((column) => {
                            var _a, _b;
                            return ({
                                accessor: column.id,
                                header: (_b = (_a = column.columnDef.header) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "",
                            });
                        }), getSelectedRows: () => table
                            .getFilteredSelectedRowModel()
                            .rows.map((row) => row.original), toggleAllRowsSelected: table.toggleAllRowsSelected }), columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: data.fetchNextPage, total: total, totalFetched: calls.length, isFetching: data.isFetching }, { children: _jsx(DataTableToolbarV2, Object.assign({ table: table, columnIdMap: columnIdMap, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_TABLE_LAYOUT, onVisibleColumnsChange: data.onVisibleColumnsChange, onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me calls", sortRuleBuilderPanelTitle: "Sort calls by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, onSearchQueryChange: data.setSearchQuery, matchingLayout: matchingLayout }, { children: _jsx(CallsTableToolbarActions, { table: table, filterQueryJson: getConvertedJsonQuery(data.filterQuery) }) })) })) })), _jsx(Drawer, Object.assign({ open: hasCallOrAccountIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate(`/calls${window.location.search}`);
                } }, { children: _jsxs(DrawerContent, Object.assign({ className: "h-full sm:max-w-none p-0 w-[92vw] min-h-screen p-0 m-0", hideCloseButton: true }, { children: [!accountIdParam && (_jsxs(_Fragment, { children: [_jsx(DrawerTitle, Object.assign({ className: "hidden" }, { children: "Call Details" })), _jsx(CallPage, { updateSelectedCall: () => { }, onClose: () => navigate(`/calls${window.location.search}`) })] })), accountIdParam && _jsx(AccountPage, { accId: accountIdParam })] })) }))] }));
}
