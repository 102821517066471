import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
const TextFieldTooltip = ({ length }) => {
    return (_jsx("div", Object.assign({ className: "flex flex-col items-start gap-2" }, { children: _jsx("span", Object.assign({ className: "text-sm font-normal text-black" }, { children: `Character limit: ${length}` })) })));
};
const NumberFieldTooltip = ({ precision, scale, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-normal text-black" }, { children: `Digits allowed before decimal point: ${precision}` })), _jsx("span", Object.assign({ className: "text-sm font-normal text-black" }, { children: `Digits allowed after decimal point: ${scale}` }))] })));
};
const SelectFieldTooltip = ({ options }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col items-start" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-normal text-black" }, { children: "Valid values:" })), _jsx("ul", { children: options.map((option) => (_jsx("li", Object.assign({ className: "text-sm font-normal text-black" }, { children: option }), option))) })] })));
};
const FieldSchemaRenderer = ({ id, fieldSchema, }) => {
    const renderFieldTooltip = () => {
        switch (fieldSchema.type) {
            case "Text":
                return _jsx(TextFieldTooltip, { length: fieldSchema.restrictions.length });
            case "Currency":
            case "Number":
                return (_jsx(NumberFieldTooltip, { precision: fieldSchema.restrictions.precision, scale: fieldSchema.restrictions.scale }));
            case "Single select":
                return (_jsx(SelectFieldTooltip, { options: fieldSchema.restrictions.picklist_values }));
            case "Multi select":
                return (_jsx(SelectFieldTooltip, { options: fieldSchema.restrictions.picklist_values }));
            default:
                return null;
        }
    };
    return (_jsxs("div", Object.assign({ className: "flex items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-xs font-medium text-gray-500" }, { children: fieldSchema.type })), fieldSchema.type !== "Boolean" && (_jsxs(_Fragment, { children: [_jsx("a", Object.assign({ "data-tooltip-id": `field-restriction-tooltip-${id}` }, { children: _jsx(QuestionMarkCircleIcon, { className: "w-4 h-4 text-gray-500 hover:cursor-pointer" }) })), _jsx(Tooltip, Object.assign({ id: `field-restriction-tooltip-${id}`, noArrow: true, opacity: 1, style: {
                            backgroundColor: "white",
                            padding: "16px 24px",
                            borderRadius: "8px",
                            border: "1px solid #E2E2E2",
                            boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
                        } }, { children: renderFieldTooltip() }))] }))] })));
};
export default FieldSchemaRenderer;
