import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BackfillColumnDropdown } from "@/table_backfill/components/backfill_column_dropdown";
import { useContext } from "react";
import { UserContext } from "../../App";
import useFeatureFlagEnabled from "../../use_feature_flag_enabled";
export const ColumnHeaderRenderer = ({ headerDisplayName, brickId, brickName, gridApi, }) => {
    const { userIsStaff, userIsTenantAdmin } = useContext(UserContext);
    const backfillBrickColumnsEnabled = useFeatureFlagEnabled("BACKFILL_BRICK_COLUMNS");
    const selectedRowIds = gridApi.getSelectedRows().map((row) => row.id);
    const tableRows = gridApi.getRenderedNodes().map((node) => node.data);
    if (!backfillBrickColumnsEnabled || !(userIsTenantAdmin || userIsStaff)) {
        return _jsx("span", { children: headerDisplayName });
    }
    return (_jsxs("div", Object.assign({ className: "group flex gap-2 items-center justify-between w-full" }, { children: [_jsx("span", Object.assign({ className: "w-1/2" }, { children: headerDisplayName })), brickId && (_jsx("div", Object.assign({ className: "invisible group-hover:visible shrink-0" }, { children: _jsx(BackfillColumnDropdown, { tableRows: tableRows, selectedRowIds: selectedRowIds, brickId: brickId, brickName: brickName }) })))] })));
};
