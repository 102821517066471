import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "@/components/ui/data_table_skeleton";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";
import { debounce } from "../common/debounce";
import { getActiveTenant, getBricksByType, getCallsForTableView, getColumnsForStructuredOutputsForCallsTable, getCreditInfo, getCrmFields, } from "../common/endpoints";
import { useCustomSearchParams } from "../crm/use_memoised_search_params";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { CallsTable } from "./calls_table";
import { convertNewSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
import "react-mosaic-component/react-mosaic-component.css";
import { BanknotesIcon } from "@heroicons/react/20/solid";
import { Mosaic } from "react-mosaic-component";
import { UserContext } from "../App";
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import { CALLS_TABLE } from "../live_cards_chat_bot/chat_source_menu";
import { ChatWithTable } from "../live_cards_chat_bot/chat_with_table";
import useTableChatMosaic from "../live_cards_chat_bot/use_table_chat_mosaic";
import { BackfillProgress } from "../table_backfill/components/backfill_progress";
import { TenantCreditTypeEnum } from "../types";
import useFeatureFlagEnabled from "../use_feature_flag_enabled";
export default function IndexPage() {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const { searchParams, layouts, isLoading: layoutsLoading, } = useCustomSearchParams("calls", DEFAULT_TABLE_LAYOUT);
    const filterQuery = convertNewSearchParamsIntoFilterQuery(searchParams, "CallExtendedWithBricks", debouncedQuery);
    const backfillBrickColumnsEnabled = useFeatureFlagEnabled("BACKFILL_BRICK_COLUMNS");
    const [visibleColumns, setVisibleColumns] = useState();
    const { chatCollapsed, updateChatCollapsed, mosaicState, onResize, onRelease, } = useTableChatMosaic();
    const pageSize = Number.parseInt(searchParams.get("pageSize") || "50") || 50;
    const { data, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery({
        initialPageParam: 0,
        queryKey: ["callsTable", filterQuery, pageSize],
        queryFn: ({ pageParam = 0 }) => getCallsForTableView(filterQuery, pageParam + 1, pageSize),
        getNextPageParam: (_lastGroup, groups) => groups.length,
    });
    const { data: structuredOutputColumns, isLoading: isStructuredOutputColumnsLoading, isError: isStructuredOutputColumnsError, } = useQuery({
        queryKey: ["getColumnsForStructuredOutputsForCallsTable"],
        queryFn: getColumnsForStructuredOutputsForCallsTable,
    });
    //flatten the array of arrays from the useInfiniteQuery hook
    const calls = useMemo(() => { var _a, _b; return (_b = (_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.flatMap((page) => page.data)) !== null && _b !== void 0 ? _b : []; }, [data]);
    const allTrackerBricksQuery = useQuery({
        queryKey: ["getBricksByType", "TRACKER"],
        queryFn: () => getBricksByType("TRACKER"),
    });
    const allTrackerBricks = allTrackerBricksQuery === null || allTrackerBricksQuery === void 0 ? void 0 : allTrackerBricksQuery.data;
    const { data: crmFields, isLoading: crmFieldsLoading } = useQuery({
        queryKey: ["getCrmFields"],
        queryFn: getCrmFields,
    });
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const { userIsStaff, userIsTenantAdmin } = useContext(UserContext);
    const { data: brickCredit } = useQuery({
        queryKey: ["brickCredit", activeTenant === null || activeTenant === void 0 ? void 0 : activeTenant.id],
        queryFn: () => getCreditInfo(TenantCreditTypeEnum.BRICK),
        enabled: userIsTenantAdmin || userIsStaff,
    });
    const { total, wiser_joined_count } = useMemo(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data.pages) === null || _a === void 0 ? void 0 : _a.length) && data.pages.length > 0) {
            return {
                total: data.pages[0].total,
                wiser_joined_count: data.pages[0].wiser_joined_count,
            };
        }
        return { total: 0, wiser_joined_count: 0 };
    }, [data === null || data === void 0 ? void 0 : data.pages]);
    const everythingIsLoaded = !isLoading &&
        !crmFieldsLoading &&
        !layoutsLoading &&
        data &&
        allTrackerBricks &&
        activeTenant &&
        layouts &&
        crmFields &&
        layouts.length > 0 &&
        structuredOutputColumns &&
        !isStructuredOutputColumnsLoading;
    const renderTableAndChat = () => {
        if (!everythingIsLoaded) {
            return (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }));
        }
        const CallsTableElement = (_jsx(CallsTable, { calls: calls, total: total, wiser_joined_count: wiser_joined_count, allTrackerBricks: allTrackerBricks, layouts: layouts, searchQuery: searchQuery, setSearchQuery: setSearchQuery, onVisibleColumnsChange: setVisibleColumns, tenant: activeTenant, searchParams: searchParams, filterQuery: filterQuery, fetchNextPage: fetchNextPage, isFetching: isFetchingNextPage, crmFields: crmFields, structuredOutputColumns: structuredOutputColumns }));
        return (_jsx(Mosaic, { resize: { minimumPaneSizePercentage: 1 }, renderTile: (id) => {
                if (id === "table") {
                    return CallsTableElement;
                }
                if (id === "chat" && (visibleColumns === null || visibleColumns === void 0 ? void 0 : visibleColumns.length)) {
                    return (_jsx(ChatWithTable, { filterQuery: getConvertedJsonQuery(filterQuery), visibleColumns: visibleColumns, collapsed: chatCollapsed, setCollapsed: updateChatCollapsed, chatSource: CALLS_TABLE }));
                }
                return _jsx(_Fragment, {});
            }, initialValue: mosaicState, onChange: onResize, onRelease: onRelease, value: mosaicState, className: "h-[83vh] my-mosaic" }));
    };
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsxs("div", Object.assign({ className: "flex w-full self-stretch justify-between p-2" }, { children: [_jsx("div", Object.assign({ className: "w-60 text-black text-3xl" }, { children: "Calls" })), backfillBrickColumnsEnabled && brickCredit && (_jsxs("div", Object.assign({ className: "flex gap-2 items-center" }, { children: [_jsx(BanknotesIcon, { className: "w-4 h-4 shrink-0" }), _jsxs("span", { children: ["Credits Remaining: ", brickCredit.credits] })] })))] })), renderTableAndChat(), backfillBrickColumnsEnabled && (_jsx(BackfillProgress, { tableObjectName: "Call" }))] })));
}
