export const getTagColor = (name) => {
    const nameHash = Array.from(name).reduce((hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0);
    const hue = Math.abs(nameHash % 360);
    const bgColor = `hsl(${hue}, 70%, 88%)`;
    const textColor = `hsl(${hue}, 70%, 30%)`;
    return { bgColor, textColor };
};
export const getDuration = (video) => {
    if (video.is_clip) {
        return video.end_time_seconds - video.start_time_seconds;
    }
    return (new Date(video.stop_recording_time).getTime() / 1000 -
        new Date(video.start_recording_time).getTime() / 1000);
};
