import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tabs, TabsContent, TabsList } from "@/components/ui/tabs";
import { TabsTrigger } from "@radix-ui/react-tabs";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { AccountSearchResult } from "./account_chat_search_result";
import { AccountWikiBricks } from "./account_wiki_bricks";
import { AccountWikiSideNav } from "./account_wiki_side_nav";
import { AccountResearchDashboardSidePanel } from "./dashboard_side_panel";
/**
 * This is the component that renders the Wiki section of the account research page.
 * This contains the table of contents, the bricks, and the side-box view of a brick.
 */
export const AccountWikiDashboard = ({ account, accountBlocks, scrollToBlock, blocksRefCallback, accountBrickSetsWithBricks, allBricks, setHighlightedBlockIds, chatMessages, }) => {
    const [selectedSideViewOptions, setSelectedSideViewOptions] = useState(null);
    const [sideViewVisible, setSideViewVisible] = useState(false);
    const TABS = [
        {
            label: "Account Research",
            value: "account_research",
        },
        {
            label: "Search Results",
            value: "search_results",
        },
    ];
    const [selectedTab, setSelectedTab] = useState("search_results");
    useEffect(() => {
        if (chatMessages.length > 0) {
            setSelectedTab("search_results");
        }
    }, [chatMessages]);
    const renderAccountBricks = (renderedInTab) => {
        return (_jsx(AccountWikiBricks, { accountBlocks: accountBlocks, blocksRefCallback: blocksRefCallback, setSelectedSideViewOptions: (options) => {
                setSelectedSideViewOptions(options);
                setSideViewVisible(true);
            }, setHighlightedBlockIds: setHighlightedBlockIds, allBricks: allBricks, renderedInTab: renderedInTab }));
    };
    return (_jsxs("div", Object.assign({ className: "flex p-2 justify-center items-start w-full overflow-y-auto" }, { children: [_jsx(AccountWikiSideNav, { accountBlocks: accountBlocks, accountBrickSetsWithBricks: accountBrickSetsWithBricks, scrollToBlock: (blockId) => {
                    setSelectedTab("account_research");
                    // HACK!
                    // This is a hack to make sure the scrollToBlock function is called
                    // after the account research drawer is rendered.
                    setTimeout(() => {
                        scrollToBlock(blockId);
                    }, 100);
                } }), chatMessages && chatMessages.length > 0 ? (_jsx("div", Object.assign({ className: "flex px-6 py-3 flex-col items-center bg-wds-gray-1 border border-l-0 border-wds-gray-3 rounded-r-2xl w-full h-full overflow-y-auto" }, { children: _jsxs(Tabs, Object.assign({ value: selectedTab, onValueChange: (value) => setSelectedTab(value), className: "w-full" }, { children: [_jsx(TabsList, Object.assign({ className: "p-0 h-auto flex items-start self-stretch border-0 border-b border-wds-gray-3 rounded-none justify-normal" }, { children: TABS.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, className: "outline-none px-4 py-3 justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-blue-600 data-[state=active]:border-b-2 font-medium text-base" }, { children: tab.label }), tab.value))) })), _jsx(TabsContent, Object.assign({ value: "account_research", className: "m-0" }, { children: renderAccountBricks(true) }), "account_research"), _jsx(TabsContent, Object.assign({ value: "search_results", className: "m-0" }, { children: _jsx(AccountSearchResult, { account: account, chatMessages: chatMessages }) }), "search_results")] })) }))) : (_jsx(_Fragment, { children: renderAccountBricks(false) })), _jsx(CSSTransition, Object.assign({ in: sideViewVisible, timeout: 150, classNames: {
                    exitActive: "opacity-50 translate-x-full hover:transition-transform duration-300",
                    exit: "opacity-100 translate-x-0",
                }, unmountOnExit: true, onExited: () => setSelectedSideViewOptions(null) }, { children: selectedSideViewOptions && (_jsx(AccountResearchDashboardSidePanel, { account: account, accountBlocks: accountBlocks, sideViewOptions: selectedSideViewOptions, onSideViewClose: () => {
                        setSideViewVisible(false);
                        setHighlightedBlockIds(new Set());
                    } })) }))] })));
};
