var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { flexRender } from "@tanstack/react-table";
import { useMemo } from "react";
import { z } from "zod";
import Ellipse from "../common/ellipse";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { departmentsFetcher } from "../departments/department.helpers";
import { DEFAULT_USER_TIMEZONE } from "../settings/constants";
import { getTeams } from "../teams/endpoints";
import { getUsers } from "./endpoints";
export const addSearchParamsToQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    const filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    const order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    const json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "email",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
export function getColumns(onRowClick) {
    const renderBoolean = (value, yesText = "Yes", noText = "No") => {
        return (_jsxs("div", Object.assign({ className: "flex w-[6.25rem] items-center" }, { children: [value && (_jsxs(Badge, Object.assign({ variant: "green" }, { children: [_jsx(Ellipse, { classes: "h-[6px] w-[6px] my-[2px] mr-[6px] bg-green-600" }), yesText] }))), !value && (_jsxs(Badge, Object.assign({ variant: "gray" }, { children: [_jsx(Ellipse, { classes: "h-[6px] w-[6px] my-[2px] mr-[6px] bg-wds-gray-6" }), noText] })))] })));
    };
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: row.original.name })) })), _jsx(TooltipContent, { children: row.original.name })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "email",
            accessorKey: "email",
            header: "Email",
            cell: ({ row, cell }) => flexRender(row.original.email, cell.getContext()),
            accessorFn: (row) => row.email,
        },
        {
            id: "is_active",
            accessorKey: "is_active",
            header: "Active",
            cell: ({ row }) => renderBoolean(row.original.is_active, "Active", "Inactive"),
            accessorFn: (row) => row.is_active,
        },
        {
            id: "is_tenant_admin",
            accessorKey: "is_tenant_admin",
            header: "Company admin",
            cell: ({ row }) => renderBoolean(row.original.is_tenant_admin),
            accessorFn: (row) => row.is_tenant_admin,
        },
        {
            id: "precall_notification_duration",
            accessorKey: "precall_notification_duration",
            header: "Pre-call notification duration",
            cell: ({ row, cell }) => flexRender(row.original.precall_notification_duration, cell.getContext()),
            accessorFn: (row) => row.precall_notification_duration,
        },
        {
            id: "precall_email_enabled",
            accessorKey: "precall_email_enabled",
            header: "Pre-call email enabled",
            cell: ({ row }) => renderBoolean(row.original.precall_email_enabled),
            accessorFn: (row) => row.precall_email_enabled,
        },
        {
            id: "precall_slack_enabled",
            accessorKey: "precall_slack_enabled",
            header: "Pre-call slack enabled",
            cell: ({ row }) => renderBoolean(row.original.precall_slack_enabled),
            accessorFn: (row) => row.precall_slack_enabled,
        },
        {
            id: "autojoin_external_meetings",
            accessorKey: "autojoin_external_meetings",
            header: "Autojoin External meetings",
            cell: ({ row }) => renderBoolean(row.original.autojoin_external_meetings),
            accessorFn: (row) => row.autojoin_external_meetings,
        },
        {
            id: "autojoin_internal_meetings",
            accessorKey: "autojoin_internal_meetings",
            header: "Autojoin Internal meetings",
            cell: ({ row }) => renderBoolean(row.original.autojoin_internal_meetings),
            accessorFn: (row) => row.autojoin_internal_meetings,
        },
        {
            id: "custom_bot_name",
            accessorKey: "custom_bot_name",
            header: "Bot name",
            cell: ({ row, cell }) => flexRender(row.original.custom_bot_name, cell.getContext()),
        },
        {
            id: "no_live_bot",
            accessorKey: "no_live_bot",
            header: "Bot-enabled meetings",
            cell: ({ row }) => renderBoolean(!row.original.no_live_bot),
            accessorFn: (row) => !row.no_live_bot,
        },
        {
            id: "timezone",
            accessorKey: "timezone",
            header: "Timezone",
            cell: ({ cell, row }) => flexRender(row.original.timezone, cell.getContext()),
        },
        {
            id: "manager",
            accessorKey: "manager",
            header: "Manager",
            cell: ({ cell, row }) => flexRender(row.original.manager, cell.getContext()),
        },
        {
            id: "team",
            accessorKey: "Team",
            header: "Team",
            cell: ({ cell, row }) => flexRender(row.original.team, cell.getContext()),
        },
        {
            id: "department",
            accessorKey: "department",
            header: "Department",
            cell: ({ cell, row }) => flexRender(row.original.department, cell.getContext()),
        },
        {
            id: "user_type",
            accessorKey: "user_type",
            header: "Account type",
            cell: ({ cell, row }) => flexRender(row.original.user_type, cell.getContext()),
        },
        {
            id: "last_login_at",
            accessorKey: "last_login_at",
            header: "Last login at",
            cell: ({ cell, row }) => flexRender(row.original.last_login_at !== "Never"
                ? new Date(row.original.last_login_at).toLocaleString()
                : "Never", cell.getContext()),
        },
        {
            id: "total_meetings",
            accessorKey: "total_meetings",
            header: "Total meetings",
            cell: ({ row, cell }) => flexRender(row.original.total_meetings, cell.getContext()),
        },
        {
            id: "total_internal_meetings",
            accessorKey: "total_internal_meetings",
            header: "Total internal meetings",
            cell: ({ row, cell }) => flexRender(row.original.total_internal_meetings, cell.getContext()),
        },
        {
            id: "total_external_meetings",
            accessorKey: "total_external_meetings",
            header: "Total external meetings",
            cell: ({ row, cell }) => flexRender(row.original.total_external_meetings, cell.getContext()),
        },
        {
            id: "internal_meeting_template",
            accessorKey: "internal_meeting_template",
            header: "Internal meeting template",
            cell: ({ row, cell }) => flexRender(row.original.internal_meeting_template, cell.getContext()),
        },
        {
            id: "external_meeting_template",
            accessorKey: "external_meeting_template",
            header: "External meeting template",
            cell: ({ row, cell }) => flexRender(row.original.external_meeting_template, cell.getContext()),
        },
        {
            id: "actions",
            accessorKey: "actions",
            header: "Update user",
            cell: ({ row }) => (_jsx(Button, Object.assign({ variant: "link", size: "icon", onClick: () => onRowClick(row.original) }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5" }) }))),
            enableHiding: false,
        },
    ];
}
export const FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "email",
        name: "Email",
        type: "string",
    },
    {
        id: "is_active",
        name: "Active",
        type: "boolean",
    },
    {
        id: "is_tenant_admin",
        name: "Company admin",
        type: "string",
    },
    {
        id: "last_login_at",
        name: "Last login at",
        type: "date",
        options: [
            "Today",
            "Yesterday",
            "Tomorrow",
            "Next week",
            "Last week",
            "Last 2 weeks",
            "Last month",
            "Last year",
        ],
    },
    {
        id: "precall_email_enabled",
        name: "Pre-call email enabled",
        type: "boolean",
    },
    {
        id: "precall_slack_enabled",
        name: "Pre-call slack enabled",
        type: "boolean",
    },
    {
        id: "autojoin_external_meetings",
        name: "Autojoin External meetings",
        type: "boolean",
    },
    {
        id: "autojoin_internal_meetings",
        name: "Autojoin Internal meetings",
        type: "boolean",
    },
    {
        id: "custom_bot_name",
        name: "Bot name",
        type: "string",
    },
    {
        id: "no_live_bot",
        name: "No live bot",
        type: "boolean",
    },
    {
        id: "total_meetings",
        name: "Total meetings",
        type: "number",
    },
    {
        id: "total_internal_meetings",
        name: "Total internal meetings",
        type: "number",
    },
    {
        id: "total_external_meetings",
        name: "Total external meetings",
        type: "number",
    },
    {
        id: "user_type",
        name: "Account type",
        type: "select",
        options: ["Recorder", "Listener"],
    },
    {
        id: "department_id",
        name: "Department",
        type: "reference",
    },
    {
        id: "team_id",
        name: "Team",
        type: "reference",
    },
    {
        id: "internal_meeting_template",
        name: "Internal meeting template",
        type: "string",
    },
    {
        id: "external_meeting_template",
        name: "External meeting template",
        type: "string",
    },
];
export const SORTABLE_FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "email",
        name: "Email",
        type: "string",
    },
    {
        id: "last_login_at",
        name: "Last login",
        type: "date",
    },
];
export const USER_SCHEMA = z.object({
    Email: z.string().email(),
    "First name": z.string().min(1),
    "Last name": z.string().min(1),
    "Custom bot Name": z.string().min(1).default("WiserAI"),
    "Botless meetings": z.boolean().default(false),
    "Is admin": z.boolean().default(false),
    Manager: z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
    Team: z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
    Department: z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
    "Autojoin external meetings": z.boolean().default(true),
    "Autojoin internal meetings": z.boolean().default(false),
    Timezone: z
        .object({
        label: z
            .string()
            .refine((val) => Intl.supportedValuesOf("timeZone").includes(val), "Must be a valid timezone"),
        value: z
            .string()
            .refine((val) => Intl.supportedValuesOf("timeZone").includes(val), "Must be a valid timezone"),
    })
        .default({
        label: DEFAULT_USER_TIMEZONE,
        value: DEFAULT_USER_TIMEZONE,
    }),
    "Is active": z.boolean().default(true),
    "Account type": z.string().default("Recorder"),
});
export const usersFetcher = (queryClient, userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    const usersQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        operator: WiserBackendQueryOperator.OR,
                        subconditions: [
                            {
                                where_condition: {
                                    field: "email",
                                    operator: WiserBackendQueryOperator.LIKE,
                                    value: userQuery,
                                },
                            },
                            {
                                where_condition: {
                                    field: "name",
                                    operator: WiserBackendQueryOperator.LIKE,
                                    value: userQuery,
                                },
                            },
                        ],
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getUsers(JSON.stringify({
                table: "User",
                order_by_fields: ["email"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getUsers", cursor, userQuery, ids],
    });
    const results = usersQueryResponse.data.map((user) => {
        return {
            label: user.email,
            value: user.id.toString(),
        };
    });
    results.push({
        label: "None",
        value: "None",
    });
    return { results };
});
export const teamsFetcher = (queryClient, userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    const teamsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getTeams(JSON.stringify({
                table: "Team",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getTeams", cursor, userQuery, ids],
    });
    const results = teamsQueryResponse.data.map((team) => {
        return {
            label: team.name,
            value: team.id.toString(),
        };
    });
    results.push({
        label: "None",
        value: "None",
    });
    return { results };
});
const _checkEmailAlreadyExists = (email, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const json_query = JSON.stringify({
        table: "User",
        order_by_fields: ["email"],
        structured_filter: {
            where_condition: {
                field: "email",
                operator: WiserBackendQueryOperator.EQUALS,
                value: email,
            },
        },
    });
    const response = yield queryClient.fetchQuery({
        queryKey: ["getUsers", email],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () { return yield getUsers(json_query); }),
    });
    return response.data.length > 0;
});
export const useFormFieldMeta = (queryClient) => {
    const formFieldMeta = useMemo(() => ({
        Email: {
            type: "text",
            placeholder: "Email",
            defaultValue: "",
            required: true,
            onBlur: (event, form) => __awaiter(void 0, void 0, void 0, function* () {
                const emailExists = yield _checkEmailAlreadyExists(event.target.value, queryClient);
                if (emailExists) {
                    form.setError("Email", {
                        type: "validate",
                        message: "This email is already in use",
                    });
                }
                else {
                    form.clearErrors("Email");
                }
            }),
        },
        "First name": {
            type: "text",
            placeholder: "Given name",
            defaultValue: "",
            required: true,
        },
        "Last name": {
            type: "text",
            placeholder: "Last name",
            defaultValue: "",
            required: true,
        },
        "Is admin": {
            type: "switch",
            defaultValue: false,
        },
        "Autojoin external meetings": {
            type: "switch",
            defaultValue: true,
        },
        "Autojoin internal meetings": {
            type: "switch",
            defaultValue: false,
        },
        "Account type": {
            type: "select",
            options: ["Recorder", "Listener"].map((v) => ({ label: v, value: v })),
            defaultValue: "Recorder",
        },
        Manager: {
            id: "manager",
            type: "async_search_select",
            placeholder: "Manager",
            defaultValue: undefined,
            optionsFetcher: (userQuery) => usersFetcher(queryClient, userQuery),
            advancedField: true,
        },
        Team: {
            id: "team",
            type: "async_search_select",
            placeholder: "Team",
            defaultValue: undefined,
            optionsFetcher: (userQuery) => teamsFetcher(queryClient, userQuery),
            advancedField: true,
        },
        Department: {
            id: "department",
            type: "async_search_select",
            placeholder: "Department",
            defaultValue: undefined,
            optionsFetcher: (userQuery) => departmentsFetcher(queryClient, userQuery),
            advancedField: true,
        },
        "Custom bot Name": {
            type: "text",
            placeholder: "WiserAI",
            defaultValue: "WiserAI",
            advancedField: true,
        },
        "Botless meetings": {
            type: "switch",
            tip: "No bot joins the call, instead Wiser uses Zoom's cloud recording to capture call information. Note: Zoom integration needs to be connected for this.",
            defaultValue: false,
            advancedField: true,
        },
    }), []);
    return formFieldMeta;
};
export const getEmailMessage = (company_name, user_name, onboarding_guide_url) => {
    const escapeHtml = (str) => str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    const safeCompanyName = escapeHtml(company_name);
    const safeUserName = escapeHtml(user_name);
    const safeOnboardingGuideUrl = escapeHtml(onboarding_guide_url || "");
    return `
    <p>Hi ${safeCompanyName} team,</p>
    <p>We're excited to welcome you to Wiser! We wanted to share a few key steps to help set up your accounts:</p>
    <ol>
      <li>Sign in to Wiser at <a href="https://app.getwiser.io">app.getwiser.io</a></li>
      <li>Name your bot (e.g., ${safeUserName}'s notetaker)</li>
      <li>Install the <a href="https://app.getwiser.io/settings/integrations">GCal integration</a></li>
      <li>Install the <a href="https://chromewebstore.google.com/detail/wiser/eojamodgpoihijaopafkbllbdmhlbemf?hl=en">Chrome plugin</a> (refresh both Google Calendar and Wiser for the integration to appear on your calendar events)</li>
      <li>Turn on <a href="https://app.getwiser.io/settings/user_settings">auto-join for external meetings</a> (you can disable Wiser from joining a call directly in GCal or Wiser's calendar)</li>
    </ol>
    <p>If you run into any issues or questions, don't hesitate to reach out to me via email or Slack.</p>
    ${onboarding_guide_url ? `<p>For more details on how to use Wiser, check out ${safeCompanyName}'s onboarding guide <a href="${safeOnboardingGuideUrl}">here</a>.</p>` : ""}
    <p>All the best,</p>
    <p>Cory</p>
  `;
};
