import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "@/components/ui/dropdown_menu";
import { cn } from "@/lib/utils";
import { ArrowUturnLeftIcon, EllipsisVerticalIcon, TrashIcon, UserIcon, } from "@heroicons/react/24/outline";
import DOMPurify from "dompurify";
import { formatMilliseconds } from "../strings";
const VideoCommentItem = ({ comment, formatCommentDate, handleDeleteComment, isParent, onReplyClick, indentation = 0, }) => {
    const parseUserMentions = (html) => {
        const mentionRegex = /@\[(.*?)\]\((\d+)\)/g; // @[__display__](__id__)
        let result = html;
        const matches = [...html.matchAll(mentionRegex)];
        for (const match of matches) {
            const display = match[1];
            const replacement = `<span class="text-wds-blue-5 font-medium">${display}</span>`;
            result = result.replace(match[0], replacement);
        }
        return result;
    };
    const parsedComment = parseUserMentions(comment.comment_html);
    return (_jsxs("div", Object.assign({ className: cn("mt-3 flex items-start gap-3", indentation > 0 && `ml-${indentation * 10}`) }, { children: [_jsx("div", Object.assign({ className: "h-8 w-8 rounded-full flex-shrink-0" }, { children: _jsxs(Avatar, { children: [_jsx(AvatarImage, { src: comment.profile_pic_url }), _jsx(AvatarFallback, { children: _jsx(UserIcon, { className: "h-5 w-5 text-wds-gray-5" }) })] }) })), _jsxs("div", Object.assign({ className: "flex-1" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx("span", Object.assign({ className: "font-semibold text-wds-gray-9 text-sm" }, { children: comment.created_by || "Unknown User" })), _jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs" }, { children: formatCommentDate(comment.created_at) })), comment.clip_start_time_seconds !== undefined &&
                                comment.clip_end_time_seconds !== undefined && (_jsxs("span", Object.assign({ className: "ml-1 text-xs rounded-md bg-wds-gray-2 px-2 py-0.5 text-wds-gray-7 cursor-pointer hover:bg-wds-gray-3" }, { children: [formatMilliseconds(comment.clip_start_time_seconds * 1000), " -", " ", formatMilliseconds(comment.clip_end_time_seconds * 1000)] })))] })), _jsx("div", { className: "text-wds-gray-9 text-sm mt-1 whitespace-pre-wrap", dangerouslySetInnerHTML: {
                            __html: DOMPurify.sanitize(parsedComment),
                        } })] })), _jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "text-wds-gray-5 hover:text-wds-gray-7 p-1 rounded-full h-auto w-auto" }, { children: _jsx(EllipsisVerticalIcon, { className: "h-5 w-5" }) })) })), _jsxs(DropdownMenuContent, Object.assign({ align: "end", className: "w-40" }, { children: [isParent && onReplyClick && (_jsxs(DropdownMenuItem, Object.assign({ className: "cursor-pointer flex items-center gap-2 px-2 py-1.5 text-sm", onClick: onReplyClick }, { children: [_jsx(ArrowUturnLeftIcon, { className: "h-4 w-4" }), _jsx("span", { children: "Reply" })] }))), _jsxs(DropdownMenuItem, Object.assign({ className: "text-red-600 hover:text-red-700 cursor-pointer flex items-center gap-2 px-2 py-1.5 text-sm", onClick: () => handleDeleteComment(comment.id) }, { children: [_jsx(TrashIcon, { className: "h-4 w-4" }), _jsx("span", { children: "Delete" })] }))] }))] })] })));
};
export default VideoCommentItem;
