import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { DocumentDuplicateIcon, InformationCircleIcon, MagnifyingGlassIcon, } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { formatCallTime } from "../strings";
import useAutoScroll from "../use_auto_scroll";
import RoleSwitcher from "./role_switcher";
const Transcript = ({ isLive, transcriptEntries, startTimeMsec, speakerToRoleMap, onRoleChange, roleSwitcherRef, scrollToBottom, setScrollToBottom, currentTranscriptIndex, setVideoTime, fullConversation, }) => {
    const { scrollContainerRef, handleScroll } = useAutoScroll([
        transcriptEntries,
    ]);
    const [searchText, setSearchText] = useState("");
    const currentTranscriptRef = useRef(null);
    // When mosaic resizes the transcript tile scroll to the most current transcript at bottom of the container
    useEffect(() => {
        var _a;
        if (scrollToBottom && scrollContainerRef.current) {
            const scrollHeight = (_a = scrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight;
            if (scrollHeight) {
                scrollContainerRef.current.scrollTop = scrollHeight;
            }
            if (setScrollToBottom) {
                setScrollToBottom(false);
            }
        }
    }, [scrollToBottom]);
    function RoleSpeaker(entry) {
        return (_jsxs("div", Object.assign({ className: "flex items-center justify-start gap-2" }, { children: [_jsx("div", Object.assign({ className: "border border-wds-gray-3 bg-wds-gray-1 rounded-lg flex items-center" }, { children: _jsx("div", Object.assign({ className: "font-medium text-[14px] px-2 text-wds-gray-6" }, { children: entry.speaker })) })), entry.role && (_jsx("span", Object.assign({ className: "font-medium text-[14px] leading-5 text-wds-gray-5" }, { children: entry.role })))] })));
    }
    useEffect(() => {
        if (currentTranscriptIndex !== undefined && currentTranscriptRef.current) {
            currentTranscriptRef.current.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }
    }, [currentTranscriptIndex]);
    return (_jsxs("div", Object.assign({ className: cn("border-wds-gray-3 h-full flex flex-col grow px-6 py-2 items-start gap-4 w-full rounded-lg", transcriptEntries.length > 0 ? "bg-white" : "bg-wds-gray-1") }, { children: [_jsx("div", Object.assign({ className: "flex w-full gap-2 pt-2" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row justify-between items-center h-full w-full gap-2 pt-2" }, { children: [transcriptEntries.length > 0 && (_jsxs("div", Object.assign({ className: "w-full h-8 px-3 bg-white rounded-lg border border-wds-gray-3 justify-start items-center gap-2 inline-flex overflow-hidden" }, { children: [_jsx(MagnifyingGlassIcon, { className: "w-5 h-5 stroke-wds-gray-5 stroke-2" }), _jsx("div", Object.assign({ className: "w-full justify-start items-center flex" }, { children: _jsx("input", { type: "text", className: "w-full text-[15px] font-normal font-['Lato'] leading-snug border-0 focus:ring-0 font-wds-gray-3 px-0", value: searchText, onChange: (e) => setSearchText(e.target.value), placeholder: "Search" }) }))] }))), isLive && onRoleChange && (_jsx("div", Object.assign({ className: "flex relative h-full" }, { children: _jsx(RoleSwitcher, { speakerToRoles: speakerToRoleMap, onRoleChange: onRoleChange, roleSwitcherRef: roleSwitcherRef }) }))), fullConversation && (_jsx("div", Object.assign({ className: "flex relative h-full" }, { children: _jsx("button", Object.assign({ className: "text-wds-gray-5 text-sm rounded-full p-2 hover:bg-wds-blue-2 active:bg-wds-blue-3 transition-colors duration-150", onClick: () => navigator.clipboard.writeText(fullConversation), "aria-label": "Copy full conversation" }, { children: _jsx(DocumentDuplicateIcon, { className: "w-5 h-5" }) })) })))] })) })), transcriptEntries.length > 0 || isLive ? (_jsx("div", Object.assign({ className: "md:grow flex flex-col overflow-y-auto border-gray-200 h-3/4 w-full md:h-full gap-4", onScroll: handleScroll, ref: scrollContainerRef }, { children: transcriptEntries
                    .filter((entry) => {
                    return (searchText.trim() === "" ||
                        entry.text
                            .toLowerCase()
                            .includes(searchText.trim().toLowerCase()) ||
                        entry.speaker
                            .toLowerCase()
                            .includes(searchText.trim().toLowerCase()));
                })
                    .map((entry, index, array) => {
                    return (_jsxs("div", Object.assign({ ref: index === currentTranscriptIndex
                            ? currentTranscriptRef
                            : undefined, className: cn("flex flex-col w-full items-stretch mb-0.5", index === currentTranscriptIndex
                            ? "bg-gray-200"
                            : "bg-white", setVideoTime ? "cursor-pointer hover:bg-gray-200" : ""), style: { gridTemplateColumns: ".75fr 1.25fr" }, onClick: () => {
                            setVideoTime === null || setVideoTime === void 0 ? void 0 : setVideoTime(entry.startTime + 1);
                        } }, { children: [_jsxs("div", Object.assign({ className: "flex w-full justify-between items-center" }, { children: [_jsx(RoleSpeaker, { role: entry.role, speaker: entry.speaker }), _jsx("div", Object.assign({ className: "flex h-[20px] text-right font-medium font-Lato text-[14px] px-2", style: { color: "#767676" } }, { children: formatCallTime(startTimeMsec, entry.startTime) }))] })), _jsx("div", Object.assign({ className: "w-full overflow-hidden flex items-start justify-start mt-1" }, { children: _jsx("p", Object.assign({ className: "whitespace-pre-wrap break-words text-black font-medium text-[14px] px-2" }, { children: entry.text })) }))] }), index));
                }) }))) : (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-4 px-2 h-full" }, { children: [_jsx(InformationCircleIcon, { className: "w-[60px] h-[60px] stroke-wds-blue-2 stroke-2" }), _jsxs("div", { children: [_jsx("div", Object.assign({ className: "text-[24px] leading-8 font-lato", style: { fontWeight: 600 } }, { children: "Call hasn\u2019t started" })), _jsx("div", Object.assign({ className: "w-[336px] font-normal leading-6 text-[16px] font-lato" }, { children: "The transcript of this call is not available." }))] })] })))] })));
};
export default Transcript;
