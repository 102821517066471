import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TagIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getTagsForVideo, tagVideo } from "../../common/endpoints";
import TagsSelector from "../../tags_selector";
import Tag from "../../video_library/tag";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
/**
 * A unified component for displaying and editing tags for videos and clips
 */
const TagsDisplay = ({ videoId, className = "", }) => {
    const [showTagEditor, setShowTagEditor] = useState(false);
    const queryClient = useQueryClient();
    // Get existing tags using the unified endpoint
    const { data: savedTags } = useQuery({
        queryKey: ["videoTags", videoId],
        queryFn: () => getTagsForVideo(videoId),
    });
    const [updatedTags, setUpdatedTags] = useState([]);
    // Update local state when saved tags change
    useEffect(() => {
        if (savedTags) {
            setUpdatedTags(savedTags);
        }
    }, [savedTags]);
    // Mutation to update tags
    const tagMutation = useMutation({
        mutationFn: ({ tags }) => tagVideo(videoId, tags),
        onSuccess: () => {
            // First invalidate queries to refresh tag data
            queryClient.invalidateQueries({
                queryKey: ["videoTags", videoId],
            });
            // Then close the editor
            setShowTagEditor(false);
        },
    });
    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Reset to saved tags first, then close the editor
        setUpdatedTags(savedTags || []);
        setShowTagEditor(false);
    };
    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!tagMutation.isPending) {
            tagMutation.mutate({
                tags: updatedTags,
            });
        }
    };
    return (_jsx("div", Object.assign({ className: className, onClick: (e) => {
            e.preventDefault();
        }, onKeyDown: (e) => { } }, { children: _jsxs("div", Object.assign({ className: "flex flex-wrap gap-1.5" }, { children: [savedTags === null || savedTags === void 0 ? void 0 : savedTags.map((tag) => (_jsx(Tag, { name: tag, className: "h-5 px-2 py-1 text-xs rounded-full inline-flex items-center hover:brightness-95" }, tag))), _jsxs(Popover, Object.assign({ open: showTagEditor, onOpenChange: setShowTagEditor }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "sm", onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowTagEditor(true);
                                }, className: "h-5 px-1 rounded-full" }, { children: _jsx(TagIcon, { className: "w-4 h-4" }) })) })), _jsxs(PopoverContent, Object.assign({ className: "w-80 p-4", align: "start" }, { children: [_jsx("div", Object.assign({ className: "text-sm font-medium mb-2" }, { children: "Edit Tags" })), _jsx(TagsSelector, { selectedTags: updatedTags, onChange: (options) => setUpdatedTags(options.map((option) => option.value)), className: "w-full mb-4" }), _jsxs("div", Object.assign({ className: "flex justify-end gap-2" }, { children: [_jsx(Button, Object.assign({ variant: "ghost", size: "sm", onClick: handleCancel, disabled: tagMutation.isPending }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: "default", size: "sm", onClick: handleSave, disabled: tagMutation.isPending }, { children: tagMutation.isPending ? "Saving..." : "Save" }))] }))] }))] }))] })) })));
};
export default TagsDisplay;
