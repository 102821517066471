var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { Skeleton } from "./skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "./table";
/**
 * Skeleton loader for the data table component.
 * @param props
 * @returns
 */
export function DataTableSkeleton(props) {
    const { columnCount, rowCount = 10, searchableColumnCount = 0, filterableColumnCount = 0, showViewOptions = true, cellWidths = ["auto"], withPagination = true, shrinkZero = false, className } = props, skeletonProps = __rest(props, ["columnCount", "rowCount", "searchableColumnCount", "filterableColumnCount", "showViewOptions", "cellWidths", "withPagination", "shrinkZero", "className"]);
    return (_jsxs("div", Object.assign({ className: cn("w-full space-y-2.5 overflow-auto", className) }, skeletonProps, { children: [_jsxs("div", Object.assign({ className: "flex w-full items-center justify-between space-x-2 overflow-auto p-1" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-1 items-center space-x-2" }, { children: [searchableColumnCount > 0
                                ? Array.from({ length: searchableColumnCount }).map((_, i) => (_jsx(Skeleton, { className: "h-7 w-40 lg:w-60" }, i)))
                                : null, filterableColumnCount > 0
                                ? Array.from({ length: filterableColumnCount }).map((_, i) => (_jsx(Skeleton, { className: "h-7 w-[4.5rem] border-dashed" }, i)))
                                : null] })), showViewOptions ? (_jsx(Skeleton, { className: "ml-auto hidden h-7 w-[4.5rem] lg:flex" })) : null] })), _jsx("div", Object.assign({ className: "rounded-md border" }, { children: _jsxs(Table, { children: [_jsx(TableHeader, { children: Array.from({ length: 1 }).map((_, i) => (_jsx(TableRow, Object.assign({ className: "hover:bg-transparent" }, { children: Array.from({ length: columnCount }).map((_, j) => (_jsx(TableHead, Object.assign({ style: {
                                        width: cellWidths[j],
                                        minWidth: shrinkZero ? cellWidths[j] : "auto",
                                    } }, { children: _jsx(Skeleton, { className: "h-6 w-full" }) }), j))) }), i))) }), _jsx(TableBody, { children: Array.from({ length: rowCount }).map((_, i) => (_jsx(TableRow, Object.assign({ className: "hover:bg-transparent" }, { children: Array.from({ length: columnCount }).map((_, j) => (_jsx(TableCell, Object.assign({ style: {
                                        width: cellWidths[j],
                                        minWidth: shrinkZero ? cellWidths[j] : "auto",
                                    } }, { children: _jsx(Skeleton, { className: "h-6 w-full" }) }), j))) }), i))) })] }) })), withPagination ? (_jsxs("div", Object.assign({ className: "flex w-full items-center justify-between gap-4 overflow-auto p-1 sm:gap-8" }, { children: [_jsx(Skeleton, { className: "h-7 w-40 shrink-0" }), _jsxs("div", Object.assign({ className: "flex items-center gap-4 sm:gap-6 lg:gap-8" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(Skeleton, { className: "h-7 w-24" }), _jsx(Skeleton, { className: "h-7 w-[4.5rem]" })] })), _jsx("div", Object.assign({ className: "flex items-center justify-center text-sm font-medium" }, { children: _jsx(Skeleton, { className: "h-7 w-20" }) })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(Skeleton, { className: "hidden h-7 w-7 lg:block" }), _jsx(Skeleton, { className: "h-7 w-7" }), _jsx(Skeleton, { className: "h-7 w-7" }), _jsx(Skeleton, { className: "hidden h-7 w-7 lg:block" })] }))] }))] }))) : null] })));
}
