// This hook provides the user feedback for all the llm outputs
// for a call, which can be used to render upvotes/downvote in call pages
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteLlmOutputFeedback, getLlmOutputFeedback, updateLlmOutputFeedback, } from "../common/endpoints";
// (Example: See live_call/index, post_call/chat_modal)
export const useLlmOutputFeedback = ({ callId, accountId, }) => {
    const queryClient = useQueryClient();
    const { data: allLlmOutputFeedback } = useQuery({
        queryKey: ["getLlmOutputFeedback", callId, accountId],
        // TODO: Handle account id in the BE API and update this accordingly.
        queryFn: () => getLlmOutputFeedback(callId, accountId),
    });
    const updateLlmOutputFeedbackMutation = useMutation({
        mutationFn: ({ provenanceLogId, userFeedback, }) => updateLlmOutputFeedback(provenanceLogId, userFeedback),
        onSuccess: (updatedFeedback) => {
            queryClient.setQueryData(["getLlmOutputFeedback", callId, accountId], (oldData) => {
                if (!oldData)
                    return [updatedFeedback];
                return [
                    ...oldData.filter((f) => f.id !== updatedFeedback.id),
                    updatedFeedback,
                ];
            });
        },
    });
    const deleteLlmOutputFeedbackMutation = useMutation({
        mutationFn: ({ provenanceLogId }) => deleteLlmOutputFeedback(provenanceLogId),
        onSuccess: (data, { provenanceLogId }) => {
            queryClient.setQueryData(["getLlmOutputFeedback", callId, accountId], (oldData) => {
                if (!oldData)
                    return [];
                return [
                    ...oldData.filter((f) => f.provenance_log_id !== provenanceLogId),
                ];
            });
        },
    });
    return {
        allLlmOutputFeedback,
        updateLlmOutputFeedbackMutation,
        deleteLlmOutputFeedbackMutation,
    };
};
