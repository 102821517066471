import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
const RadioSelector = (props) => {
    const handleOptionChange = (event) => {
        props.setSelectedOption(event.target.value);
    };
    return (_jsx("div", Object.assign({ className: "flex flex-row gap-6" }, { children: props.options.map((option) => {
            return (_jsxs("label", Object.assign({ className: cn("justify-center items-center gap-1 flex text-sm text-wds-black", props.selectedOption === option.value
                    ? "font-bold"
                    : "font-medium") }, { children: [_jsx("input", { type: "radio", value: option.value, checked: props.selectedOption === option.value, onChange: handleOptionChange, className: "focus:ring-0" }), option.label] }), option.value));
        }) })));
};
export default RadioSelector;
