var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Button } from "@/components/ui/button";
import { Kbd } from "@/components/ui/kbd";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, } from "@/components/ui/select";
import { useToast } from "@/components/ui/use_toast";
import { ArrowPathIcon, CheckCircleIcon, TrashIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEscapeKey } from "../hooks/use_escape_key";
import { getTeams } from "../teams/endpoints";
import { deleteUser, updateUser } from "./endpoints";
export function UserTableFloatingBar({ table }) {
    const queryClient = useQueryClient();
    const rows = table.getFilteredSelectedRowModel().rows;
    const { toast } = useToast();
    const [isPending, setIsPending] = React.useState(false);
    const [method, setMethod] = React.useState();
    // TODO: Handle loading state here.
    // Need to make a "tiny" async select component to handle this
    // Current async_search_select is not suitable for this use case currently due to
    // smaller constrained area in the floating bar
    const { data, isLoading: teamsLoading } = useQuery({
        queryKey: ["getAllTeams"],
        queryFn: () => getTeams(JSON.stringify({
            table: "Team",
            order_by_fields: ["name"],
            structured_filter: {},
        }), 1, 200),
    });
    const teams = data === null || data === void 0 ? void 0 : data.data;
    useEscapeKey(() => {
        table.toggleAllRowsSelected(false);
    });
    return (_jsx("div", Object.assign({ className: "fixed inset-x-0 bottom-4 z-50 mx-auto w-fit px-4" }, { children: _jsx("div", Object.assign({ className: "w-full overflow-x-auto" }, { children: _jsxs("div", Object.assign({ className: "mx-auto flex w-fit items-center gap-2 rounded-md border bg-card p-2 shadow-2xl" }, { children: [_jsxs("div", Object.assign({ className: "flex h-7 items-center rounded-md border border-dashed pl-2.5 pr-1" }, { children: [_jsxs("span", Object.assign({ className: "whitespace-nowrap text-xs" }, { children: [rows.length, " selected"] })), _jsx(Separator, { orientation: "vertical", className: "ml-2 mr-1" }), _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "h-5 w-5 hover:border", onClick: () => table.toggleAllRowsSelected(false) }, { children: _jsx(XMarkIcon, { className: "h-3.5 w-3.5 shrink-0", "aria-hidden": "true" }) })) })), _jsxs(TooltipContent, Object.assign({ className: "flex items-center border bg-accent px-2 py-1 font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: [_jsx("p", Object.assign({ className: "mr-2" }, { children: "Clear selection" })), _jsx(Kbd, Object.assign({ abbrTitle: "Escape", variant: "outline" }, { children: "Esc" }))] }))] }) })] })), _jsx(Separator, { orientation: "vertical", className: "hidden h-5 sm:block" }), _jsxs("div", Object.assign({ className: "flex items-center gap-1.5" }, { children: [_jsx(TooltipProvider, { children: _jsxs(Tooltip, Object.assign({ delayDuration: 250 }, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "secondary", size: "icon", className: "h-7 w-7 border data-[state=open]:bg-accent data-[state=open]:text-accent-foreground", disabled: isPending, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                    setMethod("delete");
                                                    setIsPending(true);
                                                    const deletePromises = rows.map((row) => deleteUser(row.original.id));
                                                    yield Promise.all(deletePromises);
                                                    // refetch the data to update the selected rows
                                                    queryClient.invalidateQueries();
                                                    setIsPending(false);
                                                    toast({
                                                        title: "User deleted",
                                                        description: "User deleted successfully",
                                                        variant: "green",
                                                    });
                                                }) }, { children: isPending && method === "delete" ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin", "aria-hidden": "true" })) : (_jsx(TrashIcon, { className: "h-3.5 w-3.5", "aria-hidden": "true" })) })) })), _jsx(TooltipContent, Object.assign({ className: "border bg-accent font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: _jsx("p", { children: "Delete user" }) }))] })) }), _jsxs(Select, Object.assign({ onValueChange: (value) => __awaiter(this, void 0, void 0, function* () {
                                    setMethod("update-team");
                                    setIsPending(true);
                                    const promises = rows.map((row) => {
                                        updateUser({
                                            id: row.original.id,
                                            Email: row.original.email,
                                            "Is admin": row.original.is_tenant_admin,
                                            "Is active": row.original.is_active,
                                            "First name": row.original.given_name,
                                            "Last name": row.original.family_name,
                                            "Custom bot Name": row.original.custom_bot_name,
                                            "Botless meetings": row.original.no_live_bot,
                                            Manager: row.original.manager_id
                                                ? {
                                                    label: row.original.manager,
                                                    value: row.original.manager_id.toString(),
                                                }
                                                : undefined,
                                            // Teams are updated only via value/id, the label of the team does not matter
                                            Team: { label: row.original.team, value },
                                            Department: row.original.department_id
                                                ? {
                                                    label: row.original.department,
                                                    value: row.original.department_id.toString(),
                                                }
                                                : undefined,
                                            "Autojoin external meetings": row.original.autojoin_external_meetings,
                                            "Autojoin internal meetings": row.original.autojoin_internal_meetings,
                                            "Account type": row.original.user_type || "Recorder",
                                            Timezone: {
                                                label: row.original.timezone,
                                                value: row.original.timezone,
                                            },
                                        });
                                    });
                                    yield Promise.allSettled(promises);
                                    queryClient.invalidateQueries();
                                    setIsPending(false);
                                }) }, { children: [_jsx(TooltipProvider, { children: _jsxs(Tooltip, Object.assign({ delayDuration: 250 }, { children: [_jsx(TooltipTrigger, { children: _jsx(SelectTrigger, { children: _jsx(Button, Object.assign({ variant: "secondary", size: "icon", className: "h-7 w-7 border data-[state=open]:bg-accent data-[state=open]:text-accent-foreground", disabled: isPending }, { children: isPending && method === "update-team" ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin", "aria-hidden": "true" })) : (_jsx(CheckCircleIcon, { className: "h-3.5 w-3.5", "aria-hidden": "true" })) })) }) }), _jsx(TooltipContent, Object.assign({ className: "border bg-accent font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: _jsx("p", { children: "Update Team" }) }))] })) }), _jsx(SelectContent, Object.assign({ align: "center" }, { children: _jsxs(SelectGroup, { children: [teams === null || teams === void 0 ? void 0 : teams.map((t) => (_jsx(SelectItem, Object.assign({ value: t.id.toString(), className: "capitalize" }, { children: t.name }), t.id))), _jsx(SelectItem, Object.assign({ value: "None", className: "capitalize" }, { children: "None" }), "None")] }) }))] }))] }))] })) })) })));
}
