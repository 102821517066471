var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { createOrUpdateGridState, deleteGridState, } from "../../common/endpoints";
import { Button } from "./button";
import { Checkbox } from "./checkbox";
import { DataTableViewSwitcher } from "./data_table_layout_selector";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Separator } from "./separator";
/**
 * React component to manage table states for AG Grids.
 * This is merely a copy paste of table layout related functionality from
 * the DataTableNewToolbar component.
 * @returns
 */
export function GridStatePopover({ tableName, 
// Default table layout in case user doesn't have any saved layouts
defaultGridState, applyLayout, currentGridState, savedLayouts, mutateSavedLayoutsAndApply, }) {
    var _a;
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedState, setSelectedState] = useState(undefined);
    const [layoutName, setLayoutName] = useState("");
    useEffect(() => {
        var _a, _b;
        setSelectedState(currentGridState.selectedGridLayout);
        setLayoutName((_b = (_a = currentGridState.selectedGridLayout) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "");
    }, [currentGridState.selectedGridLayout]);
    const defaultLayout = (_a = savedLayouts.find((l) => l.is_default)) !== null && _a !== void 0 ? _a : defaultGridState;
    const createOrUpdateLayoutMutation = useMutation({
        mutationFn: createOrUpdateGridState,
        onSuccess: (updatedLayouts) => {
            mutateSavedLayoutsAndApply([...updatedLayouts], currentGridState.fingerprint);
        },
    });
    const deleteLayoutMutation = useMutation({
        mutationFn: deleteGridState,
        onSuccess: (updatedLayouts) => {
            mutateSavedLayoutsAndApply([...updatedLayouts]);
        },
    });
    const currentViewDiffersFromLayout = currentGridState.fingerprint !== (selectedState === null || selectedState === void 0 ? void 0 : selectedState.fingerprint_v2);
    const getClassNamesForLayoutButton = () => {
        if (!currentViewDiffersFromLayout) {
            return "text-wds-blue-4 bg-wds-blue-1 border-wds-blue-2 border-[1px] rounded-lg";
        }
        return "text-yellow-800 bg-yellow-100 border-yellow-200 border-[1px] rounded-lg";
    };
    const getLayoutName = useCallback((layout) => {
        if (layout.is_public) {
            return `${layout.name} (Shared)`;
        }
        return layout.name;
    }, []);
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", className: getClassNamesForLayoutButton() }, { children: [_jsx(TableCellsIcon, { className: "mr-2 h-4 w-4" }), currentViewDiffersFromLayout
                            ? "Unsaved view"
                            : selectedState
                                ? getLayoutName(selectedState)
                                : getLayoutName(defaultLayout), _jsx(ChevronDownIcon, { className: "ml-2 h-4 w-4 text-wds-gray-5" })] })) })), _jsx(PopoverContent, { children: _jsxs("div", Object.assign({ className: "w-[320px] flex-col justify-end items-end inline-flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch pb-4 border-b flex-col justify-start items-start gap-2 flex" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-6 text-xs font-bold leading-[15px]" }, { children: "Switch to a different view" })), _jsx(DataTableViewSwitcher, { storedLayouts: savedLayouts, selectedLayout: selectedState || defaultLayout, setSelectedLayout: (layout) => {
                                        if (layout !== (selectedState === null || selectedState === void 0 ? void 0 : selectedState.name)) {
                                            savedLayouts
                                                .filter((l) => l.name === layout)
                                                .map((l) => {
                                                setSelectedState(l);
                                                applyLayout(l);
                                                setLayoutName(l.name);
                                            });
                                        }
                                    } })] })), _jsxs("div", Object.assign({ className: "self-stretch h-[103px] flex-col justify-start items-start gap-2 flex" }, { children: [_jsx("div", Object.assign({ className: "self-stretch text-wds-gray-6 text-xs font-bold leading-4 pt-4" }, { children: currentViewDiffersFromLayout
                                        ? "Name this view"
                                        : "Rename this view" })), _jsxs("div", Object.assign({ className: "self-stretch justify-start items-start gap-2 inline-flex" }, { children: [_jsx("input", { className: "focus:outline-none border-[1px] border-wds-gray-3 focus:ring-0 placeholder:text-gray-400 text-sm rounded-sm w-full", placeholder: currentViewDiffersFromLayout
                                                ? "Name this view"
                                                : selectedState
                                                    ? selectedState.name
                                                    : defaultLayout.name, value: layoutName, onChange: (event) => {
                                                setLayoutName(event.target.value);
                                            } }), _jsx(Button, Object.assign({ size: "sm", disabled: isSaving || !selectedState, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                                if (!selectedState) {
                                                    return;
                                                }
                                                setIsSaving(true);
                                                yield createOrUpdateLayoutMutation.mutateAsync({
                                                    id: selectedState.id,
                                                    table_name: selectedState.table_name,
                                                    name: layoutName,
                                                    state: currentGridState.state,
                                                    is_default: selectedState.is_default,
                                                    is_public: selectedState.is_public,
                                                    fingerprint_v2: currentGridState.fingerprint,
                                                });
                                                setIsSaving(false);
                                            }) }, { children: "Save" }))] })), _jsxs("div", Object.assign({ className: "h-6 justify-start items-center gap-1 inline-flex pt-4" }, { children: [_jsx(Checkbox, { checked: selectedState
                                                ? selectedState.is_default
                                                : defaultLayout.is_default, onCheckedChange: (checked) => setSelectedState((prev) => {
                                                if (!prev) {
                                                    return Object.assign(Object.assign({}, defaultLayout), { is_default: !!checked });
                                                }
                                                return Object.assign(Object.assign({}, prev), { is_default: !!checked });
                                            }) }), _jsx("div", Object.assign({ className: "text-wds-gray-6 text-sm font-bold leading-tight" }, { children: "Make this the default view" }))] })), _jsxs("div", Object.assign({ className: "h-6 justify-start items-center gap-1 inline-flex pt-4" }, { children: [_jsx(Checkbox, { checked: selectedState
                                                ? selectedState.is_public
                                                : defaultLayout.is_public, onCheckedChange: (checked) => setSelectedState((prev) => {
                                                if (!prev) {
                                                    return Object.assign(Object.assign({}, defaultLayout), { is_public: !!checked });
                                                }
                                                return Object.assign(Object.assign({}, prev), { is_public: !!checked });
                                            }) }), _jsx("div", Object.assign({ className: "text-wds-gray-6 text-sm font-bold leading-tight" }, { children: "Share this view with your organization" }))] }))] })), _jsx(Separator, { orientation: "horizontal", className: "mt-12 mb-2" }), _jsx(Button, Object.assign({ variant: "ghost", className: "bg-red-50 text-red-600 font-bolt hover:bg-red-100 hover:text-red-900", disabled: isDeleting || (selectedState === null || selectedState === void 0 ? void 0 : selectedState.is_default) || !selectedState, onClick: () => __awaiter(this, void 0, void 0, function* () {
                                if (!selectedState) {
                                    return;
                                }
                                setIsDeleting(true);
                                yield deleteLayoutMutation.mutateAsync({
                                    table_name: selectedState.table_name,
                                    id: selectedState.id,
                                });
                                setIsDeleting(false);
                            }) }, { children: "Delete this view" }))] })) })] }));
}
