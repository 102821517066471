import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { getRefreshLabel } from "../refresh_time_utils";
export const AccountResearchBlockOutputRefreshButton = ({ account, brick, accountResearchBlockOutput, generateResearchBlockOutput, outputGenerationInProgress, }) => {
    return (_jsxs("div", Object.assign({ className: "flex gap-1 items-center" }, { children: [accountResearchBlockOutput && (_jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs pr-2" }, { children: getRefreshLabel((new Date().getTime() -
                    new Date(accountResearchBlockOutput.last_update_timestamp).getTime()) /
                    1000) }))), _jsx(Button, Object.assign({ variant: "ghost", className: "flex items-center self-start p-0 hover:bg-transparent text-wds-blue-4", onClick: () => {
                    generateResearchBlockOutput(account.id, brick.id, 
                    /*forceRefresh=*/ true);
                }, disabled: outputGenerationInProgress }, { children: _jsx(ArrowPathIcon, { className: cn("w-4 h-4 relative", outputGenerationInProgress ? "animate-spin" : "") }) }))] })));
};
