import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getLiveCallSummary } from "../common/endpoints";
export const useCallHighlights = (callId) => {
    const { data: callHighlights, isLoading: callHighlightsLoading } = useQuery({
        queryKey: ["callHighlights", callId],
        queryFn: () => getLiveCallSummary(callId),
    });
    const queryClient = useQueryClient();
    const invalidateCallHighlights = () => {
        queryClient.invalidateQueries({
            queryKey: ["callHighlights", callId],
        });
    };
    return {
        callHighlights,
        callHighlightsLoading,
        invalidateCallHighlights,
    };
};
