import { jsx as _jsx } from "react/jsx-runtime";
import { getTableSchema } from "@/common/endpoints";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { ExpandableCell, getCellRenderer } from "../ui/ag_data_grid_cell";
import { ColumnHeaderRenderer } from "../ui/ag_grid_column_header";
export const useAgGridTableSchema = (tableName, tanstackColDefs, ignoredColumns, useTanstackCellRenderers) => {
    const getTanstackColDef = useCallback((col) => {
        var _a, _b;
        // This converts our old cell renderers into a format that works for AG Grid.
        const tanstackColDef = (_b = (_a = tanstackColDefs.find((c) => c.id === col.id)) !== null && _a !== void 0 ? _a : tanstackColDefs.find((c) => c.accessorKey === col.id)) !== null && _b !== void 0 ? _b : tanstackColDefs.find((c) => c.accessorKey === col.display_name);
        if (!tanstackColDef)
            return null;
        if (!useTanstackCellRenderers.includes(col.id)) {
            return {
                meta: tanstackColDef.meta,
            };
        }
        const cellFunc = tanstackColDef.cell;
        if (typeof cellFunc !== "function") {
            console.error("Cell function is not a function", cellFunc);
            return null;
        }
        return {
            cellRenderer: (params) => {
                if (!params.data)
                    return null;
                const value = params.data[col.id];
                if (value === undefined || value === null) {
                    return null;
                }
                // Create a context object that matches Tanstack's Row interface
                const context = {
                    row: {
                        original: params.data,
                        getValue: (_) => value,
                    },
                    cell: {
                        getValue: () => value,
                    },
                    getValue: () => value,
                };
                const cellValue = cellFunc(context);
                if (cellValue === null || cellValue === undefined) {
                    return null;
                }
                return _jsx(ExpandableCell, { child: cellValue });
            },
            meta: tanstackColDef.meta,
        };
    }, [tanstackColDefs]);
    // Convert schema column from getSchema() to ag-grid column definition
    const createColumnDef = useCallback((col) => {
        var _a;
        const colDef = {
            field: col.id,
            headerName: col.display_name,
            maxWidth: 500,
            sortable: false,
            suppressHeaderMenuButton: true,
            valueFormatter: (params) => {
                if (params.value === null || params.value === undefined)
                    return "";
                return params.value;
            },
            cellRenderer: getCellRenderer(col.type),
        };
        const specializedColDef = getTanstackColDef(col);
        if (!specializedColDef) {
            return colDef;
        }
        if ((_a = specializedColDef.meta) === null || _a === void 0 ? void 0 : _a.brickId) {
            return Object.assign(Object.assign(Object.assign({}, colDef), specializedColDef), { headerComponent: (props) => {
                    var _a, _b;
                    return (_jsx(ColumnHeaderRenderer, { headerDisplayName: col.display_name, brickId: (_a = specializedColDef.meta) === null || _a === void 0 ? void 0 : _a.brickId, brickName: (_b = specializedColDef.meta) === null || _b === void 0 ? void 0 : _b.brickName, gridApi: props.api }));
                } });
        }
        return Object.assign(Object.assign({}, colDef), specializedColDef);
    }, [tanstackColDefs, ignoredColumns]);
    const selectTransform = useCallback((schema) => {
        return schema.columns
            .filter((col) => !ignoredColumns.includes(col.id))
            .map(createColumnDef);
    }, [createColumnDef, ignoredColumns]);
    const { data: columnDefs } = useQuery({
        queryKey: ["tableSchema", tableName],
        queryFn: () => getTableSchema(tableName),
        select: selectTransform,
    });
    return {
        columnDefs,
    };
};
