import { jsx as _jsx } from "react/jsx-runtime";
import { SearchableDropdown } from "./searchable_dropdown";
export const DataTableViewSwitcher = ({ selectedLayout, storedLayouts, setSelectedLayout, }) => {
    const defaultLayout = storedLayouts.find((layout) => layout.is_default);
    if ((storedLayouts.length === 0 || !defaultLayout) &&
        !storedLayouts.includes(selectedLayout)) {
        storedLayouts.push(selectedLayout);
    }
    return (_jsx(SearchableDropdown, { options: [
            ...storedLayouts.map((layout) => ({
                value: layout.name,
                label: layout.name,
            })),
        ], onSelect: (option) => {
            if (option) {
                setSelectedLayout(option.value);
            }
        }, selectedOption: {
            value: selectedLayout.name,
            label: selectedLayout.name,
        }, placeholder: "Select a view", buttonClasses: "grow hover:none bg-white" }));
};
