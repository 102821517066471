import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Switch } from "@/components/ui/switch";
import { Tooltip, TooltipContent, TooltipProvider, } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { useEffect, useMemo, useState } from "react";
import { setAutojoinForCall } from "../common/endpoints";
const AutojoinDisplay = (props) => {
    const [checked, setChecked] = useState(props.call.autojoin && props.call.supports_meeting_bot);
    useEffect(() => {
        setChecked(props.call.autojoin && props.call.supports_meeting_bot);
    }, [props.call.autojoin, props.call.supports_meeting_bot]);
    const cantAutojoinWarning = [];
    if (!props.call.supports_meeting_bot) {
        cantAutojoinWarning.push("This call does not support autojoin.");
    }
    const toolTipText = "".concat(...props.call.errors, ...props.call.warnings, ...cantAutojoinWarning);
    const checkedIcon = useMemo(() => {
        return (_jsx("div", Object.assign({ className: "absolute inset-0 bg-white rounded-full flex items-center justify-center p-0.5" }, { children: _jsx("img", { src: "https://storage.googleapis.com/wiser-ai-public2/wiser_logo_color.png", alt: "Squiggle", className: "w-full h-full object-contain" }) })));
    }, []);
    return (_jsxs(_Fragment, { children: [props.call.errors.length === 0 && props.call.supports_meeting_bot && (_jsx("label", Object.assign({ className: "flex items-center space-x-4" }, { children: _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx(Switch, { checked: checked, onCheckedChange: (checked) => {
                                        setAutojoinForCall(props.call.id, checked).then(() => {
                                            if (props.updateSelectedCall) {
                                                const updatedCall = Object.assign(Object.assign({}, props.call), { autojoin: checked });
                                                props.updateSelectedCall(updatedCall);
                                            }
                                            setChecked(checked);
                                        });
                                    }, disabled: !props.call.supports_meeting_bot || props.disabled, checkedIcon: checkedIcon, uncheckedIcon: checkedIcon }) }), _jsx(TooltipContent, { children: checked ? "Autojoin enabled" : "Autojoin disabled" })] }) }) }))), toolTipText.length > 0 && (_jsx("div", Object.assign({ className: "px-2 py-1 justify-start items-center gap-2 inline-flex" }, { children: _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx(InformationCircleIcon, { className: cn(props.call.errors.length > 0
                                        ? "text-red-600"
                                        : "text-yellow-600", "w-5 h-5 relative") }) }), _jsx(TooltipContent, { children: toolTipText })] }) }) })))] }));
};
export default AutojoinDisplay;
