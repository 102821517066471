import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, } from "@heroicons/react/20/solid";
import CallMetric from "../common/call_metric";
const TalkRatio = (props) => {
    const { salespersonTalkTimePercent } = props;
    let color = "green-600";
    if (salespersonTalkTimePercent <= 10) {
        color = "yellow-600";
    }
    else if (salespersonTalkTimePercent > 60 &&
        salespersonTalkTimePercent <= 85) {
        color = "yellow-600";
    }
    else if (salespersonTalkTimePercent > 85) {
        color = "red-600";
    }
    const labelMetricDetail = `${salespersonTalkTimePercent.toFixed(0)}%`;
    return (_jsx(CallMetric, { label: "Sales Team Talk Ratio", labelBadge: salespersonTalkTimePercent <= 10 ? (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) : salespersonTalkTimePercent <= 60 ? (_jsx(CheckCircleIcon, { className: "w-5 h-5 text-green-600" })) : salespersonTalkTimePercent <= 85 ? (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) : (_jsx(ExclamationCircleIcon, { className: "w-5 h-5 text-red-600" })), labelMetricDetail: labelMetricDetail, metricPercentage: salespersonTalkTimePercent, metricColor: `bg-${color}`, tooltip: "The ideal talk ratio is 11% to 60%.", isPostCall: true }));
};
const SpeechSpeed = (props) => {
    const { wordsPerMinute } = props;
    let color = "green-600";
    let rateLabel = "Normal";
    if (wordsPerMinute <= 50) {
        // Too slow
        color = "red-600";
        rateLabel = "Low";
    }
    else if (wordsPerMinute > 50 && wordsPerMinute <= 100) {
        // Slow, but not too bad
        color = "yellow-600";
        rateLabel = "Medium";
    }
    else if (wordsPerMinute > 200) {
        // Too fast
        color = "red-600";
        rateLabel = "High";
    }
    const percentRate = (wordsPerMinute / 300) * 100; // assuming max speech speed is 300 wpm
    return (_jsx(CallMetric, { label: "Sales Team Speech Rate", labelBadge: color === "yellow-600" ? (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })) : color === "green-600" ? (_jsx(CheckCircleIcon, { className: "w-5 h-5 text-green-600" })) : color === "red-600" ? (_jsx(ExclamationCircleIcon, { className: "w-5 h-5 text-red-600" })) : (_jsx(ExclamationTriangleIcon, { className: "w-5 h-5 text-yellow-600" })), labelMetricDetail: `${wordsPerMinute.toFixed(0)} wpm`, metricPercentage: percentRate, metricColor: `bg-${color}`, tooltip: "The ideal speech rate is 100 to 200 wpm.", isPostCall: true }));
};
const PostCallMetrics = (props) => {
    const { salespersonTalkTimePercent, salespersonSpeechSpeedWpm } = props;
    return (_jsxs("div", Object.assign({ className: "w-full flex flex-col gap-4 p-4" }, { children: [_jsx(TalkRatio, { salespersonTalkTimePercent: salespersonTalkTimePercent }), _jsx(SpeechSpeed, { wordsPerMinute: salespersonSpeechSpeedWpm })] })));
};
export default PostCallMetrics;
