import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import Dropdown from "../common/dropdown";
export default function RoleSwitcher(props) {
    const [speakerToRoles, setSpeakerToRoles] = useState(new Map());
    useEffect(() => {
        setSpeakerToRoles(props.speakerToRoles);
    }, [props.speakerToRoles]);
    return (_jsx(Popover, { children: ({ open }) => (_jsxs(_Fragment, { children: [_jsx(Popover.Button, Object.assign({ ref: props.roleSwitcherRef, className: cn("flex h-full justify-center items-center text-neutral-500 rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-wds-blue-1", speakerToRoles.size === 0 ? "pointer-events-none" : "", open ? "bg-wds-blue-1" : "") }, { children: _jsx("span", Object.assign({ className: cn("text-wds-blue-3 font-bold w-24") }, { children: "Select roles" })) })), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-200", enterFrom: "opacity-0 translate-y-1", enterTo: "opacity-100 translate-y-1", leave: "transition ease-in duration-150", leaveFrom: "opacity-100 translate-y-1", leaveTo: "opacity-0 translate-y-1" }, { children: _jsx(Popover.Panel, Object.assign({ className: "absolute z-20 transform rounded-lg right-[0px] shadow-lg" }, { children: _jsx("div", Object.assign({ className: "flex flex-col items-start gap-4 p-4 rounded-lg border border-wds-gray-5 bg-white w-[424px] min-h-[340px]" }, { children: _jsxs("div", Object.assign({ className: "relative grid gap-4 w-full" }, { children: [_jsx("div", Object.assign({ className: "flex-nowrap font-lato text-[14px] font-medium leading-5 text-wds-gray-5" }, { children: "Roles selection" })), Array.from(speakerToRoles.entries()).map(([speaker, roleSwitcherEntry], i) => (_jsxs("div", Object.assign({ className: "grid grid-cols-2 gap-4 items-center" }, { children: [_jsx("div", Object.assign({ className: "col-span-1" }, { children: _jsx("p", Object.assign({ className: "text-black font-bold text-15px leading-6 font-lato" }, { children: speaker })) })), _jsx("div", Object.assign({ className: "col-span-1" }, { children: _jsx(Dropdown, { options: [
                                                        { value: "Salesperson", label: "Salesperson" },
                                                        { value: "Customer", label: "Customer" },
                                                    ], defaultSelected: roleSwitcherEntry.role, onOptionSelect: (selectedRole) => {
                                                        const updatedSpeakerToRoles = new Map(speakerToRoles);
                                                        updatedSpeakerToRoles.set(speaker, Object.assign(Object.assign({}, updatedSpeakerToRoles.get(speaker)), { role: selectedRole }));
                                                        setSpeakerToRoles(updatedSpeakerToRoles);
                                                        return props.onRoleChange(roleSwitcherEntry.speaker, roleSwitcherEntry.audioSource, selectedRole);
                                                    }, buttonClasses: "w-[200px] text-neutral-500 font-normal flex items-center rounded-lg border border-gray-200 bg-white" }) }))] }), speaker)))] })) })) })) }))] })) }));
}
