export const getSalesforceCrmObjectUrl = (salesforceAppIntegrationData, objectId) => {
    if (!salesforceAppIntegrationData.provider_data.instanceUrl) {
        return undefined;
    }
    return `${salesforceAppIntegrationData.provider_data.instanceUrl}/${objectId}`;
};
export const getHubSpotCrmObjectUrl = (hubSpotAppIntegrationData, objectType, objectId) => {
    if (!hubSpotAppIntegrationData.provider_id ||
        hubSpotAppIntegrationData.provider_id.length <= 0) {
        return undefined;
    }
    let hubSpotObjectType = "company";
    if (objectType === "Lead") {
        // Leads is a special case
        return `https://app.hubspot.com/prospecting/${hubSpotAppIntegrationData.provider_id}/leads/view/open-leads?leadId=${objectId}`;
    }
    switch (objectType) {
        case "Account":
            hubSpotObjectType = "company";
            break;
        case "Contact":
            hubSpotObjectType = "contact";
            break;
        case "Opportunity":
            hubSpotObjectType = "deal";
            break;
        default:
            return undefined;
    }
    return `https://app.hubspot.com/contacts/${hubSpotAppIntegrationData.provider_id}/${hubSpotObjectType}/${objectId}`;
};
export const getExternalCrmObjectLink = (connectedCrm, appIntegrationData, objectType, objectId) => {
    if (!connectedCrm)
        return undefined;
    switch (connectedCrm) {
        case "Salesforce": {
            const salesforceAppIntegrationData = appIntegrationData.find((aid) => aid.app_name === "salesforce");
            if (!salesforceAppIntegrationData)
                return undefined;
            return getSalesforceCrmObjectUrl(salesforceAppIntegrationData, objectId);
        }
        case "HubSpot": {
            const hubSpotAppIntegrationData = appIntegrationData.find((aid) => aid.app_name === "hubspot");
            if (!hubSpotAppIntegrationData)
                return undefined;
            return getHubSpotCrmObjectUrl(hubSpotAppIntegrationData, objectType, objectId);
        }
        default:
            return undefined;
    }
};
