import { useCallback } from "react";
import useParagonAuth from "../use_paragon_auth";
import { INTEGRATION_DETAILS } from "./data_processing_config";
import { isTenantConnectedToExternalCrm } from "./utils";
/**
 * Fetches & returns the data needed to load all the integrations for post-call ops page.
 * This hook allows us to fetch integrations from any source like paragon, and format them
 * in the psot-call data processing compatible format.
 */
const useIntegrations = () => {
    var _a, _b;
    const { user, paragon, paragonUserLoaded } = useParagonAuth();
    const isParagonIntegrationConnected = useCallback((integration) => {
        var _a;
        if (!user || !user.authenticated) {
            return false;
        }
        const { enabled, credentialStatus } = ((_a = user === null || user === void 0 ? void 0 : user.integrations) === null || _a === void 0 ? void 0 : _a[integration.type]) || {};
        if (!enabled) {
            return false;
        }
        if (credentialStatus !== "VALID") {
            return false;
        }
        return true;
    }, [user]);
    const integrations = [
        ...paragon
            .getIntegrationMetadata()
            .filter((integration) => isParagonIntegrationConnected(integration))
            .filter((integration) => INTEGRATION_DETAILS.has(integration.name))
            .map((integration) => {
            var _a, _b, _c, _d, _e;
            return ({
                name: (_a = INTEGRATION_DETAILS.get(integration.name)) === null || _a === void 0 ? void 0 : _a.name,
                label: integration.name,
                icon_url: (_b = INTEGRATION_DETAILS.get(integration.name)) === null || _b === void 0 ? void 0 : _b.icon_url,
                metadata: user.authenticated
                    ? ((_e = (_d = (_c = user.integrations) === null || _c === void 0 ? void 0 : _c[integration.type]) === null || _d === void 0 ? void 0 : _d.providerData) !== null && _e !== void 0 ? _e : {})
                    : {},
            });
        }),
    ];
    if (!isTenantConnectedToExternalCrm(integrations)) {
        integrations.push({
            name: (_a = INTEGRATION_DETAILS.get("Wiser CRM")) === null || _a === void 0 ? void 0 : _a.name,
            label: "Wiser CRM",
            icon_url: (_b = INTEGRATION_DETAILS.get("Wiser CRM")) === null || _b === void 0 ? void 0 : _b.icon_url,
            metadata: {},
        });
    }
    return {
        integrations,
        loaded: paragonUserLoaded,
    };
};
export default useIntegrations;
