var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AsyncSearchSelect } from "@/components/ui/async_search_select";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import Dropdown from "../common/dropdown";
import { queryCrmObjects } from "../common/endpoints";
import { CrmObjectTypeEnum, } from "../types";
import WaitingSpinner from "../waiting_spinner";
import { HUBSPOT_OBJECT_TYPES } from "./data_processing_config";
import FieldSchemaRenderer from "./field_schema_renderer";
// This is copy + paste of sfdc_target_renderer.tsx
// TODO(nitin): Refactor to share code
export const HubspotTargetItem = ({ targetDetails, hubspotFields, }) => {
    var _a, _b, _c;
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: "https://cdn.useparagon.com/3.9.2/dashboard/public/integrations/hubspot.svg", className: "w-6 h-6" }), _jsxs("div", Object.assign({ className: "inline-flex flex-col items-start" }, { children: [_jsx("div", Object.assign({ className: "flex items-center self-stretch gap-2 group text-xs font-medium text-gray-600" }, { children: (_a = targetDetails.objectName) !== null && _a !== void 0 ? _a : "Unknown object" })), _jsx("span", Object.assign({ className: "text-sm font-semibold text-black" }, { children: hubspotFields
                            ? (_b = hubspotFields[targetDetails.objectType].find((field) => field.name === targetDetails.fieldName)) === null || _b === void 0 ? void 0 : _b.label
                            : ((_c = targetDetails.fieldLabel) !== null && _c !== void 0 ? _c : "Unknown field") }))] }))] }));
};
export const HubspotDestinationSelector = ({ call, selectedTarget, hubspotFields, onTargetDetailsChange, }) => {
    var _a, _b, _c, _d, _e;
    const [selectedObjectType, setSelectedObjectType] = useState((_a = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectType) !== null && _a !== void 0 ? _a : CrmObjectTypeEnum.OPPORTUNITY);
    const [selectedObject, setSelectedObject] = useState({
        objectId: (_b = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectId) !== null && _b !== void 0 ? _b : "No object selected",
        objectName: (_c = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectName) !== null && _c !== void 0 ? _c : "No object selected",
    });
    const [selectedFieldName, setSelectedFieldName] = useState((_d = selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.fieldName) !== null && _d !== void 0 ? _d : "No field selected");
    useEffect(() => {
        if ((selectedTarget === null || selectedTarget === void 0 ? void 0 : selectedTarget.objectType) === selectedObjectType) {
            setSelectedObject({
                objectId: selectedTarget.objectId,
                objectName: selectedTarget.objectName,
            });
            setSelectedFieldName(selectedTarget.fieldName);
            return;
        }
        if (hubspotFields[selectedObjectType].length === 0) {
            setSelectedFieldName("No field selected");
        }
        else {
            setSelectedFieldName(hubspotFields[selectedObjectType][0].name);
        }
    }, [selectedObjectType, call]);
    const validTargetDetails = () => {
        return (selectedObject.objectId !== "No object selected" &&
            selectedFieldName !== "No field selected");
    };
    useEffect(() => {
        var _a, _b, _c;
        if (!validTargetDetails()) {
            return;
        }
        onTargetDetailsChange({
            objectType: selectedObjectType,
            objectId: selectedObject.objectId,
            fieldName: selectedFieldName,
            objectName: (_a = selectedObject.objectName) !== null && _a !== void 0 ? _a : `"Unknown ${selectedObjectType}"`,
            fieldLabel: (_c = (_b = hubspotFields[selectedObjectType].find((field) => field.name === selectedFieldName)) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : "Unknown field",
        });
    }, [selectedObjectType, selectedObject, selectedFieldName]);
    // The below objects memoize the options and selected value that we send
    // to SearchableDropdowns. This helps us reduce the number of .find lookups
    // and providing us O(1) time checks for figuring whether the selected object
    // belongs to these options.
    const fieldOptions = useMemo(() => {
        return hubspotFields[selectedObjectType]
            .map((field) => ({
            value: field.name,
            label: field.label,
        }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [hubspotFields, selectedObjectType]);
    const allHubspotFieldsByName = useMemo(() => {
        return hubspotFields[selectedObjectType].reduce((acc, object) => {
            acc[object.name] = object;
            return acc;
        }, {});
    }, [selectedObjectType, hubspotFields]);
    function getReadableObjectType(objectType) {
        if (objectType === "Opportunity") {
            return "Deal";
        }
        if (objectType === "Contact") {
            return "Contact";
        }
        if (objectType === "Account") {
            return "Company";
        }
        return objectType;
    }
    const queryClient = useQueryClient();
    const hubspotObjectsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const hubspotQueryResponse = yield queryClient.fetchQuery({
            queryKey: ["hubspotObjects", selectedObjectType],
            queryFn: () => queryCrmObjects(selectedObjectType, 
            /* fieldToQuery= */ "name", userQuery, cursor),
        });
        return {
            results: hubspotQueryResponse.results.map((obj) => {
                return { label: obj.name, value: obj.id };
            }),
            next_cursor: hubspotQueryResponse.next_cursor,
        };
    });
    const selectedHubspotField = useMemo(() => {
        return hubspotFields[selectedObjectType].find((field) => field.name === selectedFieldName);
    }, [selectedFieldName, hubspotFields, selectedObjectType]);
    return (_jsxs("div", Object.assign({ className: "w-96 flex flex-col flex-grow items-start self-stretch gap-4" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-base" }, { children: "Select a destination" })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Object" })), _jsx(Dropdown, { options: HUBSPOT_OBJECT_TYPES.map((obj) => ({
                            value: obj,
                            label: getReadableObjectType(obj),
                        })), defaultSelected: selectedObjectType, onOptionSelect: (option) => {
                            setSelectedObjectType(option);
                        }, buttonClasses: "bg-white rounded-lg" })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: `${getReadableObjectType(selectedObjectType)} name` })), _jsx(AsyncSearchSelect, { id: selectedObjectType, dataFetcher: hubspotObjectsFetcher, onSelect: (option) => {
                            if (!option)
                                return;
                            setSelectedObject({
                                objectId: option.value,
                                objectName: option.label,
                            });
                        }, selectedOption: selectedObject
                            ? {
                                value: selectedObject.objectId,
                                label: (_e = selectedObject.objectName) !== null && _e !== void 0 ? _e : "Unknown object",
                            }
                            : undefined, buttonClasses: "bg-white rounded-lg" })] })), _jsxs("div", Object.assign({ className: "flex flex-col items-start self-stretch gap-1" }, { children: [_jsx("span", Object.assign({ className: "text-sm font-bold text-gray-500" }, { children: "Field" })), fieldOptions.length === 0 ? (_jsx(WaitingSpinner, { text: "Loading your fields from HubSpot" })) : (_jsx(AsyncSearchSelect, { id: `${selectedObjectType}-fields`, dataFetcher: (userQuery, ids, cursor) => {
                            return new Promise((resolve) => {
                                const filteredResults = fieldOptions.filter((field) => field.label.toLowerCase().includes(userQuery.toLowerCase()));
                                resolve({
                                    results: filteredResults,
                                });
                            });
                        }, selectedOption: selectedFieldName in allHubspotFieldsByName
                            ? {
                                value: selectedFieldName,
                                label: allHubspotFieldsByName[selectedFieldName].label,
                            }
                            : undefined, onSelect: (option) => {
                            if (!option)
                                return;
                            setSelectedFieldName(option.value);
                        }, buttonClasses: "bg-white rounded-lg" })), !!selectedHubspotField && (_jsx(FieldSchemaRenderer, { id: selectedHubspotField.name, fieldSchema: selectedHubspotField.schema }))] }))] })));
};
