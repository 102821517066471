var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AsyncSearchMultiSelect, } from "@/components/ui/async_search_multi_select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTag, tagsDataFetcher } from "./common/endpoints";
/**
 * A reusable component for selecting and creating tags
 */
const TagsSelector = ({ selectedTags, onChange, className = "w-full", disableCreate = false, }) => {
    const queryClient = useQueryClient();
    const createTagMutation = useMutation({
        mutationFn: createTag,
    });
    return (_jsx(AsyncSearchMultiSelect, { dataFetcher: (query, _ids, _cursor) => {
            return queryClient.fetchQuery({
                queryKey: ["getTags", query],
                queryFn: () => tagsDataFetcher(query),
            });
        }, onChange: onChange, selectedOptions: selectedTags.map((tag) => ({
            value: tag,
            label: tag,
        })), placeholder: "Add tags", className: className, wrapPills: true, onCreate: disableCreate
            ? undefined
            : (newOptionLabel) => __awaiter(void 0, void 0, void 0, function* () {
                yield createTagMutation.mutateAsync(newOptionLabel);
                onChange([
                    ...selectedTags.map((tag) => ({
                        value: tag,
                        label: tag,
                    })),
                    { value: newOptionLabel, label: newOptionLabel },
                ]);
            }), id: "tags-selector" }));
};
export default TagsSelector;
