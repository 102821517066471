"use client";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import React from "react";
const Switch = React.forwardRef((_a, ref) => {
    var { className, checkedIcon, uncheckedIcon, checked } = _a, props = __rest(_a, ["className", "checkedIcon", "uncheckedIcon", "checked"]);
    return (_jsx(SwitchPrimitive.Root, Object.assign({ ref: ref, className: cn("relative inline-flex h-6 w-11 items-center rounded-full transition-colors disabled:pointer-events-none disabled:opacity-50", "border border-wds-gray-3", checked ? "bg-wds-blue-3" : "bg-wds-gray-2", className), checked: checked }, props, { children: _jsx(SwitchPrimitive.Thumb, Object.assign({ className: cn("inline-block h-4 w-4 rounded-full bg-white transition-transform", "data-[state=checked]:translate-x-6 data-[state=unchecked]:translate-x-1") }, { children: checked ? checkedIcon : uncheckedIcon })) })));
});
Switch.displayName = SwitchPrimitive.Root.displayName;
export { Switch };
