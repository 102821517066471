var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getGridStates } from "@/common/endpoints";
import { useQuery, useQueryClient } from "@tanstack/react-query";
export const useAgGridStates = (tableName, defaultGridState, applyLayout) => {
    const { data: savedLayouts = [] } = useQuery({
        queryKey: ["getGridStates", tableName],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const layouts = yield getGridStates(tableName, defaultGridState);
            const selection = (_a = layouts.find((l) => l.is_default)) !== null && _a !== void 0 ? _a : defaultGridState;
            applyLayout(selection);
            return layouts;
        }),
    });
    const queryClient = useQueryClient();
    const mutateSavedLayoutsAndApply = (updatedLayouts, layoutFingerprintToApply) => {
        var _a, _b;
        queryClient.setQueryData(["getGridStates", tableName], (_) => {
            return updatedLayouts;
        });
        const updatedLayout = (_b = (_a = updatedLayouts.find((l) => l.fingerprint_v2 === layoutFingerprintToApply)) !== null && _a !== void 0 ? _a : updatedLayouts.find((l) => l.is_default)) !== null && _b !== void 0 ? _b : defaultGridState;
        applyLayout(updatedLayout);
    };
    return {
        savedLayouts,
        mutateSavedLayoutsAndApply,
    };
};
