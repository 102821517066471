import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
export function ColumnResizer({ header, }) {
    if (header.column.getCanResize() === false)
        return _jsx(_Fragment, {});
    return (_jsx("div", { onDoubleClick: () => header.column.resetSize(), onMouseDown: header.getResizeHandler(), onTouchStart: header.getResizeHandler(), className: cn("absolute top-0 right-0 cursor-col-resize w-[2px] h-full bg-wds-gray-5 hover:bg-wds-gray-6 hover:w-2", header.column.getIsResizing() && "bg-wds-blue-3"), style: {
            userSelect: "none",
            touchAction: "none",
        } }));
}
