import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Separator } from "@/components/ui/separator";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import CallTimeDisplay from "./home/call_time_display";
import { PlaybackTimelineControls } from "./video_playback_timeline";
import VideoPlayer from "./video_player";
const PublicVideoAndTimeline = ({ title, videoUrl, speakerTimeline, callTime, }) => {
    const initialVideoTimeSec = new URLSearchParams(window.location.search).get("t");
    const [videoLengthMsec, setVideoLengthMsec] = useState(0);
    const [currentVideoTime, setCurrentVideoTime] = useState(initialVideoTimeSec ? Number(initialVideoTimeSec) * 1000 : 0);
    const videoRef = useRef(null);
    useEffect(() => {
        if (!videoRef.current)
            return;
        if (Math.abs(videoRef.current.currentTime * 1000 - currentVideoTime) < 1000)
            return;
        videoRef.current.currentTime = currentVideoTime / 1000;
    }, [currentVideoTime, videoRef.current]);
    return (_jsxs("div", Object.assign({ className: "w-2/3 mx-auto mt-10 flex flex-col items-center gap-4" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex flex-col items-start text-sm text-wds-gray-7 bg-wds-gray-1 p-4 rounded-lg shadow-md" }, { children: [_jsx("div", Object.assign({ className: "font-semibold text-2xl" }, { children: title })), callTime ? (_jsxs(_Fragment, { children: [_jsx(Separator, { className: "w-full" }), _jsx(CallTimeDisplay, { call: { call_time: callTime }, showDate: true, showTime: true, showClockIcon: true })] })) : null, _jsx(Separator, { className: "w-full" })] })), _jsx(VideoPlayer, { videoRef: videoRef, src: videoUrl !== null && videoUrl !== void 0 ? videoUrl : "", showControls: true, setCurrentVideoTime: setCurrentVideoTime, setVideoLengthMsec: setVideoLengthMsec, setVideoLoaded: () => { } }), videoUrl ? (_jsx(PlaybackTimelineControls, { timeline: speakerTimeline, endMsec: videoLengthMsec, currentMsec: currentVideoTime, setCurrentMsec: setCurrentVideoTime })) : (_jsx("div", Object.assign({ className: "w-full h-24 flex items-center justify-center border border-wds-blue-3 p-4 bg-white rounded-lg border-l-4 flex-inline" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-start gap-2" }, { children: [_jsx(InformationCircleIcon, { className: "h-6 w-6 text-white fill-wds-blue-3 stroke-white" }), "The call recording is not available."] })) })))] })));
};
export default PublicVideoAndTimeline;
