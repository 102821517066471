var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { Button } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger, } from "@/components/ui/collapsible";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon, ChevronUpIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAccountLabelForDisplay, getOpportunityLabelForDisplay, } from "../common/crm_utils";
import { getActiveTenant, getOrCreateUserProfile, getSavedReports, triggerWorkflowExecution, } from "../common/endpoints";
import { DEFAULT_USER_TIMEZONE } from "../settings/constants";
const triggerTypes = [
    {
        id: "CRON_SCHEDULE",
        name: "Schedule",
    },
];
const REPORT_TYPE_TO_TABLE_NAME = {
    ACCOUNT: "Account",
    CONTACT: "Contact",
    OPPORTUNITY: "Opportunity",
    LEAD: "Lead",
    CALLS: "calls",
};
export const BrickWorkflowTriggers = ({ brickType, triggers, onTriggersChange, }) => {
    const [selectedTriggerType, setSelectedTriggerType] = useState("CRON_SCHEDULE");
    const [triggerCollapsed, setTriggerCollapsed] = useState({});
    const { data: userProfile, isLoading: isUserProfileLoading } = useQuery({
        queryKey: ["getOrCreateUserProfile"],
        queryFn: getOrCreateUserProfile,
    });
    const handleAddTrigger = useCallback(() => {
        const newTrigger = {
            trigger_type: "CRON_SCHEDULE",
            trigger_metadata: {
                cron_schedule: "0 0 * * *", // Default to midnight every day
            },
            workflow_run_context: {
                account_id: null,
                call_id: null,
                contact_id: null,
                table_layout_id: null,
                report_id: null,
            },
            workflow_id: 0,
            last_triggered_at: null,
            id: triggers.length + Math.floor(Math.random() * 1000000),
        };
        onTriggersChange([...triggers, newTrigger]);
        setTriggerCollapsed((prev) => (Object.assign(Object.assign({}, prev), { [triggers.length]: true })));
    }, [triggers, onTriggersChange]);
    const handleDeleteTrigger = useCallback((index) => {
        const newTriggers = [...triggers];
        newTriggers.splice(index, 1);
        onTriggersChange(newTriggers);
        const newCollapsedState = Object.assign({}, triggerCollapsed);
        delete newCollapsedState[index];
        setTriggerCollapsed(newCollapsedState);
    }, [triggers, onTriggersChange, triggerCollapsed]);
    const handleCronChange = useCallback((value, index) => {
        const newTriggers = [...triggers];
        newTriggers[index] = Object.assign(Object.assign({}, newTriggers[index]), { trigger_metadata: {
                cron_schedule: value,
            } });
        onTriggersChange(newTriggers);
    }, [triggers, onTriggersChange]);
    const handleReportChange = useCallback((reportId, index) => {
        const newTriggers = [...triggers];
        newTriggers[index] = Object.assign(Object.assign({}, newTriggers[index]), { workflow_run_context: {
                account_id: null,
                call_id: null,
                contact_id: null,
                table_layout_id: null,
                report_id: reportId,
            } });
        onTriggersChange(newTriggers);
    }, [triggers, onTriggersChange]);
    const triggerWorkflowExecutionMutation = useMutation({
        mutationFn: (triggerId) => __awaiter(void 0, void 0, void 0, function* () {
            return triggerWorkflowExecution(triggerId);
        }),
    });
    const triggerTypeEnabled = (triggerType) => {
        return triggers.some((trigger) => trigger.trigger_type === triggerType.id);
    };
    if (isUserProfileLoading || !userProfile) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: "self-stretch bg-white flex flex-col justify-start items-start gap-6 text-wds-black" }, { children: [_jsx("span", Object.assign({ className: "text-xl font-normal leading-none" }, { children: "Triggers" })), _jsxs("div", Object.assign({ className: "flex w-full h-[60vh] justify-start items-start border border-wds-gray-3 rounded-lg overflow-hidden text-sm" }, { children: [_jsx("div", Object.assign({ className: "w-1/5 h-full bg-wds-gray-1 py-2 overflow-y-auto" }, { children: triggerTypes.map((triggerType) => (_jsxs(Button, Object.assign({ variant: "ghost", className: `px-3 py-2.5 w-full h-auto rounded-none text-black hover:bg-wds-gray-2 ${selectedTriggerType === triggerType.id ? "bg-wds-gray-2" : ""} flex justify-between items-center whitespace-normal text-left`, onClick: () => setSelectedTriggerType(triggerType.id) }, { children: [_jsx("span", { children: triggerType.name }), triggerTypeEnabled(triggerType) && (_jsx("div", Object.assign({ className: "w-5 h-5 rounded-full flex-shrink-0 bg-green-600 flex items-center justify-center ml-2" }, { children: _jsx(CheckIcon, { className: "w-4 h-4 stroke-2 text-white" }) })))] }), triggerType.id))) })), _jsx("div", Object.assign({ className: "w-4/5 h-full p-4 overflow-y-auto" }, { children: selectedTriggerType && (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [triggers.map((trigger, index) => trigger.trigger_type === selectedTriggerType && (_jsxs(Collapsible, Object.assign({ open: triggerCollapsed[index], onOpenChange: (open) => setTriggerCollapsed(Object.assign(Object.assign({}, triggerCollapsed), { [index]: open })), className: cn(triggerCollapsed[index]
                                        ? "rounded-lg border border-wds-blue-2 shadow-[0px_2px_4px_0px_theme(colors.wds-blue-2/24)]"
                                        : "") }, { children: [_jsx(CollapsibleTrigger, Object.assign({ asChild: true, className: "w-full justify-start items-center gap-5 inline-flex px-5 py-2" }, { children: _jsx(Button, Object.assign({ variant: "lightGray", size: "lg", className: "rounded-sm w-full" }, { children: _jsxs("div", Object.assign({ className: "w-full justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "justify-start items-center gap-3 flex" }, { children: `Trigger ${index + 1}` })), _jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [trigger.workflow_id !== 0 && (_jsx(Button, Object.assign({ variant: "invertedBlack", className: "rounded-md", size: "sm", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                                                        e.stopPropagation();
                                                                        yield triggerWorkflowExecutionMutation.mutateAsync(trigger.id);
                                                                    }) }, { children: _jsx("span", Object.assign({ className: "justify-start items-center gap-2 flex" }, { children: "Test" })) }))), _jsx(Button, Object.assign({ variant: "invertedRed", className: "rounded-md", size: "sm", onClick: (e) => {
                                                                        e.stopPropagation();
                                                                        handleDeleteTrigger(index);
                                                                    } }, { children: _jsxs("div", Object.assign({ className: "justify-start items-center gap-2 flex" }, { children: [_jsx(TrashIcon, { className: "w-4 h-4" }), _jsx("span", { children: "Delete" })] })) })), _jsx(Button, Object.assign({ variant: "ghost", size: "sm" }, { children: triggerCollapsed[index] ? (_jsx(ChevronDownIcon, { className: "w-4 h-4" })) : (_jsx(ChevronUpIcon, { className: "w-4 h-4" })) }))] }))] })) })) })), _jsx(CollapsibleContent, Object.assign({ className: "p-4" }, { children: trigger.trigger_type === "CRON_SCHEDULE" && (_jsx(CronScheduleTriggerSelector, { brickType: brickType, trigger: trigger, index: index, handleCronChange: handleCronChange, handleReportChange: handleReportChange, userProfile: userProfile })) }))] }), index))), _jsxs(Button, Object.assign({ variant: "light", size: "lg", className: "rounded-sm", onClick: handleAddTrigger }, { children: [_jsx("span", { children: "Add new trigger" }), _jsx(PlusIcon, { className: "ml-2 h-4 w-4" })] }))] }))) }))] }))] })));
};
const CronScheduleTriggerSelector = ({ brickType, trigger, index, handleCronChange, handleReportChange, userProfile, }) => {
    var _a, _b;
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const reportTypes = useMemo(() => {
        return [
            {
                id: "ACCOUNT",
                name: getAccountLabelForDisplay(activeTenant === null || activeTenant === void 0 ? void 0 : activeTenant.connected_crm),
            },
            { id: "CONTACT", name: "Contacts" },
            {
                id: "OPPORTUNITY",
                name: getOpportunityLabelForDisplay(activeTenant === null || activeTenant === void 0 ? void 0 : activeTenant.connected_crm),
            },
            { id: "LEAD", name: "Leads" },
            { id: "CALLS", name: "Calls" },
        ];
    }, [activeTenant]);
    const { data: reportsByReportType } = useQuery({
        queryKey: ["reportsByReportType"],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            const result = {
                ACCOUNT: [],
                CONTACT: [],
                OPPORTUNITY: [],
                LEAD: [],
                CALLS: [],
            };
            yield Promise.all(reportTypes.map((reportType) => __awaiter(void 0, void 0, void 0, function* () {
                const reports = yield getSavedReports(REPORT_TYPE_TO_TABLE_NAME[reportType.id]);
                result[reportType.id] = reports;
            })));
            return result;
        }),
    });
    if (!reportsByReportType) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [_jsx(Cron, { value: trigger.trigger_metadata.cron_schedule, setValue: (value) => handleCronChange(value, index), allowedPeriods: ["day", "week", "month"], allowedDropdowns: [
                            "period",
                            "months",
                            "month-days",
                            "week-days",
                            "hours",
                        ], clockFormat: "12-hour-clock", clearButton: false, allowEmpty: "never" }), _jsxs("span", Object.assign({ className: "pt-2 text-wds-gray-5 text-sm" }, { children: ["Timezone: ", (_a = userProfile.timezone) !== null && _a !== void 0 ? _a : DEFAULT_USER_TIMEZONE] }))] })), brickType === "REPORT" && (_jsxs(_Fragment, { children: [_jsx("span", { children: "For report: " }), _jsxs(Select, Object.assign({ value: (_b = trigger.workflow_run_context.report_id) === null || _b === void 0 ? void 0 : _b.toString(), onValueChange: (value) => handleReportChange(Number.parseInt(value), index) }, { children: [_jsx(SelectTrigger, { children: _jsx(SelectValue, { placeholder: "Select a saved report" }) }), _jsx(SelectContent, { children: reportTypes.map((reportType) => reportsByReportType[reportType.id] &&
                                    reportsByReportType[reportType.id].length > 0 && (_jsxs(SelectGroup, { children: [_jsx(SelectLabel, { children: reportType.name }), reportsByReportType[reportType.id].map((report) => (_jsx(SelectItem, Object.assign({ value: report.id.toString() }, { children: report.name }), report.id)))] }, reportType.id))) })] }))] }))] })));
};
