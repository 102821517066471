import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Command, CommandGroup, CommandItem, CommandList, } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useMemo, useRef, useState } from "react";
export const SearchableDropdown = (props) => {
    var _a, _b, _c, _d;
    const inputRef = useRef(null);
    // Initialize searchQuery with the selected option's label (if any)
    const [searchQuery, setSearchQuery] = useState(((_a = props.selectedOption) === null || _a === void 0 ? void 0 : _a.label) || "");
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    // Use internal state if selectedOption prop is not provided
    const [internalSelectedOption, setInternalSelectedOption] = useState(props.selectedOption || null);
    // Use the controlled prop if provided; otherwise, fall back to internal state
    const currentSelectedOption = (_b = props.selectedOption) !== null && _b !== void 0 ? _b : internalSelectedOption;
    // Filtering logic:
    // • If searchQuery is empty OR exactly matches the selected option's label,
    //   show all options.
    // • Otherwise, filter options that include the search query.
    const filteredItems = useMemo(() => {
        if (!searchQuery ||
            (currentSelectedOption && searchQuery === currentSelectedOption.label)) {
            return props.options;
        }
        return props.options.filter((option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery, props.options, currentSelectedOption]);
    // Sort so that the selected option (if any) always appears at the top.
    const sortedItems = useMemo(() => {
        if (!currentSelectedOption)
            return filteredItems;
        const selectedInFiltered = filteredItems.find((option) => option.value === currentSelectedOption.value);
        if (!selectedInFiltered) {
            return [currentSelectedOption, ...filteredItems];
        }
        return [...filteredItems].sort((a, b) => {
            if (a.value === currentSelectedOption.value)
                return -1;
            if (b.value === currentSelectedOption.value)
                return 1;
            return 0;
        });
    }, [filteredItems, currentSelectedOption]);
    return (_jsxs(Popover, Object.assign({ open: isPopoverOpen, onOpenChange: (open) => {
            setIsPopoverOpen(open);
            if (open) {
                // Focus the input as soon as the popover opens.
                setTimeout(() => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 0);
            }
        } }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: cn("flex items-center justify-between gap-2", "h-8 px-3 py-2", "bg-neutral-white border-2 border-neutral-stroke rounded-lg", (_c = props.buttonClasses) !== null && _c !== void 0 ? _c : "") }, { children: [_jsx("input", { ref: inputRef, className: "w-full text-sm font-medium border-0 bg-transparent focus:ring-0 focus:outline-none placeholder:text-neutral-gray-medium placeholder:text-sm rounded-lg caret-primary-strong leading-6", placeholder: (_d = props.placeholder) !== null && _d !== void 0 ? _d : "", onChange: (e) => setSearchQuery(e.target.value), value: searchQuery }), _jsx(ChevronDownIcon, { className: "w-5 h-5 shrink-0 text-neutral-dark" })] })) })), _jsx(PopoverContent, Object.assign({ align: "start", side: "bottom", className: "w-full bg-white border-0 p-0 max-h-60" }, { children: _jsx(Command, Object.assign({ className: "w-full h-full border-[1px] rounded-lg py-1.5 border-neutral-stroke !p-0 shadow-drop-down" }, { children: _jsx(CommandList, Object.assign({ className: "overflow-y-auto h-full scrollbar-hide !p-0 space-y-1.5" }, { children: sortedItems.length === 0 ? (_jsx("div", Object.assign({ className: "p-2 text-gray-700" }, { children: "Nothing found." }))) : (_jsx(CommandGroup, Object.assign({ className: "px-0 !rounded-lg" }, { children: sortedItems.map((option) => {
                                const isSelected = (currentSelectedOption === null || currentSelectedOption === void 0 ? void 0 : currentSelectedOption.value) === option.value;
                                return (_jsxs(CommandItem, Object.assign({ className: cn("flex items-center justify-between gap-2.5 px-3 py-3 cursor-pointer rounded-none ", isSelected && "bg-gray-200", "hover:bg-neutral-gray-medium"), onSelect: () => {
                                        props.onSelect(option);
                                        if (!props.selectedOption) {
                                            setInternalSelectedOption(option);
                                        }
                                        setSearchQuery(option.label);
                                        setIsPopoverOpen(false);
                                    } }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2 mr-6" }, { children: [option.icon, _jsx("span", Object.assign({ className: "text-neutral-dark ml-2 font-medium text-normal" }, { children: option.label }))] })), isSelected && (_jsx(CheckIcon, { className: "w-5 h-5 text-neutral-dark shrink-0", style: { strokeWidth: 2 } }))] }), option.value));
                            }) }))) })) })) }))] })));
};
