// Utility functions for handling cookies
// Note that we currently need to explicitly add the CSRF cookie on all post requests.
// by adding the following:
// {
//     headers: {'X-CSRFToken': getCookie('csrftoken')},
// }
export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
