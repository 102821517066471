var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { getActiveTenant, getHomePageMetaData, syncWithCalendar, } from "../common/endpoints";
import { formatToYYYY_MM_DD } from "../strings";
import Call_list_items from "./call_list_items";
import Right_panel_items from "./right_panel_items";
export default function HomeV3() {
    const v3MetadataQuery = useQuery({
        queryKey: ["getHomePageMetaData"],
        queryFn: getHomePageMetaData,
    });
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const [refreshingCalendar, setRefreshingCalendar] = useState(false);
    const queryClient = useQueryClient();
    const syncWithCalendarAndFetchCallsAgain = () => __awaiter(this, void 0, void 0, function* () {
        setRefreshingCalendar(true);
        const fromDate = new Date();
        yield queryClient.fetchQuery({
            queryKey: [
                "syncWithCalendar",
                formatToYYYY_MM_DD(fromDate),
                fromDate.getTimezoneOffset(),
            ],
            queryFn: () => syncWithCalendar(formatToYYYY_MM_DD(fromDate), fromDate.getTimezoneOffset()),
        });
        setRefreshingCalendar(false);
    });
    return (_jsx("div", Object.assign({ className: "flex h-screen relative" }, { children: _jsx("div", Object.assign({ className: "flex flex-row w-full bg-wds-gray-1 justify-center overflow-y-auto" }, { children: !v3MetadataQuery.data ? (_jsx("div", { children: "Loading..." })) : (_jsxs("div", Object.assign({ className: "max-w-screen-2xl flex flex-row w-full py-8 px-16 h-screen" }, { children: [_jsx("div", Object.assign({ className: "flex w-2/3 flex-col pr-8 pl-2 space-y-4" }, { children: _jsx(Call_list_items.WideScreenV3, { homePageMetaData: v3MetadataQuery.data, showLoading: v3MetadataQuery.isLoading || refreshingCalendar, syncWithCalendar: syncWithCalendarAndFetchCallsAgain, tenant: tenant }) })), _jsx("div", Object.assign({ className: "flex w-1/3" }, { children: _jsx(Right_panel_items.WideScreen, { accounts: v3MetadataQuery.data.recentAccounts || [] }) }))] }))) })) })));
}
