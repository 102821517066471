import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import useFeatureFlagEnabled from "@/use_feature_flag_enabled";
import { useMemo } from "react";
import { AdvancedSettingsIcon, DestinationsIcon, PencilEditIcon, } from "../icons/editor_icons";
import { FieldMappingUpdateType, } from "../types";
export const useBrickEditDialogData = ({ selectedBrickId, allBricks, brickLinks, linkAvailability, tenant, selectedType, }) => {
    const DEFAULT_BRICK = {
        prompt: undefined,
        name: undefined,
        scoring_criteria: undefined,
        data_sources: [],
        delivery_targets: [],
        model: "OPENAI_FLAGSHIP",
        web_data_model: "SONAR",
        suppress_citations: false,
        field_mappings: [],
        structured_output_format: undefined,
        brick_set_ids: [],
    };
    const clipLibraryBrickEnabled = useFeatureFlagEnabled("CLIP_LIBRARY_BRICK");
    const TYPE_TO_DELIVERY_TARGETS = useMemo(() => {
        var _a;
        const targets = new Map([
            [
                "ACCOUNT",
                [
                    { id: "AccountUI", name: "Account Page" },
                    {
                        id: "AccountSummary",
                        name: "Account Summary (only latest will be used)",
                    },
                ],
            ],
            [
                "CALL",
                [
                    { id: "Slack", name: "Slack" },
                    { id: "Email", name: "Email" },
                ],
            ],
            ["CALL_OVERVIEW", []],
            ["CONTACT", [{ id: "ContactEmailDrafts", name: "Contact Email Drafts" }]],
            [
                "TRACKER",
                [
                    { id: "CRM", name: tenant.connected_crm || "Wiser CRM" },
                    {
                        id: "CallInsights",
                        name: "Call Insights Tab",
                    },
                    {
                        id: "Email",
                        name: "Email",
                    },
                    {
                        id: "Slack",
                        name: "Slack",
                    },
                ],
            ],
            ["REPORT", [{ id: "Slack", name: "Slack" }]],
        ]);
        if (clipLibraryBrickEnabled) {
            (_a = targets.get("TRACKER")) === null || _a === void 0 ? void 0 : _a.push({
                id: "ClipLibrary",
                name: "Clip Library",
            });
        }
        return targets;
    }, [tenant.connected_crm, clipLibraryBrickEnabled]);
    const memoisedSelectedBrick = useMemo(() => {
        var _a;
        return ((_a = allBricks === null || allBricks === void 0 ? void 0 : allBricks.find((brick) => brick.id === selectedBrickId)) !== null && _a !== void 0 ? _a : DEFAULT_BRICK);
    }, [selectedBrickId, allBricks]);
    const memoisedSelectedBrickFieldMappings = useMemo(() => {
        var _a;
        const fieldMappingsFromBrick = (_a = memoisedSelectedBrick.field_mappings) === null || _a === void 0 ? void 0 : _a.map((f, idx) => {
            var _a;
            if (f.target === "SLACK") {
                return {
                    id: idx,
                    channel_id: f.target_details.channelId,
                    channel_name: f.target_details.channelName,
                    auto_apply_after_n_minutes: f.execute_post_call_after_n_minutes,
                    update_type: FieldMappingUpdateType.OVERWRITE,
                };
            }
            if (f.target === "WISER_CRM") {
                return {
                    id: idx,
                    object: f.target_details.objectType,
                    field: {
                        label: `${f.target_details.fieldId}`,
                        value: `${f.target_details.fieldId}`,
                    },
                    auto_apply_after_n_minutes: f.execute_post_call_after_n_minutes,
                    update_type: f.update_type,
                };
            }
            if (f.target === "CLIP_LIBRARY") {
                return {
                    id: idx,
                    tags: (_a = f.target_details.tags) !== null && _a !== void 0 ? _a : [],
                    auto_apply_after_n_minutes: f.execute_post_call_after_n_minutes,
                    update_type: f.update_type,
                };
            }
            return {
                id: idx,
                object: f.target_details.objectType,
                field: {
                    label: f.target_details.fieldLabel || f.target_details.fieldName,
                    value: f.target_details.fieldName,
                },
                auto_apply_after_n_minutes: f.execute_post_call_after_n_minutes,
                update_type: f.update_type,
            };
        });
        return (fieldMappingsFromBrick === null || fieldMappingsFromBrick === void 0 ? void 0 : fieldMappingsFromBrick.length) ? fieldMappingsFromBrick : [];
    }, [memoisedSelectedBrick]);
    const memoisedSelectedSourceBricks = useMemo(() => {
        return selectedBrickId
            ? brickLinks
                .filter((link) => link.target_brick_id === selectedBrickId)
                .map((link) => allBricks === null || allBricks === void 0 ? void 0 : allBricks.find((brick) => brick.id === link.source_brick_id))
                .filter((brick) => brick !== undefined)
            : [];
    }, [brickLinks, selectedBrickId, allBricks]);
    const availableSourceBricks = useMemo(() => {
        if (!linkAvailability || !allBricks)
            return [];
        const available = linkAvailability.find((availability) => availability.target_brick_id === selectedBrickId);
        return allBricks
            .map((brick) => brick.id)
            .filter((brickId) => !(available === null || available === void 0 ? void 0 : available.unavailable_source_brick_ids.includes(brickId)));
    }, [linkAvailability, allBricks, selectedBrickId]);
    const existingBrickNames = useMemo(() => {
        var _a;
        return new Set((_a = allBricks === null || allBricks === void 0 ? void 0 : allBricks.filter((brick) => brick.id !== selectedBrickId).map((brick) => brick.name)) !== null && _a !== void 0 ? _a : []);
    }, [allBricks, selectedBrickId]);
    const contextType = useMemo(() => {
        if (selectedType.includes("ACCOUNT")) {
            return "account";
        }
        if (selectedType.includes("CALL")) {
            return "call";
        }
        if (selectedType.includes("TRACKER")) {
            return "call";
        }
        if (selectedType.includes("CONTACT")) {
            return "contact";
        }
        if (selectedType.includes("REPORT")) {
            return "report";
        }
        return "account";
    }, [selectedType]);
    return {
        TYPE_TO_DELIVERY_TARGETS,
        memoisedSelectedBrick,
        memoisedSelectedBrickFieldMappings,
        memoisedSelectedSourceBricks,
        availableSourceBricks,
        existingBrickNames,
        contextType,
    };
};
export const getTabs = (selectedType) => {
    const _tabs = [
        {
            label: (_jsxs(_Fragment, { children: [_jsx(PencilEditIcon, { className: "h-4 w-4 mr-2.5" }), " Edit Prompt"] })),
            value: "create_or_edit",
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(DestinationsIcon, { className: "h-4 w-4 mr-2.5" }), " Prompt Destinations"] })),
            value: "destinations",
        },
        {
            label: (_jsxs(_Fragment, { children: [_jsx(AdvancedSettingsIcon, { className: "h-4 w-4 mr-2.5" }), " Advanced Prompt Settings"] })),
            value: "advanced",
        },
    ];
    if (selectedType.includes("REPORT")) {
        _tabs.push({
            label: "Triggers",
            value: "brick_workflow_triggers",
        });
    }
    else {
        _tabs.push({ label: "Preview", value: "preview" });
    }
    return _tabs;
};
