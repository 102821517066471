var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Button } from "@/components/ui/button";
import { Kbd } from "@/components/ui/kbd";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { toast } from "@/components/ui/use_toast";
import { ArrowPathIcon, TrashIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { deleteCrmObjects, getActiveTenant } from "../common/endpoints";
import { useEscapeKey } from "../hooks/use_escape_key";
import { CrmObjectTypeEnum } from "../types";
export const CrmTableFloatingBar = ({ crmObjectType, table, invalidateTableData, }) => {
    const rows = table.getFilteredSelectedRowModel().rows;
    const [isPending, setIsPending] = React.useState(false);
    const [method, setMethod] = React.useState();
    useEscapeKey(() => {
        table.toggleAllRowsSelected(false);
    });
    const { data: activeTenant, isLoading: activeTenantLoading } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        staleTime: Number.POSITIVE_INFINITY,
    });
    const getLabelForCrmObject = (crmObject) => {
        if (activeTenantLoading) {
            return "";
        }
        const hubspotConnected = (activeTenant === null || activeTenant === void 0 ? void 0 : activeTenant.connected_crm) === "HubSpot";
        switch (crmObject) {
            case CrmObjectTypeEnum.ACCOUNT:
                return hubspotConnected ? "Companies" : "Accounts";
            case CrmObjectTypeEnum.OPPORTUNITY:
                return hubspotConnected ? "Deals" : "Opportunities";
            case CrmObjectTypeEnum.CONTACT:
                return "Contacts";
            case CrmObjectTypeEnum.LEAD:
                return "Leads";
        }
    };
    return (_jsx("div", Object.assign({ className: "fixed inset-x-0 bottom-4 z-50 mx-auto w-fit px-4" }, { children: _jsx("div", Object.assign({ className: "w-full overflow-x-auto" }, { children: _jsxs("div", Object.assign({ className: "mx-auto flex w-fit items-center gap-2 rounded-md border bg-card p-2 shadow-2xl" }, { children: [_jsxs("div", Object.assign({ className: "flex h-7 items-center rounded-md border border-dashed pl-2.5 pr-1" }, { children: [_jsxs("span", Object.assign({ className: "whitespace-nowrap text-xs" }, { children: [rows.length, " selected"] })), _jsx(Separator, { orientation: "vertical", className: "ml-2 mr-1" }), _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "h-5 w-5 hover:border", onClick: () => table.toggleAllRowsSelected(false) }, { children: _jsx(XMarkIcon, { className: "h-3.5 w-3.5 shrink-0", "aria-hidden": "true" }) })) })), _jsxs(TooltipContent, Object.assign({ className: "flex items-center border bg-accent px-2 py-1 font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: [_jsx("p", Object.assign({ className: "mr-2" }, { children: "Clear selection" })), _jsx(Kbd, Object.assign({ abbrTitle: "Escape", variant: "outline" }, { children: "Esc" }))] }))] }) })] })), _jsx(Separator, { orientation: "vertical", className: "hidden h-5 sm:block" }), _jsx("div", Object.assign({ className: "flex items-center gap-1.5" }, { children: _jsx(TooltipProvider, { children: _jsxs(Tooltip, Object.assign({ delayDuration: 250 }, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Button, Object.assign({ variant: "secondary", size: "icon", className: "h-7 w-7 border data-[state=open]:bg-accent data-[state=open]:text-accent-foreground", disabled: isPending, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                setMethod("delete");
                                                setIsPending(true);
                                                yield deleteCrmObjects(crmObjectType, rows.map((row) => row.original.id));
                                                invalidateTableData();
                                                toast({
                                                    title: `${getLabelForCrmObject(crmObjectType)} deleted`,
                                                    description: `${getLabelForCrmObject(crmObjectType)} deleted successfully`,
                                                    variant: "green",
                                                });
                                                setIsPending(false);
                                            }) }, { children: isPending && method === "delete" ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin", "aria-hidden": "true" })) : (_jsx(TrashIcon, { className: "h-3.5 w-3.5 text-red-600", "aria-hidden": "true" })) })) })), _jsx(TooltipContent, Object.assign({ className: "border bg-accent font-semibold text-wds-gray-6 dark:bg-zinc-900" }, { children: _jsx("p", { children: "Delete" }) }))] })) }) }))] })) })) })));
};
