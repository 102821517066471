import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { VideoCameraIcon } from "@heroicons/react/24/outline";
import { memo } from "react";
import amplitudeInstance from "./amplitude";
const VideoPlayer = ({ src, thumbnail, className, videoRef, setVideoLengthMsec, setCurrentVideoTime, showControls, setIsPlaying, setVideoLoaded, }) => {
    if (!src)
        return (_jsx("div", Object.assign({ className: "flex items-center justify-center w-full h-[256px] lg:h-[400px] bg-wds-gray-3 rounded-xl" }, { children: _jsx(VideoCameraIcon, { className: "h-24 w-24 text-wds-gray-5 fill-white stroke-0" }) })));
    return (_jsx("video", { ref: videoRef, className: cn("video-js vjs-big-play-centered rounded-xl w-full h-auto", className), preload: "auto", src: src, poster: thumbnail, controls: Boolean(showControls), onTimeUpdate: (e) => {
            setCurrentVideoTime === null || setCurrentVideoTime === void 0 ? void 0 : setCurrentVideoTime(e.currentTarget.currentTime * 1000);
        }, onSeeked: (e) => {
            setCurrentVideoTime === null || setCurrentVideoTime === void 0 ? void 0 : setCurrentVideoTime(e.currentTarget.currentTime * 1000);
        }, onLoadedMetadata: (e) => {
            const duration = e.currentTarget.duration;
            if (duration === 0)
                return;
            if (Number.isNaN(duration))
                return;
            setVideoLengthMsec === null || setVideoLengthMsec === void 0 ? void 0 : setVideoLengthMsec(duration * 1000);
            setVideoLoaded(true);
        }, onPlay: () => {
            setIsPlaying === null || setIsPlaying === void 0 ? void 0 : setIsPlaying(true);
            amplitudeInstance.logEvent("Video Playback Started", {});
        }, onPause: () => {
            setIsPlaying === null || setIsPlaying === void 0 ? void 0 : setIsPlaying(false);
            amplitudeInstance.logEvent("Video Playback Paused", {});
        } }));
};
// Using memo here to avoid re-creating the video element on every render.
export default memo(VideoPlayer);
