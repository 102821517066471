var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from "@tanstack/react-query";
import { getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from "@tanstack/react-table";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSharedTableLayout } from "../common/endpoints";
export function useDataTable(_a) {
    var _b, _c, _d;
    var { filterFields = [], enableAdvancedFilter = false, enableAdvancedSorting = false } = _a, props = __rest(_a, ["filterFields", "enableAdvancedFilter", "enableAdvancedSorting"]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [columnOrderLocal, setColumnOrderLocal] = React.useState((_b = props.columnOrder) !== null && _b !== void 0 ? _b : []);
    const [sharedLayoutIdState, setSharedLayoutIdState] = React.useState(searchParams.get("layout"));
    const { data: sharedLayout } = useQuery({
        queryKey: ["getSharedTableLayoutLink", sharedLayoutIdState],
        queryFn: () => getSharedTableLayout(sharedLayoutIdState),
    });
    // Table states
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState((_d = (_c = props.initialState) === null || _c === void 0 ? void 0 : _c.columnVisibility) !== null && _d !== void 0 ? _d : {});
    React.useEffect(() => {
        if (sharedLayoutIdState && sharedLayout && sharedLayout.length > 0) {
            navigate(`?${sharedLayout[0].query_string}`);
            if (table) {
                // Toggle column visibility and set sizing
                table.getAllColumns().forEach((column) => {
                    if (!column.getIsVisible() &&
                        Object.keys(sharedLayout[0].columns).includes(column.id)) {
                        column.toggleVisibility(true);
                    }
                    else if (column.getIsVisible() &&
                        !Object.keys(sharedLayout[0].columns).includes(column.id)) {
                        column.toggleVisibility(false);
                    }
                });
                table.setColumnSizing(sharedLayout[0].columns);
            }
            setColumnOrderLocal(sharedLayout[0].column_order);
        }
    }, [sharedLayout, sharedLayoutIdState]);
    const table = useReactTable(Object.assign(Object.assign({}, props), { state: {
            columnVisibility,
            rowSelection,
            columnOrder: sharedLayoutIdState ? columnOrderLocal : props.columnOrder,
        }, enableRowSelection: true, onRowSelectionChange: setRowSelection, onColumnVisibilityChange: setColumnVisibility, getCoreRowModel: getCoreRowModel(), getFilteredRowModel: getFilteredRowModel(), getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(), getFacetedRowModel: getFacetedRowModel(), getFacetedUniqueValues: getFacetedUniqueValues(), manualPagination: true, manualSorting: true, manualFiltering: true, columnResizeMode: "onChange" }));
    return { table };
}
