var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// Creates a generic modal/overlay for editing fields that we can use in multiple places.
import { AsyncSearchMultiSelect } from "@/components/ui/async_search_multi_select";
import { AsyncSearchSelect } from "@/components/ui/async_search_select";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Collapsible, CollapsibleContent, CollapsibleTrigger, } from "@/components/ui/collapsible";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogFooter, DialogHeader, } from "@/components/ui/modal_dialog";
import { MultiSelector, MultiSelectorContent, MultiSelectorInput, MultiSelectorItem, MultiSelectorList, MultiSelectorTrigger, } from "@/components/ui/multi_select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio_group";
import { SearchableDropdown } from "@/components/ui/searchable_dropdown";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/text_area";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { ArrowPathIcon, ChevronUpDownIcon, QuestionMarkCircleIcon, } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose, DialogDescription, DialogTitle, } from "@radix-ui/react-dialog";
import { useCallback, useMemo, useState, } from "react";
import { useForm, } from "react-hook-form";
const renderField = (f, field, form) => {
    var _a;
    if (f.type === "select") {
        return (_jsx(FormControl, { children: _jsxs(Select, Object.assign({ onValueChange: (value) => {
                    if (f.onChange) {
                        f.onChange(value);
                    }
                    field.onChange(value);
                }, defaultValue: field.value }, { children: [_jsx(SelectTrigger, { children: _jsx(SelectValue, { placeholder: f.placeholder }) }), _jsx(SelectContent, { children: _jsxs(SelectGroup, { children: [_jsx(SelectLabel, { children: field.name }), f.options.map(({ label, value }) => (_jsx(SelectItem, Object.assign({ value: value }, { children: label }), value)))] }) })] })) }));
    }
    if (f.type === "radio_selector") {
        return (_jsx(FormControl, { children: _jsx(RadioGroup, Object.assign({ onValueChange: field.onChange, defaultValue: field.value, className: "flex flex-row space-x-3" }, { children: f.options.map(({ label, value }) => (_jsxs(FormItem, Object.assign({ className: "flex items-center space-x-3 space-y-0" }, { children: [_jsx(FormControl, { children: _jsx(RadioGroupItem, { value: value }) }), _jsx(FormLabel, Object.assign({ className: "font-normal" }, { children: label }))] })))) })) }));
    }
    if (f.type === "checkbox") {
        return (_jsx(_Fragment, { children: f.options.map(({ label, value }) => {
                var _a;
                return (_jsxs(FormItem, Object.assign({ className: "flex flex-row items-start space-x-3 space-y-0" }, { children: [_jsx(FormControl, { children: _jsx(Checkbox, { checked: (_a = field.value) === null || _a === void 0 ? void 0 : _a.includes(value), onCheckedChange: (checked) => {
                                    var _a;
                                    return checked
                                        ? field.onChange([...field.value, value])
                                        : field.onChange((_a = field.value) === null || _a === void 0 ? void 0 : _a.filter((v) => v !== value));
                                } }) }), _jsx(FormLabel, Object.assign({ className: "text-sm font-normal" }, { children: label }))] }), value));
            }) }));
    }
    if (f.type === "textarea") {
        return _jsx(Textarea, Object.assign({ placeholder: f.placeholder }, field));
    }
    if (f.type === "multi_select") {
        return (_jsxs(MultiSelector, Object.assign({ onValuesChange: field.onChange, values: field.value }, { children: [_jsx(MultiSelectorTrigger, { children: _jsx(MultiSelectorInput, { placeholder: f.placeholder }) }), _jsx(MultiSelectorContent, { children: _jsx(MultiSelectorList, { children: (_a = f.options) === null || _a === void 0 ? void 0 : _a.map(({ label, value }) => (_jsx(MultiSelectorItem, Object.assign({ value: value }, { children: _jsx("span", { children: label }) }), value))) }) })] })));
    }
    if (f.type === "switch") {
        return (_jsx(FormControl, { children: _jsx(Switch, { checked: field.value, onCheckedChange: field.onChange, className: "ml-2" }) }));
    }
    if (f.type === "async_search_select") {
        return (_jsx(FormControl, { children: _jsx(AsyncSearchSelect, { id: f.id, dataFetcher: f.optionsFetcher, onSelect: field.onChange, placeholder: f.placeholder, selectedOption: field.value || f.defaultValue }) }));
    }
    if (f.type === "async_search_multi_select") {
        return (_jsx(FormControl, { children: _jsx(AsyncSearchMultiSelect, { id: f.id, dataFetcher: f.optionsFetcher, onChange: field.onChange, placeholder: f.placeholder, selectedOptions: field.value || f.defaultValue }) }));
    }
    if (f.type === "text") {
        return (_jsx(Input, Object.assign({ placeholder: f.placeholder, type: f.type }, field, { onBlur: (e) => { var _a; return (_a = f.onBlur) === null || _a === void 0 ? void 0 : _a.call(f, e, form); } })));
    }
    if (f.type === "searchable_dropdown") {
        return (_jsx(SearchableDropdown, { options: f.options, selectedOption: field.value, onSelect: field.onChange }));
    }
    if (f.type === "number") {
        return (_jsx(Input, Object.assign({ placeholder: f.placeholder, type: f.type }, field, { onChange: (e) => field.onChange(e.target.valueAsNumber), onBlur: (e) => { var _a; return (_a = f.onBlur) === null || _a === void 0 ? void 0 : _a.call(f, e, form); } })));
    }
    return _jsx(Input, Object.assign({ placeholder: f.placeholder, type: f.type }, field));
};
const BaseModal2 = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const defaultValues = Object.entries(props.formFieldMeta).reduce((acc, [key, value]) => {
        if (value.defaultValue) {
            acc[key] = value.defaultValue;
        }
        return acc;
    }, {});
    const baseSchema = props.zodSchema;
    const form = useForm({
        resolver: zodResolver(baseSchema),
        // @ts-ignore
        defaultValues,
    });
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        setSubmitting(true);
        yield props.submitCallback(values);
        form.reset();
        setSubmitting(false);
    });
    const advancedFieldKeys = useMemo(() => {
        return Object.keys(props.formFieldMeta).filter((key) => props.formFieldMeta[key].advancedField);
    }, [props.formFieldMeta]);
    const renderFormField = useCallback((index, key) => (_jsx("div", Object.assign({ className: "mt-2" }, { children: _jsx(FormField, { control: form.control, name: key, render: ({ field }) => (_jsxs(FormItem, { children: [_jsxs(FormLabel, { children: [field.name, " ", props.formFieldMeta[key].tip && (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx(QuestionMarkCircleIcon, { className: "h-4 w-4 text-wds-gray-5 shrink-0" }) }), _jsx(TooltipContent, { children: props.formFieldMeta[key].tip })] }) }))] }), props.formFieldMeta[key].required && (_jsx("span", Object.assign({ className: "text-red-500" }, { children: "*" }))), renderField(props.formFieldMeta[key], field, form), _jsx(FormMessage, { children: props.formFieldMeta[key].description })] })) }, index) }))), []);
    return (_jsx(Dialog, Object.assign({ open: props.open, onOpenChange: (open) => props.setOpen(open) }, { children: _jsx(DialogContent, Object.assign({ className: cn(props.classNames, "relative px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-xl lg:max-w-4xl sm:p-6 font-normal text-sm rounded-2xl border border-neutral-200 font-['Lato']") }, { children: _jsx(Form, Object.assign({}, form, { children: _jsxs("form", Object.assign({ onSubmit: form.handleSubmit(onSubmit), className: "space-y-8" }, { children: [_jsxs(DialogHeader, { children: [_jsx(DialogTitle, Object.assign({ className: "pt-4 pb-4" }, { children: props.title })), _jsx(Separator, {}), _jsxs(DialogDescription, Object.assign({ className: "pt-4" }, { children: [props.description, _jsxs("div", Object.assign({ className: "mt-6" }, { children: [Object.keys(props.formFieldMeta).map((key, index) => {
                                                    return advancedFieldKeys.includes(key)
                                                        ? null
                                                        : renderFormField(index, key);
                                                }), props.children] }))] }))] }), _jsx(Separator, {}), advancedFieldKeys.length > 0 && (_jsx(Card, { children: _jsx(CardContent, Object.assign({ className: "p-2" }, { children: _jsxs(Collapsible, { children: [_jsx(CollapsibleTrigger, Object.assign({ asChild: true }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-center justify-between" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-row items-center" }, { children: [_jsx("div", { children: "Advanced fields" }), _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx(QuestionMarkCircleIcon, { className: "h-4 w-4 text-wds-gray-5 shrink-0" }) }), _jsx(TooltipContent, { children: "These settings should not require modification unless you have an advanced configuration with Wiser." })] }) })] })), _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", className: "w-9 p-0 text-wds-gray-6" }, { children: [_jsx(ChevronUpDownIcon, { className: "h-4 w-4" }), _jsx("span", Object.assign({ className: "sr-only" }, { children: "Toggle" }))] }))] })) })), _jsx(CollapsibleContent, { children: Object.keys(props.formFieldMeta).map((key, index) => {
                                                return advancedFieldKeys.includes(key)
                                                    ? renderFormField(index, key)
                                                    : null;
                                            }) })] }) })) })), _jsx(DialogFooter, Object.assign({ className: "sm:justify-end p-4" }, { children: _jsx(DialogClose, Object.assign({ asChild: true }, { children: _jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", size: "lg", className: "text-wds-gray-6 font-bold", onClick: () => props.setOpen(false) }, { children: "Close" })), _jsxs(Button, Object.assign({ type: "submit", size: "lg", disabled: submitting ||
                                                props.disableSubmit ||
                                                Object.keys(form.formState.errors).length > 0 }, { children: [submitting ? (_jsx(ArrowPathIcon, { className: "mr-2 h-4 w-4 animate-spin" })) : null, props.submitText] }))] }) })) }))] })) })) })) })));
};
export default BaseModal2;
