var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from "react";
import { accountsFetcher, contactsFetcher, leadsFetcher, opportunitiesFetcher, } from "../calls_table/calls_table_fetchers";
import { queryCrmOwners } from "../common/endpoints";
import { departmentsForFilter, teamsForFilter, tenantUsersForFilter, } from "../table_hooks/object_fetchers";
export const useCrmFilterFetchers = () => {
    const referenceObjectFetcherMap = useMemo(() => {
        return new Map([
            [
                "owner_id",
                (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
                    const { results } = yield queryCrmOwners(userQuery, ids, cursor);
                    const ownerOptions = results.map((owner) => ({
                        value: owner.id.toString(),
                        label: owner.name || owner.email,
                    }));
                    // We fetch by ids to restore the filter values in the UI after the user
                    // saves the filter. Essentially if the user applied the filter:
                    // User in [a, b, c] and saves, the only values in the query that get
                    // saved are the ids of a, b and c. And hence to render the name (labels)
                    // of those ids in the UI when the user revisits the page, we first fetch
                    // the Users by ids using this API.
                    // For self, we only need to add it in the result if the user saved the filter
                    // with -99 (special id for self).
                    if (!ids || ids.includes(-99)) {
                        ownerOptions.unshift({
                            value: "-99",
                            label: "Self",
                        });
                    }
                    return {
                        results: ownerOptions.filter((owner) => owner.label.toLowerCase().includes(userQuery.toLowerCase())),
                    };
                }),
            ],
            ["manager_id", tenantUsersForFilter],
            ["team_id", teamsForFilter],
            ["department_id", departmentsForFilter],
            ["account_id", accountsFetcher],
            ["contact_id", contactsFetcher],
            ["opportunity_id", opportunitiesFetcher],
            ["lead_id", leadsFetcher],
        ]);
    }, []);
    return { referenceObjectFetcherMap };
};
