var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipTrigger, } from "@/components/ui/tooltip";
import { EllipsisVerticalIcon, MagnifyingGlassIcon, PlusIcon, } from "@heroicons/react/24/outline";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { flexRender, getCoreRowModel, getFilteredRowModel, useReactTable, } from "@tanstack/react-table";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import DragIndicatorIcon from "./icons/drag_indicator_icon";
// T is the generic type for a table row, can be instance of any database models, example LiveCall, Tenant, etc.
// Using generics ensures that the all the rows in the table have the same type. see: https://www.freecodecamp.org/news/how-typescript-generics-work/
// How it works:
// 1. We create a Tanstack table with the columns and rows
// 2. Tanstack gives us a bunch of helper functions to render the table
// 3. We use the helper functions to render the table
// The helper functions include:
// 1. getHeaderGroups: to get the header groups, this method is used to render the headers, this is mainly the column names
// 2. getRowModel: to get the row model, this method is used to get the row model from the table, it returns the rows and the cells in them
// 3. flexRender: to render the table, this method is used to render the content of the table cells, this content is derived from the row model (T) & the column definitions
const BaseTable2 = (props) => {
    const { title, tip, columns, rows, expandCallback, addCallback, additionalActions, reorderRows, rowName, } = props;
    const [globalFilter, setGlobalFilter] = useState("");
    const table = useReactTable({
        data: rows,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: "includesString",
        state: {
            globalFilter: globalFilter,
        },
        enableGlobalFilter: true,
    });
    const onDragEnd = (result) => __awaiter(void 0, void 0, void 0, function* () {
        if (!reorderRows)
            return;
        if (!result.destination)
            return;
        const reordering = Array.from(rows);
        const [removed] = reordering.splice(result.source.index, 1);
        reordering.splice(result.destination.index, 0, removed);
        reorderRows(reordering);
    });
    return (_jsxs("div", Object.assign({ className: "w-full h-screen py-4 bg-white" }, { children: [_jsxs("div", Object.assign({ className: "w-full flex flex-row justify-between" }, { children: [_jsx("div", Object.assign({ className: "mt-4 w-1/2" }, { children: _jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, { children: _jsx("div", Object.assign({ className: "w-92 text-2xl capitalize" }, { children: title })) }), tip && (_jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "w-[640px]" }, { children: tip })) }))] }) }) })), _jsx("div", Object.assign({ className: "w-1/2 justify-end inline-flex items-end" }, { children: _jsxs("div", Object.assign({ className: "flex gap-4 items-center" }, { children: [_jsxs("div", Object.assign({ className: "w-60 px-3 bg-white rounded-lg border border-zinc-200 justify-between items-center flex" }, { children: [_jsx("input", { className: "px-0 focus:outline-none border-0 focus:ring-0 placeholder:text-wds-gray-7 text-sm", placeholder: `Search ${rowName}`, onChange: (e) => {
                                                setGlobalFilter(e.target.value);
                                            } }), _jsx(MagnifyingGlassIcon, { className: "text-gray-400 w-5 h-5 relative" })] })), additionalActions === null || additionalActions === void 0 ? void 0 : additionalActions.map((action, ix) => (_jsx(Button, Object.assign({ variant: "invertedBlack", className: "border border-wds-black rounded-lg text-sm font-bold", onClick: () => action.callback(), disabled: action.disabled, type: "button" }, { children: action.label }), action.id))), addCallback ? (_jsxs(Button, Object.assign({ variant: "black", onClick: addCallback, type: "button", className: "rounded-md" }, { children: [_jsxs("div", Object.assign({ className: "text-white text-sm font-bold leading-tight" }, { children: ["Create ", rowName] })), _jsx(PlusIcon, { className: "text-white fill-white stroke-white w-4 h-4 relative" })] }))) : null] })) }))] })), _jsx("div", Object.assign({ className: "w-full flex flex-col mt-4 font-medium" }, { children: _jsx("div", Object.assign({ className: "rounded-lg border border-solid border-wds-gray-2" }, { children: _jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "droppable-brick-set" }, { children: ({ droppableProps, innerRef, placeholder }) => {
                                return (_jsx("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-2 flex" }, droppableProps, { ref: innerRef }, { children: _jsxs("table", Object.assign({ className: "w-full bg-white rounded-lg" }, { children: [_jsx("thead", { children: table.getHeaderGroups().map((headerGroup) => (_jsxs("tr", Object.assign({ className: "[&>*:first-child]:rounded-tl-lg [&>*:last-child]:rounded-tr-lg [&>*:not(:last-child)]:border-r" }, { children: [!!reorderRows && (_jsx("th", { className: "font-medium bg-wds-gray-1 p-3 border-wds-gray-2 w-10" })), headerGroup.headers.map((header) => (_jsx("th", Object.assign({ className: "font-medium text-sm bg-wds-gray-1 p-3 border-wds-gray-2" }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center justify-between self-stretch gap-4" }, { children: header.isPlaceholder
                                                                    ? null
                                                                    : flexRender(header.column.columnDef.header, header.getContext()) })) }), header.id))), expandCallback ? (_jsx("th", { className: "font-medium bg-wds-gray-1 p-3 border-wds-gray-2" })) : null] }), headerGroup.id))) }), _jsxs("tbody", { children: [table.getRowModel().rows.map((row, index) => (_jsx(Draggable, Object.assign({ draggableId: row.id.toString(), index: index }, { children: ({ draggableProps, dragHandleProps, innerRef }, snapshot) => {
                                                            if (snapshot.isDragging) {
                                                                const firstCell = row.getVisibleCells()[0];
                                                                return (_jsx("div", Object.assign({}, draggableProps, { ref: innerRef }, { children: flexRender(firstCell.column.columnDef.cell, firstCell.getContext()) })));
                                                            }
                                                            return (_jsxs("tr", Object.assign({ className: "h-16 [&>*:first-child]:rounded-bl-lg [&>*:last-child]:rounded-br-lg [&>*:not(:last-child)]:border-r" }, draggableProps, { ref: innerRef }, { children: [!!reorderRows && (_jsx("td", Object.assign({ className: "items-center h-full justify-center p-3 border-t border-t-gray-100" }, dragHandleProps, { children: _jsx(DragIndicatorIcon, {}) }))), row.getVisibleCells().map((cell, index) => (_jsx("td", Object.assign({ className: "border-t border-t-gray-100 p-3 bg-white" }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))), expandCallback ? (_jsx("td", Object.assign({ className: "border-t border-t-gray-100 bg-white text-center w-12" }, { children: _jsx("button", Object.assign({ className: "rounded-full justify-center items-center inline-flex hover:bg-gray-200 w-8 h-8", onClick: () => {
                                                                                expandCallback(row.original);
                                                                            }, "aria-label": "Edit", type: "button" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-5 h-5 relative" }) })) }))) : null] }), row.id));
                                                        } }), row.id))), placeholder] })] })) })));
                            } })) })) })) }))] })));
};
export default BaseTable2;
