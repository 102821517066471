var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";
import CrmFieldValueEditor from "./field_value_editor";
import CrmFieldValueRenderer from "./field_value_renderer";
const CrmFieldValue = ({ crmObjectId, crmFieldId, crmFieldSchema, crmFieldValue, onNewValueChange, editDisabled, showRequired, }) => {
    const [newContent, setNewContent] = useState(crmFieldValue);
    // Boolean to indicate whether user "is editing" the value
    const [editNewValue, setEditNewValue] = useState(false);
    const newValueDivRef = useRef(null);
    const onNewValueEdit = (content) => {
        setNewContent(content);
    };
    const handleNewValueTextAreaKeyDown = (event) => {
        // Users can come out of the edit mode by pressing Esc.
        if (event.key === "Escape") {
            // DO NOT update the value.
            setNewContent(crmFieldValue);
            setEditNewValue(false);
        }
        // If the user clicks Enter, update the value.
        if (event.key === "Enter" && !event.shiftKey) {
            setEditNewValue(false);
            onNewValueChange(newContent);
        }
    };
    useEffect(() => {
        // Attach a click event listener to document to exit edit mode on outside click
        const handleOutsideClick = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const clickedElement = event.target;
            const isClickedInsideComponent = (_a = newValueDivRef.current) === null || _a === void 0 ? void 0 : _a.contains(clickedElement);
            // The clicked inside app check ensures that we treat only those clicks as
            // "outside" that are not in the Radix portal. Otherwise, clicks in the Radix
            // components rendered in a portal like DropdownMenu is also considered an
            // outside click and thus disrupts the functioning of this hook.
            const isClickedInsideApp = (_b = document
                .getElementById("app")) === null || _b === void 0 ? void 0 : _b.contains(clickedElement);
            if (isClickedInsideApp && !isClickedInsideComponent) {
                setEditNewValue(false);
                yield onNewValueChange(newContent);
            }
        });
        if (editNewValue) {
            // Capture the click event on the document to exit edit mode on outside click
            document.addEventListener("click", handleOutsideClick, true);
        }
        return () => {
            document.removeEventListener("click", handleOutsideClick, true);
        };
    }, [editNewValue, newContent]);
    useEffect(() => {
        setNewContent(crmFieldValue);
    }, [crmFieldValue]);
    const isEditDisabled = editDisabled;
    return (_jsx("div", Object.assign({ className: cn("p-2 h-full", editNewValue || isEditDisabled
            ? ""
            : "hover:outline-none hover:cursor-pointer hover:bg-gray-100"), onClick: () => {
            if (editNewValue || isEditDisabled)
                return;
            setEditNewValue(true);
        } }, { children: editNewValue ? (_jsx("div", Object.assign({ ref: newValueDivRef }, { children: _jsx(CrmFieldValueEditor, { crmObjectId: crmObjectId, crmFieldId: crmFieldId, crmFieldSchema: crmFieldSchema, value: newContent !== null && newContent !== void 0 ? newContent : "", onValueEdit: onNewValueEdit, handleKeyDown: handleNewValueTextAreaKeyDown, variant: "table" }) }))) : (_jsxs("div", Object.assign({ ref: newValueDivRef, className: "flex flex-row items-start justify-between gap-2" }, { children: [_jsx(CrmFieldValueRenderer, { crmObjectId: crmObjectId, crmFieldSchema: crmFieldSchema, crmFieldValue: crmFieldValue }), showRequired && _jsx("span", Object.assign({ className: "self-end text-red-700" }, { children: "*" }))] }))) })));
};
export default CrmFieldValue;
