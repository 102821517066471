// This function is used to authenticate a user with Paragon using a token.
// It first checks if the Paragon SDK is available and if a token is present.
// If both are available, it calls the 'authenticate' method on the Paragon SDK with the project ID and the token.
// Once the user is authenticated, it sets the 'user' state with the authenticated user.
// If the authentication fails, it logs the error to the console.
// The function returns the authenticated user.
// ref: https://docs.useparagon.com/tutorials/building-an-in-app-integrations-catalog
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from "@tanstack/react-query";
import { SDK_EVENT, paragon } from "@useparagon/connect";
import { useCallback, useEffect, useState } from "react";
import { getParagonUserToken, paragonIntegrationsUpdateCallback, } from "./common/endpoints";
const PARAGON_PROJECT_ID = window.location.href.startsWith("https://staging.getwiser.io")
    ? process.env.PARAGON_STAGING_PROJECT_ID
    : process.env.PARAGON_PROJECT_ID;
export default function useParagonAuth() {
    const user = paragon.getUser();
    const [latestUser, setLatestUser] = useState(user);
    const { data: token, isLoading: tokenLoading, isError: tokenError, } = useQuery({
        queryKey: ["getParagonUserToken"],
        queryFn: getParagonUserToken,
    });
    const { data: paragonAuthenticated, isLoading: paragonAuthenticatedLoading, isError: paragonAuthenticatedError, } = useQuery({
        queryKey: ["paragonAuthenticated"],
        queryFn: () => __awaiter(this, void 0, void 0, function* () {
            if (!token)
                return false;
            yield paragon.authenticate(PARAGON_PROJECT_ID, token);
            return true;
        }),
        enabled: !!token && !tokenLoading,
    });
    const updateIntegrations = useCallback(() => {
        paragonIntegrationsUpdateCallback(paragon.getUser());
        setLatestUser(paragon.getUser());
    }, [paragon]);
    useEffect(() => {
        paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateIntegrations);
        paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateIntegrations);
        return () => {
            paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateIntegrations);
            paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateIntegrations);
        };
    }, [updateIntegrations]);
    return {
        user: latestUser,
        paragon,
        paragonUserLoaded: !paragonAuthenticatedLoading,
    };
}
