var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { Tab } from "@headlessui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import amplitudeInstance from "../amplitude";
import * as endpoints from "../common/endpoints";
import Transcript from "../live_call/transcript";
import { CHAT_SOURCES, CURRENT_DEAL, } from "../live_cards_chat_bot/chat_source_menu";
import DataProcessingTab from "../post_call/data_processing_tab";
import PreCallPrep from "../pre_call_prep";
import AssignCallBrickset from "./assign_call_brickset";
import CallDetailsPanel from "./call_details_panel";
import { ChatBox } from "./chat_box_v3";
/**
 * Main call content of the call launch page. Contains Different tabs like Summary (transript), Agenda (playbooks), & more in future.
 *
 * @param {LiveCall} call - The current live call object.
 * @param {(call: LiveCall) => void} updateSelectedCall - Passes updated call object to the parent component if any call attributes change.
 */
export default ({ call, notes, updateSelectedCall, fetchUpdatedCalls, showChatModal, showChatBox, }) => {
    var _a;
    const [transcript, setTranscript] = useState(null);
    const [trackerBrickSet, setTrackerBrickSet] = useState(null);
    // "Are we before or after the call"
    // We don't rely on call time because plenty of calls happen out of sync with the planned call (often late, sometimes early)
    // following is a heuristic to determine if the call happened or is in future
    const isFutureCall = !(transcript === null || transcript === void 0 ? void 0 : transcript.transcript.length);
    useEffect(() => {
        endpoints.getCallTranscript(call.id).then(setTranscript);
        endpoints.getTrackerBrickSetForLiveCall(call.id).then(setTrackerBrickSet);
    }, [call]);
    useEffect(() => {
        endpoints.getTrackerBrickSetForLiveCall(call.id).then(setTrackerBrickSet);
    }, []);
    const tabs = [
        { name: "Research" },
        { name: "Trackers" },
        { name: "Call details" },
        { name: "Transcript" },
    ];
    const updateCallTrackerBrickSet = (brickSet) => __awaiter(void 0, void 0, void 0, function* () {
        setTrackerBrickSet(brickSet);
        updateSelectedCall(yield endpoints.setCallTrackerBrickSet(call.id, brickSet.id));
    });
    const roleSwitcherRef = useRef(null);
    const transcriptEntries = useMemo(() => {
        if (transcript) {
            return transcript.transcript;
        }
        return [];
    }, [transcript]);
    const firstTranscriptMsec = ((_a = transcriptEntries[0]) === null || _a === void 0 ? void 0 : _a.startTime) || 0;
    return (_jsxs("div", Object.assign({ className: "w-full flex flex-col flex-1 overflow-y-auto relative" }, { children: [showChatBox && (_jsx("div", Object.assign({ className: "py-4 px-8 w-full" }, { children: _jsx(ChatBox, { call: call, defaultChatSource: CURRENT_DEAL, chatSources: CHAT_SOURCES }) }))), _jsxs(Tab.Group, Object.assign({ as: "div", className: "flex flex-col flex-1 min-h-0" }, { children: [_jsx("div", Object.assign({ className: "w-full pl-4 pr-8 h-[38px] justify-between items-center inline-flex sticky top-0 bg-white" }, { children: _jsx(Tab.List, Object.assign({ className: "bg-white justify-start items-center flex" }, { children: tabs.map((tab) => (_jsx(Tab, Object.assign({ className: ({ selected }) => cn("outline-none px-4 py-2 justify-start items-center flex rounded-tl-lg rounded-tr-lg border-blue-600", selected
                                    ? "border-b-2 font-bold"
                                    : "hover:bg-blue-100 font-normal"), onClick: () => {
                                    amplitudeInstance.track("Click Home Tab", {
                                        tabName: tab.name,
                                    });
                                } }, { children: _jsx("div", Object.assign({ className: cn("leading-snug") }, { children: tab.name })) }), tab.name))) })) })), _jsxs(Tab.Panels, Object.assign({ className: "flex flex-col grow w-full pt-8 bg-wds-gray-1" }, { children: [_jsx(Tab.Panel, { children: _jsx(PreCallPrep, { call: call }) }), _jsx(Tab.Panel, { children: isFutureCall ? (_jsx(AssignCallBrickset, { brickSet: trackerBrickSet, updateCallTrackerBrickSet: updateCallTrackerBrickSet })) : (_jsx("div", Object.assign({ className: "px-8" }, { children: _jsx(DataProcessingTab, { call: call, callNotes: notes }) }))) }), _jsx(Tab.Panel, { children: _jsx(CallDetailsPanel, { call: call, fetchUpdatedCalls: fetchUpdatedCalls }) }), _jsx(Tab.Panel, { children: _jsx(Transcript, { isLive: false, transcriptEntries: transcriptEntries, startTimeMsec: firstTranscriptMsec, speakerToRoleMap: new Map(), roleSwitcherRef: roleSwitcherRef, scrollToBottom: true, setScrollToBottom: () => { } }) })] }))] }))] })));
};
