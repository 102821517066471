var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { ExpandablePostCall } from "@/components/ui/expandable";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { awaitBrickExecution, makeBrickPreview } from "../common/endpoints";
import WaitingSpinner from "../waiting_spinner";
import { BrickScoreDisplay } from "./brick_score_display";
import { PreviewContextSelector, } from "./preview_context_selector";
const BrickPreviews = ({ brickId, newPrompt, brickName, contextType, selectedDataSources, selectedSourceBricks, selectedModel, scoringCriteria, selectedWebDataModel, suppressCitations, }) => {
    const [brickPreviews, setBrickPreviews] = useState(new Map());
    const [selectedTab, setSelectedTab] = useState("output");
    const [selectedContextOptions, setSelectedContextOptions] = useState([]);
    // 1. Get three most recent brick outputs for the brick
    // 2. Make a new brick (preview type) with the newPrompt
    // 3. Run the new brick on the three most recent output sources
    // 4. Wait for the updates to come in
    // 5. Display the diffs
    const brickOutputCallback = (brickOutput, idToName) => {
        setBrickPreviews((prev) => {
            var _a, _b;
            const newPreviews = new Map(prev);
            let callAccountName = "";
            let sourceId = 0;
            if (brickOutput.call_id) {
                callAccountName = "Call ";
                sourceId = brickOutput.call_id;
            }
            else if (brickOutput.account_id) {
                callAccountName = "Account ";
                sourceId = brickOutput.account_id;
            }
            else if (brickOutput.contact_id) {
                callAccountName = "Contact ";
                sourceId = brickOutput.contact_id;
            }
            callAccountName += (_a = idToName[sourceId]) !== null && _a !== void 0 ? _a : sourceId;
            const brickPreviewRow = (_b = newPreviews.get(callAccountName)) !== null && _b !== void 0 ? _b : {};
            const isPreview = brickOutput.research_block_type.includes("PREVIEW");
            const isScoring = brickOutput.research_block_type.includes("SCORING");
            if (!isScoring) {
                const update = isPreview
                    ? { newOutput: brickOutput }
                    : { oldOutput: brickOutput };
                const updatedRow = Object.assign(Object.assign({}, brickPreviewRow), update);
                newPreviews.set(callAccountName, updatedRow);
            }
            else {
                const update = isPreview
                    ? { newScoringOutput: brickOutput }
                    : { oldScoringOutput: brickOutput };
                const updatedRow = Object.assign(Object.assign({}, brickPreviewRow), update);
                newPreviews.set(callAccountName, updatedRow);
            }
            return newPreviews;
        });
    };
    const makePreviewMutation = useMutation({
        mutationFn: (params) => __awaiter(void 0, void 0, void 0, function* () {
            const contextParams = params.contextOption
                ? {
                    [params.contextOption.type === "account"
                        ? "accountId"
                        : params.contextOption.type === "call"
                            ? "callId"
                            : "contactId"]: Number.parseInt(params.contextOption.id),
                }
                : undefined;
            return yield makeBrickPreview(brickId, params.newPrompt, false, // force_refresh
            /* fromGallery= */ false, selectedDataSources, selectedSourceBricks, selectedModel, selectedWebDataModel, suppressCitations, contextParams, params.scoringCriteria);
        }),
        onSuccess: (previewLaunchedResponse) => __awaiter(void 0, void 0, void 0, function* () {
            const prevOptions = [...selectedContextOptions];
            Object.entries(previewLaunchedResponse.id_to_name).forEach(([id, name]) => {
                if (!prevOptions.some((option) => option.id === id.toString())) {
                    prevOptions.push({
                        id: id.toString(),
                        value: id.toString(),
                        label: name,
                        type: contextType,
                    });
                }
            });
            setSelectedContextOptions(prevOptions);
            const cb = (brickOutput) => brickOutputCallback(brickOutput, previewLaunchedResponse.id_to_name);
            previewLaunchedResponse.brick_outputs.forEach(cb);
            yield awaitBrickExecution(previewLaunchedResponse, cb);
        }),
        onError: (error) => {
            console.error("Error launching brick preview:", error);
        },
    });
    const handleContextOptionsChange = (newOptions) => {
        const newOption = newOptions.find((option) => !selectedContextOptions.some((existing) => existing.id === option.id));
        if (newOption && brickId) {
            makePreviewMutation.mutate({
                newPrompt: newPrompt !== null && newPrompt !== void 0 ? newPrompt : "",
                contextOption: newOption,
                selectedDataSources,
                selectedSourceBricks,
                selectedModel,
                scoringCriteria,
                selectedWebDataModel,
                suppressCitations,
            });
        }
        setSelectedContextOptions(newOptions);
    };
    useEffect(() => {
        if (!brickId)
            return;
        makePreviewMutation.mutate({
            newPrompt: newPrompt !== null && newPrompt !== void 0 ? newPrompt : "",
            selectedDataSources,
            selectedSourceBricks,
            selectedModel,
            scoringCriteria,
            selectedWebDataModel,
            suppressCitations,
        });
    }, [
        newPrompt,
        selectedDataSources,
        selectedSourceBricks,
        selectedModel,
        scoringCriteria,
        selectedWebDataModel,
        suppressCitations,
    ]);
    const tabs = [
        { label: "Compare Outputs", value: "output" },
        { label: "Compare Prompts", value: "prompt" },
    ];
    const renderHeader = (brickOutput, name) => {
        return (_jsxs("div", Object.assign({ className: "flex flex-row justify-start items-center gap-x-2.5" }, { children: [(brickOutput === null || brickOutput === void 0 ? void 0 : brickOutput.score_json) && (_jsx(BrickScoreDisplay, { scoring_color: brickOutput.score_json.score_color, rationale: brickOutput.score_json.rationale })), _jsx("span", { children: name })] })));
    };
    return (_jsxs("div", { children: [_jsx("div", Object.assign({ className: "flex flex-col justify-start items-start pb-6" }, { children: _jsxs("div", Object.assign({ className: "text-xl font-normal leading-none" }, { children: ["Preview of \"", brickName, "\""] })) })), _jsx(PreviewContextSelector, { setContextOptions: handleContextOptionsChange, contextOptions: selectedContextOptions, contextType: contextType }), _jsx(Tabs, Object.assign({ defaultValue: "output", onValueChange: (value) => setSelectedTab(value), className: "w-full border-bborder-wds-gray-3 pb-0.5 pt-4", variant: "underline" }, { children: _jsx("div", Object.assign({ className: "flex flex-row justify-between items-center" }, { children: _jsx(TabsList, Object.assign({ className: "border-none gap-x-6 mb-8 flex-shrink-0" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, variant: "underline" }, { children: tab.label }), tab.value))) })) })) })), _jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx("div", Object.assign({ className: "w-32 h-fit p-1 bg-wds-gray-2 rounded-2xl" }, { children: Array.from(brickPreviews.entries())
                            .filter(([callAccountName, example]) => {
                            return selectedContextOptions.some((option) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                                return option.id ===
                                    ((_m = ((_k = (_h = (_f = (_d = (_b = (_a = example.oldOutput) === null || _a === void 0 ? void 0 : _a.account_id) !== null && _b !== void 0 ? _b : (_c = example.oldOutput) === null || _c === void 0 ? void 0 : _c.call_id) !== null && _d !== void 0 ? _d : (_e = example.oldOutput) === null || _e === void 0 ? void 0 : _e.contact_id) !== null && _f !== void 0 ? _f : (_g = example.newOutput) === null || _g === void 0 ? void 0 : _g.account_id) !== null && _h !== void 0 ? _h : (_j = example.newOutput) === null || _j === void 0 ? void 0 : _j.call_id) !== null && _k !== void 0 ? _k : (_l = example.newOutput) === null || _l === void 0 ? void 0 : _l.contact_id)) === null || _m === void 0 ? void 0 : _m.toString());
                            });
                        })
                            .map(([callAccountName]) => {
                            const name = callAccountName.split(" ")[1];
                            return (_jsx("button", Object.assign({ onClick: () => {
                                    const element = document.getElementById(callAccountName);
                                    element === null || element === void 0 ? void 0 : element.scrollIntoView({ behavior: "smooth" });
                                }, className: "w-full text-left p-2 hover:bg-wds-gray-1 hover:font-semibold rounded-2xl truncate" }, { children: name }), callAccountName));
                        }) })), _jsx("div", Object.assign({ className: "flex-1 px-4 pb-4" }, { children: Array.from(brickPreviews.entries())
                            .filter(([callAccountName, example]) => {
                            return selectedContextOptions.some((option) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                                return option.id ===
                                    ((_m = ((_k = (_h = (_f = (_d = (_b = (_a = example.oldOutput) === null || _a === void 0 ? void 0 : _a.account_id) !== null && _b !== void 0 ? _b : (_c = example.oldOutput) === null || _c === void 0 ? void 0 : _c.call_id) !== null && _d !== void 0 ? _d : (_e = example.oldOutput) === null || _e === void 0 ? void 0 : _e.contact_id) !== null && _f !== void 0 ? _f : (_g = example.newOutput) === null || _g === void 0 ? void 0 : _g.account_id) !== null && _h !== void 0 ? _h : (_j = example.newOutput) === null || _j === void 0 ? void 0 : _j.call_id) !== null && _k !== void 0 ? _k : (_l = example.newOutput) === null || _l === void 0 ? void 0 : _l.contact_id)) === null || _m === void 0 ? void 0 : _m.toString());
                            });
                        })
                            .map(([callAccountName, example]) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h;
                            const cells = [
                                {
                                    title: renderHeader(example.oldOutput, selectedTab === "output" ? "Old Output" : "Old Prompt"),
                                    content: selectedTab === "output"
                                        ? ((_b = (_a = example.oldOutput) === null || _a === void 0 ? void 0 : _a.markdown) !== null && _b !== void 0 ? _b : (_jsx(WaitingSpinner, { text: "Running prompt..." })))
                                        : (_c = example.oldOutput) === null || _c === void 0 ? void 0 : _c.full_prompt,
                                    scoringOutput: (_d = example.oldScoringOutput) === null || _d === void 0 ? void 0 : _d.markdown,
                                },
                                {
                                    title: renderHeader(example.newOutput, selectedTab === "output" ? "New Output" : "New Prompt"),
                                    content: selectedTab === "output"
                                        ? ((_f = (_e = example.newOutput) === null || _e === void 0 ? void 0 : _e.markdown) !== null && _f !== void 0 ? _f : (_jsx(WaitingSpinner, { text: "Running prompt..." })))
                                        : (_g = example.newOutput) === null || _g === void 0 ? void 0 : _g.full_prompt,
                                    scoringOutput: (_h = example.newScoringOutput) === null || _h === void 0 ? void 0 : _h.markdown,
                                },
                            ];
                            return (_jsxs("div", Object.assign({ id: callAccountName, className: "border rounded-lg p-4 mb-4" }, { children: [_jsx("h3", Object.assign({ className: "font-semibold text-xl mb-4" }, { children: callAccountName })), _jsx("div", Object.assign({ className: "grid grid-cols-2 gap-4" }, { children: cells.map((cell, index) => (_jsxs("div", Object.assign({ className: "flex flex-col gap-y-2" }, { children: [_jsx("h4", Object.assign({ className: "font-medium" }, { children: cell.title })), _jsx("div", Object.assign({ className: "relative group" }, { children: _jsxs("div", Object.assign({ className: "p-4 bg-white rounded-lg" }, { children: [_jsx(ExpandablePostCall, { alwaysShowExpandButton: true, showLessContent: cell.content, showMoreContent: cell.content, lineClampClass: "line-clamp-6" }), cell.content && (_jsxs(Button, Object.assign({ variant: "invertedBlack", onClick: () => navigator.clipboard.writeText(cell.content), className: "absolute bottom-2 right-2 hover:bg-gray-200 border border-wds-black rounded-lg\n                                text-wds-black\n                                opacity-0 group-hover:opacity-100 transition-opacity duration-200" }, { children: ["Copy", " ", selectedTab === "output" ? "Output" : "Prompt"] })))] })) }))] }), index))) }))] }), callAccountName));
                        }) }))] }))] }));
};
export default BrickPreviews;
