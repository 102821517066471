var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger, } from "@/components/ui/popover";
import { EllipsisVerticalIcon, PencilIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/solid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import BrickEditDialog from "./bricks/brick_edit_dialog";
import { addPreCallPrepBlockToTemplate, getActiveTenant, getAllBricks, getPreCallPrepBlocksForTemplate, getPreCallPrepTemplate, removePreCallPrepBlockFromTemplate, reorderPreCallPrepBlocks, updatePreCallPrepTemplate, } from "./common/endpoints";
import DeleteModal from "./delete_modal";
import AddBriefingBlockModal from "./pre_call_prep/add_briefing_block_modal";
const EditPreCallTemplate = (props) => {
    var _a, _b, _c;
    const { templateId, refetch } = props;
    const location = useLocation();
    const [isEditing, setIsEditing] = useState(false);
    const [editingBlockId, setEditingBlockId] = useState((_a = location.state) === null || _a === void 0 ? void 0 : _a.editingBlockId);
    const [showAddBlockModal, setShowAddBlockModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [blockToBeDeleted, setBlockToBeDeleted] = useState();
    const [creatingBlock, setCreatingBlock] = useState((_c = (_b = location.state) === null || _b === void 0 ? void 0 : _b.creatingBlock) !== null && _c !== void 0 ? _c : false);
    const queryClient = useQueryClient();
    const { data: template, isLoading: templateLoading, isError: templateError, } = useQuery({
        queryKey: ["getPreCallPrepTemplate", templateId],
        queryFn: () => getPreCallPrepTemplate(templateId),
    });
    const { data: allBricksResponse, isLoading: bricksLoading, isError: bricksError, } = useQuery({
        queryKey: ["getAllBricks"],
        queryFn: getAllBricks,
    });
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const { data: blocks, isLoading: blocksLoading, isError: blocksError, } = useQuery({
        queryFn: () => getPreCallPrepBlocksForTemplate(templateId),
        queryKey: ["getPreCallPrepBlocksForTemplate", templateId],
    });
    const name = template === null || template === void 0 ? void 0 : template.name;
    const allBricks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.bricks;
    const brickLinks = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.brick_links;
    const linkAvailability = allBricksResponse === null || allBricksResponse === void 0 ? void 0 : allBricksResponse.link_availability;
    const updateTemplateNameMutation = useMutation({
        mutationFn: (data) => updatePreCallPrepTemplate(templateId, data.name),
        onSuccess: () => __awaiter(void 0, void 0, void 0, function* () {
            yield refetch();
        }),
        onSettled: () => {
            setIsEditing(false);
        },
    });
    const removeBockMutation = useMutation({
        mutationFn: (data) => removePreCallPrepBlockFromTemplate(templateId, data.id),
        onSuccess: () => __awaiter(void 0, void 0, void 0, function* () {
            queryClient.invalidateQueries({
                queryKey: ["getPreCallPrepBlocksForTemplate", templateId],
            });
            yield refetch();
        }),
        onSettled: () => {
            setBlockToBeDeleted(undefined);
        },
    });
    const reOrderBlocksMutation = useMutation({
        mutationFn: (data) => reorderPreCallPrepBlocks(templateId, data.order),
    });
    const onDragEnd = (result) => __awaiter(void 0, void 0, void 0, function* () {
        if (!result.destination || !blocks)
            return;
        const reOrderedBlocks = [...blocks];
        const [removedBlock] = reOrderedBlocks.splice(result.source.index, 1);
        reOrderedBlocks.splice(result.destination.index, 0, removedBlock);
        queryClient.setQueryData(["getPreCallPrepBlocksForTemplate", templateId], reOrderedBlocks);
        yield reOrderBlocksMutation.mutateAsync({
            order: reOrderedBlocks.map((block) => block.id),
        });
    });
    return (_jsxs("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-4 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch pb-4 border-b border-wds-gray-3 flex-col justify-start items-start gap-4 flex" }, { children: [_jsxs("div", Object.assign({ className: "self-stretch h-6 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-bold leading-normal" }, { children: "Details" })), !isEditing ? (_jsxs(Button, Object.assign({ variant: "ghost", size: "sm", onClick: () => {
                                    setIsEditing(true);
                                }, className: "gap-2" }, { children: [_jsx("div", Object.assign({ className: "text-wds-blue-3 font-bold leading-tight" }, { children: "Edit" })), _jsx(PencilIcon, { className: "text-wds-blue-3 w-4 h-4 relative" })] }))) : null] })), _jsxs("div", Object.assign({ className: "w-full flex-col justify-start items-start gap-1 flex" }, { children: [_jsx("div", Object.assign({ className: "text-wds-gray-5 text-xs font-medium leading-none" }, { children: "Name" })), isEditing ? (_jsx("input", { type: "text", value: name !== null && name !== void 0 ? name : "", onChange: (e) => template
                                    ? queryClient.setQueryData(["getPreCallPrepTemplate", templateId], Object.assign(Object.assign({}, template), { name: e.target.value }))
                                    : null, className: "w-full h-9 px-1 bg-white shadow border-t rounded-lg border-wds-gray-3 justify-start items-center gap-1 inline-flex", onBlur: () => __awaiter(void 0, void 0, void 0, function* () {
                                    yield updateTemplateNameMutation.mutateAsync({
                                        name: (template === null || template === void 0 ? void 0 : template.name) || "",
                                    });
                                }) })) : (_jsx("div", Object.assign({ className: "text-black leading-snug" }, { children: name })))] }))] })), _jsxs("div", Object.assign({ className: "self-stretch h-6 justify-between items-center inline-flex" }, { children: [_jsx("div", Object.assign({ className: "text-black text-base font-bold leading-normal" }, { children: "Research Blocks" })), _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", onClick: () => {
                            setShowAddBlockModal(true);
                        }, className: "gap-2" }, { children: [_jsx("div", Object.assign({ className: "text-wds-blue-3 font-bold leading-tight" }, { children: "Add" })), _jsx(PlusIcon, { className: "text-wds-blue-3 w-4 h-4 relative" })] }))] })), _jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "research-blocks" }, { children: ({ droppableProps, innerRef, placeholder }) => {
                        return (_jsxs("div", Object.assign({ className: "self-stretch flex-col justify-start items-start gap-2 flex" }, droppableProps, { ref: innerRef }, { children: [blocks === null || blocks === void 0 ? void 0 : blocks.map((block, index) => (_jsx(Draggable, Object.assign({ draggableId: block.id.toString(), index: index }, { children: ({ draggableProps, dragHandleProps, innerRef }) => {
                                        return (_jsxs("div", Object.assign({ className: "relative self-stretch pl-4 pr-2 py-4 bg-white rounded-lg border border-neutral-200 justify-end items-start gap-2 inline-flex" }, draggableProps, dragHandleProps, { ref: innerRef }, { children: [_jsx("div", Object.assign({ className: "grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex" }, { children: _jsx("div", Object.assign({ className: "self-stretch text-neutral-950 font-medium leading-tight" }, { children: block.name })) })), _jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-wds-blue-1" }, { children: _jsx(EllipsisVerticalIcon, { className: "w-4 h-4" }) })), _jsx(PopoverContent, Object.assign({ className: "p-1" }, { children: _jsxs("div", Object.assign({ className: "flex-col inline-flex place-items-start" }, { children: [_jsxs(Button, Object.assign({ variant: "ghost", size: "sm", onClick: () => {
                                                                            setEditingBlockId(block.id);
                                                                        }, className: "gap-2 text-wds-gray-6 rounded-none" }, { children: [_jsx(PencilIcon, { className: "w-4 h-4 relative text-wds-blue-3" }), "Edit block configuration"] })), _jsxs(Button, Object.assign({ variant: "ghost", size: "sm", onClick: () => {
                                                                            setBlockToBeDeleted(block);
                                                                            setShowDeleteModal(true);
                                                                        }, className: "gap-2 text-wds-gray-6 rounded-none" }, { children: [_jsx(TrashIcon, { className: "w-4 h-4 relative text-destructive" }), "Remove from this template"] }))] })) }))] })] })));
                                    } }), block.id))), placeholder] })));
                    } })) })), showAddBlockModal && !creatingBlock && (_jsx(AddBriefingBlockModal, { researchBlockType: props.researchBlockType, onClose: () => setShowAddBlockModal(false), onAddBriefingBlocks: (blockIds) => __awaiter(void 0, void 0, void 0, function* () {
                    yield Promise.all(blockIds.map((blockId) => addPreCallPrepBlockToTemplate(templateId, blockId)));
                    queryClient.invalidateQueries({
                        queryKey: ["getPreCallPrepBlocksForTemplate", templateId],
                    });
                    yield refetch();
                    setShowAddBlockModal(false);
                }), allBlocks: allBricks !== null && allBricks !== void 0 ? allBricks : [], existingBlockIds: (blocks === null || blocks === void 0 ? void 0 : blocks.map((block) => block.id)) || [], open: showAddBlockModal && !creatingBlock, createCallback: () => setCreatingBlock(true) })), activeTenant && (editingBlockId || creatingBlock) && (_jsx(BrickEditDialog, { creatingBrick: editingBlockId || creatingBlock, setCreatingBrick: (open) => {
                    if (open)
                        return;
                    setEditingBlockId(undefined);
                    setCreatingBlock(false);
                }, selectedBrickId: editingBlockId, selectedType: props.researchBlockType, selectedTypeLabel: "Research Block" // TODO
                , allBricks: allBricks !== null && allBricks !== void 0 ? allBricks : [], brickLinks: brickLinks !== null && brickLinks !== void 0 ? brickLinks : [], linkAvailability: linkAvailability !== null && linkAvailability !== void 0 ? linkAvailability : [], tenant: activeTenant })), blockToBeDeleted && showDeleteModal && (_jsx(DeleteModal, { deleteModal: showDeleteModal, setShowDeleteModal: setShowDeleteModal, title: `Remove block ${blockToBeDeleted.name}`, description: "Are you sure you want to remove this block from the template?", deleteText: "Remove", deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield removeBockMutation.mutateAsync({ id: blockToBeDeleted.id });
                }) }))] })));
};
export default EditPreCallTemplate;
