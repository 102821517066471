var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { flexRender } from "@tanstack/react-table";
import { useMemo } from "react";
import { z } from "zod";
import { queryMeetingTemplates } from "../common/endpoints";
import { WiserBackendQueryOperator, } from "../common/query_builder/types";
import { getTeams } from "./endpoints";
export const PARENT_TEAM = "Parent team";
export const INTERNAL_MEETING_TEMPLATE_OVERRIDE = "Internal meeting template override";
export const EXTERNAL_MEETING_TEMPLATE_OVERRIDE = "External meeting template override";
export const APPLICABLE_MEETING_TEMPLATES = "Applicable meeting templates";
export const NAME = "Name";
export const addSearchParamsToQuery = (searchParams, tableName, debouncedQuery) => {
    const copySearchParams = new URLSearchParams(searchParams.toString());
    const filter = copySearchParams.has("filter")
        ? JSON.parse(copySearchParams.get("filter"))
        : undefined;
    const order_by = copySearchParams.has("order_by")
        ? JSON.parse(copySearchParams.get("order_by"))
        : undefined;
    const json_query = {
        table: tableName,
    };
    if (filter) {
        json_query.structured_filter = filter;
    }
    if (order_by) {
        json_query.order_by_v2 = order_by;
    }
    if (debouncedQuery) {
        json_query.structured_filter = {
            where_condition: {
                operator: WiserBackendQueryOperator.AND,
                subconditions: [
                    {
                        where_condition: {
                            operator: WiserBackendQueryOperator.OR,
                            subconditions: [
                                {
                                    where_condition: {
                                        operator: WiserBackendQueryOperator.LIKE,
                                        field: "name",
                                        value: debouncedQuery,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        if (filter) {
            json_query.structured_filter.where_condition.subconditions.push(filter);
        }
    }
    return JSON.stringify(json_query);
};
export function getColumns(onRowClick) {
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: NAME,
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: row.original.name })) })), _jsx(TooltipContent, { children: row.original.name })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "parent_team_name",
            accessorKey: "parent_team_name",
            header: PARENT_TEAM,
            cell: ({ row, cell }) => flexRender(row.original.parent_team_name, cell.getContext()),
            accessorFn: (row) => row.parent_team_name,
        },
        {
            id: "actions",
            accessorKey: "actions",
            header: "Update team",
            cell: ({ row }) => (_jsx(Button, Object.assign({ variant: "link", size: "icon", onClick: () => onRowClick(row.original) }, { children: _jsx(ChevronRightIcon, { className: "w-5 h-5" }) }))),
            enableHiding: false,
        },
        {
            id: "default_internal_meeting_template_name",
            accessorKey: "default_internal_meeting_template_name",
            header: INTERNAL_MEETING_TEMPLATE_OVERRIDE,
            cell: ({ row, cell }) => flexRender(row.original.default_internal_meeting_template_name, cell.getContext()),
            accessorFn: (row) => row.default_internal_meeting_template_name,
        },
        {
            id: "default_external_meeting_template_name",
            accessorKey: "default_external_meeting_template_name",
            header: EXTERNAL_MEETING_TEMPLATE_OVERRIDE,
            cell: ({ row, cell }) => flexRender(row.original.default_external_meeting_template_name, cell.getContext()),
            accessorFn: (row) => row.default_external_meeting_template_name,
        },
        {
            id: "applicable_meeting_template_names",
            accessorKey: "applicable_meeting_template_names",
            header: APPLICABLE_MEETING_TEMPLATES,
            cell: ({ row, cell }) => {
                var _a;
                return flexRender((_a = row.original.applicable_meeting_template_names) === null || _a === void 0 ? void 0 : _a.join(", "), cell.getContext());
            },
            accessorFn: (row) => row.applicable_meeting_template_names,
        },
    ];
}
export const FIELDS = [
    {
        id: "name",
        name: NAME,
        type: "string",
    },
    {
        id: "parent_team_name",
        name: "Parent team name",
        type: "string",
    },
    {
        id: "default_internal_meeting_template_id",
        name: INTERNAL_MEETING_TEMPLATE_OVERRIDE,
        type: "reference",
    },
    {
        id: "default_external_meeting_template_id",
        name: EXTERNAL_MEETING_TEMPLATE_OVERRIDE,
        type: "reference",
    },
];
export const SORTABLE_FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
];
export const teamsFetcher = (queryClient, userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    const teamsQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return getTeams(JSON.stringify({
                table: "Team",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["getTeams", cursor, userQuery, ids],
    });
    const results = teamsQueryResponse.data.map((team) => {
        return {
            label: team.name,
            value: team.id.toString(),
        };
    });
    results.push({
        label: "None",
        value: "None",
    });
    return { results };
});
export const templateFetcherWithoutNone = (userQuery, ids, cursor, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    // Fetch the templates without appending "None" as an option, this is generally to be used when doing "create"
    // Since "None" is a placeholder for "reset" and the concept of "None" is only applicable to "updates"
    if (!queryClient) {
        return {
            results: [],
            total: 0,
        };
    }
    const templateQueryResponse = yield queryClient.fetchQuery({
        queryFn: () => {
            const filterConditions = [
                {
                    where_condition: {
                        field: "name",
                        operator: WiserBackendQueryOperator.LIKE,
                        value: userQuery,
                    },
                },
            ];
            if (ids) {
                filterConditions.push({
                    where_condition: {
                        field: "id",
                        operator: WiserBackendQueryOperator.IN,
                        value: ids,
                    },
                });
            }
            return queryMeetingTemplates(JSON.stringify({
                table: "MeetingTemplate",
                order_by_fields: ["name"],
                structured_filter: {
                    where_condition: {
                        operator: WiserBackendQueryOperator.AND,
                        subconditions: filterConditions,
                    },
                },
            }), 1, 200);
        },
        queryKey: ["queryMeetingTemplates", cursor, userQuery, ids],
    });
    const results = templateQueryResponse.data.map((template) => {
        return {
            label: template.name,
            value: template.id.toString(),
        };
    });
    return {
        results,
        total: templateQueryResponse.data.length,
    };
});
export const templateFetcher = (queryClient, userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield templateFetcherWithoutNone(userQuery, ids, cursor, queryClient);
    resp.results.push({
        label: "None",
        value: "None",
    });
    return resp;
});
export const TEAM_SCHEMA = z.object({
    [NAME]: z.string().min(1),
    [PARENT_TEAM]: z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
    [INTERNAL_MEETING_TEMPLATE_OVERRIDE]: z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
    [EXTERNAL_MEETING_TEMPLATE_OVERRIDE]: z
        .object({
        label: z.string().min(1),
        value: z.string().min(1),
    })
        .optional(),
    [APPLICABLE_MEETING_TEMPLATES]: z
        .array(z.object({
        label: z.string().min(1),
        value: z.string().min(1),
    }))
        .optional(),
});
export const checkNameAlreadyExists = (name, queryClient) => __awaiter(void 0, void 0, void 0, function* () {
    const json_query = JSON.stringify({
        table: "Team",
        order_by_fields: ["name"],
        structured_filter: {
            where_condition: {
                field: "name",
                operator: WiserBackendQueryOperator.EQUALS,
                value: name,
            },
        },
    });
    const response = yield queryClient.fetchQuery({
        queryKey: ["getTeams", name],
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () { return yield getTeams(json_query); }),
    });
    return response.data.length > 0;
});
export const useFormFieldMeta = (queryClient) => {
    const formFieldMeta = useMemo(() => ({
        Name: {
            type: "text",
            placeholder: "Name",
            defaultValue: "",
            required: true,
            onBlur: (event, form) => __awaiter(void 0, void 0, void 0, function* () {
                const nameExists = yield checkNameAlreadyExists(event.target.value, queryClient);
                if (nameExists) {
                    form.setError("Name", {
                        type: "validate",
                        message: "This name is already in use",
                    });
                }
                else {
                    form.clearErrors("Name");
                }
            }),
        },
        "Parent team": {
            type: "async_search_select",
            placeholder: "Parent team",
            defaultValue: undefined,
            optionsFetcher: (userQuery) => teamsFetcher(queryClient, userQuery),
        },
        "Applicable meeting templates": {
            type: "async_search_multi_select",
            placeholder: "Applicable meeting templates",
            defaultValue: [],
            optionsFetcher: (userQuery) => templateFetcherWithoutNone(userQuery, undefined, undefined, queryClient),
        },
    }), []);
    return formFieldMeta;
};
