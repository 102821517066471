import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { ChevronRightIcon, MagnifyingGlassIcon, } from "@heroicons/react/20/solid";
import { useEffect, useMemo, useState } from "react";
const IntegrationSelector = (props) => {
    const [searchText, setSearchText] = useState("");
    const [selectedItem, setSelectedItem] = useState(props.defaultSelected);
    const getIntegrationLabel = (integrationName) => {
        var _a;
        return (_a = props.integrations.find((i) => i.name === integrationName)) === null || _a === void 0 ? void 0 : _a.label;
    };
    const filteredItems = useMemo(() => {
        return props.integrations
            .filter((i) => i.name === selectedItem ||
            i.label.toLowerCase().includes(searchText.toLowerCase()))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [props.integrations, selectedItem, searchText]);
    useEffect(() => {
        if (selectedItem || filteredItems.length <= 0)
            return;
        handleItemClick(filteredItems[0].name);
    });
    const handleItemClick = (item) => {
        setSelectedItem(item);
        props.onSelect(item);
    };
    const renderIntegration = (integration, selected) => {
        return (_jsxs("div", Object.assign({ className: cn("flex flex-row items-center self-stretch justify-between", "rounded-full px-4 py-2", selected ? "bg-blue-100" : "") }, { children: [_jsxs("div", Object.assign({ className: "flex items-center gap-2" }, { children: [_jsx("img", { src: integration.icon_url, className: "h-6 w-6" }), getIntegrationLabel(integration.name)] })), selected && _jsx(ChevronRightIcon, { className: "h-5 w-5 shrink-0" })] }), integration.name));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", Object.assign({ className: cn("flex flex-row px-4 py-2 items-center gap-1 self-stretch rounded-full bg-gray-100") }, { children: [_jsx(MagnifyingGlassIcon, { className: "h-5 w-5 text-gray-400 shrink-0" }), _jsx("input", { type: "text", placeholder: "Search", value: searchText, onChange: (e) => setSearchText(e.target.value), className: cn("w-full flex-grow font-normal text-sm bg-gray-100", "focus:outline-none border-0 focus:ring-0") })] })), _jsx("div", Object.assign({ className: "flex flex-col gap-1" }, { children: filteredItems.map((item) => (_jsx("div", Object.assign({ className: "cursor-pointer hover:bg-blue-100 hover:rounded-full hover:outline-none", onClick: () => handleItemClick(item.name) }, { children: renderIntegration(item, item.name === selectedItem) }), item.name))) }))] })));
};
export default IntegrationSelector;
