var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { ArrowTopRightOnSquareIcon, DocumentTextIcon, HandThumbDownIcon, HandThumbUpIcon, LightBulbIcon, XMarkIcon, } from "@heroicons/react/24/outline";
import DOMPurify from "dompurify";
import { useContext, useMemo } from "react";
import tw from "tailwind-styled-components";
import { UserContext } from "../App";
import { LoadingEllipsis } from "../common/loading_ellipsis";
import ChatSourceMenu, { CHAT_SOURCES } from "./chat_source_menu";
import WiserAvatar from "./wiser_avatar";
// Creates a bounding box for chat answers.
const ChatCardContainer = tw.div `
  mx-4
  self-stretch
`;
const ChatMessageActionButtonContainer = tw.div `
  flex
  w-8 h-8
  justify-center
  items-center
  border border-solid
  border-wds-gray-3
  rounded-full
  bg-white
  hover:bg-wds-gray-2
`;
const ChatMessageUpvoteButton = ({ cardContent, userLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, }) => {
    const defaultUpvoted = useMemo(() => {
        return (userLlmOutputFeedback && userLlmOutputFeedback.user_feedback === "Upvote");
    }, [userLlmOutputFeedback]);
    return (_jsx("button", Object.assign({ onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            // This computes whether the user click results in an upvote, or neutral
            // If there is no original feedback, or if the feedback was Downvote, then
            // this click results in an upvote.
            // If the original feedback was Upvote already, then this will toggle it off (neutral)
            const upvoted = !userLlmOutputFeedback ||
                userLlmOutputFeedback.user_feedback === "Downvote";
            if (!cardContent.provenanceLogId)
                return;
            if (upvoted) {
                yield updateLlmOutputFeedbackMutation.mutateAsync({
                    provenanceLogId: cardContent.provenanceLogId,
                    userFeedback: "Upvote",
                });
            }
            else {
                yield deleteLlmOutputFeedbackMutation.mutateAsync({
                    provenanceLogId: cardContent.provenanceLogId,
                });
            }
        }) }, { children: _jsx(ChatMessageActionButtonContainer, Object.assign({ className: cn(defaultUpvoted ? "bg-wds-gray-2" : "") }, { children: _jsx(HandThumbUpIcon, { className: cn("w-5 h-5 shrink-0", defaultUpvoted ? "text-green-600" : "text-wds-gray-5") }) })) })));
};
const ChatMessageDownvoteButton = ({ cardContent, userLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, }) => {
    const defaultDownvoted = useMemo(() => {
        return (userLlmOutputFeedback &&
            userLlmOutputFeedback.user_feedback === "Downvote");
    }, [userLlmOutputFeedback]);
    return (_jsx("button", Object.assign({ onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            // This computes whether the user click results in an downvote, or neutral
            // If there is no original feedback, or if the feedback was upvote, then
            // this click results in an downvote.
            // If the original feedback was Downvote already, then this will toggle it off (neutral)
            const downvoted = !userLlmOutputFeedback ||
                userLlmOutputFeedback.user_feedback === "Upvote";
            if (!cardContent.provenanceLogId)
                return;
            if (downvoted) {
                yield updateLlmOutputFeedbackMutation.mutateAsync({
                    provenanceLogId: cardContent.provenanceLogId,
                    userFeedback: "Downvote",
                });
            }
            else {
                yield deleteLlmOutputFeedbackMutation.mutateAsync({
                    provenanceLogId: cardContent.provenanceLogId,
                });
            }
        }) }, { children: _jsx(ChatMessageActionButtonContainer, Object.assign({ className: cn(defaultDownvoted ? "bg-wds-gray-2" : "") }, { children: _jsx(HandThumbDownIcon, { className: cn("w-5 h-5 shrink-0", defaultDownvoted ? "text-red-700" : "text-wds-gray-5") }) })) })));
};
const ChatMessageFeedbackButtons = ({ cardContent, userLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, }) => {
    return (_jsxs("div", Object.assign({ className: "flex gap-2 items-start self-end" }, { children: [_jsx(ChatMessageUpvoteButton, { cardContent: cardContent, userLlmOutputFeedback: userLlmOutputFeedback, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation }), _jsx(ChatMessageDownvoteButton, { cardContent: cardContent, userLlmOutputFeedback: userLlmOutputFeedback, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation })] })));
};
const Message = ({ message, addUserMessageToChatAndRespond, userLlmOutputFeedback, updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation, onMessageDismiss, }) => {
    const { userPicture } = useContext(UserContext);
    const animationStyle = { animationDuration: "0.3s" };
    let content = null;
    let badge = null;
    if (message.sender === "User") {
        badge = (_jsx("img", { className: "w-6 h-6 rounded-full border", src: userPicture, alt: "User", style: { width: "24px", height: "24px" } }));
    }
    else {
        badge = _jsx(WiserAvatar, {});
    }
    if (message.sender === "User" || message.messageType === "Message") {
        content = (_jsx("div", Object.assign({ className: "text-wds-gray-6 font-bold mx-4" }, { children: message.messageContent })));
    }
    else if (message.messageType === "Loading") {
        content = LoadingEllipsis();
    }
    else if (message.messageType === "Card" ||
        message.messageType === "ChatProgress") {
        let documentLinkTable = null;
        if (message.card.documentSources) {
            documentLinkTable = (_jsx("div", Object.assign({ className: "border border-wds-gray-3 rounded-md w-full" }, { children: message.card.documentSources.map((doc, index) => (_jsxs("div", Object.assign({ className: cn("flex items-center justify-between p-2 w-full text-wds-gray-4 hover:text-wds-gray-7 hover:bg-wds-gray-1", index > 0 ? "border-t" : "") }, { children: [_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx(DocumentTextIcon, { className: "w-6 h-6 mr-2" }) })), _jsxs("a", Object.assign({ href: doc.url, target: "_blank", className: "flex flex-row hover:underline justify-between items-center w-full", rel: "noreferrer" }, { children: [_jsx("span", Object.assign({ className: "text-black" }, { children: doc.title })), _jsx(ArrowTopRightOnSquareIcon, { className: "w-6 h-6" })] }))] }), index))) })));
        }
        if (message.messageType === "ChatProgress") {
            content = (_jsxs("div", Object.assign({ className: "w-full" }, { children: [_jsxs("div", Object.assign({ className: "w-full text-wds-gray-5 pb-2 items-center" }, { children: ["Searching \"", message.card.name, "\""] })), documentLinkTable, _jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(LoadingEllipsis, {}) }))] })));
        }
        else {
            let sanitizedHtml = DOMPurify.sanitize(message.card.html);
            // Edit any anchor refs to open in a new tab
            sanitizedHtml = sanitizedHtml.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" ');
            content = (_jsx(ChatCardContainer, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-2 grow" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-4 self-stretch items-start justify-between" }, { children: [_jsx("h3", { children: message.card.name }), _jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [message.card.provenanceLogId && (_jsx(ChatMessageFeedbackButtons, { cardContent: message.card, userLlmOutputFeedback: userLlmOutputFeedback, updateLlmOutputFeedbackMutation: updateLlmOutputFeedbackMutation, deleteLlmOutputFeedbackMutation: deleteLlmOutputFeedbackMutation })), onMessageDismiss && (_jsxs(_Fragment, { children: [_jsx(Separator, { orientation: "vertical", className: "h-auto bg-wds-gray-3" }), _jsx(Button, Object.assign({ variant: "ghost", className: "p-0 h-auto hover:bg-transparent text-wds-gray-5", onClick: onMessageDismiss }, { children: _jsx(XMarkIcon, { className: "w-6 h-6 shrink-0" }) }))] }))] }))] })), documentLinkTable, _jsx("div", { className: "self-stretch", dangerouslySetInnerHTML: {
                                __html: sanitizedHtml,
                            } }), message.card.name !== "Answer that question" &&
                            message.card.source &&
                            CHAT_SOURCES.map((chatSource) => chatSource.id).includes(message.card.source) &&
                            addUserMessageToChatAndRespond && (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-1 self-end" }, { children: [_jsx("span", Object.assign({ className: "text-wds-gray-5 text-xs font-medium" }, { children: "Source:" })), _jsx(ChatSourceMenu, { selectedSource: CHAT_SOURCES.find((chatSource) => chatSource.id === message.card.source), setSelectedSource: (chatSource) => {
                                        addUserMessageToChatAndRespond(message.card.name, chatSource.id);
                                    }, renderedInCard: true })] })))] })) }));
        }
        badge = _jsx(WiserAvatar, {});
    }
    else if (message.messageType === "Recommendation") {
        badge = _jsx(WiserAvatar, {});
        content = (_jsxs(ChatCardContainer, { children: [_jsx("div", Object.assign({ className: "flex gap-2 items-start border-b border-gray-300" }, { children: _jsxs("div", Object.assign({ className: "flex pb-4 gap-1 flex-col items-start flex-wrap" }, { children: [_jsxs("div", Object.assign({ className: "flex py-1 px-2 gap-1 border border-yellow-200 bg-yellow-100 rounded-full" }, { children: [_jsx("div", Object.assign({ className: "self-center" }, { children: _jsx(LightBulbIcon, { className: "h-4 w-4 text-yellow-700" }) })), _jsx("span", Object.assign({ className: "text-xs text-yellow-800" }, { children: "RECOMMENDATION" }))] })), _jsx("span", Object.assign({ className: "font-bold text-base text-black" }, { children: message.messageTitle }))] })) })), _jsx("div", { className: "pt-4 self-stretch", dangerouslySetInnerHTML: {
                        __html: DOMPurify.sanitize(message.messageContent),
                    } })] }));
    }
    return (_jsxs("div", Object.assign({ className: cn("flex justify-start items-start gap-2 my-4", "slide-in-animation"), style: animationStyle }, { children: [badge, _jsx("div", Object.assign({ className: "flex flex-col items-start gap-1 w-full" }, { children: content }))] })));
};
export default Message;
