import { useQuery } from "@tanstack/react-query";
import { getAppIntegrationData } from "./endpoints";
export const useAppIntegrationData = (tenantId) => {
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", tenantId],
        queryFn: getAppIntegrationData,
        staleTime: Number.POSITIVE_INFINITY,
    });
    return {
        appIntegrationData,
    };
};
