var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import TagsDisplay from "@/components/ui/tags_display";
import { CameraIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../delete_modal";
import { DeleteIcon, ScissorsIcon, VideoCameraIcon, } from "../icons/editor_icons";
import WaitingSpinner from "../waiting_spinner";
const VideoThumbnail = ({ id, title, duration, thumbnailUrl, deleteVideo, isClip, }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    return (_jsxs(Link, Object.assign({ className: "group w-80 bg-white border-2 border-transparent rounded-lg shadow-md hover:border-wds-blue-3 cursor-pointer", to: `/library/video/${id}` }, { children: [_jsxs("div", Object.assign({ className: "relative" }, { children: [thumbnailUrl ? (_jsx("img", { src: thumbnailUrl, alt: "Video Thumbnail", className: "rounded-lg w-full h-40" })) : (_jsx("div", Object.assign({ className: "rounded-lg w-full h-40 bg-gray-200 flex items-center justify-center" }, { children: _jsx(CameraIcon, { className: "w-10 h-10 text-gray-400" }) }))), _jsxs("div", Object.assign({ className: "absolute bottom-2 left-2 h-7 pl-2 pr-2.5 py-1.5 bg-wds-blue-4 text-white rounded-full inline-flex items-center gap-1" }, { children: [_jsx(ClockIcon, { className: "w-4 h-4 fond-bold" }), duration > 60 ? (_jsxs("span", { children: [Math.round(duration / 60), " min"] })) : (_jsxs("span", { children: [Math.round(duration), " sec"] }))] })), _jsxs("div", Object.assign({ className: "absolute bottom-2 right-2 h-7 pl-2 pr-2.5 py-1.5 bg-wds-blue-4 text-white rounded-full inline-flex items-center gap-1" }, { children: [isClip ? _jsx(ScissorsIcon, {}) : _jsx(VideoCameraIcon, {}), isClip ? _jsx("span", { children: "Clip" }) : _jsx("span", { children: "Full Video" })] })), _jsx("div", Object.assign({ className: "absolute top-2 right-2 flex flex-col gap-2 opacity-0 group-hover:opacity-100" }, { children: deleteVideo && (_jsx(Button, Object.assign({ variant: "invertedRed", className: "rounded-lg gap-2", onClick: (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowDeleteConfirmation(true);
                            }, disabled: isDeleting }, { children: isDeleting ? (_jsx(WaitingSpinner, { text: "" })) : (_jsxs(_Fragment, { children: [_jsx(DeleteIcon, {}), _jsx("span", Object.assign({ className: "font-semibold" }, { children: "Delete" }))] })) }))) }))] })), _jsxs("div", Object.assign({ className: "gap-4 p-4" }, { children: [_jsx("div", Object.assign({ className: "flex gap-2 flex-wrap pb-2 h-6 overflow-y-auto" }, { children: _jsx(TagsDisplay, { videoId: id }) })), _jsx("div", Object.assign({ className: "text-xl font-medium line-clamp-1" }, { children: title }))] })), showDeleteConfirmation && (_jsx(DeleteModal, { deleteModal: showDeleteConfirmation, setShowDeleteModal: setShowDeleteConfirmation, title: "Delete Video", description: "Are you sure you want to delete this video?", deleteText: "Delete", deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsDeleting(true);
                    yield (deleteVideo === null || deleteVideo === void 0 ? void 0 : deleteVideo());
                    setIsDeleting(false);
                    setShowDeleteConfirmation(false);
                }) }))] })));
};
export default VideoThumbnail;
