import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { getActiveTenant, updateTenantPreCallPrepDeliveryConfig, } from "../common/endpoints";
export const useTenantPreCallDeliveryConfig = (appName) => {
    const queryClient = useQueryClient();
    const { data: tenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
        staleTime: Number.POSITIVE_INFINITY,
    });
    const preCallPrepDeliveryConfigUpdateMutation = useMutation({
        mutationKey: ["updateTenantPreCallPrepDeliveryConfig", appName],
        mutationFn: ({ tenantId, deliveryConfig, }) => updateTenantPreCallPrepDeliveryConfig(tenantId, appName, deliveryConfig),
        onSettled: (tenant) => {
            queryClient.invalidateQueries({
                queryKey: ["activeTenant"],
            });
        },
    });
    const preCallDeliveryConfigForApp = useMemo(() => {
        if (!tenant)
            return [];
        return tenant.pre_call_prep_delivery_targets.filter((config) => config.target === appName);
    }, [tenant]);
    return {
        tenantId: tenant === null || tenant === void 0 ? void 0 : tenant.id,
        preCallPrepDeliveryTargets: preCallDeliveryConfigForApp,
        preCallPrepDeliveryConfigUpdateMutation,
    };
};
