var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPreCallPrepTemplate, deletePreCallPrepTemplate, getAllBrickSets, setBrickSetOrdering, } from "./common/endpoints";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { cn } from "@/lib/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import BaseModal2 from "./base_modal2";
import BaseTable2 from "./base_table2";
import EditPreCallTemplate from "./edit_pre_call_template";
import RightDrawer from "./right_drawer";
import { CenterScreen } from "./settings/styled_components";
import useFeatureFlagEnabled from "./use_feature_flag_enabled";
import WaitingSpinner from "./waiting_spinner";
const PreCallTemplateTable = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const location = useLocation();
    const queryClient = useQueryClient();
    const contactOutreachEnabled = useFeatureFlagEnabled("CONTACT_OUTREACH");
    const { data: allTemplates, isLoading: templatesLoading, isError: templatesError, } = useQuery({
        queryKey: ["getAllBrickSets"],
        queryFn: getAllBrickSets,
    });
    const [addTemplateModalOpen, setAddTemplateModalOpen] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.templateId) !== null && _b !== void 0 ? _b : null);
    const [brickType, setBrickType] = useState((_d = (_c = location.state) === null || _c === void 0 ? void 0 : _c.brickType) !== null && _d !== void 0 ? _d : "CALL");
    const templates = useMemo(() => {
        return allTemplates === null || allTemplates === void 0 ? void 0 : allTemplates.filter((t) => t.brick_type === brickType);
    }, [allTemplates, brickType]);
    const tabs = useMemo(() => {
        const _tabs = [
            { label: "Account", value: "ACCOUNT" },
            { label: "Pre-call prep", value: "CALL" },
            { label: "Post-call Trackers", value: "TRACKER" },
        ];
        if (contactOutreachEnabled) {
            _tabs.push({ label: "Contact email", value: "CONTACT" });
        }
        return _tabs;
    }, [contactOutreachEnabled]);
    const templateCreate = useMutation({
        mutationFn: (data) => createPreCallPrepTemplate(data.name, data.brickType),
        onSuccess: (template) => {
            queryClient.setQueryData(["getAllBrickSets"], (oldData) => {
                return oldData ? [...oldData, template] : [template];
            });
            setAddTemplateModalOpen(false);
            setSelectedTemplateId(template.id);
        },
    });
    const templateDelete = useMutation({
        mutationFn: deletePreCallPrepTemplate,
        onSuccess: () => {
            queryClient.setQueryData(["getAllBrickSets"], (oldData) => {
                return oldData
                    ? oldData.filter((t) => t.id !== selectedTemplateId)
                    : [];
            });
            setSelectedTemplateId(null);
        },
    });
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]);
        return ref.current;
    }
    const prevBrickType = usePrevious(brickType);
    useEffect(() => {
        if (!prevBrickType || prevBrickType === brickType) {
            return;
        }
        // Closes the right drawer when we change brick type.")
        setSelectedTemplateId(null);
        setAddTemplateModalOpen(false);
    }, [brickType]);
    const brickTypeName = (_e = tabs.find((tab) => tab.value === brickType)) === null || _e === void 0 ? void 0 : _e.label;
    const zodSchema = z.object({
        Name: z.string().min(1),
    });
    if (templatesLoading || templatesError || !templates) {
        return (_jsx(CenterScreen, { children: _jsx(WaitingSpinner, { text: "Loading research sets..." }) }));
    }
    return (_jsxs("div", Object.assign({ className: cn("p-8", selectedTemplateId ? "w-3/4" : "w-full") }, { children: [_jsx("div", Object.assign({ className: "text-[32px] font-medium leading-8" }, { children: "Prompt Sets" })), _jsx(Tabs, Object.assign({ defaultValue: brickType, onValueChange: (value) => {
                    setBrickType(value);
                }, className: "border-b border-wds-gray-3 pb-0.5 pt-4" }, { children: _jsx(TabsList, Object.assign({ className: "mx-4 border-none gap-x-6" }, { children: tabs.map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, className: "outline-none py-3 px-0 text-sm font-normal justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-wds-blue-3 data-[state=active]:border-b-2 data-[state=active]:bg-white data-[state=active]:text-wds-blue-3" }, { children: tab.label }), tab.value))) })) })), _jsx(BaseTable2, { title: `${brickTypeName} sets`, columns: [
                    {
                        header: "Name",
                        accessorKey: "name",
                    },
                    {
                        header: "Research Blocks",
                        accessorKey: "blocks_count",
                    },
                ], rows: templates, addCallback: () => {
                    setAddTemplateModalOpen(true);
                }, expandCallback: (row) => {
                    if (selectedTemplateId === row.id) {
                        setSelectedTemplateId(null);
                    }
                    else {
                        setSelectedTemplateId(row.id);
                    }
                }, reorderRows: (rows) => {
                    setBrickSetOrdering(rows.map((brick) => brick.id)).then((brickSets) => {
                        queryClient.setQueryData(["getAllBrickSets"], brickSets);
                    });
                } }), addTemplateModalOpen && (_jsx(BaseModal2, { title: "Add a research set", description: "A research set is a collection of research blocks that you can use to prepare for a call.", zodSchema: zodSchema, formFieldMeta: {
                    Name: {
                        type: "text",
                        required: true,
                        placeholder: "Enter a name for the research set",
                    },
                }, open: addTemplateModalOpen, setOpen: setAddTemplateModalOpen, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    yield templateCreate.mutateAsync({
                        name: fields.Name,
                        brickType: brickType,
                    });
                }), submitText: "Add research set" })), selectedTemplateId && (_jsx(RightDrawer, Object.assign({ title: (_g = (_f = templates.find((t) => t.id === selectedTemplateId)) === null || _f === void 0 ? void 0 : _f.name) !== null && _g !== void 0 ? _g : "", isOpen: selectedTemplateId !== null, deleteCallback: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield templateDelete.mutateAsync(selectedTemplateId);
                }), onClose: () => setSelectedTemplateId(null) }, { children: _jsx(EditPreCallTemplate, { templateId: selectedTemplateId, refetch: () => __awaiter(void 0, void 0, void 0, function* () {
                        queryClient.invalidateQueries({
                            queryKey: ["getAllBrickSets"],
                        });
                    }), researchBlockType: brickType }) })))] })));
};
export default PreCallTemplateTable;
