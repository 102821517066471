import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef } from "react";
import "./carousel.css";
import { SparklesIcon } from "@heroicons/react/24/outline";
const CarouselItem = ({ startDismissing, name, onClick, animationDuration, id, removed, onRemove, }) => {
    const removalExecuted = useRef(false);
    const animationStyle = {
        animation: `progressAnimation ${animationDuration}s linear forwards`,
    };
    useEffect(() => {
        // Check if the card is marked as removed and the removal logic hasn't been executed yet,
        if (removed && !removalExecuted.current) {
            onRemove(id);
            // Mark that the removal logic has been executed to prevent future executions
            removalExecuted.current = true;
        }
    }, [removed, onRemove, id]);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ onClick: onClick, className: `flex items-center justify-start relative h-14 p-2 gap-2 border overflow-hidden rounded-lg bg-white border-gray-300 cursor-pointer w-full ${removed ? "slide-out-animation" : ""}` }, { children: [_jsx("div", { className: "absolute top-0 right-0 h-full bg-wds-gray-1", style: startDismissing ? animationStyle : {} }), _jsxs("div", Object.assign({ className: "relative top-0 right-0 h-full flex flex-row items-center px-4" }, { children: [_jsx(SparklesIcon, { className: "text-blue-500 w-4 h-4 flex-shrink-0" }), _jsx("div", Object.assign({ className: "w-full overflow-hidden  text-black font-Lato text-[14px] font-normal font-Lato pl-2", style: { lineHeight: "20px" } }, { children: _jsx("div", Object.assign({ className: "overflow-hidden", style: {
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",
                                } }, { children: name })) }))] }))] })) }));
};
export default React.memo(CarouselItem);
