import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio_group";
import { useState } from "react";
import { JsonSchemaType } from "../types";
import { BrickOutputBuilder } from "./brick_output_builder";
import { BrickOutputTypeEnum } from "./output_format.types";
const DEFAULT_STRUCTURED_OUTPUT_FORMAT = {
    type: "json_schema",
    json_schema: {
        name: "structured_output",
        description: "Structured output",
        strict: true,
        schema: {
            type: "object",
            properties: {
                "Field 1": {
                    type: JsonSchemaType.STRING,
                },
            },
            required: ["Field 1"],
            additionalProperties: false,
        },
    },
};
export const BrickOutputFormat = ({ brickOutputStructure, setBrickOutputStructure, }) => {
    const getInitialOutputType = () => {
        var _a;
        const fieldNames = Object.keys((_a = brickOutputStructure === null || brickOutputStructure === void 0 ? void 0 : brickOutputStructure.json_schema.schema.properties) !== null && _a !== void 0 ? _a : {});
        if (fieldNames.length === 0) {
            return BrickOutputTypeEnum.FREE_TEXT;
        }
        return BrickOutputTypeEnum.STRUCTURED_OUTPUT;
    };
    const [outputType, setOutputType] = useState(getInitialOutputType());
    // Initialize savedStructure with the current brickOutputStructure value
    const [savedStructure, setSavedStructure] = useState((brickOutputStructure === null || brickOutputStructure === void 0 ? void 0 : brickOutputStructure.json_schema.schema.type) === "object"
        ? brickOutputStructure
        : undefined);
    const handleOutputTypeChange = (value) => {
        const newOutputType = value;
        setOutputType(newOutputType);
        if (newOutputType === BrickOutputTypeEnum.FREE_TEXT) {
            // Save the current structure before switching to free text
            if (brickOutputStructure &&
                brickOutputStructure.json_schema.schema.type === "object" &&
                Object.keys(brickOutputStructure.json_schema.schema.properties || {})
                    .length > 0) {
                setSavedStructure(brickOutputStructure);
            }
            // Set to default free text structure
            setBrickOutputStructure(undefined);
        }
        else {
            // Restore the saved structure when switching back to structured output
            if (savedStructure) {
                setBrickOutputStructure(savedStructure);
            }
            else {
                // If there's no saved structure, create a basic one
                setBrickOutputStructure(DEFAULT_STRUCTURED_OUTPUT_FORMAT);
            }
        }
    };
    return (_jsxs("div", Object.assign({ className: "flex-col justify-center items-start gap-3.5 inline-flex h-full w-full" }, { children: [_jsx("div", Object.assign({ className: "text-wds-black text-base font-semibold" }, { children: "Output format" })), _jsx("div", { children: _jsxs(RadioGroup, Object.assign({ defaultValue: outputType, value: outputType, onValueChange: handleOutputTypeChange }, { children: [_jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(RadioGroupItem, { value: BrickOutputTypeEnum.FREE_TEXT, id: "r1" }), _jsx(Label, Object.assign({ htmlFor: "r1" }, { children: "Free text" }))] })), _jsxs("div", Object.assign({ className: "flex items-center space-x-2" }, { children: [_jsx(RadioGroupItem, { value: BrickOutputTypeEnum.STRUCTURED_OUTPUT, id: "r2" }), _jsx(Label, Object.assign({ htmlFor: "r2" }, { children: "Structured output" }))] }))] })) }), outputType === BrickOutputTypeEnum.STRUCTURED_OUTPUT && (_jsx(BrickOutputBuilder, { brickOutputStructure: brickOutputStructure, setBrickOutputStructure: setBrickOutputStructure }))] })));
};
