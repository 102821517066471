import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDuration } from "./utils";
import VideoThumbnail from "./video_thumbnail";
const VideoThumbnailGroup = ({ title, videos, deleteVideo, }) => {
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-3" }, { children: [_jsx("div", Object.assign({ className: "text-xl font-medium capitalize" }, { children: title })), _jsx("div", Object.assign({ className: "flex flex-wrap items-center gap-3.5" }, { children: videos.map((video) => {
                    const duration = getDuration(video);
                    return (_jsx(VideoThumbnail, { id: video.id, title: video.title, duration: duration, thumbnailUrl: video.thumbnail, deleteVideo: () => deleteVideo({ videoId: video.id, isClip: video.is_clip }), isClip: video.is_clip }, video.id));
                }) }))] })));
};
export default VideoThumbnailGroup;
