import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useLayoutEffect, useRef, useState } from "react";
import { Badge } from "./badge";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
export const ExpandableCell = ({ value, child, }) => {
    // This component gives us the expand-on-click popover behavior in each cell,
    // so that we can see content too large to display in 1-2 rows.
    const [isOpen, setIsOpen] = useState(false);
    const cellRef = useRef(null);
    const popoverRef = useRef(null);
    const [width, setWidth] = useState(0);
    // Use useLayoutEffect to measure and update width before render.
    // This makes it use the width of the parent, even if that width changes.
    useLayoutEffect(() => {
        if (cellRef.current) {
            const updateWidth = () => {
                var _a;
                const newWidth = (_a = cellRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth;
                if (newWidth) {
                    setWidth(newWidth);
                }
            };
            updateWidth();
            // Add resize observer to handle column resizing
            const resizeObserver = new ResizeObserver(updateWidth);
            resizeObserver.observe(cellRef.current);
            return () => {
                if (cellRef.current) {
                    resizeObserver.unobserve(cellRef.current);
                }
            };
        }
    }, []);
    // Renddering json isn't great, but it's better than showing an opaque object.
    const getFormattedContent = () => {
        if (child)
            return child;
        // Check if value is a list
        if (Array.isArray(value)) {
            return value.join("\n\n");
        }
        // Check if value is an object
        if (typeof value === "object") {
            return JSON.stringify(value, null, 4);
        }
        // Otherwise, just return the value as a string
        return String(value);
    };
    return (_jsxs(Popover, Object.assign({ open: isOpen, onOpenChange: setIsOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ ref: cellRef, className: "line-clamp-2 cursor-pointer hover:text-wds-blue-5 h-full content-center leading-normal", title: "Click to expand" }, { children: getFormattedContent() })) })), _jsx(PopoverContent, Object.assign({ ref: popoverRef, style: {
                    width: `${width}px`,
                }, className: "overflow-y-auto whitespace-pre-wrap p-4 bg-white shadow-lg rounded-md max-h-[400px] z-[1000]", sideOffset: 5 }, { children: getFormattedContent() }))] })));
};
const DATETIME_CELL_RENDERER = (params) => {
    if (!params.value)
        return "";
    return (_jsx("span", Object.assign({ className: "flex items-center gap-2 h-full" }, { children: new Date(params.value).toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        }) })));
};
const BOOLEAN_CELL_RENDERER = (params) => {
    if (params.value === null || params.value === undefined)
        return "";
    return (_jsxs("span", Object.assign({ className: "flex items-center gap-2 h-full" }, { children: [params.value && (_jsx(CheckCircleIcon, { className: "shrink-0 h-5 w-5 text-muted-foreground", "aria-hidden": "true" })), params.value ? "Yes" : "No"] })));
};
const NUMBER_CELL_RENDERER = (params) => {
    if (params.value === null || params.value === undefined)
        return "";
    if (typeof params.value !== "number")
        return "";
    return (_jsx("span", Object.assign({ className: "flex items-center gap-2 h-full" }, { children: Number.isInteger(params.value)
            ? params.value.toLocaleString()
            : params.value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }) })));
};
const SINGLE_SELECT_CELL_RENDERER = (params) => {
    if (params.value === null || params.value === undefined)
        return "";
    return (_jsx("span", Object.assign({ className: "flex items-center gap-2 h-full" }, { children: _jsx(Badge, Object.assign({ variant: "light" }, { children: params.value })) })));
};
const MULTI_SELECT_CELL_RENDERER = (params) => {
    if (params.value === null || params.value === undefined)
        return "";
    const values = params.value.split(";");
    return (_jsx("div", Object.assign({ className: "flex items-center gap-2 overflow-y-auto" }, { children: values.map((value) => (_jsx(Badge, Object.assign({ variant: "light" }, { children: value }), value))) })));
};
const ARRAY_CELL_RENDERER = (params) => {
    if (params.value === null || params.value === undefined)
        return "";
    const content = params.value.join("\n");
    return _jsx(ExpandableCell, { value: content });
};
const DEFAULT_CELL_RENDERER = (params) => {
    if (params.value === null || params.value === undefined)
        return "";
    return _jsx(ExpandableCell, { value: params.value });
};
export const getCellRenderer = (type) => {
    switch (type) {
        case "datetime":
            return DATETIME_CELL_RENDERER;
        case "boolean":
            return BOOLEAN_CELL_RENDERER;
        case "number":
            return NUMBER_CELL_RENDERER;
        case "single_select":
            return SINGLE_SELECT_CELL_RENDERER;
        case "multi_select":
            return MULTI_SELECT_CELL_RENDERER;
        case "array":
            return ARRAY_CELL_RENDERER;
        default:
            return DEFAULT_CELL_RENDERER;
    }
};
