import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { PARAGON_REDIRECT_URL } from "@/settings/constants";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
const OAuthRedirect = () => {
    const location = useLocation();
    // Immediately build the target URL and perform redirection
    if (typeof window !== "undefined") {
        const queryParams = new URLSearchParams(location.search);
        const targetUrl = new URL(PARAGON_REDIRECT_URL);
        targetUrl.search = queryParams.toString();
        window.location.href = targetUrl.toString();
    }
    return (_jsx("div", Object.assign({ className: "flex justify-center items-center h-screen" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-center justify-center" }, { children: [_jsx("h2", { children: "Redirecting..." }), _jsxs("div", Object.assign({ className: "flex flex-col items-center" }, { children: [_jsx(ArrowPathIcon, { className: "h-6 w-6 animate-spin" }), _jsxs("p", { children: ["If you are not redirected automatically, click", " ", _jsx(Button, Object.assign({ onClick: () => {
                                        const queryParams = new URLSearchParams(location.search);
                                        const targetUrl = new URL(PARAGON_REDIRECT_URL);
                                        targetUrl.search = queryParams.toString();
                                        window.location.href = targetUrl.toString();
                                    }, variant: "link" }, { children: "here" }))] })] }))] })) })));
};
export default OAuthRedirect;
