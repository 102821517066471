import { useState } from "react";
const INITIAL_CHAT_MESSAGE = {
    sender: "Bot",
    messageType: "Message",
    messageContent: "Hey, I'm Wiser! I'll listen to your call and provide suggestions as you go. You can also ask a question to me directly.",
};
const useChatMessages = (initialChatMessage) => {
    const [chatMessages, setChatMessages] = useState([
        initialChatMessage !== null && initialChatMessage !== void 0 ? initialChatMessage : INITIAL_CHAT_MESSAGE,
    ]);
    const handleChatProgress = (response) => {
        if (!response.chat_progress)
            return;
        const chatMessage = response.chat_progress;
        addChatMessage({
            sender: "Bot",
            messageType: "ChatProgress",
            card: chatMessage,
        });
    };
    const addChatMessage = (message) => {
        setChatMessages((prevMessages) => [
            ...prevMessages.filter((prevMessage) => {
                if (prevMessage.sender !== "Bot")
                    return true;
                if (prevMessage.messageType === "Loading")
                    return false;
                if (prevMessage.messageType === "ChatProgress")
                    return false;
                return true;
            }),
            message,
        ]);
    };
    const resetChatMessages = () => {
        setChatMessages([initialChatMessage !== null && initialChatMessage !== void 0 ? initialChatMessage : INITIAL_CHAT_MESSAGE]);
    };
    const handlePlaybookRecommendations = (message) => {
        if (!message.playbook_recommendations)
            return;
        if (!message.playbook_recommendations.questions)
            return;
        addChatMessage({
            sender: "Bot",
            messageType: "Recommendation",
            messageTitle: `"${message.playbook_recommendations.playbook_item_name}" - try asking:`,
            messageContent: message.playbook_recommendations.questions,
        });
        return message.playbook_recommendations.playbook_item_id;
    };
    const handleChatMessage = (response) => {
        if (!response.chat_message)
            return;
        if (response.chat_message.typeName === "NOT_FOUND") {
            addChatMessage({
                sender: "Bot",
                messageType: "Message",
                messageContent: "I couldn't find anything related to your query.",
            });
            return;
        }
        const chatMessage = {
            sender: "Bot",
            messageType: "Card",
            card: response.chat_message,
        };
        addChatMessage(chatMessage);
    };
    const handleEchoChat = (response, clientId) => {
        if (!response.echo_back_chat)
            return;
        if (response.echo_back_chat.clientId &&
            response.echo_back_chat.clientId === clientId)
            return;
        addChatMessage(response.echo_back_chat);
    };
    return {
        chatMessages,
        addChatMessage,
        resetChatMessages,
        handlePlaybookRecommendations,
        handleChatMessage,
        handleEchoChat,
        handleChatProgress,
    };
};
export default useChatMessages;
