import { useCallback } from "react";
import { JsonSchemaType, } from "../types";
export const useBrickOutputBuilder = ({ setBrickOutputStructure, }) => {
    const onChange = useCallback((field, updatedFieldName, updatedFieldStructure) => {
        setBrickOutputStructure((prev) => {
            if (!prev) {
                return prev;
            }
            const updatedProperties = Object.keys(prev.json_schema.schema.properties).reduce((acc, fieldName) => {
                if (fieldName === field) {
                    acc[updatedFieldName] = updatedFieldStructure;
                }
                else {
                    acc[fieldName] = prev.json_schema.schema.properties[fieldName];
                }
                return acc;
            }, {});
            const updatedRequiredFields = prev.json_schema.schema.required.map((f) => {
                if (f === field) {
                    return updatedFieldName;
                }
                return f;
            });
            return Object.assign(Object.assign({}, prev), { json_schema: Object.assign(Object.assign({}, prev.json_schema), { schema: Object.assign(Object.assign({}, prev.json_schema.schema), { properties: updatedProperties, required: updatedRequiredFields }) }) });
        });
    }, [setBrickOutputStructure]);
    const addOutput = useCallback(() => {
        setBrickOutputStructure((prev) => {
            if (!prev) {
                return {
                    type: "json_schema",
                    json_schema: {
                        name: "structured_output",
                        description: "Structured output",
                        strict: true,
                        schema: {
                            type: "object",
                            properties: {
                                "Field 1": {
                                    type: JsonSchemaType.STRING,
                                },
                            },
                            required: ["Field 1"],
                            additionalProperties: false,
                        },
                    },
                };
            }
            // NOTE: This can fail if there is an exact match with ths field already in the properties
            // but this is unlikely to happen
            const fieldNumber = Math.random().toString(36).slice(2, 6).toUpperCase();
            const updatedStruct = Object.assign(Object.assign({}, prev), { json_schema: Object.assign(Object.assign({}, prev.json_schema), { schema: Object.assign(Object.assign({}, prev.json_schema.schema), { properties: Object.assign(Object.assign({}, prev.json_schema.schema.properties), { [`Field ${fieldNumber}`]: {
                                type: JsonSchemaType.STRING,
                            } }), required: [
                            ...prev.json_schema.schema.required,
                            `Field ${fieldNumber}`,
                        ] }) }) });
            return updatedStruct;
        });
    }, [setBrickOutputStructure]);
    return {
        onChange,
        addOutput,
    };
};
