var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "@/components/ui/badge";
import { ExpandablePostCall, useExpandablePostCall, } from "@/components/ui/expandable";
import { cn } from "@/lib/utils";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import ResearchBlockWithProvenance from "../common/research_block_with_provenance";
import FieldValueEditor from "./field_value_editor";
import FieldValueRenderer, { CrmEventTargetValueRenderer, } from "./field_value_renderer";
import { isCrmEventPushItem } from "./utils";
export const FieldMappingNewValue = ({ editNewValue, setEditNewValue, postCallOutputItem, onNewValueChange, fieldSchema, expanded, provenanceRecords, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [newContent, setNewContent] = useState((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "");
    const newValueDivRef = useRef(null);
    const onNewValueEdit = (content) => {
        setNewContent(content);
    };
    const handleNewValueTextareaKeyDown = (event) => {
        var _a, _b;
        // Users can come out of the edit mode by pressing either Esc or click outside.
        if (event.key === "Escape") {
            // DO NOT update the value.
            setNewContent((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "");
            setEditNewValue(false);
        }
    };
    const { showMore, setShowMore } = useExpandablePostCall();
    useEffect(() => {
        // Attach a click event listener to document to exit edit mode on outside click
        const handleOutsideClick = (event) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            const clickedElement = event.target;
            const isClickedInsideComponent = (_a = newValueDivRef.current) === null || _a === void 0 ? void 0 : _a.contains(clickedElement);
            // The clicked inside app check ensures that we treat only those clicks as
            // "outside" that are not in the Radix portal. Otherwise, clicks in the Radix
            // components rendered in a portal like DropdownMenu is also considered an
            // outside click and thus disrupts the functioning of this hook.
            const isClickedInsideApp = (_b = document
                .getElementById("post-call-content")) === null || _b === void 0 ? void 0 : _b.contains(clickedElement);
            if (isClickedInsideApp && !isClickedInsideComponent) {
                yield onNewValueChange(newContent);
                setEditNewValue(false);
                if (setShowMore) {
                    setShowMore(false);
                }
            }
        });
        if (editNewValue) {
            // Capture the click event on the document to exit edit mode on outside click
            document.addEventListener("click", handleOutsideClick, true);
        }
        return () => {
            document.removeEventListener("click", handleOutsideClick, true);
        };
    }, [editNewValue, newContent]);
    useEffect(() => {
        var _a, _b;
        setNewContent((_b = (_a = postCallOutputItem.valueToUpdate) !== null && _a !== void 0 ? _a : postCallOutputItem.proposedValue) !== null && _b !== void 0 ? _b : "");
    }, [postCallOutputItem]);
    const itemIgnored = postCallOutputItem.state === "IGNORED";
    const editDisabled = postCallOutputItem.state === "PROCESSED" ||
        postCallOutputItem.state === "PROCESSING" ||
        isCrmEventPushItem(postCallOutputItem) ||
        itemIgnored;
    return (_jsx("div", Object.assign({ className: "inline-flex gap-4 items-start flex-1" }, { children: _jsx("div", Object.assign({ className: "inline-flex flex-col gap-2 flex-1 self-stretch" }, { children: editNewValue && showMore ? (_jsx("div", Object.assign({ ref: newValueDivRef, className: "self-stretch flex-1" }, { children: _jsx(FieldValueEditor, { postCallOutputId: postCallOutputItem.callId, value: newContent, fieldSchema: fieldSchema, onValueEdit: onNewValueEdit, handleKeyDown: handleNewValueTextareaKeyDown }) }))) : (_jsxs("div", Object.assign({ className: "flex flex-row space-x-4 items-start bg-wds-gray-1 p-2 rounded-lg" }, { children: [_jsx("div", Object.assign({ ref: newValueDivRef, className: cn("text-black font-normal w-full whitespace-pre-wrap overflow-hidden", editDisabled ? "" : "w-full h-full"), onClick: () => {
                            if (editDisabled) {
                                return;
                            }
                            setEditNewValue(true);
                            if (setShowMore) {
                                setShowMore(true);
                            }
                        } }, { children: isCrmEventPushItem(postCallOutputItem) ? (_jsx(CrmEventTargetValueRenderer, { postCallOutputItemId: postCallOutputItem.callId, value: (_d = (_c = postCallOutputItem.valueToUpdate) !== null && _c !== void 0 ? _c : postCallOutputItem.proposedValue) !== null && _d !== void 0 ? _d : "", fieldSchema: fieldSchema, expanded: expanded || editNewValue })) : (_jsx(FieldValueRenderer, { postCallOutputItemId: postCallOutputItem.callId, value: (_f = (_e = postCallOutputItem.valueToUpdate) !== null && _e !== void 0 ? _e : postCallOutputItem.proposedValue) !== null && _f !== void 0 ? _f : "", fieldSchema: fieldSchema, expanded: expanded || editNewValue })) })), editDisabled ? null : (_jsx("button", Object.assign({ onClick: () => {
                            if (setShowMore) {
                                setShowMore(true);
                                setEditNewValue(true);
                            }
                        } }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) })))] }))) })) })));
};
export const EmptyTargetFieldMappingValue = ({ postCallOutputItem, loading, provenanceRecords, }) => {
    const [editing, setEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(postCallOutputItem.contentCaptured);
    const TrackerContent = ({ className, provenanceRecords, }) => {
        var _a;
        const { textBoxRef } = useExpandablePostCall();
        return (_jsxs("div", Object.assign({ className: "flex flex-row w-full space-x-4 justify-between items-start" }, { children: [_jsx("div", Object.assign({ className: "flex flex-row space-x-4 items-start bg-wds-gray-1 rounded-lg p-2 pb-1 w-full" }, { children: _jsx("div", Object.assign({ className: cn("grow self-stretch text-black font-normal px-1 text-sm", className), ref: textBoxRef }, { children: ((_a = postCallOutputItem.contentCaptured) === null || _a === void 0 ? void 0 : _a.includes("/>")) ? (_jsx(ResearchBlockWithProvenance, { provenanceRecords: provenanceRecords, htmlString: postCallOutputItem.contentCaptured || "", collapsable: false })) : (_jsx("span", Object.assign({ className: "whitespace-pre-wrap" }, { children: postCallOutputItem.contentCaptured }))) })) })), _jsx(Badge, Object.assign({ variant: "outline", className: "text-xs bg-wds-gray-1 text-wds-gray-6 border-wds-gray-3 font-extrabold" }, { children: "Unmapped" }))] })));
    };
    return (_jsx("div", Object.assign({ className: "px-4 pb-3" }, { children: _jsx(ExpandablePostCall, { showLessContent: _jsx(TrackerContent, { className: "line-clamp-4", provenanceRecords: provenanceRecords }), showMoreContent: editing ? (_jsx("textarea", { className: "text-black font-normal whitespace-pre-wrap rounded-lg p-2 w-full", rows: 6, onBlur: () => {
                    // Maybe we should also collapse... I'm not sure.
                    setEditing(false);
                }, value: editedValue, onChange: (e) => setEditedValue(e.target.value) })) : (_jsx(TrackerContent, { provenanceRecords: provenanceRecords })) }) })));
};
