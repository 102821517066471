var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AsyncSearchSelect, } from "@/components/ui/async_search_select";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Collapsible, CollapsibleContent, CollapsibleTrigger, } from "@/components/ui/collapsible";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage, } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll_area";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import TagsSelector from "@/tags_selector";
import { ArrowPathIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, PlusIcon, TrashIcon, } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { getAccountLabelForDisplay, getOpportunityLabelForDisplay, } from "../common/crm_utils";
import { getCrmFields, getHubspotFieldsForPostCall, getSfdcFieldsForPostCall, getSlackChannelsForPostCall, } from "../common/endpoints";
import { replaceUnserscoreWithSpace } from "../strings";
import { CrmObjectTypeEnum, FieldMappingUpdateType, } from "../types";
const DeliveryTargetSelection = ({ deliveryTargets, setDeliveryTargets, deliveryTargetOptions = [], tenant, fieldMappings, setFieldMappings, }) => {
    var _a, _b;
    const [selectedParentTarget, setSelectedParentTarget] = useState((_b = (_a = deliveryTargetOptions[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null);
    const deliveryTargetEnabled = useCallback((target) => {
        const targetPresent = deliveryTargets.has(target.id);
        if (!targetPresent) {
            return false;
        }
        if (target.id !== "CRM" && target.id !== "Slack") {
            return true;
        }
        if (target.id === "CRM" && !!fieldMappings.find((fm) => fm.field)) {
            return true;
        }
        if (target.id === "Slack" &&
            !!fieldMappings.find((fm) => fm.channel_id)) {
            return true;
        }
        return false;
    }, [deliveryTargets, fieldMappings]);
    const targetOptions = useMemo(() => {
        return deliveryTargetOptions.filter((target) => target.id === selectedParentTarget);
    }, [selectedParentTarget, deliveryTargetOptions, tenant.connected_crm]);
    const handleTargetChange = (targetId, checked) => {
        const newDeliveryTargets = new Set(deliveryTargets);
        if (checked) {
            newDeliveryTargets.add(targetId);
        }
        else {
            newDeliveryTargets.delete(targetId);
        }
        setDeliveryTargets(newDeliveryTargets);
    };
    return (_jsxs("div", Object.assign({ className: "self-stretch bg-white flex flex-col justify-start items-start gap-6 text-wds-black" }, { children: [_jsx("div", Object.assign({ className: "text-xl font-normal leading-none" }, { children: "Prompt Destination" })), _jsxs("div", Object.assign({ className: "h-[65vh] flex flex-row w-full justify-start items-start border border-wds-gray-3 rounded-lg overflow-hidden text-sm" }, { children: [_jsx("div", Object.assign({ className: "w-1/3 h-full bg-wds-gray-1 py-2 overflow-y-auto" }, { children: deliveryTargetOptions.map((target) => (_jsxs(Button, Object.assign({ variant: "ghost", className: `px-3 py-2.5 w-full h-auto rounded-none text-black hover:bg-wds-gray-2 ${selectedParentTarget === target.id ? "bg-wds-gray-2" : ""} flex justify-between items-center whitespace-normal text-left`, onClick: () => setSelectedParentTarget(target.id) }, { children: [_jsx("span", { children: target.name }), deliveryTargetEnabled(target) && (_jsx("div", Object.assign({ className: "w-5 h-5 rounded-full flex-shrink-0 bg-green-600 flex items-center justify-center ml-2" }, { children: _jsx(CheckIcon, { className: "w-4 h-4 stroke-2 text-white" }) })))] }), target.id))) })), _jsx("div", Object.assign({ className: "w-2/3 h-full p-4 overflow-y-auto" }, { children: selectedParentTarget && (_jsx("div", Object.assign({ className: "flex flex-col gap-2" }, { children: targetOptions.map((target) => (_jsx(MemoisedDeliveryTarget, { target: target, deliveryTargets: deliveryTargets, handleTargetChange: handleTargetChange, tenant: tenant, fieldMappings: fieldMappings, setFieldMappings: setFieldMappings }, target.id))) }))) }))] }))] })));
};
const DeliveryTargetOption = ({ target, deliveryTargets, handleTargetChange, tenant, fieldMappings: allFieldMappings, setFieldMappings, }) => {
    const fieldMappings = useMemo(() => {
        if (target.id === "CRM")
            return allFieldMappings.filter((f) => !!f.object || (!f.object && !f.channel_id && !f.tags));
        return allFieldMappings.filter((f) => !!f.channel_id || (!f.object && !f.channel_id));
    }, [target, allFieldMappings]);
    const memoisedLocalField = useMemo(() => {
        return fieldMappings.reduce((acc, f) => {
            acc[f.id] = f.field;
            return acc;
        }, {});
    }, [fieldMappings]);
    const [mappingOpen, setMappingOpen] = useState({
        0: true,
    });
    const [localField, setLocalField] = useState(memoisedLocalField);
    const SPECIAL_TARGETS = ["CRM", "Slack", "ClipLibrary"];
    const isSpecialTarget = SPECIAL_TARGETS.includes(target.id);
    if (!isSpecialTarget) {
        return (_jsx("label", Object.assign({ className: "flex flex-col w-full py-1 justify-start items-start gap-2.5" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row justify-start items-center gap-1.5" }, { children: [_jsx(Checkbox, { checked: deliveryTargets.has(target.id), onCheckedChange: (checked) => handleTargetChange(target.id, checked) }), _jsx("div", Object.assign({ className: "font-medium leading-tight" }, { children: target.name }))] })) }), target.id));
    }
    return (_jsxs(_Fragment, { children: [fieldMappings.map((f, idx) => (_jsxs(Collapsible, Object.assign({ open: mappingOpen[idx], onOpenChange: (open) => setMappingOpen(Object.assign(Object.assign({}, mappingOpen), { [idx]: open })), className: cn(mappingOpen[idx]
                    ? "rounded-lg border border-wds-blue-2 shadow-[0px_2px_4px_0px_theme(colors.wds-blue-2/24)]"
                    : "") }, { children: [_jsx(CollapsibleTrigger, Object.assign({ asChild: true, className: "w-full justify-start items-center gap-5 inline-flex px-5 py-2" }, { children: _jsx(Button, Object.assign({ variant: "lightGray", size: "lg", className: "rounded-sm w-full" }, { children: _jsxs("div", Object.assign({ className: "w-full justify-between items-center inline-flex" }, { children: [_jsxs("div", Object.assign({ className: "justify-start items-center gap-3 flex" }, { children: [`Output destination ${idx + 1}`, (localField === null || localField === void 0 ? void 0 : localField[f.id]) && (_jsx(CheckIcon, { className: "w-4 h-4 stroke-2 text-white rounded-full flex-shrink-0 bg-green-600" })), !localField && (_jsx(CheckIcon, { className: "w-4 h-4 stroke-2 text-white rounded-full flex-shrink-0 bg-wds-gray-4" }))] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx(Button, Object.assign({ variant: "invertedRed", className: "rounded-md", size: "sm", onClick: (e) => {
                                                    e.stopPropagation();
                                                    if (fieldMappings.length === 1) {
                                                        handleTargetChange(target.id, false);
                                                    }
                                                    setFieldMappings((prev) => prev.filter((fm) => fm.id !== f.id));
                                                    setLocalField((prev) => {
                                                        if (!prev)
                                                            return prev;
                                                        delete prev[f.id];
                                                        return prev;
                                                    });
                                                    setMappingOpen((prev) => {
                                                        if (!prev)
                                                            return prev;
                                                        delete prev[idx];
                                                        return prev;
                                                    });
                                                } }, { children: _jsxs("div", Object.assign({ className: "justify-start items-center gap-2 flex" }, { children: [_jsx(TrashIcon, { className: "w-4 h-4" }), _jsx("span", { children: "Delete" })] })) })), _jsx(Button, Object.assign({ variant: "ghost", size: "sm" }, { children: mappingOpen[idx] ? (_jsx(ChevronDownIcon, { className: "w-4 h-4" })) : (_jsx(ChevronUpIcon, { className: "w-4 h-4" })) }))] }))] })) })) })), _jsxs(CollapsibleContent, Object.assign({ className: "p-4" }, { children: [target.id === "CRM" && (_jsx(CRMDeliveryTargetSelection, { tenant: tenant, fieldMapping: f, setFieldMappings: setFieldMappings, setLocalField: setLocalField, handleTargetChange: handleTargetChange })), target.id === "Slack" && (_jsx(SlackDeliveryTargetSelection, { tenant: tenant, fieldMapping: f, setFieldMappings: setFieldMappings, setLocalField: setLocalField, handleTargetChange: handleTargetChange })), target.id === "ClipLibrary" && (_jsx(ClipLibraryDeliveryTargetSelection, { tenant: tenant, fieldMapping: f, setFieldMappings: setFieldMappings, setLocalField: setLocalField, handleTargetChange: handleTargetChange }))] }))] })))), _jsxs(Button, Object.assign({ variant: "light", size: "lg", className: "rounded-sm", onClick: () => {
                    setFieldMappings((prev) => {
                        if (prev.length === 0) {
                            return [
                                {
                                    id: 0,
                                    object: target.id === "CRM" ? CrmObjectTypeEnum.ACCOUNT : undefined,
                                    field: undefined,
                                    auto_apply_after_n_minutes: 0,
                                    channel_id: undefined,
                                    channel_name: undefined,
                                    update_type: FieldMappingUpdateType.OVERWRITE,
                                },
                            ];
                        }
                        return [
                            ...prev,
                            {
                                id: prev[prev.length - 1].id + 1,
                                object: target.id === "CRM" ? CrmObjectTypeEnum.ACCOUNT : undefined,
                                field: undefined,
                                auto_apply_after_n_minutes: 0,
                                channel_id: undefined,
                                channel_name: undefined,
                                update_type: FieldMappingUpdateType.OVERWRITE,
                            },
                        ];
                    });
                    setMappingOpen((prev) => {
                        return Object.assign(Object.assign({}, prev), { [fieldMappings.length]: true });
                    });
                } }, { children: [_jsx("span", { children: "Add new output" }), _jsx(PlusIcon, { className: "ml-2 h-4 w-4" })] }))] }));
};
const MemoisedDeliveryTarget = React.memo(DeliveryTargetOption, (prev, next) => {
    return (prev.target.id === next.target.id &&
        prev.target.name === next.target.name &&
        prev.deliveryTargets.size === next.deliveryTargets.size &&
        [...prev.deliveryTargets].every((t) => next.deliveryTargets.has(t)) &&
        prev.fieldMappings.length === next.fieldMappings.length &&
        [...prev.fieldMappings].every((t) => next.fieldMappings.includes(t)));
});
const CRMDeliveryTargetSelection = ({ tenant, fieldMapping, setFieldMappings, setLocalField, handleTargetChange, }) => {
    const crmTargetSchema = z.object({
        object: z.nativeEnum(CrmObjectTypeEnum),
        field: z.object({
            value: z.string(),
            label: z.string(),
        }),
        auto_apply_after_n_minutes: z.number(),
        update_type: z.nativeEnum(FieldMappingUpdateType),
    });
    const form = useForm({
        resolver: zodResolver(crmTargetSchema),
        defaultValues: {
            object: fieldMapping.object,
            field: fieldMapping.field,
            auto_apply_after_n_minutes: fieldMapping.auto_apply_after_n_minutes,
            update_type: fieldMapping.update_type,
        },
    });
    const { data: crmFields, isLoading, isError, } = useQuery({
        queryKey: ["getCrmFields"],
        queryFn: getCrmFields,
        enabled: !tenant.connected_crm,
    });
    const { data: salesforceCrmFields, isLoading: salesforceCrmFieldsLoading, isError: salesforceCrmFieldsError, } = useQuery({
        queryKey: ["getSfdcFieldsForPostCall"],
        queryFn: getSfdcFieldsForPostCall,
        enabled: tenant.connected_crm === "Salesforce",
    });
    const { data: hubspotCrmFields, isLoading: hubspotCrmFieldsLoading, isError: hubspotCrmFieldsError, } = useQuery({
        queryKey: ["getHubspotFieldsForPostCall"],
        queryFn: getHubspotFieldsForPostCall,
        enabled: tenant.connected_crm === "HubSpot",
    });
    const getCrmFieldApplicable = () => {
        if (!tenant.connected_crm) {
            return crmFields === null || crmFields === void 0 ? void 0 : crmFields.filter((f) => fieldMapping.object &&
                f.applicable_objects.includes(fieldMapping.object)).map((f) => {
                var _a;
                return ({
                    label: f.name,
                    value: (_a = f.external_id) !== null && _a !== void 0 ? _a : f.id.toString(),
                });
            });
        }
        if (tenant.connected_crm === "Salesforce" && fieldMapping.object) {
            return salesforceCrmFields === null || salesforceCrmFields === void 0 ? void 0 : salesforceCrmFields[fieldMapping.object].map((f) => ({
                label: f.label,
                value: f.name,
            }));
        }
        if (tenant.connected_crm === "HubSpot" && fieldMapping.object) {
            return hubspotCrmFields === null || hubspotCrmFields === void 0 ? void 0 : hubspotCrmFields[fieldMapping.object].map((f) => ({
                label: f.label,
                value: f.name,
            }));
        }
        return [];
    };
    const getCrmFieldLoadingStatus = () => {
        if (!tenant.connected_crm) {
            return isLoading;
        }
        if (tenant.connected_crm === "Salesforce") {
            return salesforceCrmFieldsLoading;
        }
        return hubspotCrmFieldsLoading;
    };
    const crmFieldApplicable = getCrmFieldApplicable();
    const crmFieldsLoading = getCrmFieldLoadingStatus();
    const [selectedCrmObject, setSelectedCrmObject] = useState(CrmObjectTypeEnum.ACCOUNT);
    return (_jsx("div", Object.assign({ className: "max-h-full overflow-y-auto pb-4" }, { children: _jsx(ScrollArea, { children: _jsx(Form, Object.assign({}, form, { children: _jsxs("form", Object.assign({ onSubmit: form.handleSubmit((values) => __awaiter(void 0, void 0, void 0, function* () { return console.log("form submitted", values); })), className: "space-y-8" }, { children: [_jsx(FormField, { control: form.control, name: "object", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Object" }), _jsxs(Select, Object.assign({ onValueChange: (v) => {
                                            field.onChange(v);
                                            setFieldMappings((prev) => {
                                                return prev.map((f) => {
                                                    if (f.id === fieldMapping.id) {
                                                        f.object = v;
                                                    }
                                                    return f;
                                                });
                                            });
                                            setSelectedCrmObject(v);
                                        }, defaultValue: field.value }, { children: [_jsx(FormControl, { children: _jsx(SelectTrigger, { children: _jsx(SelectValue, { placeholder: "Select crm object destination" }) }) }), _jsx(SelectContent, { children: Object.values(CrmObjectTypeEnum).map((value) => (_jsx(SelectItem, Object.assign({ value: value }, { children: value === CrmObjectTypeEnum.ACCOUNT
                                                        ? getAccountLabelForDisplay(tenant.connected_crm)
                                                        : value === CrmObjectTypeEnum.OPPORTUNITY
                                                            ? getOpportunityLabelForDisplay(tenant.connected_crm)
                                                            : value })))) })] })), _jsx(FormDescription, { children: "This is the CRM object whose field you want to use as a delivery target" }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "field", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Field" }), _jsx(FormControl, { children: _jsxs(_Fragment, { children: [crmFieldsLoading && (_jsxs("div", { children: [_jsx(ArrowPathIcon, { className: "animate-spin h-5 w-5 text-gray-500" }), "Loading fields"] })), !crmFieldsLoading && (_jsx(AsyncSearchSelect, { id: selectedCrmObject, dataFetcher: (userQuery, ids, cursor) => {
                                                        return new Promise((resolve) => {
                                                            const filteredResults = crmFieldApplicable === null || crmFieldApplicable === void 0 ? void 0 : crmFieldApplicable.filter((field) => field.label
                                                                .toLowerCase()
                                                                .includes(userQuery.toLowerCase()));
                                                            resolve({
                                                                results: filteredResults !== null && filteredResults !== void 0 ? filteredResults : [],
                                                            });
                                                        });
                                                    }, onSelect: (option) => {
                                                        field.onChange(option);
                                                        if (!option)
                                                            return;
                                                        setLocalField((prev) => {
                                                            return Object.assign(Object.assign({}, prev), { [fieldMapping.id]: option });
                                                        });
                                                        setFieldMappings((prev) => prev.map((f) => {
                                                            if (f.id === fieldMapping.id) {
                                                                f.field = option;
                                                            }
                                                            return f;
                                                        }));
                                                        handleTargetChange("CRM", true);
                                                    }, selectedOption: field.value, placeholder: "CRM field to use as delivery target" }))] }) }), _jsx(FormDescription, { children: "This is the CRM field whose value you want to update" }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "auto_apply_after_n_minutes", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Automatically send after this many minutes (zero means never)" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({ type: "number" }, field, { onChange: (e) => {
                                                field.onChange(e.target.valueAsNumber);
                                                setFieldMappings((prev) => {
                                                    return prev.map((f) => {
                                                        if (f.id === fieldMapping.id) {
                                                            f.auto_apply_after_n_minutes =
                                                                e.target.valueAsNumber;
                                                        }
                                                        return f;
                                                    });
                                                });
                                            } })) }), _jsx(FormDescription, { children: "Changes apply only to future calls" }), _jsx(FormMessage, {})] })) }), _jsx(FormField, { control: form.control, name: "update_type", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Update type" }), _jsxs(Select, Object.assign({ onValueChange: (v) => {
                                            field.onChange(v);
                                            setFieldMappings((prev) => {
                                                return prev.map((f) => {
                                                    if (f.id === fieldMapping.id) {
                                                        f.update_type = v;
                                                    }
                                                    return f;
                                                });
                                            });
                                        }, defaultValue: field.value }, { children: [_jsx(FormControl, { children: _jsx(SelectTrigger, { children: _jsx(SelectValue, { placeholder: "Select update type" }) }) }), _jsx(SelectContent, { children: Object.values(FieldMappingUpdateType).map((value) => (_jsx(SelectItem, Object.assign({ value: value }, { children: `${value.charAt(0).toUpperCase()}${replaceUnserscoreWithSpace(value.slice(1)).toLowerCase()}` })))) })] })), _jsx(FormDescription, { children: "This denotes how the field will be updated" }), _jsx(FormMessage, {})] })) })] })) })) }) })));
};
const SlackDeliveryTargetSelection = ({ tenant, fieldMapping, setFieldMappings, setLocalField, handleTargetChange, }) => {
    const slackTargetSchema = z.object({
        slackChannelDetails: z.object({
            value: z.string(),
            label: z.string(),
        }),
        auto_apply_after_n_minutes: z.number(),
    });
    const form = useForm({
        resolver: zodResolver(slackTargetSchema),
        defaultValues: {
            slackChannelDetails: {
                value: fieldMapping.channel_id,
                label: fieldMapping.channel_name,
            },
            auto_apply_after_n_minutes: fieldMapping.auto_apply_after_n_minutes,
        },
    });
    const queryClient = useQueryClient();
    const slackChannelsFetcher = (userQuery, ids, _cursor) => __awaiter(void 0, void 0, void 0, function* () {
        // TODO: Update this when we add support for searching slack channels by name
        // and paginate the results.
        const queryResponse = yield queryClient.fetchQuery({
            queryKey: ["slackChannels"],
            queryFn: getSlackChannelsForPostCall,
            // To not refetch on every user query
            staleTime: Number.POSITIVE_INFINITY,
        });
        return {
            results: [
                {
                    name: "Send to internal meeting participants",
                    id: "internal_participants",
                },
                ...queryResponse.private,
                ...queryResponse.public,
            ]
                .filter((channel) => channel.name.toLowerCase().includes(userQuery.toLowerCase()))
                .slice(0, 200)
                .map((obj) => {
                return { label: obj.name, value: obj.id };
            }),
        };
    });
    return (_jsx("div", Object.assign({ className: "max-h-full overflow-y-auto pb-4" }, { children: _jsx(ScrollArea, { children: _jsx(Form, Object.assign({}, form, { children: _jsxs("form", Object.assign({ onSubmit: form.handleSubmit((values) => __awaiter(void 0, void 0, void 0, function* () { return console.log("form submitted", values); })), className: "space-y-8" }, { children: [_jsx(FormField, { control: form.control, name: "slackChannelDetails", render: ({ field }) => {
                                var _a;
                                return (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Destination" }), _jsx(FormControl, { children: _jsx(AsyncSearchSelect, { dataFetcher: slackChannelsFetcher, selectedOption: (fieldMapping === null || fieldMapping === void 0 ? void 0 : fieldMapping.channel_id)
                                                    ? {
                                                        value: fieldMapping.channel_id,
                                                        label: (_a = fieldMapping.channel_name) !== null && _a !== void 0 ? _a : "",
                                                    }
                                                    : undefined, onSelect: (option) => {
                                                    field.onChange(option);
                                                    setFieldMappings((prev) => {
                                                        return prev.map((f) => {
                                                            if (f.id === fieldMapping.id) {
                                                                f.channel_id = option === null || option === void 0 ? void 0 : option.value;
                                                                f.channel_name = option === null || option === void 0 ? void 0 : option.label;
                                                            }
                                                            return f;
                                                        });
                                                    });
                                                    if (!option)
                                                        return;
                                                    setLocalField((prev) => {
                                                        return Object.assign(Object.assign({}, prev), { [fieldMapping.id]: option });
                                                    });
                                                    handleTargetChange("Slack", true);
                                                }, buttonClasses: "bg-white rounded-lg" }) }), _jsx(FormDescription, { children: "The is the channel where the content will be sent" }), _jsx(FormMessage, {})] }));
                            } }), _jsx(FormField, { control: form.control, name: "auto_apply_after_n_minutes", render: ({ field }) => (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Automatically send after this many minutes (zero means never)" }), _jsx(FormControl, { children: _jsx(Input, Object.assign({ type: "number" }, field, { onChange: (e) => {
                                                field.onChange(e.target.valueAsNumber);
                                                setFieldMappings((prev) => {
                                                    return prev.map((f) => {
                                                        if (f.id === fieldMapping.id) {
                                                            f.auto_apply_after_n_minutes =
                                                                e.target.valueAsNumber;
                                                        }
                                                        return f;
                                                    });
                                                });
                                            } })) }), _jsx(FormDescription, { children: "Changes apply only to future calls" }), _jsx(FormMessage, {})] })) })] })) })) }) })));
};
const ClipLibraryDeliveryTargetSelection = ({ tenant, fieldMapping, setFieldMappings, setLocalField, handleTargetChange, }) => {
    var _a;
    const clipLibraryTargetSchema = z.object({
        tags: z.array(z.string()).min(1, {
            message: "Select at least one tag",
        }),
        execute_after_n_minutes: z.number().min(0, {
            message: "Must be greater than 0",
        }),
    });
    const form = useForm({
        resolver: zodResolver(clipLibraryTargetSchema),
        defaultValues: {
            tags: (_a = fieldMapping === null || fieldMapping === void 0 ? void 0 : fieldMapping.tags) !== null && _a !== void 0 ? _a : [],
            execute_after_n_minutes: 1,
        },
    });
    return (_jsx("div", Object.assign({ className: "max-h-full overflow-y-auto pb-4" }, { children: _jsx(ScrollArea, { children: _jsx(Form, Object.assign({}, form, { children: _jsx("form", Object.assign({ onSubmit: form.handleSubmit((values) => __awaiter(void 0, void 0, void 0, function* () { return console.log("form submitted", values); })), className: "space-y-8" }, { children: _jsx(FormField, { control: form.control, name: "tags", render: ({ field }) => {
                            var _a;
                            return (_jsxs(FormItem, { children: [_jsx(FormLabel, { children: "Tags" }), _jsx(FormControl, { children: _jsx(TagsSelector, { selectedTags: (_a = fieldMapping.tags) !== null && _a !== void 0 ? _a : [], onChange: (option) => {
                                                field.onChange(option.map((o) => o.value));
                                                setFieldMappings((prev) => {
                                                    return prev.map((f) => {
                                                        var _a;
                                                        if (f.id === fieldMapping.id) {
                                                            f.tags = (_a = option === null || option === void 0 ? void 0 : option.map((o) => o.value)) !== null && _a !== void 0 ? _a : [];
                                                        }
                                                        return f;
                                                    });
                                                });
                                                setLocalField((prev) => {
                                                    var _a;
                                                    return Object.assign(Object.assign({}, prev), { [fieldMapping.id]: (_a = option[0]) !== null && _a !== void 0 ? _a : undefined });
                                                });
                                                handleTargetChange("ClipLibrary", !!option.length);
                                            } }) }), _jsx(FormDescription, { children: "The tags that will be used to filter the clips in the clip library" }), _jsx(FormMessage, {})] }));
                        } }) })) })) }) })));
};
export default DeliveryTargetSelection;
