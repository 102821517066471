export const integrationListingContent = {
    Gmail: {
        shortDescription: "Sync emails with Wiser",
        longDescription: "Connect to your Gmail account to have Wiser sync emails related to relevant accounts and meetings. This will enable Wiser to provide insights and summaries based on your email conversations.",
        capabilities: ["Syncs your Gmail account inbox"],
        connectButtonLabel: "Sign in with Google",
        connectButtonLogo: "https://storage.googleapis.com/wiser-ai-public2/google-logo.png",
    },
    Slack: {
        shortDescription: "Receive sales call briefs over Slack",
        longDescription: "Connect to your Slack account to have Wiser send your team sales call briefs via Slack ahead of their calls with prospects.",
        capabilities: ["Receives sales call briefs to a Slack channel or as a DM"],
    },
    Salesforce: {
        shortDescription: "Sync records with Salesforce",
        longDescription: "Connect your Salesforce account to sync your Salesforce accounts, contacts, leads, and opportunities to Wiser. Enable your sales team to close more deals by summarizing CRM data with your reps ahead of calls and pushing updates to Salesforce automatically after calls.",
        capabilities: [
            "Sync in records from Salesforce for sales call briefings and account research",
            "Sync out updates to your Salesforce records for deal and account updates based on your conversations",
        ],
    },
    "Google Calendar": {
        shortDescription: "Sync events with Google Calendar",
        longDescription: "Connect your Google account and sync events with your Google Calendar. Increase your productivity by ensuring your schedule is always up to date - without manual data entry.",
        capabilities: ["Sync events from your Google Calendar"],
        connectButtonLabel: "Sign in with Google",
        connectButtonLogo: "https://storage.googleapis.com/wiser-ai-public2/google-logo.png",
    },
    Zoom: {
        shortDescription: "Identify meeting participants & open Wiser from Zoom",
        longDescription: "Connect your Zoom account to automatically detect meeting participants and send Wiser links directly within Zoom messages.",
        capabilities: [
            "Identify participant roles based on their Wiser profiles",
            "Share Wiser links with participants seamlessly during meetings",
        ],
    },
    HubSpot: {
        shortDescription: "Sync records with HubSpot",
        longDescription: "Connect your Hubspot account to sync your Hubspot accounts, contacts, and deals with Wiser. Enable your sales team to close more deals by summarizing Hubspot data with your reps ahead of calls and pushing deal updates to Hubspot automatically after calls.",
        capabilities: [
            "Sync in records from Hubspot for sales call briefings and account research",
            "Sync out updates to your Hubspot records for deal and account updates based on your conversations",
        ],
    },
    Gong: {
        shortDescription: "Sync call recordings from Gong",
        longDescription: "Connect your Gong account to sync your Gong call recordings with Wiser. This will let Wiser create call summaries and insights from your call recordings.",
        capabilities: ["Sync call recordings from Gong into Wiser"],
    },
};
export const DEFAULT_TABLE_LAYOUT = {
    table_name: "calls",
    name: "Default View",
    is_default: true,
    is_public: false,
    column_order: [],
    columns: {
        select: 35,
        name: 150,
        call_time: 150,
        call_type: 60,
        transcripts: 150,
        account_name: 80,
        wiser_joined: 40,
        playbook_items: 150,
        opportunity_name: 120,
    },
    query_string: 'order_by=[{"field":"call_time","order":"DESC"}]&filter={"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"EQUALS","field":"call_type","value":"External"}},{"where_condition":{"operator":"EQUALS","field":"wiser_joined","value":true}},{"where_condition":{"operator":"IN","field":"call_time","value":"Last+2+weeks"}}]}}]}}',
};
export const DEFAULT_USERS_TABLE_LAYOUT = {
    table_name: "users",
    name: "Default View",
    is_default: true,
    is_public: false,
    column_order: [],
    columns: {
        name: 150,
        team: 150,
        email: 150,
        select: 35,
        actions: 150,
        manager: 150,
        is_active: 40,
        no_live_bot: 40,
        custom_bot_name: 150,
        is_tenant_admin: 40,
        autojoin_external_meetings: 40,
        autojoin_internal_meetings: 40,
    },
    query_string: 'order_by=[{"field":"name","order":"ASC"}]&filter={"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"EQUALS","field":"is_active","value":true}}]}}',
};
export const DEFAULT_TEAM_TABLE_LAYOUT = {
    table_name: "teams",
    name: "Default View",
    is_default: true,
    is_public: false,
    column_order: [],
    columns: {
        name: 150,
        select: 45,
        actions: 150,
        parent_team_name: 150,
        applicable_meeting_template_names: 150,
        default_internal_meeting_template_name: 150,
        default_external_meeting_template_name: 150,
    },
    query_string: 'order_by=[{"field":"name","order":"ASC"}]',
};
export const DEFAULT_DEPARTMENT_TABLE_LAYOUT = {
    table_name: "teams",
    name: "Default View",
    is_default: true,
    is_public: false,
    column_order: [],
    columns: {
        name: 150,
        select: 45,
        actions: 150,
        parent_department_name: 150,
    },
    query_string: 'order_by=[{"field":"name","order":"ASC"}]',
};
export const DEFAULT_CALLS_GRID_LAYOUT = {
    columnOrder: {
        orderedColIds: [
            "name",
            "call_time",
            "call_type",
            "account_name",
            "opportunity_name",
            "wiser_joined",
        ],
    },
};
export const DEFAULT_CALLS_GRID_STATE = {
    id: 0,
    table_name: "calls",
    name: "Default View",
    state: DEFAULT_CALLS_GRID_LAYOUT,
    fingerprint_v2: "0",
    is_default: true,
    is_public: false,
};
export const DEFAULT_USER_TIMEZONE = "America/Los_Angeles";
export const PARAGON_REDIRECT_URL = "https://passport.useparagon.com/oauth";
export const LOCAL_URL = "http://localhost:8010";
export const STAGING_URL = "https://staging.getwiser.io";
