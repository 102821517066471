import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { ContactAvatar } from "../../../common/contact_avatar";
export const ContactDetailSideView = ({ contact, onClose, }) => {
    const renderWorkExperience = () => {
        return (_jsxs("div", Object.assign({ className: "flex flex-col self-stretch items-start gap-4 grow" }, { children: [_jsx("span", Object.assign({ className: "text-xs font-medium text-wds-gray-5" }, { children: "Work history" })), !contact.experiences || contact.experiences.length === 0 ? (_jsx("span", Object.assign({ className: "text-sm text-wds-gray-5 font-medium" }, { children: "No work history found for this contact" }))) : (_jsx(_Fragment, { children: contact.experiences.map((experience, index) => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "flex flex-col justify-center text-sm items-start gap-1" }, { children: [_jsx("span", Object.assign({ className: "font-bold text-black" }, { children: experience.company })), _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-1 pl-4" }, { children: [_jsx("span", Object.assign({ className: "text-black" }, { children: experience.title })), _jsx("span", Object.assign({ className: "text-xs text-wds-gray-5" }, { children: `${experience.start_date} - ${(_a = experience.end_date) !== null && _a !== void 0 ? _a : "present"}` }))] }))] }), index));
                    }) }))] })));
    };
    return (_jsxs("div", Object.assign({ className: "flex flex-col p-6 gap-4 items-start w-full overflow-y-auto" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsx(ContactAvatar, { contact: contact }), _jsx(Button, Object.assign({ variant: "ghost", size: "icon", className: "text-black h-5 w-5 hover:border", onClick: onClose }, { children: _jsx(ChevronDoubleRightIcon, { className: "h-3.5 w-3.5 shrink-0", "aria-hidden": "true" }) }))] })), renderWorkExperience()] })));
};
