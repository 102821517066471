var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import * as endpoints from "./common/endpoints";
import WaitingSpinner from "./waiting_spinner";
export default function TenantPicker() {
    const [tenants, setTenants] = useState([]);
    const [tenantId, setTenantId] = useState(0);
    const [switchingToTenantId, setSwitchingToTenantId] = useState(0);
    useEffect(() => {
        endpoints.getTenants().then(({ tenants, active_tenant_id }) => {
            tenants = tenants.filter((tenant) => !tenant.name.startsWith("test-"));
            setTenants(tenants);
            setTenantId(active_tenant_id);
        });
    }, []);
    const setActiveTenant = (tenantId) => __awaiter(this, void 0, void 0, function* () {
        setSwitchingToTenantId(tenantId);
        const response = yield endpoints.setActiveTenant(tenantId);
        localStorage.setItem("wiser_jwt", response.wiser_jwt);
        localStorage.setItem("active_tenant_id", tenantId.toString());
        localStorage.setItem("jwt_issue_time_msec", Date.now().toString());
        setTenantId(tenantId);
        setSwitchingToTenantId(0);
        window.location.reload();
    });
    return (_jsxs(Popover, Object.assign({ className: "relative" }, { children: [_jsxs(Popover.Button, Object.assign({ className: "inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" }, { children: [_jsx("span", { children: "Tenants" }), _jsx(ChevronDownIcon, { className: "h-5 w-5", "aria-hidden": "true" })] })), _jsx(Popover.Panel, Object.assign({ className: "absolute right-0 z-10 mt-5 flex p-4" }, { children: _jsx("div", Object.assign({ className: "flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5" }, { children: _jsx("fieldset", { children: _jsx("div", Object.assign({ className: "divide-y divide-gray-200 border-b border-t border-gray-200" }, { children: tenants.map((tenant) => (_jsxs("div", Object.assign({ className: "relative flex items-start py-4" }, { children: [_jsx("div", Object.assign({ className: "min-w-0 flex-1 text-sm leading-6" }, { children: _jsx("div", Object.assign({ className: "select-none font-medium text-gray-900 px-4" }, { children: tenant.name })) })), _jsx("div", Object.assign({ className: "ml-3 flex h-6 items-center px-4" }, { children: switchingToTenantId === tenant.id ? (_jsx(WaitingSpinner, { text: "" })) : (_jsx("input", { id: `person-${tenant}`, name: `person-${tenant}`, type: "checkbox", className: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600", checked: tenantId === tenant.id, onChange: () => __awaiter(this, void 0, void 0, function* () {
                                                yield setActiveTenant(tenant.id);
                                            }), disabled: !!switchingToTenantId })) }))] }), tenant.id))) })) }) })) }))] })));
}
