import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getActiveTenant, getCrmAccount } from "../../common/endpoints";
import { SettingsLoader } from "../../common/loaders";
import { CenterScreen } from "../../settings/styled_components";
import { useAccountResearchBlocks } from "./use_account_research_blocks";
import { AccountResearchDrawerBody } from "./v2/drawer_body";
import { AccountResearchDrawerHeader } from "./v2/drawer_header";
export const AccountPage = ({ accId }) => {
    // We get the accId in the case this is loaded from the calls table
    const { accountIdParam } = useParams();
    const accountId = accId ? Number(accId) : Number(accountIdParam);
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const { data: account, isLoading: accountLoading } = useQuery({
        queryKey: ["crmAccount", accountId],
        queryFn: () => getCrmAccount(accountId, /* includeNonCrmAccounts= */ true),
    });
    const { accountBlocks: accountBlocksToRender, scrollToBlock: scrollToBlockHandler, blocksRefCallback, accountBrickSetsWithBricks, allBricks, setHighlightedBlockIds, } = useAccountResearchBlocks({
        account,
    });
    if (accountLoading || !account || !activeTenant) {
        return (_jsx(CenterScreen, { children: _jsx(SettingsLoader, {}) }));
    }
    return (_jsxs("div", Object.assign({ className: "flex w-full flex-col items-center h-[calc(100vh - 3.125rem)] lg:h-screen" }, { children: [_jsx(AccountResearchDrawerHeader, {}), _jsx(AccountResearchDrawerBody, { account: account, accountBlocks: accountBlocksToRender, scrollToBlock: scrollToBlockHandler, blocksRefCallback: blocksRefCallback, accountBrickSetsWithBricks: accountBrickSetsWithBricks, setHighlightedBlockIds: setHighlightedBlockIds, allBricks: allBricks !== null && allBricks !== void 0 ? allBricks : [] })] })));
};
