var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useState, } from "react";
import { Button } from "@/components/ui/button";
import { DataTable } from "@/components/ui/data_table";
import { useMatchingLayout } from "@/components/ui/data_table_toolbar_hooks";
import { DataTableToolbarV2 } from "@/components/ui/data_table_toolbar_v2";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient, } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import BaseModal2 from "../base_modal2";
import { createMeetingTemplate, createPreCallPrepBlock, updateMeetingTemplate, updatePreCallPrepBlock, } from "../common/endpoints";
import CreateOrEditBriefing from "../pre_call_prep/create_or_edit_briefing";
import { useDataTable } from "../table_hooks/use_data_table";
import { DEFAULT_MEETING_TEMPLATE_TABLE_LAYOUT, MEETING_TEMPLATE_SCHEMA, getColumns, useFormFieldMeta, } from "./meeting_template.helpers";
import { MeetingTemplateFloatingBar } from "./meeting_template_floating_bar";
const MeetingTemplateTable = (props) => {
    var _a, _b, _c, _d;
    const location = useLocation();
    const queryClient = useQueryClient();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const onRowSelect = useCallback((t) => {
        setSelectedTemplate(t);
        setModalOpen(true);
    }, [setSelectedTemplate, setModalOpen]);
    const columns = useMemo(() => getColumns(onRowSelect), [getColumns, onRowSelect]);
    const [columnOrder, setColumnOrder] = useState(columns.map((c) => c.id));
    const matchingLayout = useMatchingLayout(props.layouts, props.searchParams);
    const [templateSelectionBlockOpen, setTemplateSelectionBlockOpen] = useState(false);
    const [templateSelectionPrompt, setTemplateSelectionPrompt] = useState((_a = props.selectTemplateBlock) === null || _a === void 0 ? void 0 : _a.prompt);
    const [selectedDataSources, setSelectedDataSources] = useState(new Set(((_b = props.selectTemplateBlock) === null || _b === void 0 ? void 0 : _b.data_sources) || []));
    const [brickOutputStructure, setBrickOutputStructure] = useState((_c = props.selectTemplateBlock) === null || _c === void 0 ? void 0 : _c.structured_output_format);
    const [brickSetIds, setBrickSetIds] = useState(((_d = props.selectTemplateBlock) === null || _d === void 0 ? void 0 : _d.brick_set_ids) || []);
    const formFieldMeta = useFormFieldMeta(queryClient, selectedTemplate);
    const templateCreate = useMutation({
        mutationFn: createMeetingTemplate,
        onSuccess: (template) => {
            queryClient.invalidateQueries();
            setModalOpen(false);
        },
    });
    const templateUpdate = useMutation({
        mutationFn: updateMeetingTemplate,
        onSuccess: (template) => {
            queryClient.invalidateQueries();
            setSelectedTemplate(undefined);
            setModalOpen(false);
        },
    });
    const saveTemplateSelectionBlock = () => __awaiter(void 0, void 0, void 0, function* () {
        if (props.selectTemplateBlock) {
            yield updatePreCallPrepBlock(props.selectTemplateBlock.id, "Template Selection Rules", Array.from(selectedDataSources), templateSelectionPrompt || "", [], "OPENAI_FLAGSHIP");
        }
        else {
            yield createPreCallPrepBlock("Template Selection Rules", Array.from(selectedDataSources), templateSelectionPrompt || "", [], "MEETING_TEMPLATE_ASSIGNMENT");
        }
        yield queryClient.invalidateQueries({
            queryKey: ["select_template_block"],
        });
        setTemplateSelectionBlockOpen(false);
    });
    const { table } = useDataTable({
        data: props.meetingTemplates,
        columns,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: {
                precall_notification_duration: false,
                precall_email_enabled: false,
                precall_slack_enabled: false,
                custom_bot_name: false,
                no_live_bot: false,
                timezone: false,
                total_internal_meetings: false,
            },
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
    });
    const renderNewTable = () => {
        var _a;
        return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, columnOrder: columnOrder, setColumnOrder: setColumnOrder, fetchNextPage: props.fetchNextPage, total: props.total, totalFetched: props.meetingTemplates.length, isFetching: props.isFetching, floatingBar: _jsx(MeetingTemplateFloatingBar, { table: table }) }, { children: _jsxs(DataTableToolbarV2, Object.assign({ table: table, columnIdMap: {}, defaultTableLayout: DEFAULT_MEETING_TEMPLATE_TABLE_LAYOUT, enableFiltering: false, enableSorting: false, showSearchBar: true, onSearchQueryChange: props.setSearchQuery, matchingLayout: matchingLayout, layouts: props.layouts }, { children: [_jsx(Button, Object.assign({ onClick: () => setTemplateSelectionBlockOpen(true) }, { children: "Set Template Selection Prompt" })), _jsxs(Button, Object.assign({ onClick: () => {
                                    setSelectedTemplate(undefined);
                                    setModalOpen(true);
                                } }, { children: ["Add", _jsx(PlusIcon, { className: "h-4 w-4 ml-2 stroke-2 text-wds-white" })] }))] })) })), modalOpen && (_jsx(BaseModal2, { title: `${selectedTemplate ? "Update" : "Create"} template`, description: "A meeting template describes a common type of meeting, like an internal meeting, discovery call, or demo call.", open: modalOpen, setOpen: setModalOpen, submitText: selectedTemplate ? "Update" : "Create", submitCallback: (values) => __awaiter(void 0, void 0, void 0, function* () {
                        if (selectedTemplate) {
                            yield templateUpdate.mutateAsync(Object.assign(Object.assign({}, values), { id: selectedTemplate.id }));
                        }
                        else {
                            yield templateCreate.mutateAsync(values);
                        }
                    }), zodSchema: MEETING_TEMPLATE_SCHEMA, formFieldMeta: formFieldMeta })), templateSelectionBlockOpen && (_jsx(BaseModal2, Object.assign({ open: templateSelectionBlockOpen, setOpen: setTemplateSelectionBlockOpen, title: "Set Template Selection Prompt", description: "This prompt controls how the AI selects a template for a meeting.", submitCallback: saveTemplateSelectionBlock, submitText: "Save", disableSubmit: false, zodSchema: z.object({}), formFieldMeta: {} }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: "ACCOUNT", name: "Template Selection Rules", setName: () => { }, prompt: templateSelectionPrompt, setPrompt: setTemplateSelectionPrompt, selectedDataSources: selectedDataSources, setSelectedDataSources: setSelectedDataSources, isGalleryBrick: !!((_a = props.selectTemplateBlock) === null || _a === void 0 ? void 0 : _a.gallery_brick_id), brickOutputStructure: brickOutputStructure, setBrickOutputStructure: setBrickOutputStructure, brickSetIds: brickSetIds, setBrickSetIds: setBrickSetIds }) })))] }));
    };
    return renderNewTable();
};
export default MeetingTemplateTable;
