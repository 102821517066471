var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import Request from "../common/request";
export const getDepartments = (json_query, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    const response = yield Request.get("/api/get_departments", {
        json_query: new_json_query,
        per_page: perPage,
        page,
    });
    return response;
});
export const downlodAllDepartments = (json_query) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    return yield Request.get("/api/export_all_teams", {
        json_query: new_json_query,
    }, {
        responseType: "blob",
    });
});
export const createDepartment = (values) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return yield Request.post("/api/create_department", {
        name: values.Name,
        parent_department_id: (_a = values["Parent department"]) === null || _a === void 0 ? void 0 : _a.value,
    });
});
export const updateDepartment = (values) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    return yield Request.post("/api/update_department", {
        id: values.id,
        name: values.Name,
        parent_department_id: (_b = values["Parent department"]) === null || _b === void 0 ? void 0 : _b.value,
    });
});
export const deleteDepartment = (departmentId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_department", { id: departmentId });
});
