var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Request from "../common/request";
export const getVideoDetails = (videoId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_video_details", {
        video_id: videoId,
    });
    return response;
});
export const getVideoComments = (videoId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.get("/api/get_video_comments", {
        video_id: videoId,
    });
    return response.comments || [];
});
export const createVideoComment = (videoId, text, parentCommentId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield Request.post("/api/create_video_comment", {
        video_id: videoId,
        text,
        parent_comment_id: parentCommentId || null,
    });
    return response;
});
export const deleteVideoComment = (commentId) => __awaiter(void 0, void 0, void 0, function* () {
    yield Request.post("/api/delete_video_comment", {
        comment_id: commentId,
    });
});
