import { v4 as uuid4 } from "uuid";
// Utility function to generate a UUID
const generateUUID = () => {
    return uuid4();
};
export class QueryBuilder {
    constructor(config) {
        this.filters = new Map();
        this.filterGroups = new Map();
        this.topLevelFilterGroupId = null;
        this.config = config;
    }
    getFilterGroup(id) {
        const group = this.filterGroups.get(id);
        if (!group) {
            throw new Error(`FilterGroup with ID ${id} does not exist.`);
        }
        return group;
    }
    // Adds a filter to a specific filter group
    addFilter(filterGroupId, filter) {
        var _a;
        const group = this.getFilterGroup(filterGroupId);
        const maxSubConditions = (_a = this.config.maxSubConditions) !== null && _a !== void 0 ? _a : 3;
        if (group.conditions.length >= maxSubConditions) {
            throw new Error(`FilterGroup with ID ${filterGroupId} reached maximum number of subconditions`);
        }
        this.filters.set(filter.id, filter);
        group.conditions.push(filter);
    }
    // Adds a new filter group to an existing filter group.
    // For creating the first top-level filter group, keep the filterGroupId as undefined
    addFilterGroup(filterGroupId, filterGroup) {
        var _a;
        if (!filterGroupId && this.topLevelFilterGroupId !== null) {
            throw new Error("Cannot create a top-level filter group if one already exists!");
        }
        if (!filterGroupId) {
            this.topLevelFilterGroupId = filterGroup.id;
        }
        else {
            const group = this.getFilterGroup(filterGroupId);
            const maxSubConditions = (_a = this.config.maxSubConditions) !== null && _a !== void 0 ? _a : 3;
            if (group.conditions.length >= maxSubConditions) {
                throw new Error(`FilterGroup with ID ${filterGroupId} reached maximum number of subconditions`);
            }
            group.conditions.push(filterGroup);
        }
        this.filterGroups.set(filterGroup.id, filterGroup);
        filterGroup.conditions.forEach((subCondition) => {
            if (subCondition.objectType === "FILTER") {
                this.filters.set(subCondition.id, subCondition);
            }
            else if (subCondition.objectType === "FILTER_GROUP") {
                this.filterGroups.set(subCondition.id, subCondition);
            }
        });
    }
    // API to update an existing filter in the query
    updateFilter(filterGroupId, filterId, updatedFilter) {
        const group = this.getFilterGroup(filterGroupId);
        group.conditions = [
            ...group.conditions.map((subCondition) => {
                if (subCondition.id === filterId) {
                    return Object.assign({ id: filterId, objectType: "FILTER" }, updatedFilter);
                }
                return subCondition;
            }),
        ];
        // For book-keeping
        this.filters.set(filterId, Object.assign({ objectType: "FILTER", id: filterId }, updatedFilter));
    }
    updateFilterGroup(parentFilterGroupId, filterGroupId, updatedFilterGroup) {
        if (!parentFilterGroupId && this.topLevelFilterGroupId !== filterGroupId) {
            throw new Error(`Trying to update the top-level filter group but it isn't ${filterGroupId}`);
        }
        const updatedQueryFilterGroup = Object.assign({ objectType: "FILTER_GROUP", id: filterGroupId }, updatedFilterGroup);
        this.filterGroups.set(filterGroupId, updatedQueryFilterGroup);
        updatedFilterGroup.conditions.forEach((subCondition) => {
            if (subCondition.objectType === "FILTER") {
                this.filters.set(subCondition.id, subCondition);
            }
            else if (subCondition.objectType === "FILTER_GROUP") {
                this.filterGroups.set(subCondition.id, subCondition);
            }
        });
        if (!parentFilterGroupId) {
            // Updating the top-level group
            return;
        }
        const group = this.getFilterGroup(parentFilterGroupId);
        group.conditions = [
            ...group.conditions.map((subCondition) => {
                if (subCondition.id === filterGroupId) {
                    return updatedQueryFilterGroup;
                }
                return subCondition;
            }),
        ];
    }
    // API to remove an existing filter from a filter group
    removeFilter(filterGroupId, filterId) {
        const group = this.getFilterGroup(filterGroupId);
        group.conditions = [
            ...group.conditions.filter((subCondition) => subCondition.id !== filterId),
        ];
        this.filters.delete(filterId);
    }
    // API to remove an existing filter group
    removeFilterGroup(parentFilterGroupId, filterGroupId) {
        if (filterGroupId === this.topLevelFilterGroupId) {
            this.topLevelFilterGroupId = null;
        }
        this.filterGroups.delete(filterGroupId);
        if (!parentFilterGroupId)
            return;
        const group = this.getFilterGroup(parentFilterGroupId);
        group.conditions = [
            ...group.conditions.filter((subCondition) => subCondition.id !== filterGroupId),
        ];
    }
    getQueryFilter() {
        if (!this.topLevelFilterGroupId ||
            !this.filterGroups.has(this.topLevelFilterGroupId)) {
            return null;
        }
        return this.filterGroups.get(this.topLevelFilterGroupId);
    }
}
export const createFilter = (filter) => {
    return filter.value === null
        ? {
            objectType: "FILTER",
            id: generateUUID(),
            field: filter.field,
            operator: filter.operator,
            value: null,
            parentFilterGroupId: filter.parentFilterGroupId,
        }
        : {
            objectType: "FILTER",
            id: generateUUID(),
            field: filter.field,
            operator: filter.operator,
            value: filter.value,
            parentFilterGroupId: filter.parentFilterGroupId,
        };
};
export const createFilterGroup = (operator, conditions, parentId) => {
    return {
        objectType: "FILTER_GROUP",
        id: generateUUID(),
        operator,
        conditions,
        parentId,
    };
};
