import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import { cn } from "../lib/utils";
import { formatTime } from "../strings";
function renderDate(date) {
    if (new Date().toDateString() === date.toDateString())
        return "Today";
    if (new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toDateString() ===
        date.toDateString())
        return "Tomorrow";
    if (new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toDateString() ===
        date.toDateString())
        return "Yesterday";
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}
export const CallTimeDisplayOptions = {
    Default: "default",
    V3: "v3",
};
/**
 *
 * CallTimeDisplay is a React component that displays the start and end time of a call.
 * It takes a LiveCall object as a prop and formats the call_time and call_end_time to a readable format.
 * If the call_end_time is null, it only displays the start time.
 * @param call: LiveCall instance
 * @returns
 */
const CallTimeDisplay = ({ call, showDate, showTime = true, className = "", callTimeClassNames = "", containerClassName = "", // custom styles for call and time pills
showCalendarIcon = true, showClockIcon = false, variant = CallTimeDisplayOptions.Default, }) => {
    const textColor = variant === CallTimeDisplayOptions.Default
        ? "text-wds-gray-6"
        : "text-wds-blue-4";
    const iconColor = variant === CallTimeDisplayOptions.Default
        ? "text-wds-gray-5"
        : "text-wds-blue-4";
    return (_jsxs(_Fragment, { children: [showDate && (_jsx("div", Object.assign({ className: containerClassName || undefined }, { children: _jsxs("div", Object.assign({ className: cn("items-center font-lato leading-20 px-2 flex gap-2", textColor, className) }, { children: [showCalendarIcon && (_jsx(CalendarIcon, { className: `h-4 w-4 relative ${iconColor}` })), _jsxs("span", { children: [renderDate(new Date(call.call_time)), showTime && !showClockIcon && ","] })] })) }))), showTime && (_jsx("div", Object.assign({ className: containerClassName || undefined }, { children: _jsxs("div", Object.assign({ className: cn("font-lato leading-20 px-2 flex gap-2 items-center", textColor, className, callTimeClassNames) }, { children: [showClockIcon && (_jsx(ClockIcon, { className: `h-4 w-4 relative ${iconColor}` })), call.call_end_time
                            ? `${formatTime(new Date(call.call_time))} - ${formatTime(new Date(call.call_end_time))}`
                            : formatTime(new Date(call.call_time))] })) })))] }));
};
export default CallTimeDisplay;
