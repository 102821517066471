// Common types used throughout the app
// Types used in RPC's should go here as well.
import { z } from "zod";
// Wiser CRM type interfaces
export var CrmObjectTypeEnum;
(function (CrmObjectTypeEnum) {
    CrmObjectTypeEnum["ACCOUNT"] = "Account";
    CrmObjectTypeEnum["CONTACT"] = "Contact";
    CrmObjectTypeEnum["OPPORTUNITY"] = "Opportunity";
    CrmObjectTypeEnum["LEAD"] = "Lead";
})(CrmObjectTypeEnum || (CrmObjectTypeEnum = {}));
export const SALESFORCE_OBJECT_TYPES = [
    CrmObjectTypeEnum.ACCOUNT,
    CrmObjectTypeEnum.CONTACT,
    CrmObjectTypeEnum.OPPORTUNITY,
    CrmObjectTypeEnum.LEAD,
];
export const WISER_CRM_OBJECT_TYPES = [
    CrmObjectTypeEnum.ACCOUNT,
    CrmObjectTypeEnum.CONTACT,
    CrmObjectTypeEnum.OPPORTUNITY,
    CrmObjectTypeEnum.LEAD,
];
export const HUBSPOT_OBJECT_TYPES = [
    CrmObjectTypeEnum.ACCOUNT,
    CrmObjectTypeEnum.CONTACT,
    CrmObjectTypeEnum.OPPORTUNITY,
    CrmObjectTypeEnum.LEAD,
];
export var PostCallOutputTarget;
(function (PostCallOutputTarget) {
    PostCallOutputTarget["SALESFORCE"] = "SALESFORCE";
    PostCallOutputTarget["SLACK"] = "SLACK";
    PostCallOutputTarget["WISER_CRM"] = "WISER_CRM";
    PostCallOutputTarget["HUBSPOT"] = "HUBSPOT";
    PostCallOutputTarget["GMAIL"] = "GMAIL";
    PostCallOutputTarget["CRM_EVENT"] = "CRM_EVENT";
    PostCallOutputTarget["CLIP_LIBRARY"] = "CLIP_LIBRARY";
})(PostCallOutputTarget || (PostCallOutputTarget = {}));
export var JsonSchemaType;
(function (JsonSchemaType) {
    JsonSchemaType["STRING"] = "string";
    JsonSchemaType["NUMBER"] = "number";
    JsonSchemaType["BOOLEAN"] = "boolean";
    JsonSchemaType["INTEGER"] = "integer";
    JsonSchemaType["ARRAY"] = "array";
})(JsonSchemaType || (JsonSchemaType = {}));
export var CallFilter;
(function (CallFilter) {
    CallFilter["DATE"] = "date";
    CallFilter["ACCOUNT"] = "account";
    CallFilter["CONTACT"] = "contact";
    CallFilter["SUBQUERY"] = "subQuery";
    CallFilter["OWNER"] = "owner";
})(CallFilter || (CallFilter = {}));
export var MatchOptions;
(function (MatchOptions) {
    MatchOptions["MATCH_ANY"] = "matchAny";
    MatchOptions["MATCH_ALL"] = "matchAll";
})(MatchOptions || (MatchOptions = {}));
export const ChatSourceSchema = z.enum([
    "Account info",
    "Past call transcripts",
    "This deal",
    "This call",
    "Knowledge base",
]);
export const ChatApplicabletoSchema = z.enum([
    "Account",
    "Live Call",
    "Post Call Output",
]);
export var CallState;
(function (CallState) {
    CallState["DEFAULT"] = "DEFAULT";
    CallState["CALL_HAS_JOIN_TOKEN"] = "CALL_HAS_JOIN_TOKEN";
    CallState["PARTICIPANT_WEBHOOK_RECEIVED"] = "PARTICIPANT_WEBHOOK_RECEIVED";
    CallState["SOCKET_INITIALIZED"] = "SOCKET_INITIALIZED";
    CallState["JOIN_ATTEMPTED"] = "JOIN_ATTEMPTED";
    CallState["IN_WAITING_ROOM"] = "IN_WAITING_ROOM";
    CallState["KICKED_FROM_WAITING_ROOM"] = "KICKED_FROM_WAITING_ROOM";
    CallState["JOINED"] = "JOINED";
    CallState["PARTICIPANTS_JOINED"] = "PARTICIPANTS_JOINED";
    CallState["HOST_PRESENT"] = "HOST_PRESENT";
    CallState["HOST_SETTINGS_BLOCK_LOCAL_RECORDING"] = "HOST_SETTINGS_BLOCK_LOCAL_RECORDING";
    CallState["RECORDING_PERMISSION_GRANTED"] = "RECORDING_PERMISSION_GRANTED";
    CallState["TRANSCRIPT_LOGGED"] = "TRANSCRIPT_LOGGED";
    CallState["BOT_STOPPED_UNEXPECTEDLY"] = "BOT_STOPPED_UNEXPECTEDLY";
    CallState["TRANSCRIPTION_STOPPED_UNEXPECTEDLY"] = "TRANSCRIPTION_STOPPED_UNEXPECTEDLY";
    CallState["UNEXPECTED_RELAY_DISCONNECT"] = "UNEXPECTED_RELAY_DISCONNECT";
    CallState["FATAL_BOT_ERROR"] = "FATAL_BOT_ERROR";
    CallState["KICKED_FROM_MEETING"] = "KICKED_FROM_MEETING";
    CallState["BUILT_FROM_BOT_FULL_AUDIO_RECORDING"] = "BUILT_FROM_BOT_FULL_AUDIO_RECORDING";
    CallState["BUILT_FROM_ZOOM_CLOUD_RECORDING"] = "BUILT_FROM_ZOOM_CLOUD_RECORDING";
    CallState["CALL_PROCESSING_COMPLETED"] = "CALL_PROCESSING_COMPLETED";
})(CallState || (CallState = {}));
export var FieldMappingUpdateType;
(function (FieldMappingUpdateType) {
    FieldMappingUpdateType["OVERWRITE"] = "OVERWRITE";
    FieldMappingUpdateType["APPEND"] = "APPEND";
    FieldMappingUpdateType["OVERWRITE_WITHOUT_MERGE"] = "OVERWRITE_WITHOUT_MERGE";
})(FieldMappingUpdateType || (FieldMappingUpdateType = {}));
export var TenantCreditTypeEnum;
(function (TenantCreditTypeEnum) {
    TenantCreditTypeEnum["BRICK"] = "BRICK";
})(TenantCreditTypeEnum || (TenantCreditTypeEnum = {}));
