import { useCallback } from "react";
export const useAgGridFilterChange = (gridApi, onSaveGridColumnState) => {
    const onFilterChange = useCallback((updatedFilter) => {
        var _a;
        if (!gridApi) {
            return;
        }
        gridApi.setGridOption("context", {
            wiserFilter: updatedFilter,
            wiserSort: (_a = gridApi.getGridOption("context")) === null || _a === void 0 ? void 0 : _a.wiserSort,
        });
        onSaveGridColumnState({
            type: "filterModified",
            api: gridApi,
            context: gridApi.getGridOption("context"),
        });
        gridApi.refreshServerSide();
    }, [gridApi, onSaveGridColumnState]);
    return { onFilterChange };
};
