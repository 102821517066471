import { jsx as _jsx } from "react/jsx-runtime";
import { getActiveTenant, getAppIntegrationData, getBricksByType, getColumnsForStructuredOutputsForCallsTable, getCrmFields, } from "@/common/endpoints";
import { AgDataGrid } from "@/components/ui/ag_data_grid";
import { DataTableSkeleton } from "@/components/ui/data_table_skeleton";
import { useBuildFieldsForFilter } from "@/table_hooks/use_filters";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { Suspense, useMemo } from "react";
import { DEFAULT_CALLS_GRID_STATE } from "../settings/constants";
import { getColumns } from "./calls_table_columns";
import { FIELDS, SORTABLE_FIELDS } from "./calls_table_filters";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
// By default, we just render the whole table. Some of these columns, though,
// shouldn't be user-facing (mostly ids).
const IGNORED_COLUMNS = [
    "call_end_time",
    "timestamp",
    "tenant_id",
    "id",
    "playbook_items",
    "participants_autojoin",
    "account_id",
    "external_account_id",
    "opportunity_id",
    "external_opportunity_id",
    "owner_id",
    "manager_ids",
    "team_ids",
    "department_ids",
    "lead_id",
    "playbook_data",
    "call_brick_structured_outputs",
    "deduped",
];
// List of columns that should use the cell renderer of the tanstack col def
const TANSTACK_CELL_RENDERERS = [
    "name",
    "participants",
    // Reference switchers
    "account_name",
    "opportunity_name",
    "call_state",
    "call_source", // (Google Meet, Zoom, etc)
];
function CallsGrid() {
    const { data: activeTenant } = useSuspenseQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const { data: crmFields } = useSuspenseQuery({
        queryKey: ["getCrmFields"],
        queryFn: getCrmFields,
    });
    const { data: structuredOutputColumns } = useSuspenseQuery({
        queryKey: ["structuredOutputColumns", "calls"],
        queryFn: getColumnsForStructuredOutputsForCallsTable,
    });
    const { data: allTrackerBricks } = useSuspenseQuery({
        queryKey: ["trackerBricks"],
        queryFn: () => getBricksByType("TRACKER"),
    });
    const { data: appIntegrationData } = useSuspenseQuery({
        queryKey: ["appIntegrationData"],
        queryFn: getAppIntegrationData,
    });
    const queryClient = useQueryClient();
    const tanstackColDefs = useMemo(() => {
        return getColumns(activeTenant, 
        /* filterQuery= */ undefined, crmFields, structuredOutputColumns, queryClient, 
        /* playbookItemGroups= */ [], allTrackerBricks, appIntegrationData);
    }, [
        activeTenant,
        crmFields,
        structuredOutputColumns,
        allTrackerBricks,
        appIntegrationData,
        queryClient,
    ]);
    const brickNames = useMemo(() => allTrackerBricks.map((item) => item.name), [allTrackerBricks]);
    const fieldsForFilter = useBuildFieldsForFilter({
        crmFields,
        fields: FIELDS,
        table: "calls",
        structuredOutputColumns,
        brickNames,
    });
    return (_jsx(AgDataGrid, { tableName: "calls", defaultGridState: DEFAULT_CALLS_GRID_STATE, ignoredColumns: IGNORED_COLUMNS, useTanstackCellRenderers: TANSTACK_CELL_RENDERERS, tanstackColDefs: tanstackColDefs, filterableFields: fieldsForFilter, sortableFields: SORTABLE_FIELDS, floatingBar: CallsTableFloatingBar }));
}
export function Grid() {
    return (_jsx(Suspense, Object.assign({ fallback: _jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true }) }, { children: _jsx(CallsGrid, {}) })));
}
