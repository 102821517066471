import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";
import { getTagColor } from "./utils";
const Tag = ({ name, className }) => {
    const { bgColor, textColor } = getTagColor(name);
    const tagUrl = `/library?filters=${encodeURIComponent(JSON.stringify({
        tags: [{ label: name, value: name }],
        teams: [],
        departments: [],
    }))}`;
    return (_jsx(Link, Object.assign({ to: tagUrl, onClick: (e) => e.stopPropagation(), target: "_blank", rel: "noopener noreferrer", className: cn(className, "hover:brightness-95"), style: {
            backgroundColor: bgColor,
            color: textColor,
        } }, { children: name })));
};
export default Tag;
