var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createBrick, createOrUpdateWorkflowTriggers, getWorkflowTriggers, setBrickLinksToTarget, updateBrick, } from "../common/endpoints";
import CreateOrEditBriefing from "../pre_call_prep/create_or_edit_briefing";
import { Drawer, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, } from "@/components/ui/drawer_dialog";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { cn } from "@/lib/utils";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { TabsContent } from "@radix-ui/react-tabs";
import AdvancedPromptSettings from "../bricks/advanced_prompt_settings";
import DeliveryTargetSelection from "../bricks/delivery_target_selection";
import { getTabs, useBrickEditDialogData } from "./brick_edit_dialog.helpers";
import BrickPreviews from "./brick_previews";
import { BrickWorkflowTriggers } from "./brick_workflow_triggers";
import { PreCallPrepTargetSelection } from "./pre_call_prep_target_selection";
const sourceBrickTypeMap = {
    ACCOUNT: ["ACCOUNT", "TRACKER"],
    CALL: ["CALL", "ACCOUNT", "TRACKER"],
    CONTACT: ["CONTACT", "ACCOUNT"],
    MEETING_TEMPLATE_ASSIGNMENT: ["MEETING_TEMPLATE_ASSIGNMENT"],
    CALL_OVERVIEW: ["CALL_OVERVIEW"],
    CALL_KEYPOINTS: ["CALL_KEYPOINTS"],
    ACCOUNT_PREVIEW: ["ACCOUNT_PREVIEW"],
    CALL_PREVIEW: ["CALL_PREVIEW"],
    MEETING_TEMPLATE_ASSIGNMENT_PREVIEW: ["MEETING_TEMPLATE_ASSIGNMENT_PREVIEW"],
    CALL_OVERVIEW_PREVIEW: ["CALL_OVERVIEW_PREVIEW"],
    CALL_KEYPOINTS_PREVIEW: ["CALL_KEYPOINTS_PREVIEW"],
    TRACKER: ["TRACKER"],
    REPORT: ["REPORT"],
};
const BrickEditDialog = ({ creatingBrick, setCreatingBrick, selectedBrickId, selectedType, selectedTypeLabel, allBricks, brickLinks, linkAvailability, tenant, }) => {
    const queryClient = useQueryClient();
    const { TYPE_TO_DELIVERY_TARGETS, memoisedSelectedBrick, memoisedSelectedBrickFieldMappings, memoisedSelectedSourceBricks, availableSourceBricks, existingBrickNames, contextType, } = useBrickEditDialogData({
        selectedBrickId,
        allBricks,
        brickLinks,
        linkAvailability,
        tenant,
        selectedType,
    });
    const [brickOutputStructure, setBrickOutputStructure] = useState(memoisedSelectedBrick.structured_output_format);
    const [tempBrickForPreviewId, setTempBrickForPreviewId] = useState();
    const [newBrickPrompt, setNewBrickPrompt] = useState(memoisedSelectedBrick.prompt);
    const [brickSetIds, setBrickSetIds] = useState(memoisedSelectedBrick.brick_set_ids);
    const [newBrickName, setNewBrickName] = useState(memoisedSelectedBrick.name);
    const [scoringCriteria, setScoringCriteria] = useState(memoisedSelectedBrick.scoring_criteria);
    const [selectedDataSources, setSelectedDataSources] = useState(new Set(memoisedSelectedBrick.data_sources));
    const [selectedDeliveryTargets, setSelectedDeliveryTargets] = useState(new Set(memoisedSelectedBrick.delivery_targets));
    const [fieldMappings, setFieldMappings] = useState(memoisedSelectedBrickFieldMappings);
    const [selectedSourceBricks, setSelectedSourceBricks] = useState(memoisedSelectedSourceBricks);
    const [selectedModel, setSelectedModel] = useState(memoisedSelectedBrick.model);
    const [selectedWebDataModel, setSelectedWebDataModel] = useState(memoisedSelectedBrick.web_data_model);
    const [suppressCitations, setSuppressCitations] = useState(memoisedSelectedBrick.suppress_citations);
    const [triggers, setTriggers] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [previewOrEditTabSelection, setPreviewOrEditTabSelection] = useState("create_or_edit");
    const potentialSourceBricks = allBricks
        .filter((brick) => sourceBrickTypeMap[selectedType].includes(brick.research_block_type))
        .filter((brick) => availableSourceBricks.includes(brick.id));
    const setBrickLinksMutation = useMutation({
        mutationFn: setBrickLinksToTarget,
        onSuccess: (response) => {
            queryClient.setQueryData(["getAllBricks"], (oldData) => {
                var _a;
                return {
                    bricks: (_a = oldData === null || oldData === void 0 ? void 0 : oldData.bricks) !== null && _a !== void 0 ? _a : [],
                    brick_links: response.brick_links,
                    link_availability: response.link_availability,
                };
            });
        },
    });
    const { data } = useQuery({
        queryKey: ["workflow_triggers", selectedBrickId],
        queryFn: () => {
            if (!selectedBrickId) {
                return [];
            }
            return getWorkflowTriggers(selectedBrickId);
        },
        enabled: !!selectedBrickId,
    });
    useEffect(() => {
        setTriggers(data !== null && data !== void 0 ? data : []);
    }, [data]);
    const createOrUpdateWorkflowTriggersMutation = useMutation({
        mutationFn: ({ brickId, triggers, }) => {
            return createOrUpdateWorkflowTriggers(brickId, triggers);
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({
                queryKey: ["workflow_triggers"],
            });
        },
    });
    const createBrickMutation = useMutation({
        mutationFn: createBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            const prevBricks = allBricks !== null && allBricks !== void 0 ? allBricks : [];
            queryClient.setQueryData(["getAllBricks"], {
                bricks: [...prevBricks, updatedBrick],
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
            yield createOrUpdateWorkflowTriggersMutation.mutateAsync({
                brickId: updatedBrick.id,
                triggers,
            });
        }),
        onSettled: () => {
            setIsSaving(false);
        },
    });
    const updateBrickMutation = useMutation({
        mutationFn: updateBrick,
        onSuccess: (updatedBrick) => __awaiter(void 0, void 0, void 0, function* () {
            queryClient.setQueryData(["getAllBricks"], {
                bricks: allBricks === null || allBricks === void 0 ? void 0 : allBricks.map((b) => b.id === updatedBrick.id ? Object.assign(Object.assign({}, b), updatedBrick) : b),
                brick_links: brickLinks,
                link_availability: linkAvailability,
            });
            yield setBrickLinksMutation.mutateAsync({
                sourceBrickIds: selectedSourceBricks.map((brick) => brick.id),
                targetBrickId: updatedBrick.id,
            });
            yield createOrUpdateWorkflowTriggersMutation.mutateAsync({
                brickId: updatedBrick.id,
                triggers,
            });
        }),
        onSettled: () => {
            setIsSaving(false);
        },
    });
    return (_jsx(Drawer, Object.assign({ modal: false, open: creatingBrick, onOpenChange: setCreatingBrick }, { children: _jsx(DrawerContent, Object.assign({ className: cn(previewOrEditTabSelection === "preview" ? "" : "lg:max-w-[50%]", "sm:max-w-[80%] h-full p-8 max-h-screen overflow-y-auto overflow-x-hidden") }, { children: _jsxs("div", Object.assign({ className: "flex flex-col h-full" }, { children: [_jsxs(DrawerHeader, Object.assign({ className: "flex-shrink-0" }, { children: [_jsx(DrawerTitle, Object.assign({ className: "text-[32px] font-normal text-wds-black" }, { children: "Prompt Editor" })), _jsxs(Tabs, Object.assign({ defaultValue: "create_or_edit", className: "w-full pb-0.5 pt-4", onValueChange: setPreviewOrEditTabSelection, value: previewOrEditTabSelection, variant: "underline" }, { children: [_jsx(TabsList, Object.assign({ className: "border-none gap-x-6 mb-8 flex-shrink-0" }, { children: getTabs(selectedType).map((tab) => (_jsx(TabsTrigger, Object.assign({ value: tab.value, variant: "underline" }, { children: tab.label }), tab.value))) })), _jsxs("div", Object.assign({ className: "overflow-y-auto flex-1 min-h-0" }, { children: [_jsx(TabsContent, Object.assign({ value: "create_or_edit" }, { children: _jsx(CreateOrEditBriefing, { researchBlockType: selectedType, name: newBrickName, setName: setNewBrickName, prompt: newBrickPrompt, setPrompt: setNewBrickPrompt, selectedDataSources: selectedDataSources, setSelectedDataSources: setSelectedDataSources, isGalleryBrick: !!selectedBrickId &&
                                                        allBricks.some((brick) => brick.id === selectedBrickId &&
                                                            brick.gallery_brick_id), potentialSourceBricks: potentialSourceBricks, selectedSourceBricks: selectedSourceBricks, setSelectedSourceBricks: setSelectedSourceBricks, selectedModel: selectedModel, setSelectedModel: setSelectedModel, existingBrickNames: existingBrickNames, brickOutputStructure: brickOutputStructure, setBrickOutputStructure: setBrickOutputStructure, brickSetIds: brickSetIds, setBrickSetIds: setBrickSetIds }) })), _jsx(TabsContent, Object.assign({ value: "preview" }, { children: contextType !== "report" && (_jsx(BrickPreviews, { newPrompt: newBrickPrompt, brickId: selectedBrickId !== null && selectedBrickId !== void 0 ? selectedBrickId : tempBrickForPreviewId, brickName: newBrickName, contextType: contextType, selectedDataSources: Array.from(selectedDataSources), selectedSourceBricks: selectedSourceBricks, selectedModel: selectedModel, selectedWebDataModel: selectedWebDataModel, suppressCitations: suppressCitations, scoringCriteria: scoringCriteria })) })), _jsxs(TabsContent, Object.assign({ value: "destinations" }, { children: [selectedType !== "CALL" && (_jsx(DeliveryTargetSelection, { deliveryTargets: selectedDeliveryTargets, setDeliveryTargets: setSelectedDeliveryTargets, deliveryTargetOptions: TYPE_TO_DELIVERY_TARGETS.get(selectedType), tenant: tenant, fieldMappings: fieldMappings, setFieldMappings: setFieldMappings })), selectedType === "CALL" && _jsx(PreCallPrepTargetSelection, {})] })), _jsx(TabsContent, Object.assign({ value: "advanced" }, { children: _jsx(AdvancedPromptSettings, { selectedModel: selectedModel, setSelectedModel: setSelectedModel, selectedWebDataModel: selectedDataSources.has("WebData")
                                                        ? selectedWebDataModel
                                                        : null, setSelectedWebDataModel: setSelectedWebDataModel, scoringCriteria: scoringCriteria, setScoringCriteria: setScoringCriteria, suppressCitations: suppressCitations, setSuppressCitations: setSuppressCitations }) })), _jsx(TabsContent, Object.assign({ value: "brick_workflow_triggers" }, { children: _jsx(BrickWorkflowTriggers, { brickType: selectedType, triggers: triggers, onTriggersChange: setTriggers }) }))] }))] }))] })), _jsxs(DrawerFooter, Object.assign({ className: "flex justify-end items-center sm:flex-col flex-shrink-0 mt-auto" }, { children: [_jsx(Separator, { orientation: "horizontal", className: "-mx-8 my-8" }), _jsx("div", Object.assign({ className: "flex flex-row w-full justify-between items-center" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full sm:flex-row sm:justify-between" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "ghost", className: "text-destructive font-semibold", onClick: () => setCreatingBrick(false) }, { children: "Cancel" })), _jsxs("div", Object.assign({ className: "flex flex-row gap-2" }, { children: [_jsx(Button, Object.assign({ type: "button", variant: "light", className: "rounded-lg", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        if (!selectedBrickId) {
                                                            // If the brick does not exist, we make a preview version
                                                            // TODO: I think this causes us to update bricks and flush the
                                                            // current data
                                                            const resp = yield createBrickMutation.mutateAsync({
                                                                name: newBrickName !== null && newBrickName !== void 0 ? newBrickName : "",
                                                                dataSources: [...selectedDataSources],
                                                                prompt: newBrickPrompt !== null && newBrickPrompt !== void 0 ? newBrickPrompt : "",
                                                                deliveryTargets: [...selectedDeliveryTargets],
                                                                researchBlockType: selectedType,
                                                                preview: true,
                                                                model: selectedModel,
                                                                webDataModel: selectedWebDataModel,
                                                                suppressCitations: suppressCitations,
                                                                scoringCriteria: scoringCriteria,
                                                                fieldMappings: fieldMappings,
                                                                structuredOutputFormat: brickOutputStructure,
                                                                brickSetIds: brickSetIds,
                                                            });
                                                            setTempBrickForPreviewId(resp.id);
                                                        }
                                                        setPreviewOrEditTabSelection("preview");
                                                    }) }, { children: "Preview Output" })), _jsx(Button, Object.assign({ type: "button", variant: "black", className: "rounded-lg", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        if (selectedBrickId) {
                                                            setIsSaving(true);
                                                            yield updateBrickMutation.mutateAsync({
                                                                id: selectedBrickId,
                                                                name: newBrickName !== null && newBrickName !== void 0 ? newBrickName : "",
                                                                dataSources: [...selectedDataSources],
                                                                prompt: newBrickPrompt !== null && newBrickPrompt !== void 0 ? newBrickPrompt : "",
                                                                deliveryTargets: [...selectedDeliveryTargets],
                                                                model: selectedModel,
                                                                webDataModel: selectedWebDataModel,
                                                                suppressCitations: suppressCitations,
                                                                scoringCriteria: scoringCriteria,
                                                                fieldMappings: fieldMappings,
                                                                structuredOutputFormat: brickOutputStructure,
                                                                brickSetIds: brickSetIds,
                                                            });
                                                        }
                                                        else {
                                                            const resp = yield createBrickMutation.mutateAsync({
                                                                name: newBrickName !== null && newBrickName !== void 0 ? newBrickName : "",
                                                                dataSources: [...selectedDataSources],
                                                                prompt: newBrickPrompt !== null && newBrickPrompt !== void 0 ? newBrickPrompt : "",
                                                                deliveryTargets: [...selectedDeliveryTargets],
                                                                researchBlockType: selectedType,
                                                                model: selectedModel,
                                                                webDataModel: selectedWebDataModel,
                                                                suppressCitations: suppressCitations,
                                                                scoringCriteria: scoringCriteria,
                                                                fieldMappings: fieldMappings,
                                                                structuredOutputFormat: brickOutputStructure,
                                                                brickSetIds: brickSetIds,
                                                            });
                                                        }
                                                        setCreatingBrick(false);
                                                    }), disabled: !newBrickName ||
                                                        !newBrickPrompt ||
                                                        existingBrickNames.has(newBrickName) }, { children: isSaving ? (_jsx(ArrowPathIcon, { className: "h-3.5 w-3.5 animate-spin" })) : ("Save") }))] }))] })) }))] }))] })) })) })));
};
export default BrickEditDialog;
