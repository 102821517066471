var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useToast } from "@/components/ui/use_toast";
import { cn } from "@/lib/utils";
import { ArrowRightIcon, MagnifyingGlassIcon, } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { debounce } from "../common/debounce";
import { copyBricks, getAllBricks, getAllGalleryBricks, } from "../common/endpoints";
import { CenterScreen } from "../settings/styled_components";
import { BrickCard } from "./brick_card";
export const BrickGalleryPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedBrickIds, setSelectedBrickIds] = useState(new Set());
    const [selectedBrickTypeTab, setSelectedBrickTypeTab] = useState("ACCOUNT");
    const [importingBrickIds, setImportingBrickIds] = useState(new Set());
    const { data: galleryPageResponse } = useQuery({
        queryKey: ["getAllGalleryBricks"],
        queryFn: getAllGalleryBricks,
    });
    const { data: existingTenantBricksResponse } = useQuery({
        queryKey: ["getExistingTenantBricks"],
        queryFn: getAllBricks,
    });
    const tabs = useMemo(() => {
        if (!galleryPageResponse)
            return [];
        return galleryPageResponse.brick_type_config
            .filter((brickTypeConfig) => galleryPageResponse.bricks.some((brick) => brick.research_block_type === brickTypeConfig.id))
            .map((brickTypeConfig) => {
            return {
                label: brickTypeConfig.label,
                value: brickTypeConfig.id,
            };
        });
    }, [galleryPageResponse]);
    const debouncedSearchQuery = debounce(searchQuery, 500);
    const allBricks = useMemo(() => {
        if (!galleryPageResponse)
            return [];
        return galleryPageResponse.bricks.filter((brick) => brick.name.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
            brick.prompt.toLowerCase().includes(debouncedSearchQuery.toLowerCase()));
    }, [galleryPageResponse, debouncedSearchQuery]);
    const bricksToRender = useMemo(() => {
        return allBricks.filter((brick) => brick.research_block_type === selectedBrickTypeTab);
    }, [allBricks, selectedBrickTypeTab]);
    const { toast } = useToast();
    const importPromptsMutation = useMutation({
        mutationFn: (brickIds) => {
            setImportingBrickIds(new Set(brickIds));
            return copyBricks(brickIds, /* fromGallery= */ true);
        },
        onSuccess: (_, brickIds) => {
            toast({
                title: `${brickIds.length} ${brickIds.length > 1 ? "prompts" : "prompt"} imported successfully!`,
                description: (_jsxs(Link, Object.assign({ to: "/custom_prompts", className: "flex items-center gap-2 text-wds-blue-3" }, { children: ["View Prompts List", _jsx(ArrowRightIcon, { className: "w-4 h-4" })] }))),
                variant: "green",
            });
        },
        onError: () => {
            toast({
                title: "Failed to import prompts!",
                description: "Prompts already exist.",
                variant: "destructive",
            });
        },
        onSettled: () => {
            setImportingBrickIds(new Set());
            setSelectedBrickIds(new Set());
        },
    });
    const onImportClick = (brickIds) => __awaiter(void 0, void 0, void 0, function* () {
        yield importPromptsMutation.mutateAsync(brickIds !== null && brickIds !== void 0 ? brickIds : Array.from(selectedBrickIds));
    });
    if (!galleryPageResponse)
        return null;
    return (_jsx("div", Object.assign({ className: "w-full lg:h-screen p-8" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-8 justify-center w-full items-start" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col items-start gap-3.5 w-full self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex items-center justify-between w-full self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-2xl font-medium" }, { children: "Prompts Gallery" })), _jsxs("div", Object.assign({ className: "h-10 flex items-center justify-end gap-2.5 pr-3 py-2 w-1/5 rounded-lg border border-wds-gray-3" }, { children: [_jsx("input", { type: "text", className: "w-full h-full text-base border-none focus:border-none focus:ring-0 focus:outline-none", placeholder: "Search prompt", value: searchQuery, onChange: (e) => setSearchQuery(e.target.value) }), _jsx(MagnifyingGlassIcon, { className: "w-4 h-4 shrink-0" })] }))] })), _jsxs(Alert, Object.assign({ variant: "default" }, { children: [_jsx(AlertTitle, Object.assign({ className: "text-base font-medium text-wds-gray-6" }, { children: "How does Wiser gallery work?" })), _jsx(AlertDescription, Object.assign({ className: "text-sm text-wds-gray-5" }, { children: "All the prompts under the Wiser gallery are managed by Wiser. If you want to make edits, you first need to import these to your custom prompt list." }))] }))] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start self-stretch gap-4" }, { children: [_jsx(Tabs, Object.assign({ defaultValue: tabs.length > 0 ? tabs[0].value : undefined, onValueChange: (value) => {
                                setSelectedBrickTypeTab(value);
                                setSelectedBrickIds(new Set());
                            }, className: "w-full border-b border-wds-gray-3 pb-0.5 pt-4" }, { children: _jsx(TabsList, Object.assign({ className: "border-none gap-x-6" }, { children: tabs.map((tab) => (_jsxs(TabsTrigger, Object.assign({ value: tab.value, className: "outline-none py-3 px-0 gap-2 text-sm font-normal justify-start items-center flex rounded-none rounded-tl-lg rounded-tr-lg border-wds-blue-3 data-[state=active]:border-b-2 data-[state=active]:bg-white data-[state=active]:text-wds-blue-3" }, { children: [tab.label, _jsx(Badge, { children: allBricks.filter((brick) => brick.research_block_type === tab.value).length })] }), tab.value))) })) })), _jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "font-medium text-black" }, { children: ["Prompts selected", " ", _jsxs("span", Object.assign({ className: "text-wds-blue-3" }, { children: ["(", selectedBrickIds.size, ")"] }))] })), selectedBrickIds.size > 0 && (_jsx(Button, Object.assign({ variant: "black", className: cn("py-3 px-2.5 rounded-lg", importingBrickIds.size > 0 && "opacity-50"), onClick: () => onImportClick(), disabled: importingBrickIds.size > 0 }, { children: _jsx("span", { children: `Import ${selectedBrickIds.size} ${selectedBrickIds.size > 1 ? "prompts" : "prompt"}` }) })))] })), _jsx("div", Object.assign({ className: "flex flex-wrap gap-4 mb-8 w-full items-start justify-start" }, { children: bricksToRender.length > 0 ? (bricksToRender.map((brick) => {
                                var _a;
                                return (_jsx(BrickCard, { brick: brick, isSelected: selectedBrickIds.has(brick.id), setIsSelected: (isSelected) => {
                                        if (isSelected) {
                                            setSelectedBrickIds((prev) => new Set(prev).add(brick.id));
                                        }
                                        else {
                                            setSelectedBrickIds((prev) => {
                                                const newSet = new Set(prev);
                                                newSet.delete(brick.id);
                                                return newSet;
                                            });
                                        }
                                    }, dataSourceConfig: galleryPageResponse.brick_data_source_config, onImportClick: () => onImportClick([brick.id]), isImporting: importingBrickIds.has(brick.id), isExisting: (_a = existingTenantBricksResponse === null || existingTenantBricksResponse === void 0 ? void 0 : existingTenantBricksResponse.bricks.some((b) => b.gallery_brick_id === brick.id)) !== null && _a !== void 0 ? _a : false }));
                            })) : (_jsx(CenterScreen, Object.assign({ className: "h-auto w-full text-lg text-wds-gray-5 font-medium" }, { children: "No bricks available" }))) }))] }))] })) })));
};
