var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { authorizeForGmailRead, getGmailIntegrationData, removeGmailAuth, } from "../common/endpoints";
import { integrationListingContent } from "./constants";
/**
 * Fetches & returns the data needed to load caledar integration data on first user login and integrations page
 */
const useGmailIntegrations = (onConnect) => {
    const queryClient = useQueryClient();
    const { data: gmailIntegrationData, isLoading: isGmailDataLoading, isError: calendarDataError, } = useQuery({
        queryKey: ["gmailIntegrationData"],
        queryFn: getGmailIntegrationData,
    });
    const disconnectGmailMutation = useMutation({
        mutationFn: removeGmailAuth,
        onSuccess: () => {
            queryClient.setQueryData(["gmailIntegrationData"], {
                status: "Not connected",
            });
        },
    });
    const fetchGmailAfterConnectionChange = () => __awaiter(void 0, void 0, void 0, function* () {
        queryClient.invalidateQueries({
            queryKey: ["gmailIntegrationData"],
        });
        if (onConnect) {
            onConnect();
        }
    });
    const connectGmailIntegration = () => __awaiter(void 0, void 0, void 0, function* () {
        authorizeForGmailRead(fetchGmailAfterConnectionChange);
    });
    const formattedIntegrations = useMemo(() => {
        return [
            {
                name: "Gmail",
                icon: "https://storage.googleapis.com/wiser-ai-public2/google-gmail-official.png",
                status: (gmailIntegrationData === null || gmailIntegrationData === void 0 ? void 0 : gmailIntegrationData.status) || "Not connected",
                handleConnect: connectGmailIntegration,
                handleDisconnect: disconnectGmailMutation.mutateAsync,
                listingContent: integrationListingContent.Gmail,
            },
        ];
    }, [gmailIntegrationData]);
    if (isGmailDataLoading || calendarDataError) {
        return {
            integrations: [],
            loaded: !isGmailDataLoading,
        };
    }
    return {
        integrations: formattedIntegrations,
        loaded: !isGmailDataLoading,
    };
};
export default useGmailIntegrations;
