import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatDistanceToNow } from "date-fns";
import DOMPurify from "dompurify";
import { useState } from "react";
import CommentInput from "./comment_input";
import { createVideoComment, deleteVideoComment, getVideoComments, } from "./endpoints";
import VideoCommentItem from "./video_comment_item";
const VideoComments = ({ videoId, hideTitle = false }) => {
    const queryClient = useQueryClient();
    const { data: comments = [] } = useQuery({
        queryKey: ["videoComments", videoId],
        queryFn: () => getVideoComments(Number.parseInt(videoId, 10)),
    });
    const [replyToComment, setReplyToComment] = useState(null);
    const createCommentMutation = useMutation({
        mutationFn: ({ text, parentCommentId, }) => createVideoComment(Number.parseInt(videoId, 10), DOMPurify.sanitize(text), parentCommentId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["videoComments", videoId],
            });
            setReplyToComment(null);
        },
    });
    const deleteCommentMutation = useMutation({
        mutationFn: ({ commentId }) => deleteVideoComment(commentId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["videoComments", videoId],
            });
        },
    });
    const handleNewComment = (text) => {
        createCommentMutation.mutate({ text });
    };
    const handleReply = (text, parentCommentId) => {
        createCommentMutation.mutate({
            text,
            parentCommentId,
        });
    };
    const handleDeleteComment = (commentId) => {
        // We assume all comments are "recording" type as the data structure doesn't provide a way to differentiate
        // If the API needs to differentiate between clip and recording comments, adjust this logic
        deleteCommentMutation.mutate({ commentId });
    };
    // Format date to a user-friendly format (e.g., "1 hr ago")
    const formatCommentDate = (dateString) => {
        try {
            const date = new Date(dateString);
            return `${formatDistanceToNow(date, { addSuffix: false })} ago`;
        }
        catch (e) {
            return "recently";
        }
    };
    // Group comments into parent comments and their replies
    const groupComments = (comments) => {
        const parentComments = [];
        const repliesByParentId = {};
        comments.forEach((comment) => {
            if (comment.parent_comment_id) {
                if (!repliesByParentId[comment.parent_comment_id]) {
                    repliesByParentId[comment.parent_comment_id] = [];
                }
                repliesByParentId[comment.parent_comment_id].push(comment);
            }
            else {
                parentComments.push(comment);
            }
        });
        return { parentComments, repliesByParentId };
    };
    const { parentComments, repliesByParentId } = groupComments(comments);
    return (_jsxs("div", Object.assign({ className: "mt-4 border border-wds-gray-3 rounded-lg p-4" }, { children: [!hideTitle && (_jsxs("div", Object.assign({ className: "font-semibold text-wds-gray-9 mb-4" }, { children: ["Comments (", comments.length, ")"] }))), comments.length === 0 && (_jsx("div", Object.assign({ className: "text-wds-gray-5 text-sm italic mb-4" }, { children: "No comments yet." }))), parentComments.map((comment) => (_jsxs("div", Object.assign({ className: "mb-6" }, { children: [_jsx(VideoCommentItem, { comment: comment, formatCommentDate: formatCommentDate, handleDeleteComment: handleDeleteComment, isParent: true, onReplyClick: () => setReplyToComment(replyToComment === comment.id ? null : comment.id) }), replyToComment === comment.id && (_jsx(CommentInput, { onSubmit: (text) => handleReply(text, comment.id), isPending: createCommentMutation.isPending, className: "mt-2 ml-10" })), repliesByParentId[comment.id] &&
                        repliesByParentId[comment.id].map((reply) => (_jsx(VideoCommentItem, { comment: reply, formatCommentDate: formatCommentDate, handleDeleteComment: handleDeleteComment, isParent: false, indentation: 1 }, reply.id)))] }), comment.id))), _jsx(CommentInput, { onSubmit: handleNewComment, isPending: createCommentMutation.isPending, className: "mt-2" })] })));
};
export default VideoComments;
