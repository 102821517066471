var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { cn } from "@/lib/utils";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cva } from "class-variance-authority";
import * as React from "react";
/**
 * Reusable and custom style supporting pre-styled tabs component.
 * @example
 * ```
 * <Tabs defaultValue="account" className="w-[400px]">
  <TabsList>
    <TabsTrigger value="account">Account</TabsTrigger>
    <TabsTrigger value="password">Password</TabsTrigger>
  </TabsList>
  <TabsContent value="account">Make changes to your account here.</TabsContent>
  <TabsContent value="password">Change your password here.</TabsContent>
</Tabs>
```
 */
const Tabs = React.forwardRef((_a, ref) => {
    var { className, variant = "default" } = _a, props = __rest(_a, ["className", "variant"]);
    return (_jsx(TabsPrimitive.Root, Object.assign({ ref: ref, className: cn(className) }, props)));
});
Tabs.displayName = TabsPrimitive.Root.displayName;
const tabsListVariants = cva("inline-flex h-10 items-center justify-center", {
    variants: {
        variant: {
            default: "p-1 text-wds-black rounded-full border-wds-gray-3 border-[1px]",
            rectangular: "p-1 text-wds-black border-wds-gray-3 border-[1px]",
            underline: "",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
const TabsList = React.forwardRef((_a, ref) => {
    var { className, variant } = _a, props = __rest(_a, ["className", "variant"]);
    return (_jsx(TabsPrimitive.List, Object.assign({ ref: ref, className: cn(tabsListVariants({ variant, className })) }, props)));
});
TabsList.displayName = TabsPrimitive.List.displayName;
const tabsTriggerVariants = cva("inline-flex items-center whitespace-nowrap transition-all", {
    variants: {
        variant: {
            default: [
                "justify-center rounded-full px-3 py-1.5 text-sm font-medium",
                "ring-offset-background focus-visible:outline-none focus-visible:ring-2",
                "focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none",
                "disabled:opacity-50 data-[state=active]:bg-wds-blue-1 data-[state=active]:text-wds-black",
                "data-[state=active]:font-bold data-[state=active]:shadow-sm w-full",
            ].join(" "),
            rectangular: [
                "justify-center px-3 py-1.5 text-sm font-medium",
                "ring-offset-background focus-visible:outline-none focus-visible:ring-2",
                "focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none",
                "disabled:opacity-50 data-[state=active]:text-wds-black hover:bg-wds-blue-1",
                "data-[state=active]:font-bold data-[state=active]:shadow-sm w-fit",
            ].join(" "),
            underline: [
                "outline-none py-3 px-0 text-sm font-normal justify-start items-center flex",
                "rounded-none rounded-tl-lg rounded-tr-lg border-wds-blue-3",
                "data-[state=active]:border-b-2 data-[state=active]:bg-white data-[state=active]:text-wds-blue-3",
            ].join(" "),
        },
    },
    defaultVariants: {
        variant: "default",
    },
});
const TabsTrigger = React.forwardRef((_a, ref) => {
    var { className, variant } = _a, props = __rest(_a, ["className", "variant"]);
    return (_jsx(TabsPrimitive.Trigger, Object.assign({ ref: ref, className: cn(tabsTriggerVariants({ variant, className })) }, props)));
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;
const TabsContent = React.forwardRef((_a, ref) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (_jsx(TabsPrimitive.Content, Object.assign({ ref: ref, className: cn("mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2", className) }, props)));
});
TabsContent.displayName = TabsPrimitive.Content.displayName;
export { Tabs, TabsList, TabsTrigger, TabsContent };
