var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getConvertedJsonQuery } from "../common/query_builder/json_query_converter";
import Request from "../common/request";
export const getUsers = (json_query, page = 1, perPage = 100) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    const response = yield Request.get("/api/get_users", {
        json_query: new_json_query,
        per_page: perPage,
        page,
    });
    return response;
});
export const downloadAllUsers = (json_query) => __awaiter(void 0, void 0, void 0, function* () {
    const new_json_query = getConvertedJsonQuery(json_query);
    return yield Request.get("/api/export_all_users", {
        json_query: new_json_query,
    }, {
        responseType: "blob",
    });
});
export const createUser = (values) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    return yield Request.post("/api/create_user_v2", {
        email: values.Email,
        is_active: true,
        is_tenant_admin: values["Is admin"],
        given_name: values["First name"],
        family_name: values["Last name"],
        custom_bot_name: values["Custom bot Name"],
        no_live_bot: values["Botless meetings"],
        manager: (_a = values.Manager) === null || _a === void 0 ? void 0 : _a.value,
        team: (_b = values.Team) === null || _b === void 0 ? void 0 : _b.value,
        department: (_c = values.Department) === null || _c === void 0 ? void 0 : _c.value,
        autojoin_external_meetings: values["Autojoin external meetings"],
        autojoin_internal_meetings: values["Autojoin internal meetings"],
        timezone: values.Timezone.value,
        user_type: values["Account type"],
    });
});
export const updateUser = (values) => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e, _f;
    return yield Request.post("/api/update_user_v2", {
        id: values.id,
        email: values.Email,
        is_active: values["Is active"],
        is_tenant_admin: values["Is admin"],
        given_name: values["First name"],
        family_name: values["Last name"],
        custom_bot_name: values["Custom bot Name"],
        no_live_bot: values["Botless meetings"],
        manager: (_d = values.Manager) === null || _d === void 0 ? void 0 : _d.value,
        team: (_e = values.Team) === null || _e === void 0 ? void 0 : _e.value,
        department: (_f = values.Department) === null || _f === void 0 ? void 0 : _f.value,
        autojoin_external_meetings: values["Autojoin external meetings"],
        autojoin_internal_meetings: values["Autojoin internal meetings"],
        timezone: values.Timezone.value,
        user_type: values["Account type"],
    });
});
export const deleteUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/delete_user_v2", { id: userId });
});
export const importUsers = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    formData.append("file", file);
    return yield Request.post("/api/import_users", formData);
});
export const sendUserInviteEmail = ({ from_email, to_email, subject, body, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Request.post("/api/send_user_invite_email", {
        from_email,
        to_email,
        subject,
        body,
    });
});
