import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@tanstack/react-query";
import { getCallRecordingDetailsPublic } from "./common/endpoints";
import PublicVideoAndTimeline from "./public_video_and_timeline";
const PublicCallRecordingPage = ({ expiringLinkId, }) => {
    var _a, _b, _c;
    const { data, isLoading } = useQuery({
        queryKey: ["callRecordingDetailsPublic", expiringLinkId],
        queryFn: () => getCallRecordingDetailsPublic(expiringLinkId),
    });
    if (isLoading)
        return _jsx("div", { children: "Loading..." });
    return (_jsx(PublicVideoAndTimeline, { title: (_a = data === null || data === void 0 ? void 0 : data.call_name) !== null && _a !== void 0 ? _a : "", videoUrl: (_b = data === null || data === void 0 ? void 0 : data.video_url) !== null && _b !== void 0 ? _b : null, speakerTimeline: (_c = data === null || data === void 0 ? void 0 : data.speaker_timeline) !== null && _c !== void 0 ? _c : [], callTime: data === null || data === void 0 ? void 0 : data.call_time }));
};
export default PublicCallRecordingPage;
