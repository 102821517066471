var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "@/components/ui/dropdown_menu";
import { cn } from "@/lib/utils";
import { DocumentDuplicateIcon, LinkIcon, ShareIcon, } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import BaseModal from "./base_modal";
import { createExpiringLinkForCallRecordingClip } from "./common/endpoints";
const ClipShareButton = ({ clipId, callId }) => {
    const [internalModalOpen, setInternalModalOpen] = useState(false);
    const [externalModalOpen, setExternalModalOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { children: _jsx(Button, Object.assign({ variant: "ghost", size: "sm", className: "inline-flex text-wds-black gap-2 py-2" }, { children: _jsx(ShareIcon, { className: "w-4 h-4 stroke-wds-gray-5" }) })) }), _jsxs(DropdownMenuContent, { children: [_jsx(DropdownMenuItem, Object.assign({ onClick: () => setInternalModalOpen(true) }, { children: "Share Internally" })), _jsx(DropdownMenuItem, Object.assign({ onClick: () => setExternalModalOpen(true) }, { children: "Share Externally" }))] })] }), internalModalOpen && (_jsx(InternalClipShareModal, { clipId: clipId, callId: callId, open: internalModalOpen, onClose: () => setInternalModalOpen(false) })), externalModalOpen && (_jsx(ExternalClipShareModal, { clipId: clipId, open: externalModalOpen, onClose: () => setExternalModalOpen(false) }))] }));
};
const InternalClipShareModal = ({ clipId, callId, open, onClose, }) => {
    const [shareLink, setShareLink] = useState("");
    useEffect(() => {
        const callLink = `${window.location.origin}/post_call/${callId}`;
        const clipIdParam = new URLSearchParams();
        clipIdParam.set("clip_id", clipId.toString());
        const url = new URL(callLink);
        url.searchParams.set("clip_id", clipId.toString());
        setShareLink(url.toString());
    }, [clipId]);
    return (_jsx(BaseModal, Object.assign({ title: "Share Link", description: "Share the link to the video with others within your organization.", open: open, setOpen: (open) => !open && onClose(), submitText: "Done", submitCallback: onClose, fields: [] }, { children: _jsx("div", Object.assign({ className: "flex flex-col gap-4" }, { children: _jsxs("div", Object.assign({ className: "w-full h-12 px-4 py-2 bg-wds-gray-3 rounded-lg justify-between items-center inline-flex hover:bg-wds-blue-2" }, { children: [_jsx("div", { children: shareLink || "Generate a link to share" }), _jsx("button", Object.assign({ className: "w-8 h-8 rounded-full justify-center items-center flex hover:bg-wds-blue-2 active:bg-wds-blue-3 transition-colors duration-150", onClick: () => {
                            navigator.clipboard.writeText(shareLink);
                        }, "aria-label": "Copy link" }, { children: _jsx(DocumentDuplicateIcon, { className: "w-5 h-5 relative" }) }))] })) })) })));
};
const ExternalClipShareModal = ({ clipId, open, onClose, }) => {
    const [shareLink, setShareLink] = useState("");
    const [accessExpirationDays, setAccessExpirationDays] = useState(365);
    const generateExternalLink = () => __awaiter(void 0, void 0, void 0, function* () {
        const resp = yield createExpiringLinkForCallRecordingClip(clipId, accessExpirationDays);
        const shareLink = `${window.location.origin}/shared/${resp.link_id}`;
        setShareLink(shareLink);
    });
    return (_jsx(BaseModal, Object.assign({ title: "Share Link", description: "Anyone with this link can view the video.", open: open, setOpen: (open) => !open && onClose(), submitText: "Done", submitCallback: onClose, fields: [] }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsxs("div", Object.assign({ className: "h-12 flex-col justify-start items-start gap-1 inline-flex" }, { children: [_jsx("div", Object.assign({ className: "font-bold leading-snug" }, { children: "Share access" })), _jsx("div", Object.assign({ className: "leading-snug" }, { children: "Specify when access to this call should expire via this link." }))] })), _jsxs("div", Object.assign({ className: "flex-col justify-start items-start gap-1 inline-flex" }, { children: [_jsx("div", Object.assign({ className: "justify-start items-start gap-1 inline-flex" }, { children: _jsx("div", Object.assign({ className: "font-medium" }, { children: "Access expires in" })) })), _jsx("div", Object.assign({ className: "h-10 w-1/4 bg-[#fafcfe] rounded-lg border border-[#cfdae9] justify-start items-center gap-2 inline-flex" }, { children: _jsxs("div", Object.assign({ className: "grow shrink basis-0 h-5 justify-between items-center flex" }, { children: [_jsx("input", { type: "number", placeholder: "e.g. 365", value: accessExpirationDays, onChange: (e) => {
                                            var _a;
                                            return setAccessExpirationDays(Math.max((_a = Number.parseInt(e.target.value)) !== null && _a !== void 0 ? _a : 0, 0));
                                        }, className: cn("px-4 mr-4 bg-transparent border-none focus:outline-none focus:ring-0", !!shareLink && "text-gray-400"), disabled: !!shareLink }), _jsx("div", { children: "days" })] })) }))] })), _jsx("div", { children: !shareLink ? (_jsxs(Button, Object.assign({ className: "gap-1", onClick: generateExternalLink }, { children: [_jsx("span", Object.assign({ className: "text-white" }, { children: "Generate Link" })), _jsx(LinkIcon, { className: "text-white w-4 h-4" })] }))) : null }), shareLink ? (_jsxs("div", Object.assign({ className: "w-full h-12 px-4 py-2 bg-wds-gray-3 rounded-lg justify-between items-center inline-flex hover:bg-wds-blue-2" }, { children: [_jsx("div", { children: shareLink }), _jsx("button", Object.assign({ className: "w-8 h-8 rounded-full justify-center items-center flex hover:bg-wds-blue-2 active:bg-wds-blue-3 transition-colors duration-150", onClick: () => {
                                navigator.clipboard.writeText(shareLink);
                            }, "aria-label": "Copy link" }, { children: _jsx(DocumentDuplicateIcon, { className: "w-5 h-5 relative" }) }))] }))) : null] })) })));
};
export default ClipShareButton;
