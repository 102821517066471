var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "@/components/ui/tooltip";
import { convertHeadersToBold } from "@/strings";
import { ArrowTopRightOnSquareIcon, CheckCircleIcon, } from "@heroicons/react/24/outline";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { getConnectedCrmLogo } from "../common/crm_logo";
import Ellipse from "../common/ellipse";
import { setCallAccountAndOpportunity, setCallLeadIds, } from "../common/endpoints";
import { getExternalCrmObjectLink } from "../crm/common/external_crm_link";
import { CrmObjectReferenceSwitcher } from "../crm/crm_object_reference_switcher";
import CallTimeDisplay from "../home/call_time_display";
import { CrmObjectTypeEnum, } from "../types";
import { getMeetingTypeEnumFromCallSource, getUserReadableStatus, } from "../video_playback_status_utils";
import { IMAGE_SRCS } from "../video_with_transcript_constants";
import { accountsFetcher, leadsFetcher, opportunitiesFetcher, } from "./calls_table_fetchers";
export function getColumns(tenant, filterQuery, crmFields, structuredOutputColumns, queryClient, playbookItemGroups, allTrackerBricks, appIntegrationData) {
    const renderBoolean = (value, yesText = "Yes", noText = "No") => {
        return (_jsxs("div", Object.assign({ className: "flex w-[6.25rem] items-center" }, { children: [value && (_jsxs(Badge, Object.assign({ variant: "green" }, { children: [_jsx(Ellipse, { classes: "h-[6px] w-[6px] my-[2px] mr-[6px] bg-green-600" }), yesText] }))), !value && (_jsxs(Badge, Object.assign({ variant: "gray" }, { children: [_jsx(Ellipse, { classes: "h-[6px] w-[6px] my-[2px] mr-[6px] bg-wds-gray-6" }), noText] })))] })));
    };
    // TODO: remove playbookItemGroups from the function signature after
    // we've migrated to bricks. In the meantime, only playbookItemGroups
    // OR allTrackerBricks will be defined, not both.
    // This dedupes the playbook items by name
    const newPlaybookItems = playbookItemGroups
        ? Object.values(playbookItemGroups.reduce((acc, item) => {
            acc[item.default_item.name] = item;
            return acc;
        }, {}))
        : null;
    const newTrackerBricks = allTrackerBricks
        ? Object.values(allTrackerBricks === null || allTrackerBricks === void 0 ? void 0 : allTrackerBricks.reduce((acc, item) => {
            acc[item.name] = item;
            return acc;
        }, {}))
        : null;
    const getConnectedCrmColumns = () => {
        const accountColumns = [];
        const opportunityColumns = [];
        const leadColumns = [];
        crmFields.forEach((crmField) => {
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.ACCOUNT)) {
                accountColumns.push({
                    id: `account_fields__z${crmField.id.toString()}`,
                    accessorKey: `account_fields__z${crmField.id.toString()}`,
                    header: crmField.name.startsWith("Account:")
                        ? crmField.name
                        : `Account: ${crmField.name}`,
                    cell: ({ row }) => { var _a; return (_a = row.original.account_fields) === null || _a === void 0 ? void 0 : _a[`z${crmField.id.toString()}`]; },
                });
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.OPPORTUNITY)) {
                opportunityColumns.push({
                    id: `opportunity_fields__z${crmField.id.toString()}`,
                    accessorKey: `opportunity_fields__z${crmField.id.toString()}`,
                    header: crmField.name.startsWith("Opportunity:")
                        ? crmField.name
                        : `Opportunity: ${crmField.name}`,
                    cell: ({ row }) => { var _a; return (_a = row.original.opportunity_fields) === null || _a === void 0 ? void 0 : _a[`z${crmField.id.toString()}`]; },
                });
            }
            if (crmField.applicable_objects.includes(CrmObjectTypeEnum.LEAD)) {
                leadColumns.push({
                    id: `lead_fields__z${crmField.id.toString()}`,
                    accessorKey: `lead_fields__z${crmField.id.toString()}`,
                    header: crmField.name.startsWith("Lead:")
                        ? crmField.name
                        : `Lead: ${crmField.name}`,
                    cell: ({ row }) => { var _a; return (_a = row.original.lead_fields) === null || _a === void 0 ? void 0 : _a[`z${crmField.id.toString()}`]; },
                });
            }
        });
        if (!(tenant === null || tenant === void 0 ? void 0 : tenant.connected_crm)) {
            return [...accountColumns, ...opportunityColumns];
        }
        return [
            {
                id: "external_account_id",
                accessorKey: "external_account_id",
                header: "CRM: Account",
                cell: ({ row }) => {
                    if (tenant.connected_crm && row.original.external_account_id) {
                        return (_jsx("div", Object.assign({ className: "link-styles" }, { children: _jsxs("a", Object.assign({ href: getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], CrmObjectTypeEnum.ACCOUNT, row.original.external_account_id), target: "_blank", rel: "noopener noreferrer", className: "flex flex-row" }, { children: [_jsx("img", { className: "h-5 w-5 mr-2", src: getConnectedCrmLogo(tenant.connected_crm), alt: tenant.connected_crm }), row.original.account_name] })) })));
                    }
                    return null;
                },
                accessorFn: (row) => row.external_account_id,
            },
            {
                id: "external_opportunity_id",
                accessorKey: "external_opportunity_id",
                header: "CRM: Opportunity",
                cell: ({ row }) => {
                    if (tenant.connected_crm && row.original.external_opportunity_id) {
                        return (_jsx("div", Object.assign({ className: "link-styles" }, { children: _jsxs("a", Object.assign({ href: getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], CrmObjectTypeEnum.OPPORTUNITY, row.original.external_opportunity_id), target: "_blank", rel: "noopener noreferrer", className: "flex flex-row" }, { children: [_jsx("img", { className: "h-5 w-5 mr-2", src: getConnectedCrmLogo(tenant.connected_crm), alt: tenant.connected_crm }), row.original.opportunity_name] })) })));
                    }
                    return null;
                },
                accessorFn: (row) => row.external_opportunity_id,
            },
            {
                id: "external_lead_id",
                accessorKey: "external_lead_id",
                header: "CRM: Lead",
                cell: ({ row }) => {
                    if (tenant.connected_crm && row.original.external_lead_id) {
                        return (_jsx("div", Object.assign({ className: "link-styles" }, { children: _jsxs("a", Object.assign({ href: getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], CrmObjectTypeEnum.LEAD, row.original.external_lead_id), target: "_blank", rel: "noopener noreferrer", className: "flex flex-row" }, { children: [_jsx("img", { className: "h-5 w-5 mr-2", src: getConnectedCrmLogo(tenant.connected_crm), alt: tenant.connected_crm }), row.original.lead_name] })) })));
                    }
                    return null;
                },
                accessorFn: (row) => row.external_lead_id,
            },
            ...accountColumns,
            ...opportunityColumns,
            ...leadColumns,
        ];
    };
    const playbookTrackerColumns = newPlaybookItems === null || newPlaybookItems === void 0 ? void 0 : newPlaybookItems.map((playbookItemGroup) => {
        return {
            id: playbookItemGroup.default_item.name,
            accessorKey: playbookItemGroup.default_item.name,
            header: playbookItemGroup.default_item.name,
            cell: ({ row }) => {
                var _a;
                const playbookItemNotes = row.original.playbook_data;
                const filteredNotes = JSON.parse((playbookItemNotes === null || playbookItemNotes === void 0 ? void 0 : playbookItemNotes[playbookItemGroup.default_item.name]) || "[]");
                const res = (_a = filteredNotes
                    .flat()) === null || _a === void 0 ? void 0 : _a.map((note) => note.content).join("");
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx(ReactMarkdown, { children: convertHeadersToBold(res) }) })) })), _jsx(TooltipContent, { children: _jsx(ReactMarkdown, { children: res }) })] }) }));
            },
        };
    });
    const trackerBrickColumns = newTrackerBricks === null || newTrackerBricks === void 0 ? void 0 : newTrackerBricks.map((trackerBrick) => {
        return {
            id: trackerBrick.name,
            accessorKey: trackerBrick.name,
            header: trackerBrick.name,
            meta: {
                brickId: trackerBrick.id,
                brickName: trackerBrick.name,
                brickType: trackerBrick.research_block_type,
            },
            cell: ({ row }) => {
                const trackerNotes = row.original.playbook_data;
                const res = (trackerNotes === null || trackerNotes === void 0 ? void 0 : trackerNotes[trackerBrick.name]) || "";
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx(ReactMarkdown, { children: convertHeadersToBold(res) }) })) })), _jsx(TooltipContent, { children: _jsx(ReactMarkdown, { children: res }) })] }) }));
            },
        };
    });
    const structuredOutputColumnDefs = structuredOutputColumns.map((column) => {
        const columnName = Object.keys(column)[0];
        return {
            id: columnName,
            accessorKey: columnName,
            header: columnName,
            cell: ({ row }) => {
                var _a;
                const res = ((_a = row.original.call_brick_structured_outputs) === null || _a === void 0 ? void 0 : _a[columnName]) || "";
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: res })) })), _jsx(TooltipContent, { children: res })] }) }));
            },
        };
    });
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: "Call Name",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Link, Object.assign({ to: `/calls/${row.original.id}${window.location.search}`, rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: row.getValue("name") }) })) })) })), _jsx(TooltipContent, { children: row.getValue("name") })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "call_type",
            accessorKey: "call_type",
            header: "Call type",
            cell: ({ row }) => {
                const call_type = ["Internal", "External"].find((call_type) => call_type === row.original.call_type);
                return (_jsx("div", Object.assign({ className: "flex space-x-2" }, { children: call_type && _jsx(Badge, Object.assign({ variant: "outline" }, { children: call_type })) })));
            },
            accessorFn: (row) => row.call_type,
        },
        {
            id: "wiser_joined",
            accessorKey: "wiser_joined",
            header: "Wiser joined",
            cell: ({ row }) => {
                if (!row.original.wiser_joined)
                    return null;
                return (_jsxs("div", Object.assign({ className: "flex w-[6.25rem] items-center" }, { children: [_jsx(CheckCircleIcon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "capitalize" }, { children: "Recorded" }))] })));
            },
            filterFn: (row, id, value) => {
                return Array.isArray(value) && value.includes(row.getValue(id));
            },
            accessorFn: (row) => row.wiser_joined,
        },
        {
            id: "call_state",
            accessorKey: "call_state",
            header: "Call state",
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: getUserReadableStatus(cell.getValue(), "Predates status tracking", "Recording Successful") })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: getUserReadableStatus(cell.getValue(), "Predates status tracking", "Recording Successful") })) })] }) })),
            accessorFn: (row) => row.call_state,
        },
        {
            id: "call_time",
            accessorKey: "call_time",
            header: "Call time (local)",
            cell: ({ row, cell }) => (_jsx("div", Object.assign({ className: "w-[150px]" }, { children: _jsx(CallTimeDisplay, { call: row.original, showDate: true, showCalendarIcon: false }) }))),
            accessorFn: (row) => row.call_time,
        },
        {
            id: "playbook_content",
            accessorKey: "playbook_content",
            header: "Playbook content",
            cell: ({ cell }) => (_jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#trackers`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: "View tracker notes" }) })) }))),
            enableHiding: false,
        },
        {
            id: "playbook_items",
            accessorKey: "playbook_items",
            header: "Tracker notes",
            // This cell is rendered as a collapsed JSON. Once design mocks are there rework on this.
            cell: ({ cell }) => {
                var _a;
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#trackers`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(Button, Object.assign({ variant: "link", size: "sm" }, { children: "View tracker notes" })) })) })), _jsx(TooltipContent, { children: _jsx("ul", { children: (_a = cell.getValue()) === null || _a === void 0 ? void 0 : _a.map((playbookItemName) => (_jsx("li", { children: playbookItemName }, playbookItemName))) }) })] }) }));
            },
            accessorFn: (row) => row.playbook_items,
        },
        {
            id: "description",
            accessorKey: "description",
            header: "Description",
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: cell.getValue(),
                                    } }) })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: cell.getValue(),
                                    } }) })) })] }) })),
            accessorFn: (row) => row.description,
        },
        {
            id: "call_source",
            accessorKey: "call_source",
            header: "Call source",
            cell: ({ row }) => (_jsxs("div", Object.assign({ className: "flex justify-start items-center w-full text-wds-gray-6 text-sm" }, { children: [_jsx("img", { src: IMAGE_SRCS.get(getMeetingTypeEnumFromCallSource(row.original.call_source)), className: "h-4 w-4 mx-2", alt: "call source" }), row.original.call_source] }))),
        },
        {
            id: "participants",
            accessorKey: "participants",
            header: "Participants",
            cell: ({ row }) => {
                var _a, _b;
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: (_a = row.original.participants) === null || _a === void 0 ? void 0 : _a.filter((participant) => participant !== row.original.host_email).map((participant) => (_jsx("div", { children: participant }, participant))) })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: (_b = row.original.participants) === null || _b === void 0 ? void 0 : _b.filter((participant) => participant !== row.original.host_email).map((participant) => (_jsx("div", { children: participant }, participant))) })) })] }) }));
            },
            accessorFn: (row) => row.description,
        },
        {
            id: "call_duration_minutes",
            accessorKey: "call_duration_minutes",
            header: "Call duration minutes",
            cell: ({ cell }) => {
                // We don't want to show decimals here. The value is often stored as a string,
                // so we need to parse it into a number first.
                const value = cell.getValue();
                if (value === null || value === undefined)
                    return "";
                if (typeof value !== "number" && typeof value !== "string")
                    return "";
                const numValue = typeof value === "string" ? Number.parseFloat(value) : value;
                if (Number.isNaN(numValue))
                    return "";
                return Math.round(numValue).toLocaleString();
            },
        },
        {
            id: "post_call_all_slack_items_sent",
            accessorKey: "post_call_all_slack_items_sent",
            header: "All slack items sent?",
            cell: ({ cell }) => (cell.getValue() ? "Yes" : "No"),
        },
        {
            id: "post_call_any_slack_item_sent",
            accessorKey: "post_call_any_slack_item_sent",
            header: "Any slack item sent?",
            cell: ({ cell }) => (cell.getValue() ? "Yes" : "No"),
        },
        {
            id: "post_call_crm_updates_mapped",
            accessorKey: "post_call_crm_updates_mapped",
            header: "CRM updates mapped",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            id: "post_call_crm_updates_pushed",
            accessorKey: "post_call_crm_updates_pushed",
            header: "CRM updates processed",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            id: "meeting_host",
            accessorKey: "meeting_host",
            header: "Meeting host",
            cell: ({ row }) => { var _a; return (_a = row.original.host_email) !== null && _a !== void 0 ? _a : row.original.meeting_host; },
        },
        {
            id: "crm_owner_name",
            accessorKey: "crm_owner_name",
            header: "CRM Owner Name",
            cell: ({ row }) => row.original.crm_owner_name,
        },
        {
            id: "account_name",
            accessorKey: "account_name",
            header: "Account",
            cell: ({ cell, row }) => (_jsxs("div", Object.assign({ className: "flex gap-1 items-center justify-center pr-2" }, { children: [_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                            columnId: "account",
                            objectsFetcher: (userQuery, ids, cursor) => __awaiter(this, void 0, void 0, function* () {
                                return yield accountsFetcher(userQuery, 
                                /* ids= */ undefined, cursor, queryClient);
                            }),
                        }, selectedOption: row.original.account_id && row.original.account_name
                            ? {
                                label: row.original.account_name,
                                value: row.original.account_id.toString(),
                            }
                            : undefined, onSelectedOptionChange: (option) => __awaiter(this, void 0, void 0, function* () {
                            yield setCallAccountAndOpportunity(row.original.id, Number(option.value));
                            if (queryClient) {
                                queryClient.invalidateQueries({
                                    queryKey: ["callsTable", filterQuery, 50],
                                });
                            }
                        }) }), row.original.account_id && (_jsx(Link, Object.assign({ to: `${window.location.pathname}/account/${row.original.account_id}${window.location.search}` }, { children: _jsx(Button, Object.assign({ variant: "ghost", className: "group flex h-8 w-8 hover:bg-wds-gray-2 rounded-full text-wds-blue-3 hover:text-wds-blue-5 translate-y-0.5" }, { children: _jsx(ArrowTopRightOnSquareIcon, { className: "w-5 h-5 flex-shrink-0" }) })) })))] }))),
            accessorFn: (row) => row.account_name,
        },
        {
            id: "opportunity_name",
            accessorKey: "opportunity_name",
            header: "Opportunity",
            cell: ({ cell, row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center pr-2" }, { children: _jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                        columnId: "opportunity",
                        objectsFetcher: (userQuery, ids, cursor) => __awaiter(this, void 0, void 0, function* () {
                            return yield opportunitiesFetcher(userQuery, 
                            /* ids= */ undefined, cursor, queryClient);
                        }),
                    }, selectedOption: row.original.opportunity_id && row.original.opportunity_name
                        ? {
                            label: row.original.opportunity_name,
                            value: row.original.opportunity_id.toString(),
                        }
                        : undefined, onSelectedOptionChange: (option) => __awaiter(this, void 0, void 0, function* () {
                        yield setCallAccountAndOpportunity(row.original.id, 
                        /* accountId= */ undefined, Number(option.value));
                        if (queryClient) {
                            queryClient.invalidateQueries({
                                queryKey: ["callsTable", filterQuery, 50],
                            });
                        }
                    }) }) }))),
            accessorFn: (row) => row.opportunity_name,
        },
        {
            id: "lead_name",
            accessorKey: "lead_name",
            header: "Lead",
            cell: ({ cell, row }) => (_jsx("div", Object.assign({ className: "flex gap-1 items-center justify-center pr-2" }, { children: _jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                        columnId: "lead",
                        objectsFetcher: (userQuery, ids, cursor) => __awaiter(this, void 0, void 0, function* () {
                            return yield leadsFetcher(userQuery, 
                            /* ids= */ undefined, cursor, queryClient);
                        }),
                    }, selectedOption: row.original.lead_id && row.original.lead_name
                        ? {
                            label: row.original.lead_name,
                            value: row.original.lead_id.toString(),
                        }
                        : undefined, onSelectedOptionChange: (option) => __awaiter(this, void 0, void 0, function* () {
                        yield setCallLeadIds(row.original.id, [Number(option.value)]);
                        if (queryClient) {
                            queryClient.invalidateQueries({
                                queryKey: ["callsTable", filterQuery, 50],
                            });
                        }
                    }) }) }))),
            accessorFn: (row) => row.lead_name,
        },
        ...getConnectedCrmColumns(),
        {
            id: "transcripts",
            accessorKey: "transcripts",
            header: "Transcripts",
            cell: ({ cell }) => (_jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#transcript`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: "View transcripts" }) })) }))),
            accessorFn: (row) => row.id,
        },
        ...(playbookTrackerColumns !== null && playbookTrackerColumns !== void 0 ? playbookTrackerColumns : []),
        ...(trackerBrickColumns !== null && trackerBrickColumns !== void 0 ? trackerBrickColumns : []),
        ...(structuredOutputColumnDefs !== null && structuredOutputColumnDefs !== void 0 ? structuredOutputColumnDefs : []),
        {
            id: "salesperson_speech_speed_wpm",
            accessorKey: "salesperson_speech_speed_wpm",
            header: "Sales Team Speech Speed",
            cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value || typeof value !== "number") {
                    return "N/A";
                }
                return `${value.toFixed(2)} WPM`;
            },
        },
        {
            id: "salesperson_talk_time_percent",
            accessorKey: "salesperson_talk_time_percent",
            header: "Sales Team Talk Time Percent",
            cell: ({ cell }) => {
                const value = cell.getValue();
                if (!value || typeof value !== "number") {
                    return "N/A";
                }
                return `${value.toFixed(2)}%`;
            },
        },
        {
            id: "autojoin",
            accessorKey: "autojoin",
            header: "Autojoin",
            cell: ({ cell }) => {
                return renderBoolean(cell.getValue());
            },
        },
        {
            id: "call_summary",
            accessorKey: "call_summary",
            header: "Call Summary",
            cell: ({ cell }) => { var _a; return (_a = cell.getValue()) !== null && _a !== void 0 ? _a : "N/A"; },
        },
        {
            id: "call_keypoints",
            accessorKey: "call_keypoints",
            header: "Call Keypoints",
            cell: ({ cell }) => {
                if (cell.getValue() === undefined || cell.getValue() === null) {
                    return "N/A";
                }
                return cell.getValue().join("\n");
            },
        },
        {
            id: "call_next_steps",
            accessorKey: "call_next_steps",
            header: "Call Next Steps",
            cell: ({ cell }) => {
                if (cell.getValue() === undefined || cell.getValue() === null) {
                    return "N/A";
                }
                return cell.getValue().join("\n");
            },
        },
        {
            id: "managers",
            accessorKey: "manager_names",
            header: "Managers",
            cell: ({ cell }) => {
                if (cell.getValue() === undefined || cell.getValue() === null) {
                    return "N/A";
                }
                const uniqueNames = new Set(cell.getValue());
                return Array.from(uniqueNames).join(", ");
            },
        },
        {
            id: "departments",
            accessorKey: "department_names",
            header: "Departments",
            cell: ({ cell }) => {
                if (cell.getValue() === undefined || cell.getValue() === null) {
                    return "N/A";
                }
                const uniqueNames = new Set(cell.getValue());
                return Array.from(uniqueNames).join(", ");
            },
        },
        {
            id: "teams",
            accessorKey: "team_names",
            header: "Teams",
            cell: ({ cell }) => {
                if (cell.getValue() === undefined || cell.getValue() === null) {
                    return "N/A";
                }
                const uniqueNames = new Set(cell.getValue());
                return Array.from(uniqueNames).join(", ");
            },
        },
    ];
}
export function getColumnIdColumnNameMap() {
    // Returns list of column id and column name
    // Only for those that have "React.Node" type header
    return {
        select: "Select",
        name: "Call Name",
        call_type: "Call type",
        call_time: "Call time",
        wiser_joined: "Wiser joined",
        playbook_items: "Tracker notes",
        account_name: "Account",
        opportunity_name: "Opportunity",
    };
}
